import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@material-ui/icons';
import {
  makeStyles, Button, FormControlLabel, Switch, Typography,
} from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import ProfileSelectInput from '../ProfileSelectInput/ProfileSelectInput';
import styles from './ProfileList.styles';

const useStyles = makeStyles(styles, { name: 'ProfileList' });

type LinkType = {
  type: 'link';
  to: string;
};

type SelectType = {
  type: 'select';
  onChange: (value: string) => void;
  options: { value: string; text: string }[];
  value: string;
};

type SwitchType = {
  type: 'switch';
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export type ProfileItem = { 'data-test-id': string; label: string; visible?: boolean } & (LinkType | SelectType | SwitchType);

type Props = {
  items: ProfileItem[];
};

const ProfileList = ({ items }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  if (items.length === 0) {
    return null;
  }

  const renderItem = (item: ProfileItem) => {
    if (item.type === 'link') {
      return (
        <Button
          className={clsx(classes.profileLink, classes.text)}
          classes={{ endIcon: classes.profileLinkEndIcon, label: classes.profileLinkLabel }}
          onClick={() => history.push(item.to)}
          data-test-id={item['data-test-id']}
          endIcon={<KeyboardArrowRightIcon />}
          role="link"
        >
          <Typography className={clsx(classes.formControlLabelLabel, classes.text)}>
            {item.label}
          </Typography>
        </Button>
      );
    }

    if (item.type === 'select') {
      return (
        <ProfileSelectInput
          className={classes.select}
          data-test-id={item['data-test-id']}
          name={item['data-test-id']}
          label={item.label}
          onChange={item.onChange}
          options={item.options}
          value={item.value}
        />
      );
    }

    if (item.type === 'switch') {
      return (
        <FormControlLabel
          className={classes.formControlLabel}
          control={(
            <Switch
              data-test-id={item['data-test-id']}
              checked={item.checked}
              onChange={item.onChange}
            />
        )}
          label={(
            <Typography className={clsx(classes.formControlLabelLabel, classes.text)}>
              {item.label}
            </Typography>
        )}
          labelPlacement="start"
        />
      );
    }

    throw new Error('Unknown item type passed.');
  };

  return (
    <ul className={classes.root}>
      {/* eslint-disable-next-line react/no-array-index-key */}
      {items.filter((item) => item.visible).map((item, idx) => <li key={idx} className={classes.item}>{renderItem(item)}</li>)}
    </ul>
  );
};

export default ProfileList;
