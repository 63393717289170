import { Contact, MyDataContactType } from '@crew-webui/common/consts';

export const getLabel = (type: string, t: T): string => {
  switch (type) {
    case MyDataContactType.PHONE: return t('myData.contactInfo.phoneNumber');
    case MyDataContactType.MOBILE: return t('myData.contactInfo.phoneNumber');
    case MyDataContactType.EMAIL: return t('myData.contactInfo.emailAddress');
    default: return '';
  }
};

export const getSubmitButtonLabel = (newContact: boolean, type: string, t: T): string => {
  if (!newContact) {
    return t('myData.saveChanges');
  }
  switch (type) {
    case MyDataContactType.PHONE: return t('myData.contactInfo.addNewPhoneNumber');
    case MyDataContactType.EMAIL: return t('myData.contactInfo.addNewEmailAddress');
    default: return '';
  }
};

export const getTitle = (contact: Contact | undefined, newContact: boolean, type: string, t: T): string => {
  if (newContact) {
    switch (type) {
      case MyDataContactType.PHONE: return t('myData.contactInfo.newPhoneNumber');
      case MyDataContactType.EMAIL: return t('myData.contactInfo.newEmailAddress');
      default: return '';
    }
  }

  const label = getLabel(type, t);
  if (!contact) {
    return label;
  }

  if (type === MyDataContactType.EMAIL) {
    return `${label}: ${contact.contact}`;
  }

  if (!contact.phoneNumber?.regionCode && !contact.phoneNumber?.areaCode && !contact.phoneNumber?.localNo) {
    return label;
  }
  return contact.phoneNumber ? `${label}: ${contact.phoneNumber?.regionCode} ${contact.phoneNumber?.areaCode} ${contact.phoneNumber?.localNo}` : label;
};
