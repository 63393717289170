import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import { FeatureAccess } from '@crew-webui/common/consts';
import { useFeature, useMultiColumn } from '@crew-webui/common/hooks';
import { OfflineEditInfo } from '@crew-webui/common/view/components';

import { Feature } from 'consts';
import { useDailyRemarkListing } from 'hooks';
import { IadpPage } from 'view/components';
import DailyRemarksSection from './DailyRemarksSection';
import styles from './DailyRemarks.styles';

const useStyles = makeStyles(styles, { name: 'DailyRemarks' });

const DailyRemarks = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    dailyRemarksFromMe, dailyRemarksFromSched, hasLoaded, hasNotification, removeNotification,
  } = useDailyRemarkListing(true);
  const isFeatureEnabled = useFeature();
  const multiColumn = useMultiColumn();

  // remove the notification badge when the DailyRemarks page is shown
  useEffect(() => {
    if (hasNotification) {
      removeNotification();
    }
  }, [hasNotification, removeNotification]);

  return (
    <IadpPage
      contentClass={clsx(classes.root, { [classes.multiColumn]: multiColumn })}
      headerProps={{ title: t('dailyRemarks.title') }}
      dataLoaded={hasLoaded}
    >
      {isFeatureEnabled(Feature.VIEW_DAILY_REMARKS, FeatureAccess.WRITE) && (
        <OfflineEditInfo />
      )}
      <DailyRemarksSection
        hasLoaded={hasLoaded}
        items={dailyRemarksFromSched}
        subtitle={t('dailyRemarks.subtitle')}
      />
      {isFeatureEnabled(Feature.VIEW_DAILY_REMARKS, FeatureAccess.WRITE) && (
        <DailyRemarksSection
          byCrewMember
          hasLoaded={hasLoaded}
          items={dailyRemarksFromMe}
          subtitle={t('dailyRemarks.remarksFromMe')}
        />
      )}
    </IadpPage>
  );
};

export default DailyRemarks;
