import {
  Delete,
  ExitToApp,
  ExpandMore,
  Feedback,
  Info,
  Warning,
} from '@material-ui/icons';

import { AvailableLanguages, MyDataCategory, MyDataTravelDocType } from '@crew-webui/common/consts';
import { VacIcon, SbyIcon, SickIcon } from '@crew-webui/common/view/components/icons';

import {
  CheckInTitle,
  CustomerProfile,
  PersonalDataFieldPaths,
  ProfileFeature,
  SelectionMode,
  CountryIssueDefaultValues,
} from 'consts';
import defaultCommonProfileSettings from './defaultProfile';

const btiProfileSettings: CustomerProfile = {
  ...defaultCommonProfileSettings,
  // based on the advancedEu profile
  enablings: {
    [ProfileFeature.VIEW_BLOCKMONTH_SPECIFIC_DATA]: false,
    // BASIC package parameters
    [ProfileFeature.VIEW_LEG]: true,
    [ProfileFeature.VIEW_DEADHEAD]: true,
    [ProfileFeature.VIEW_CHECK_IN]: true,
    [ProfileFeature.PERFORM_CHECK_IN]: true,
    [ProfileFeature.VIEW_NOTIFICATIONS]: true,
    [ProfileFeature.VIEW_PAIRING]: true,
    [ProfileFeature.VIEW_SUMMARY]: true,
    [ProfileFeature.VIEW_BLOCKMONTH_SWITCH_ON_SUMMARY]: true,

    // ADVANCED package parameters
    [ProfileFeature.VIEW_HOTEL]: true,
    [ProfileFeature.PERFORM_ROLE_EDITING]: false,
    [ProfileFeature.VIEW_ROSTER_TAGS_CALENDAR_FILTER]: true,

  },

  // BASIC
  logo: '',
  // US/EU specific setting: pairing number is not used in EU
  showPairingNumber: false,
  showHomeBase: true,
  showAircraftTypeCodeOfPairing: true,
  showRank: true,
  showCookieBanner: true,
  showUTCTime: true,
  showLocalTime: false,
  localTimePostfix: 'LT',
  utcTimePostfix: 'Z',
  cacheBeforeDaysNo: 30,
  cacheAfterDaysNo: 30,
  cacheFlightInfoDaysNo: 2,
  printCacheSize: true,
  // US/EU specific parameter. By default set to false for US customers and set to true for EU customers
  calendarWeekStartMonday: true,
  showRosterChanges: true,
  showLargeSuspIcon: true,
  showGroundActivityCodeInCalendar: false,
  showCalendarAfterLogin: true,
  autoRefreshEnabledByDefault: true,
  showBlockMonthCode: true,
  showBlockMonthInterval: false,
  showAircraftTypeCodeOfLeg: true,
  showAircraftSubType: true,
  showFinNumber: false,
  showGateInfo: false,
  showRegistration: true,
  showArrivalAirportOfPairing: true,
  showPairingClassification: true,
  flightDetailsEncollapsed: false,
  icons: {
    ...defaultCommonProfileSettings.icons,
    SICK: SickIcon,
    SBY: SbyIcon,
    VAC: VacIcon,
  },
  buttonIcons: {
    ExpandMore,
    Info,
  },
  newsListing: {
    checkIn: {
      Icon: ExitToApp,
      color: '#60AE60',
    },
    rosterChange: {
      Icon: Warning,
      color: '#FFC901',
    },
    message: {
      Icon: Feedback,
      color: '#177FD2',
    },
    delete: {
      Icon: Delete,
    },
  },
  typeCodesCalendar: {},
  typeCodesDutyList: {
    GROUND: 'GRND',
    FPR: 'DH',
  },
  // EU specific time formats
  dateFormats: {
    DATE: 'dd MMM yyyy',
    SHORT_DATE: 'ddMMM',
    SHORT_DATE_SEPARATED: 'dd MMM',
    TIME: 'HH:mm',
    DATE_TIME: 'dd MMM yyyy HH:mm',
    SHORT_DATE_TIME: 'dd/MM HH:mm',
  },
  notificationConfirmationSelectionMode: SelectionMode.ALL,
  checkInTitle: CheckInTitle.SHOW_ONLY_PAIRING_NUMBER,
  disabledHotelFields: [],
  hiddenHeadersIfNoData: [],
  countryIssueDefaultValue: CountryIssueDefaultValues.NONE,
  preDefinedPhoneValue: '+',
  phoneTypeCodes: ['Default', 'Cell', 'Home', 'Landline'],
  disabledPersonalDataFields: [
    PersonalDataFieldPaths.birthCountry,
    PersonalDataFieldPaths.birthDate,
    PersonalDataFieldPaths.birthPlace,
    PersonalDataFieldPaths.birthState,
    PersonalDataFieldPaths.firstName,
    PersonalDataFieldPaths.lastName,
    PersonalDataFieldPaths.middleName,
    PersonalDataFieldPaths.gender,
    PersonalDataFieldPaths.isSmoker,
    PersonalDataFieldPaths.nationality,
  ],
  enabledTravelDocTypes: [
    MyDataTravelDocType.PASSPORT,
  ],
  readonlyMyDataCategories: [
    MyDataCategory.PERSONAL,
    MyDataCategory.CONTACT,
    MyDataCategory.ADDRESS,
    MyDataCategory.TRAVEL,
    MyDataCategory.VISA,
    MyDataCategory.PASSPORT,
    MyDataCategory.ALIEN_RESIDENT_NUMBER,
    MyDataCategory.IDENTITY,
    MyDataCategory.DRIVER_LICENCE,
    MyDataCategory.RAIC,
  ],
  availableLanguages: [AvailableLanguages.EN, AvailableLanguages.FR],
};

export default btiProfileSettings;
