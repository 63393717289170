import { useState, useCallback, useMemo } from 'react';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import { useTranslation } from 'react-i18next';
import {
  Route, Switch, useLocation,
} from 'react-router-dom';
import { services, useIadpParams } from '@crew-webui/common/api';
import { Event } from '@crew-webui/common/consts/dutiesTypes';
import { EventContext, FOREIGN_LEG_SEARCH_PARAM } from '@crew-webui/common/consts';
import {
  useConnection, useDomain, useEventDetails, useFeature, useOnlineAuthorized, useDutiesModel,
} from '@crew-webui/common/hooks';
import {
  MobileHeader, OfflineEditInfo, OfflineInfo, LegDetailsContent, Page,
} from '@crew-webui/common/view/components';

import { Feature, routePaths } from 'consts';
import { IadpPage } from 'view/components';
import EditRoles from './EditRoles';
import { RoleType } from './roleTypes';
import styles from './LegDetails.styles';

export const getSubtitleText = (event: Event | undefined, t: T) => {
  const eventDescription = event?.eventDescription;
  const flightNumber = eventDescription?.flightNumber;
  const fromDate = event?.fromDate;
  const fromAirport = event?.fromAirport;
  const toAirport = event?.toAirport;
  const flightSuffix = eventDescription?.flightSuffix;

  return flightNumber && fromDate && fromAirport && toAirport ?
    t('duties.legDetails.subtitle', {
      fromDate,
      flightNumber,
      flightSuffix: flightSuffix || '',
      fromAirport,
      toAirport,
    }) :
    '';
};

const useStyles = makeStyles(styles, { name: 'LegDetails' });

const LegDetails = ({ history }: { history: any }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { context, crmId, logicalId } = useIadpParams<{ context: EventContext; crmId: string; logicalId: string }>();
  const { search } = useLocation();
  const { pemsDomain } = useDomain();
  const { buddyMode } = useDutiesModel();

  const logicalEventId = decodeURIComponent(logicalId);
  const [crewMembers, setCrewMembers] = useState<any>({});

  const { eventDetails, fetchEventDetails } = useEventDetails();
  const { online } = useConnection();
  const [availableRoles, setAvailableRoles] = useState<RoleType[]>([]);

  const event = eventDetails[logicalEventId];
  const dataLoaded = !!event;
  const legId = event?.id;
  const roles = event?.roles;

  const isFeatureEnabled = useFeature();

  const subtitleText = useMemo(() => getSubtitleText(event, t), [event, t]);
  const legSubtitle = subtitleText && <Page.Title className={classes.subtitle}>{subtitleText}</Page.Title>;
  const canEditRoles = isFeatureEnabled(Feature.PERFORM_ROLE_EDITING);
  const isForeign = (new URLSearchParams(search).get(FOREIGN_LEG_SEARCH_PARAM)) === 'true';

  const navigateTo = useCallback((to: string) => () => {
    const { pathname } = history.location;

    history.push(`${pathname}/${to}`);
  }, [history]);

  useOnlineAuthorized(() => {
    fetchEventDetails({ context, logicalEventId });
  }, [context, logicalEventId]);

  useOnlineAuthorized(() => {
    if (!legId || isForeign) {
      return;
    }

    const srv = buddyMode ? services.getBuddyLegCrewMembers : services.getLegCrewMembers;

    srv({
      crmId,
      legId,
      pemsDomain,
      ...(!!buddyMode && { crmId: buddyMode.buddyCrmId, buddyCrmId: crmId }),
    })
      .then(({ data }) => {
        const { success, result } = data || {};

        if (success) {
          setCrewMembers(result);
        }
      });

    if (canEditRoles && !buddyMode && !isForeign) {
      services
        .getRoleInfo({ crmId, legId })
        .then(({ data: { success, result } }) => {
          if (success) {
            setAvailableRoles(result);
          }
        });
    }
  }, [legId, crmId, canEditRoles, t]);

  const editableRoles = availableRoles.filter((availableRole) => availableRole.updateByCrewMember === true);
  const showEditRoleButton = !!editableRoles.length && canEditRoles;
  const assignedRoles = useMemo(() => (roles ? roles.map((role) => ({ id: role.id, shortName: role.name })) : []), [roles]);
  const showAdditionalFlightDetails = useMemo(
    () => isFeatureEnabled(Feature.OPERATIONAL_AND_FLIGHT_INFORMATION_ENABLED) && !buddyMode,
    [buddyMode, isFeatureEnabled],
  );

  if (!online && !event?.id) {
    return (
      <div className={classes.crewOnLeg}>
        <MobileHeader title={t('duties.legDetails.title')} showBackButton />
        <OfflineInfo visible>
          &nbsp;
        </OfflineInfo>
      </div>
    );
  }

  return (
    <Switch>
      <Route
        exact
        path={`${routePaths.leg}/:context/:crmId/:logicalId`}
        render={() => (
          <IadpPage
            contentClass={classes.content}
            dataLoaded={!!legId}
            headerProps={{ title: t('duties.main.title'), subtitle: t('duties.legDetails.title'), showBackButton: true }}
          >
            <div className={classes.crewOnLeg}>
              {legSubtitle}
              <OfflineEditInfo />
              {dataLoaded && (
                <LegDetailsContent
                  crewMembers={crewMembers}
                  event={event}
                  editRoles={navigateTo('edit-roles')}
                  eventRemarkEnabled={isFeatureEnabled(Feature.VIEW_EVENT_REMARKS)}
                  showAdditionalFlightDetails={showAdditionalFlightDetails}
                  online={online}
                  showEditRoleButton={showEditRoleButton}
                  displayAdditionalFlightDetails={navigateTo('additional-flight-details')}
                  isForeign={isForeign}
                />
              )}
            </div>
          </IadpPage>
        )}
      />
      <Route
        exact
        path={`${routePaths.leg}/:context/:crmId/:logicalId/edit-roles`}
        render={() => (
          <EditRoles
            assignedRoles={assignedRoles}
            availableRoles={availableRoles}
            history={history}
            subtitle={legSubtitle}
          />
        )}
      />
    </Switch>
  );
};

export default LegDetails;
