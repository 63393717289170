import {
  useCallback, useContext, useMemo, useState,
} from 'react';

import FlightSearchContext from './FlightSearchContext';

const FlightSearchProvider = ({ children }: { children: React.ReactNode }) => {
  const [flightSearchFormData, setFlightSearchFormData] = useState({});

  const editFlightSearchFormData = useCallback((data: any) => {
    setFlightSearchFormData(data);
  }, []);

  // eslint-disable-next-line arrow-body-style
  const getFlightSearchFormData = useCallback(() => {
    return flightSearchFormData;
  }, [flightSearchFormData]);

  const ctx = useMemo(() => ({
    editFlightSearchFormData,
    getFlightSearchFormData,
  }), [editFlightSearchFormData, getFlightSearchFormData]);

  return <FlightSearchContext.Provider value={ctx}>{children}</FlightSearchContext.Provider>;
};

export const useFlightSearch = () => useContext(FlightSearchContext);

export default FlightSearchProvider;
