import { useCallback, useMemo, useState } from 'react';

import { useDutiesPresentation } from '../../../hooks';

const useDutyRowExpanded = (handleLocally: boolean, eventId: number, detailView: boolean) => {
  const { openedEventBlocks, toggleEventBlock } = useDutiesPresentation();

  const [expandedLocally, setExpandedLocally] = useState(false);

  const expanded = handleLocally ? expandedLocally : !!(openedEventBlocks.includes(eventId) || detailView);

  const toggleExpanded = useCallback(() => {
    if (handleLocally) {
      setExpandedLocally((prevState) => !prevState);
    } else {
      toggleEventBlock(eventId);
    }
  }, [eventId, handleLocally, toggleEventBlock]);

  return useMemo(() => ({ expanded, toggleExpanded }), [expanded, toggleExpanded]);
};

export default useDutyRowExpanded;
