import React, { createContext } from 'react';

type PageRememberContextType = {
  clearData: (name: string) => void;
  getData: (name: string, defaultValue?: any) => any;
  storeData: (name: string, data: any) => void;
  storeScroll: (name: string, reactRef: React.RefObject<any>) => Function | undefined;
  restoreScroll: (name: string, reactRef: React.RefObject<any>) => void;
};

const defaultValue = {
  clearData: () => {},
  getData: () => {},
  storeData: () => {},
  storeScroll: () => undefined,
  restoreScroll: () => {},
};

const PageRememberContext = createContext<PageRememberContextType>(defaultValue);

export default PageRememberContext;
