// eslint-disable-next-line max-classes-per-file
import { useCallback, useMemo } from 'react';
import { DatePickerProps } from '@material-ui/pickers';
import { DatePicker as MuiDatePicker, useUtils } from '@lsy-netline-ui/netline-ui/pickers';
import { useTranslation } from 'react-i18next';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { useProfile } from '@crew-webui/common/hooks';

const MuiDatePickerNonTypeSafe: any = MuiDatePicker;

type Props = Omit<DatePickerProps, 'onChange'> & {
  classes?: Record<string, any>;
  initialFocusedDate?: string;
  maxDate?: string;
  minDate?: string;
  onChange: (value: string) => void;
  value?: string | null | Date;
};

export const DATE_FORMAT = 'yyyy-MM-dd';

const DatePicker = ({
  initialFocusedDate, maxDate, minDate, onChange, value, ...otherProps
}: Props) => {
  const { profile: { dateFormats } } = useProfile();
  const { t } = useTranslation();
  const utils = useUtils();

  const dateValue = useMemo(() => {
    if (value && (typeof value === 'string' || value instanceof String)) {
      return utils.parse(value as string, DATE_FORMAT);
    }
    return value;
  }, [value, utils]);

  const handleChange = useCallback((dateObj: MaterialUiPickersDate) => {
    onChange(utils.format(dateObj, DATE_FORMAT));
  }, [onChange, utils]);

  return (
    <MuiDatePickerNonTypeSafe
      autoOk
      disableToolbar={false}
      format={dateFormats.DATE}
      initialFocusedDate={initialFocusedDate && utils.parse(initialFocusedDate, DATE_FORMAT)}
      maxDate={maxDate && utils.parse(maxDate, DATE_FORMAT)}
      minDate={minDate && utils.parse(minDate, DATE_FORMAT)}
      onChange={handleChange}
      value={dateValue}
      variant="dialog"
      cancelLabel={t('common.cancel')}
      {...otherProps}
    />
  );
};

export default DatePicker;
