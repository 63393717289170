import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useDutiesModel } from '@crew-webui/common/hooks';

interface LocationState {
  loadDuties: any;
}

const useGetEventsWhenMounted = () => {
  const { state: { loadDuties: confirmResult } = {} } = useLocation<LocationState>();
  const { confirmDuties, loadDuties } = useDutiesModel();

  useEffect(() => {
    if (confirmResult) {
      confirmDuties(confirmResult);
    } else {
      loadDuties();
    }
  }, [confirmDuties, confirmResult, loadDuties]);
};

export default useGetEventsWhenMounted;
