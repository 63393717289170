const TITLE = process.env.REACT_APP_TITLE;

const useAppframeServices = ({ services, setTitle } : SpaModuleProps) => {
  if (services) {
    // use netline appframe
    services.appFrame.setTitle(`${TITLE}`);
    services.appFrame.header?.removeItem(['AppFrame-PreferencesMenu']);
  } else if (setTitle) {
    // use CSI appframe
    setTitle(`${TITLE}`);
  }

  return services;
};

export default useAppframeServices;
