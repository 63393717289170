import { isArray, isPlainObject, includes } from 'lodash';
import axios, { AxiosError } from 'axios';

import axiosRetry from 'axios-retry';

import {
  Service, ServiceMap, ServiceConfig, ParamTypes,
} from './api-helper';
import { SmartPunctationSymbols } from '../consts';

const AXIOS_TIMEOUT = 60000;
const RETRY_STATUSES = [408, 500, 502, 503, 504, 522, 524];

/**
 * Transforms a map of parameters to a ? and & separated URI encoded query string.
 *
 * @param queryParams Map of parameters and values which needs to be transformed.
 */
const encodeQueryParams = (queryParams?: Record<string, any>): string => {
  if (!queryParams) {
    return '';
  }

  const paramList = Object.keys(queryParams).reduce((acc: string[], name) => {
    const value = queryParams[name];
    const encodedName = encodeURIComponent(name);

    if (isArray(value)) {
      return [...acc, ...value.map((item) => `${encodedName}=${encodeURIComponent(item)}`)];
    }

    return [...acc, `${encodedName}=${encodeURIComponent(value)}`];
  }, []);

  return paramList.length ? `?${paramList.join('&')}` : '';
};

/**
 * Replaces parameters with values in a URL with URI encoding.
 *
 * @param url    The URL which contains parameter variables in a curly brace (eg. /rest/foo/{fooId}/bar/{barId})
 * @param params Map of parameters and values which needs to be replaced.
 * @returns      The resolved URL which contains the replaced parameters.
 */
export const replaceUrlParams = (url: string, params?: Record<string, any>) => Object.keys(params || {})
  .reduce((acc: string, key) => {
    const regexp = new RegExp(`\\{${key}\\}`, 'g');
    return acc.replace(regexp, encodeURIComponent((params as Record<string, any>)[key]));
  }, url);

/**
 * Replace smart punctation symbols in string
 */
export const transformSmartPunctationStr = (str: string) => {
  let result = str;
  Object.entries(SmartPunctationSymbols).forEach(([from, to]) => {
    result = result.replace(new RegExp(from, 'g'), to);
  });
  return result;
};

/**
 * Replace smart punctation symbols
 */
export const transformSmartPunctation = (data: any) => {
  if (typeof data === 'object' && data !== null) {
    const newData: any = Array.isArray(data) ? [] : {};
    Object.keys(data).forEach((fieldName) => {
      // eslint-disable-next-line no-param-reassign
      newData[fieldName] = transformSmartPunctation(data[fieldName]);
    });
    return newData;
  }
  if (typeof data === 'string') {
    return transformSmartPunctationStr(data);
  }
  return data;
};

export const processParam = (
  result: { urlParams?: object; queryParams?: object; data?: object; headers?: object },
  paramTypes: Record<string, ParamTypes>,
  paramName: string,
  param: string | object,
): object => {
  const {
    urlParams = {}, queryParams = {}, data = {}, headers = {},
  } = result;

  const paramType = paramTypes[paramName] || 'url';

  // transform iOS Smart Punctations
  const transformedParam = transformSmartPunctation(param);

  let newData = data;
  if (paramType === 'data') {
    // inject the content of param into data
    if (isArray(transformedParam)) {
      newData = transformedParam;
    } else {
      newData = { ...data, ...transformedParam };
    }
  } else if (paramType === 'body') {
    // inject param with its name into data
    newData = { ...data, [paramName]: transformedParam };
  } else if (paramType === 'url' || paramType === 'query') {
    // in case of url and query parameters we calculate an object which will be merged to the parameter list
    newData = isPlainObject(transformedParam) ? transformedParam : { [paramName]: transformedParam };
  } else if (paramType === 'header') {
    newData = { [paramName]: `${transformedParam}` };
  }

  return {
    urlParams: paramType === 'url' ? { ...urlParams, ...newData } : urlParams,
    queryParams: paramType === 'query' ? { ...queryParams, ...newData } : queryParams,
    data: paramType === 'body' || paramType === 'data' ? newData : data,
    headers: paramType === 'header' ? { ...headers, ...newData } : headers,
  };
};

/**
 * Convert service option objects to API requesting functions
 *
 * @param {Object}  config              The service configuration object which will describe the service call behaviour.
 * @param {string}  [config.method=get] The HTTP method which the service will be invoked.
 * @param {string}  config.url          URL part concatenated after base url (e.g. /employee/{id}/group/{group_id}).
 * @param {?Object} config.paramTypes   Should contain properties with string values
 *                                          the key is the name of the param, the value is the type, possible values are query, body, data
 *                                          params with query param type aren't required to list here
 * @returns {Function}
 */
const createService = (config: ServiceConfig): Service => (params?: { [paramName: string]: any }, extraConfig?: object) => {
  const method = (config.method || 'get').toLowerCase();
  const {
    urlParams, queryParams, data, headers,
  } = Object.keys(params || {}).reduce(
    (result: any, paramName) => processParam(
      result,
      config.paramTypes || {},
      paramName,
      (params!)[paramName],
    ),
    {},
  );

  const source = axios.CancelToken.source();
  const req = (axios as any)({
    timeout: AXIOS_TIMEOUT,
    ...config,
    urlPattern: config.url,
    method,
    url: replaceUrlParams(config.url, urlParams) + encodeQueryParams(queryParams),
    data,
    headers,
    withCredentials: true,
    cancelToken: source.token,
    // if false the value of the `error` field of PEMS responses will be handled globally
    handleErrorFieldLocally: false,
    ...extraConfig,
  });

  req.cancel = (reason: any) => source.cancel(reason);
  req.isCancelled = (thrown: any) => axios.isCancel(thrown);
  return req;
};

/**
 * Convert service config objects to API requesting functions
 *
 * @param {Object} servicesCfg - properties are service configs
 * @param {Object} baseCfg - base options for all services, can be overriden in individual services
 */
export default (servicesCfg: { [configName: string]: ServiceConfig }, baseCfg: {}): ServiceMap => {
  axiosRetry(axios, {
    retries: 3,
    retryCondition: (err: AxiosError) => includes(RETRY_STATUSES, err?.response?.status),
    retryDelay: () => 2000,
  });

  const services = Object.keys(servicesCfg).reduce(
    (result, name) => ({
      ...result,
      [name]: createService({ ...(baseCfg || {}), ...servicesCfg[name] }),
    }),
    {},
  );

  return services;
};
