// eslint-disable-next-line max-classes-per-file
import { TimePickerProps } from '@material-ui/pickers';
import { TimePicker as MuiTimePicker } from '@lsy-netline-ui/netline-ui/pickers';

import { useProfile } from '@crew-webui/common/hooks';

const MuiTimePickerNonTypeSafe: any = MuiTimePicker;

type Props = Omit<TimePickerProps, 'onChange'> & {
  classes?: Record<string, any>;
  value?: string | null;
};

const TimePicker = ({
  value, ...otherProps
}: Props) => {
  const { profile: { dateFormats } } = useProfile();

  return (
    <MuiTimePickerNonTypeSafe
      autoOk
      disableToolbar={false}
      format={dateFormats.TIME}
      value={value}
      variant="dialog"
      {...otherProps}
    />
  );
};

export default TimePicker;
