import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  root: {
    padding: 0,
  },
  section: {
    'padding': 16,
    'borderBottom': `1px solid ${palette.iadp.borderColor}`,
    '&:last-of-type': {
      borderBottom: 0,
    },
  },
  sectionTitle: {
    color: palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  roles: {
    display: 'flex',
    flex: '1 1',
    flexDirection: 'column',
  },
  pageTitle: {
    margin: spacing(2),
  },
});
