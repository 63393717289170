import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import {
  MyDataType, MyDataContactType, MyDataPhoneTypes, MyDataCategory,
} from '@crew-webui/common/consts';
import { useMyData, useOnlineAuthorized, useProfile } from '@crew-webui/common/hooks';

import { CategoryType, routePaths } from 'consts';
import { cardCommonProps, getAccess, getUniqueContactId } from 'utils';
import MyDataPage from '../MyDataPage/MyDataPage';
import styles from './Contacts.styles';

const useStyles = makeStyles(styles, { name: 'Contacts' });

const Contacts = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { contacts, loadMyData } = useMyData();
  const { profile: { readonlyMyDataCategories } } = useProfile();

  useOnlineAuthorized(() => {
    loadMyData(MyDataType.CONTACT);
  }, [loadMyData]);

  const contactItems = contacts
    ?.map((contactItem) => {
      const {
        access, contact, natKey, phoneNumber, phoneTypeCode, remark,
      } = contactItem;
      const id = getUniqueContactId(contactItem);
      const remarkText = phoneTypeCode && remark ? ` - ${remark}` : remark;
      const content = phoneNumber ?
        `${phoneNumber.regionCode} ${phoneNumber.areaCode} ${phoneNumber.localNo}` :
        contact;
      const handleClick = () => {
        history.push(`${routePaths.myData}/contacts/${natKey.type}/com.lhsystems.crew.pems:pems-client-web-app:jar:2023.1.0`);
      };

      return {
        id,
        onClick: handleClick,
        type: natKey.type,
        prio: natKey.prio,
        doc: {
          ...cardCommonProps,
          title: getAccess(access, t),
          content,
          footer: <>{phoneTypeCode} <span className={classes.remark}>{remarkText}</span></>,
          rightText: natKey.prio,
        },
      };
    })
    .sort((a, b) => a.prio - b.prio);

  const dataLoaded = !!contacts;

  const categories: CategoryType[] = [
    {
      label: t('myData.contactInfo.phoneNumbers'),
      items: contactItems?.filter(({ type }) => MyDataPhoneTypes.includes(type)),
      newButton: {
        'data-test-id': 'add-phone-button',
        'label': t('myData.contactInfo.phoneNumber'),
        'onClick': () => history.push(`${routePaths.myData}/contacts/${MyDataContactType.PHONE}`),
      },
    },
    {
      label: t('myData.contactInfo.emailAddresses'),
      items: contactItems?.filter(({ type }) => type === MyDataContactType.EMAIL),
      newButton: {
        'data-test-id': 'add-email-button',
        'label': t('myData.contactInfo.emailAddress'),
        'onClick': () => history.push(`${routePaths.myData}/contacts/${MyDataContactType.EMAIL}`),
      },
    },
  ];

  return (
    <MyDataPage
      categories={categories}
      dataLoaded={dataLoaded}
      description={t('myData.contactInfo.description')}
      subtitle={t('myData.contactInfo.title')}
      title={t('myData.contactInfo.title')}
      readonly={readonlyMyDataCategories && readonlyMyDataCategories.includes(MyDataCategory.CONTACT)}
      onBack={() => history.push(`${routePaths.myData}`)}
    />
  );
};

export default Contacts;
