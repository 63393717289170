import en from '../resources/lang/en.json';
import fr from '../resources/lang/fr.json';

export const languages = [
  {
    key: 'en',
    label: 'English',
    file: en,
  },
  {
    key: 'fr',
    label: 'Français',
    file: fr,
  },
];

export const languageFiles = languages.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.file }), {});
