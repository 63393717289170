import {
  isArray, isPlainObject, isString, trimStart, trimEnd,
} from 'lodash';
import { AxiosRequestConfig, AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

import { IMAGE_SOURCES, LocalStorageKeys, PersistTypes } from '../consts';

// cut keycloak state query parameters after '&' if exists
export function useIadpParams<Params extends { [K in keyof Params]?: string } = {}>() {
  const params = useParams();
  Object.keys(params).forEach((k) => {
    const key = k as keyof typeof params;
    const val = params[key] as string;
    const idx = val.indexOf('&');
    if (idx > 0) {
      params[key] = val.substring(0, idx) as never;
    }
  });
  return params as Params;
}

export function useEncodedParams<Params extends { [K in keyof Params]?: string } = {}>() {
  const params = useIadpParams();
  Object.keys(params).forEach((k) => {
    const key = k as keyof typeof params;
    params[key] = encodeURIComponent(decodeURIComponent(params[key])) as never;
  });
  return params as Params;
}

export interface CancelablePromise<T> extends Promise<T> {
  cancel: (reason: string) => void;
  isCancelled: (error: AxiosError) => boolean;
}

export type ParamTypes = 'url' | 'data' | 'body' | 'query' | 'header';

export interface ServiceConfig extends AxiosRequestConfig {
  url: string;
  serverUrl?: string | Function;
  paramTypes?: { [paramName: string]: ParamTypes };
  persistType?: PersistTypes;
  showProgress?: boolean | readonly string[];
  skipRepeated?: boolean;
  ignoreErrors?: boolean;
  cacheWithReqBody?: boolean;
}

export type Service = (params?: { [paramName: string]: any }, extraConfig?: object) => CancelablePromise<any>;

export type ServiceMap = { [configName: string]: Service };

/**
 * Generated message by axios, when cancel the request
 */
const REQUEST_ABORTED = 'Request aborted';

export const PUBLIC_URL = process.env.PUBLIC_URL || '';
const PREFIX_REGEXP_WITHOUT_PATH = /http[s]?:\/\/[^/]*\/(.*)\/[^/]*\/server-config.json/;

export const getUrlWithoutHash = () => window.location.origin + window.location.pathname;

export const reload = () => {
  window.location.href = `${getUrlWithoutHash()}#/`;
  window.location.reload();
};

export const loadConfigByPath = async (path: string) => {
  const responseJson = await fetch(path).then((result) => result.json());
  return responseJson;
};

export const ServerConfigHandler = (() => {
  let possibleServers: ServerConfig[] | undefined;
  let usedServer: ServerConfig | undefined;

  const getServerConfigPathPrefix = (config: ServerConfig, serverConfigUrl: string) => {
    const prePath = trimStart(config.path, '/');
    if (prePath) {
      // find prefix on https://domain/prefix?/$predefinedPath/webAppName/server-config.json
      const pathRegexp = new RegExp(`http[s]?://[^/]*/(.*)/${prePath}/.*`);
      const match = serverConfigUrl.match(pathRegexp);
      return match ? match[1] : '';
    }
    // find prefix on https://domain/prefix?/webAppName/server-config.json
    const match = serverConfigUrl.match(PREFIX_REGEXP_WITHOUT_PATH);
    return match ? match[1] : '';
  };

  const loadServerConfigsJson = async () => {
    const response = await fetch('./server-config.json');
    const responseJson = await response.json();

    const configs = (Array.isArray(responseJson) ? responseJson : [responseJson]) as ServerConfig[];
    if (configs.length === 0) {
      throw new Error('Server config not found');
    }
    const pathPrefix = configs[0].pathPrefix || getServerConfigPathPrefix(configs[0], response.url);
    return pathPrefix ? configs.map((config) => (
      {
        ...config,
        pathPrefix,
      }
    )) : configs;
  };

  const checkInitialized = () => {
    if (!possibleServers) {
      throw new Error('first call initServerConfig');
    }
  };

  const findServer = () => {
    if (possibleServers!.length === 0) {
      throw new Error('No defined server in config');
    }

    const storedServerName = localStorage.getItem(LocalStorageKeys.SERVER_CONFIG);
    if (storedServerName) {
      const stored = possibleServers!.find((s) => s.name === storedServerName);
      if (stored) {
        return stored;
      }
      // not found stored server
      localStorage.removeItem(LocalStorageKeys.SERVER_CONFIG);
    }
    if (possibleServers!.length < 3) {
      // only server and test server defined
      return possibleServers![0];
    }
    return undefined;
  };

  /**
   * Init server configuration by given configuration if using as module or load public/server-config.json
   */
  const initServerConfig = async (configs?: ServerConfig[]) => {
    possibleServers = !configs ? await loadServerConfigsJson() : configs;
    window.IADP_SERVERS = possibleServers;
    usedServer = findServer();
  };

  const onLogin = () => {
    if (!usedServer) {
      throw new Error('Must select server before login');
    }
    localStorage.setItem(LocalStorageKeys.SERVER_CONFIG, usedServer!.name);
  };

  const switchToTestServer = () => {
    localStorage.setItem(LocalStorageKeys.SERVER_CONFIG, possibleServers![possibleServers!.length - 1].name);
    reload();
  };

  const backToDefaultServer = () => {
    localStorage.removeItem(LocalStorageKeys.SERVER_CONFIG);
    reload();
  };

  const onLogout = () => {
  };

  const setUsedServerByName = (serverName: string) => {
    checkInitialized();
    const check = possibleServers!.find((srv) => srv.name === serverName);
    if (!check) {
      throw new Error(`Not found server with name ${serverName}`);
    }
    usedServer = check;
  };

  const getUsedServer = () => usedServer;
  const getPossibleServers = () => possibleServers;

  return {
    backToDefaultServer,
    getUsedServer,
    getPossibleServers,
    initServerConfig,
    onLogin,
    onLogout,
    setUsedServerByName,
    switchToTestServer,
  };
})();

/**
   * This method will parse response data and prepares a message list to be displayed.
   *
   * @param  {String|Object} data The response data which contains the 'messages' property.
   * @return {Array}              An array of message object which can be called.
   */
export const parseApiMessages = (
  data: string | { errState?: any; messages?: any; errorMessages?: any },
  defaultMessage = null,
  messageMap: any = {},
) => {
  if (!data) {
    return [];
  }

  if (isString(data)) {
    return [messageMap[data] || messageMap[defaultMessage as any] || defaultMessage || data];
  }

  const { errMessage } = data?.errState || {};
  if (errMessage) {
    return [messageMap[errMessage] || errMessage];
  }

  const { messages, errorMessages } = data;
  if (isArray(messages)) {
    return messages
      .map((message) => {
        if (isString(message)) {
          return messageMap[message] || message;
        }

        if (isPlainObject(message)) {
          const { params, messageKey } = message;
          const messageText = messageMap[messageKey] || messageKey;

          return messageText.replace(/{(.+?)}/g, ($: any, replaceKey: any) => params[replaceKey] || '');
        }

        return undefined;
      })
      .filter((message) => message);
  }

  if (isArray(errorMessages)) {
    return errorMessages.map((errorMessage) => (isString(errorMessage) ? messageMap[errorMessage] || errorMessage : []));
  }

  return [];
};

export const buildServerUrl = (serverConfig: ServerConfig) => {
  const {
    path, pathPrefix, origin, port,
  } = serverConfig;
  const pathPrefixWithSlash = !pathPrefix || pathPrefix.startsWith('/') ? (pathPrefix || '') : `/${pathPrefix}`;
  const pathWithSlash = path.startsWith('/') ? path : `/${path}`;
  if (origin) {
    return `${origin}${port ? `:${port}` : ''}${pathPrefixWithSlash}${pathWithSlash}`;
  }
  return `${window.location.origin}${pathPrefixWithSlash}${pathWithSlash}`;
};

/**
 * Loads server configuration by matching the stored and enlisted server configs.
 * If the enlisted server config with the same name exists in the current config, it will by updated.
 * If the enlisted server config is not containing the stored one, it will revert to the first enlisted match.
 *
 * @returns Object The actual server configuration.
 */
export const loadServerConfig = () => ServerConfigHandler.getUsedServer()!;

export const getServerUrl = () => {
  const config = loadServerConfig();
  return buildServerUrl(config);
};

export const getPathPrefix = () => {
  const { IADP_SERVERS } = window;
  if (!IADP_SERVERS) {
    return '';
  }
  const { pathPrefix } = loadServerConfig();
  return pathPrefix || '';
};

export const normalizeUrl = (url: string, path = '') => {
  const pathPrefix = getPathPrefix();
  const pathPrefixWithSlash = !pathPrefix || pathPrefix.startsWith('/') ? pathPrefix : `/${pathPrefix}`;
  const leadingSlash = path.startsWith('/');

  if (path.startsWith(url)) {
    return path;
  }
  if (leadingSlash) {
    const baseUrl = url.replace(/(https?:\/\/.+?)(\/.*)/g, '$1');
    return `${trimEnd(baseUrl, '/')}${pathPrefixWithSlash}/${trimStart(path, '/')}`;
  }
  return `${trimEnd(url, '/')}/${trimStart(path, '/')}`;
};

export const getImageServerUrl = (image: string) => normalizeUrl(getServerUrl(), IMAGE_SOURCES) + image;

/**
 * Returns the cache url
 * @param config The request config
 * @returns {string|*} Returns the request unique url.
 */
export const getCacheUrl = (config: ServiceConfig) => {
  if (config.cacheWithReqBody && config.data) {
    const reqParams = JSON.parse(config.data);
    const urlParams = Object.keys(reqParams).map((key) => `${key}:${reqParams[key]}`);
    urlParams.sort((a, b) => a.localeCompare(b));
    return `${config.url}?${urlParams.join('&')}`;
  }
  return config.url;
};

export const isOffline = (status?: number) => !status || status === 503 || status === 504;

export const isOfflineError = (error: any) => isOffline(error?.response?.status) && (error?.message !== REQUEST_ABORTED);
