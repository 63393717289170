import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { logger, services, syncStorage as storage } from '../../api';
import { LocalStorageKeys } from '../../consts';
import { debounce } from '../../utils';
import { useCrewAuth } from '../CrewAuth';
import { useDevice } from '../Device';
import { useDomain } from '../Domain';

const EVENT = 'firebase_msg_received';

const debouncedResetBadge = debounce(services.resetBadge);

const useNotificationClick = (crewComDomain: string, loadNews: () => void) => {
  const history = useHistory();
  const { authenticated } = useCrewAuth();
  const { deviceId } = useDevice();
  const { routePrefix } = useDomain();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const clearPushNotifications = useCallback(() => {
    if (window.CsiFirebase) {
      window.CsiFirebase.clearAllNotifications();
      window.CsiFirebase.setBadgeNumber(0);

      if (deviceId) {
        debouncedResetBadge({ crewComDomain, uuid: deviceId });
      } else {
        logger.error('Cannot call resetBadge, deviceId is missing');
      }
    }
  }, [deviceId, crewComDomain]);

  const handleMessageReceived = useCallback(({ detail }: CustomEvent) => {
    const messageId = (
      detail?.['google.message_id'] || // Android
      detail?.['gcm.message_id'] // iOS
    );
    const lastHandledPushMessageId = storage.getItem(LocalStorageKeys.LAST_HANDLED_PUSH_MESSAGE_ID);

    if (
      !detail?.tap ||
      // to solve the bug of the firebase plugin that after logout the getInitialPushPayload()
      // again contains the previous push notification
      lastHandledPushMessageId === messageId
    ) {
      return;
    }

    storage.setItem(LocalStorageKeys.LAST_HANDLED_PUSH_MESSAGE_ID, messageId);
    setShouldRedirect(true);
  }, []);

  useEffect(() => {
    if (authenticated && shouldRedirect) {
      setShouldRedirect(false);
      history.push(`${routePrefix}/news`);
      loadNews(); // load news even if the user was already on the News page
      clearPushNotifications();
    }
  }, [authenticated, clearPushNotifications, history, loadNews, routePrefix, shouldRedirect]);

  useEffect(() => {
    if (window.CsiFirebase) {
      const notHandled = window.CsiFirebase.getNotHandledMessage();
      if (notHandled) {
        handleMessageReceived(notHandled);
      }
    }

    window.addEventListener(EVENT, handleMessageReceived as EventListener);
    return () => window.removeEventListener(EVENT, handleMessageReceived as EventListener);
  }, [handleMessageReceived]);

  return clearPushNotifications;
};

export default useNotificationClick;
