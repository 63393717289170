import defaultTheme from './defaultTheme';
import { CustomerTheme } from './themeTypes';

const reltstTheme: CustomerTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
  },
};

export default reltstTheme;
