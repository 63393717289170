import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { LocalStorageKeys } from '../../../consts';
import {
  useDutiesModel, useProfile, useUpdates, useUserPreferences,
} from '../../../hooks';

const useRosterUpdate = () => {
  const { lastRosterChangeTimestamp } = useUpdates();
  const { profile: { autoRefreshEnabledByDefault } } = useProfile();
  const { loadDuties } = useDutiesModel();
  const {
    [LocalStorageKeys.AUTO_REFRESH_ENABLED]: autoRefreshEnabled = autoRefreshEnabledByDefault,
  } = useUserPreferences();

  const [currentRosterChange, setCurrentRosterChange] = useState(lastRosterChangeTimestamp);

  const updateDuties = useCallback(() => {
    loadDuties().then(() => setCurrentRosterChange(lastRosterChangeTimestamp));
  }, [lastRosterChangeTimestamp, loadDuties]);

  useEffect(() => {
    if (!currentRosterChange) {
      setCurrentRosterChange(lastRosterChangeTimestamp);
    }
  }, [currentRosterChange, lastRosterChangeTimestamp]);

  const hasUpdates = !!(
    lastRosterChangeTimestamp && currentRosterChange && lastRosterChangeTimestamp !== currentRosterChange
  );

  useEffect(() => {
    if (autoRefreshEnabled && hasUpdates) {
      updateDuties();
    }
  }, [autoRefreshEnabled, hasUpdates, lastRosterChangeTimestamp, updateDuties]);

  return useMemo(() => ({
    hasUpdates: !autoRefreshEnabled && hasUpdates,
    updateDuties,
  }), [autoRefreshEnabled, hasUpdates, updateDuties]);
};

export default useRosterUpdate;
