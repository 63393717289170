import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';

import { isOfflineError, logger, services } from '../../api';
import { LocalStorageKeys, NEWS_HIDING_TIMEOUT, NewsStatus } from '../../consts';
import { debounce } from '../../utils';
import { useOnlineAuthorized } from '../common';
import { useDomain } from '../Domain';
import { useUpdates } from '../Updates';
import { useNotification, useUserPreferences } from '../UserPreferences';
import useNotificationClick from './useNotificationClick';
import NewsContext, { NewsId, NewsItem } from './NewsContext';

const debouncedGetNews = debounce(services.getNews);

const NewsProvider = ({ children }: { children: React.ReactNode }) => {
  const { crewComDomain } = useDomain();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [news, setNews] = useState<NewsItem[]>([]);
  const { lastNewsTimestamp } = useUpdates();
  const { [LocalStorageKeys.LAST_READ_NEWS_TIMESTAMP]: storedTimestamp, setPref } = useUserPreferences();
  const [deletingIds, setDeletingIds] = useState<NewsId[]>([]);
  const timeoutIdForRemoving = useRef<number>();

  const IDs = useMemo(() => news.map((newsItem) => newsItem.notificationId), [news]);
  const { hasNotification, removeNotification } = useNotification(LocalStorageKeys.SHOWN_NEWS_IDS, IDs);

  // clear up when unmounting
  useEffect(() => () => clearTimeout(timeoutIdForRemoving.current), []);

  const loadNews = useCallback(() => {
    debouncedGetNews({ domain: crewComDomain })
      .then(({ data: { messages } }: any) => {
        setNews(messages || []);
        setHasLoaded(true);
      })
      .catch((error) => {
        if (isOfflineError(error)) {
          setHasLoaded(false);
          logger.error(error);
        } else {
          throw error;
        }
      });
  }, [crewComDomain]);

  const clearPushNotifications = useNotificationClick(crewComDomain, loadNews);

  useOnlineAuthorized(() => {
    if (lastNewsTimestamp && lastNewsTimestamp !== storedTimestamp) {
      loadNews();
      setPref(LocalStorageKeys.LAST_READ_NEWS_TIMESTAMP, lastNewsTimestamp);
    }
  }, [lastNewsTimestamp, loadNews, setPref, storedTimestamp]);

  const removeNewsItem = useCallback((id: NewsId) => {
    setDeletingIds((prevDeletingIds) => [...prevDeletingIds, id]);
    timeoutIdForRemoving.current = window.setTimeout(() => {
      services.deleteNews({ id })
        .then(() => {
          setNews((prevNews) => prevNews.filter((newsItem) => newsItem.notificationId !== id));
        })
        .catch((error) => {
          logger.error(error);
          setDeletingIds((prevDeletingIds) => prevDeletingIds.filter((prevDeletingId) => prevDeletingId !== id));
        });
    }, NEWS_HIDING_TIMEOUT);
  }, []);

  const markAsRead = useCallback((id: NewsId) => {
    services.updateNewsStatus({ id, status: NewsStatus.READ })
      .then(() => {
        setNews((prevNews) => prevNews.map((newsItem) => {
          if (newsItem.notificationId !== id) {
            return newsItem;
          }
          return { ...newsItem, recipients: [{ status: NewsStatus.READ }] };
        }));
      })
      .catch((error) => logger.error(error));
  }, []);

  const ctx = useMemo(() => ({
    clearPushNotifications,
    deletingIds,
    hasLoaded,
    hasNotification,
    loadNews,
    markAsRead,
    news,
    removeNewsItem,
    removeNotification,
  }), [
    clearPushNotifications,
    deletingIds,
    hasLoaded,
    hasNotification,
    loadNews,
    markAsRead,
    news,
    removeNewsItem,
    removeNotification,
  ]);

  return <NewsContext.Provider value={ctx}>{children}</NewsContext.Provider>;
};

export const useNews = () => useContext(NewsContext);

export default NewsProvider;
