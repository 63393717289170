import { useCallback, useMemo } from 'react';

import { LocalStorageKeys } from '../../consts';
import { useUserPreferences } from './UserPreferencesProvider';

const useShowRosterTags = () => {
  const { [LocalStorageKeys.SHOW_ROSTER_TAGS]: rosterTagsShown = true, setPref } = useUserPreferences();

  const toggleRosterTags = useCallback(() => {
    setPref(LocalStorageKeys.SHOW_ROSTER_TAGS, !rosterTagsShown);
  }, [rosterTagsShown, setPref]);

  return useMemo(() => ({ rosterTagsShown, toggleRosterTags }), [rosterTagsShown, toggleRosterTags]);
};

export default useShowRosterTags;
