import { useCallback, useMemo } from 'react';

import { LocalStorageKeys } from '../../consts';
import { useUserPreferences } from './UserPreferencesProvider';

const useShowPairingCount = () => {
  const { [LocalStorageKeys.SHOW_PAIRING_COUNT]: pairingCountShown = true, setPref } = useUserPreferences();

  const toggleShowPairings = useCallback(() => {
    setPref(LocalStorageKeys.SHOW_PAIRING_COUNT, !pairingCountShown);
  }, [pairingCountShown, setPref]);

  return useMemo(() => ({ pairingCountShown, toggleShowPairings }), [pairingCountShown, toggleShowPairings]);
};

export default useShowPairingCount;
