import { forwardRef } from 'react';
import { MenuItem, TextField } from '@lsy-netline-ui/netline-ui';
import { createFilterOptions } from '@material-ui/lab';
import { merge } from 'lodash';

import { DatePicker } from '@crew-webui/common/view/components';

import { MyDataFieldType, OptionType } from 'consts';
import { Autocomplete } from 'view/components';

const stringify = (option: OptionType) => option.label;
const filterOptions = createFilterOptions({ stringify });
const filterOptionsFromStart = createFilterOptions({ matchFrom: 'start', stringify });

type MyDataFormElementProps = {
  disabledField?: boolean;
  error: boolean;
  field: MyDataFieldType;
  helperText?: React.ReactNode;
  onChange: (value: any) => void;
  value: any;
};

const convertApiDateToForm = (dateStr: string | undefined): string | undefined => (dateStr ? dateStr!.substring(0, 10) : undefined);
const convertFormDateToApi = (dateStr: string | undefined): string | undefined => (dateStr ? `${dateStr}T00:00` : undefined);

const MyDataFormElement = forwardRef<any, MyDataFormElementProps>(
  // eslint-disable-next-line prefer-arrow-callback
  function MyDataFormElement({
    disabledField, error, field, helperText, ...props
  }: MyDataFormElementProps, ref) {
    const textFieldProps = {
      error,
      helperText,
      inputProps: {
        max: field.max,
        maxLength: field.maxLength,
        min: field.min,
        step: field.max !== undefined ? 1 : undefined,
      },
      inputRef: ref,
      label: field.label,
      required: field.required && !disabledField,
    };

    const selectProps = (!field.options || field.autocomplete) ? {} : {
      select: true,
      children: field.options?.map((option: OptionType) => (
        <MenuItem
          key={option.value}
          data-test-id={`field-${field.valuePath}-${option.value}`}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      )),
    };

    if (field.autocomplete) {
      const filterFn: any = field.filterFromStart ? filterOptionsFromStart : filterOptions;

      return (
        <Autocomplete
          {...props}
          onChange={(event, item) => props.onChange(item.value || item)}
          onInputChange={(event, value) => props.onChange(value)}
          options={filterFn(field.options, { inputValue: props.value || '' })}
          renderInput={(params) => <TextField {...merge(params, textFieldProps)} />}
        />
      );
    }

    if (field.date) {
      return (
        <DatePicker
          {...props}
          {...textFieldProps}
          value={field.dateWithTime ? convertApiDateToForm(props.value) : props.value}
          onChange={(dateStr) => (props.onChange(field.dateWithTime ? convertFormDateToApi(dateStr) : dateStr))}
        />
      );
    }
    return (
      <TextField
        {...props}
        {...textFieldProps}
        {...selectProps}
      />
    );
  },
);

export default MyDataFormElement;
