import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  root: {
    width: '100%',
  },
  checkboxLabel: {
    justifyContent: 'space-between',
    marginRight: spacing(1),
    marginLeft: 0,
    color: palette.text.label,
  },
  checkboxLabelText: {
    marginRight: spacing(1),
    fontWeight: 500,
    lineHeight: '18px',
  },
});
