import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CompareArrows as CompareArrowsIcon, DoDisturb as DoDisturbIcon,
} from '@material-ui/icons';
import {
  makeStyles, IconButton, Typography,
} from '@lsy-netline-ui/netline-ui';

import { SearchTextPage } from '@crew-webui/common/view/components';
import { services } from '@crew-webui/common/api';
import { useCrewAuth, useDutiesModel } from '@crew-webui/common/hooks';

import styles from './BuddyRosterSearch.styles';
import { routePaths } from 'consts';

interface CrewMember {
  firstName: string;
  lastName: string;
  crewId: string;
  accessGrantedForMe: boolean;
}

const formId = 'buddyRosterSearchForm';

const useStyles = makeStyles(styles);

const BuddyRosterSearch = () => {
  const { t } = useTranslation();
  const { crmId } = useCrewAuth();
  const classes = useStyles();
  const { enterBuddyMode } = useDutiesModel();

  const [searchValue, setSearchValue] = useState<string>('');
  const [crewMemberList, setCrewMemberList] = useState<CrewMember[]>();

  const selectBuddy = useCallback((crewMember: CrewMember) => {
    enterBuddyMode({
      buddyCrmId: crewMember.crewId,
      buddyName: `${crewMember.firstName} ${crewMember.lastName}`,
    }, routePaths.root);
  }, [enterBuddyMode]);

  const onSubmit = useCallback((value: string) => {
    if (!value) {
      return new Promise((resolve) => {
        resolve(undefined);
      });
    }

    return services.searchBuddyRoster({ crmId, value }).then(({ data }) => {
      setSearchValue(value);
      const { success, result } = data;
      if (success && result) {
        setCrewMemberList(result.crewMemberList);
      }
    });
  }, [crmId]);

  return (
    <SearchTextPage
      headerProps={{ title: t('buddy.buddyRosterSearch.title') }}
      formId={formId}
      searchLabel={t('buddy.buddyRosterSearch.fields.colleaguesLabel')}
      searchPlaceholder={t('buddy.buddyRosterSearch.fields.colleaguesPlaceholder')}
      onSubmit={onSubmit}
    >
      {!!crewMemberList && (
        <>
          <span className={classes.resultsFor}>{`${t('buddy.buddyRosterSearch.resultsFor')} "${searchValue}"`}</span>
          {crewMemberList.map((crewMember) => (
            <div key={crewMember.crewId} className={classes.resultItem}>
              <div>
                <Typography className={classes.crewName}>
                  {`${crewMember.firstName} ${crewMember.lastName}`}
                </Typography>
                <Typography className={classes.crewId}>{crewMember.crewId}</Typography>
              </div>
              <div>
                <IconButton
                  onClick={() => selectBuddy(crewMember)}
                  disabled={!crewMember.accessGrantedForMe}
                >
                  {crewMember.accessGrantedForMe ? (
                    <CompareArrowsIcon />
                  ) : <DoDisturbIcon />}
                </IconButton>
              </div>
            </div>
          ))}
        </>
      )}
    </SearchTextPage>
  );
};

export default BuddyRosterSearch;
