import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  root: {
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'lineHeight': '20px',
    '&.multiColumn': {
      color: palette.iadp.button.textColor,
    },
  },
  disabled: {
    opacity: 0,
    visibility: 'hidden',
  },
  navigate: {
    'padding': 0,
    '&.multiColumn': {
      color: palette.iadp.button.textColor,
    },
  },
  text: {
    textAlign: 'center',
  },
});
