import {
  useCallback, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  AuthState,
  useCrewAuth,
  useDialog,
  useDutiesModel,
  useUserPreferences,
} from '@crew-webui/common/hooks';
import { LocalStorageKeys } from '@crew-webui/common/consts';

import { routePaths } from 'consts';

const useNotificationPopup = () => {
  const { t } = useTranslation();

  const { allEvents, eventsHaveLoaded, buddyMode } = useDutiesModel();
  const { crmId } = useCrewAuth();
  const history = useHistory();
  const hasPendingChange = useMemo(() => allEvents.some((event) => event.originalEventType === 'SUSP'), [allEvents]);
  const { [LocalStorageKeys.HAS_PREV_PENDING_CHANGE]: hasPrevPendingChange, setPref } = useUserPreferences();
  const { authState } = useCrewAuth();
  const { showDialog, hideDialog } = useDialog();

  const closeDialog = useCallback(() => {
    hideDialog();
  }, [hideDialog]);

  const viewNotification = useCallback(() => {
    hideDialog();
    history.push(`${routePaths.notifications}/${crmId}`);
  }, [crmId, hideDialog, history]);

  const buttons = useMemo(() => [{
    text: t('button.view'),
    onClick: viewNotification,
  }, {
    text: t('common.cancel'),
    onClick: closeDialog,
  }], [closeDialog, t, viewNotification]);

  useEffect(() => {
    if (authState === AuthState.LOGGED_OUT) {
      setPref(LocalStorageKeys.HAS_PREV_PENDING_CHANGE, false);
    }
    if (authState === AuthState.AUTHORIZED && eventsHaveLoaded) {
      if (hasPendingChange && !hasPrevPendingChange && !buddyMode) {
        showDialog({ title: t('notificationConfirmation.unconfirmedRosterChanges'), buttons });
        setPref(LocalStorageKeys.HAS_PREV_PENDING_CHANGE, true);
      } else if (!hasPendingChange) {
        setPref(LocalStorageKeys.HAS_PREV_PENDING_CHANGE, false);
      }
    }
  }, [buttons, hasPendingChange, hasPrevPendingChange, eventsHaveLoaded, authState, setPref, showDialog, t, buddyMode]);
};

export default useNotificationPopup;
