import { forwardRef } from 'react';
import { SvgIcon } from '@lsy-netline-ui/netline-ui';

const SickIcon = forwardRef<any>((props, ref) => (
  <SvgIcon ref={ref} {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.4382 10.6057H10.6765V13.3672L7.86328 13.3672L7.86328 16.1289H10.6765V18.8907H13.4382V16.1289H16.1483V13.3672L13.4382 13.3672V10.6057Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.5449 2.18081L1.48604 9.33071C0.737055 9.86309 1.11371 11.0431 2.03262 11.0431H3.94847V21.0692C3.94847 21.5902 4.37086 22.0126 4.8919 22.0126H19.292C19.8131 22.0126 20.2355 21.5902 20.2355 21.0692V11.0431H21.9781C22.8931 11.0431 23.2724 9.87139 22.531 9.3352L12.6444 2.18531C12.3167 1.94836 11.8745 1.94655 11.5449 2.18081ZM11.9848 20.1944C15.0305 20.1944 17.4995 17.7253 17.4995 14.6796C17.4995 11.6339 15.0305 9.16482 11.9848 9.16482C8.93904 9.16482 6.47 11.6339 6.47 14.6796C6.47 17.7253 8.93904 20.1944 11.9848 20.1944Z" />
  </SvgIcon>
));

export default SickIcon;
