import { AvailableLanguages, MyDataCategory, MyDataTravelDocType } from '@crew-webui/common/consts';

/* #CHECK_IN
check in details referencing actual event to which crew member checks in
additionally it can contain reference to a pairing,
or replace event by a pairing reference
https://docspace.lhsystems.com/pages/viewpage.action?pageId=418342087
*/
export enum CheckInTitle {
  // e.g. Check-in for T5008/02Sep19
  SHOW_ONLY_PAIRING_NUMBER = 'SHOW_ONLY_PAIRING_NUMBER',

  // e.g. Check-in for T5008/02Sep19 (AC137 from YYZ)
  ADD_PAIRING_NUMBER = 'ADD_PAIRING_NUMBER',

  // e.g. Check-in for 11 Sep / (AC137 from YYZ)
  NO_PAIRING_NUMBER = 'NO_PAIRING_NUMBER',
}

/* #HOTEL https://docspace.lhsystems.com/display/NLC/Hotel+Detail+Info?src=contextnavpagetreemode */
export enum HotelFields {
  ADDRESS = 'ADDRESS',
  DATE = 'DATE',
  CONTACTS = 'CONTACTS',
  NAME = 'NAME',
  PICKUP_TIME = 'PICKUP_TIME',
  REMARK = 'REMARK',
  TRANSFER_TIME = 'TRANSFER_TIME',
  TRANSPORT_COMPANY_ADDRESS = 'TRANSPORT_COMPANY_ADDRESS',
  TRANSPORT_COMPANY_CONTACTS = 'TRANSPORT_COMPANY_CONTACTS',
  TRANSPORT_COMPANY_NAME = 'TRANSPORT_COMPANY_NAME',
  TRANSPORT_COMPANY_TYPE = 'TRANSPORT_COMPANY_TYPE',
  TYPE = 'TYPE',
}

/* #SUMMARY Name of the headers in Summary page */
export enum SummaryHeaders {
  CUMMULATIVE_DATA = 'Cummulative data',
  RECURRENT_CHECKS = 'Recurrent trainings/checks',
  LICENCES = 'Licences',
  PASSPORTS_AND_VISAS = 'Passports / Visas',
  ADDITIONAL_SUMMARY = 'Additional summary',
  RAIC = 'Restricted Area Identity Cards',
}

export enum CountryIssueDefaultValues {
  NONE = '',
  CA = 'CA',
}

/* #MYDATA https://docspace.lhsystems.com/display/NLC/My+Data?src=contextnavpagetreemode
List of the fields on the Personal data My Data page which should be disabled, so not editable
*/
export enum PersonalDataFieldPaths {
  birthCountry = 'birthCountry',
  birthDate = 'birthDate',
  birthPlace = 'birthPlace',
  birthState = 'birthState',
  firstName = 'firstName',
  gender = 'gender',
  isSmoker = 'isSmoker',
  lastName = 'lastName',
  middleName = 'middleName',
  nationality = 'nationality',
}

export enum ProfileFeature {
  /* #BLOCKMONTH_CRM_DATA (Block) Month Specific Crew Member Additional Data display. Possible values true|false
  https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  VIEW_BLOCKMONTH_SPECIFIC_DATA = 'viewBlockMonthSpecificData',

  /* #SUMMARY Calendar-based Summary Page: enable switch block month state. Possible values true|false
  https://docspace.lhsystems.com/display/NLC/Summary+Page?src=contextnavpagetreemode
  */
  VIEW_BLOCKMONTH_SWITCH_ON_SUMMARY = 'viewBlockmonthSwitchOnSummary',

  /* #CHECK_IN enable duty row link to view checkin and display check-in details. Possible values true|false
   https://docspace.lhsystems.com/pages/viewpage.action?pageId=418342087&src=contextnavpagetreemode
  */
  VIEW_CHECK_IN = 'viewCheckIn',

  /* #CREW_ON_LEG enable duty row link to view deadhead and display details. Possible values true|false
   https://docspace.lhsystems.com/display/NLC/Leg+Detail+Info?src=contextnavpagetreemode
   */
  VIEW_DEADHEAD = 'viewDeadhead',

  /* #HOTEL enable duty row link to view hotel and display extended info
  https://docspace.lhsystems.com/display/NLC/Hotel+Detail+Info?src=contextnavpagetreemode
  */
  VIEW_HOTEL = 'viewHotel',

  /* #CREW_ON_LEG enable duty row link to view leg details
   https://docspace.lhsystems.com/display/NLC/Leg+Detail+Info?src=contextnavpagetreemode
  */
  VIEW_LEG = 'viewLeg',

  /* #NOTIFICATIONS enable view roster change notification details
    https://docspace.lhsystems.com/display/NLC/Roster+Change+Confirmation?src=contextnavpagetreemode
  */
  VIEW_NOTIFICATIONS = 'viewNotifications',

  /* #PAIRING enable duty row link to view pairing details (smart info)
    https://docspace.lhsystems.com/display/NLC/Pairing+Detail+Info+IADP?src=contextnavpagetreemode
   */
  VIEW_PAIRING = 'viewPairing',

  /* #ROSTER_TAG enable show roster tags in calendar filter
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  VIEW_ROSTER_TAGS_CALENDAR_FILTER = 'viewRosterTagsCalendarFilter',

  /* #SUMMARY enable view summary
   https://docspace.lhsystems.com/display/NLC/Summary+Page?src=contextnavpagetreemode
  */
  VIEW_SUMMARY = 'viewSummary',

  /* #CHECK_IN enable perform checkin
   https://docspace.lhsystems.com/pages/viewpage.action?pageId=418342087&src=contextnavpagetreemode
  */
  PERFORM_CHECK_IN = 'performCheckIn',

  /* #ROLE enable to edit roles
   https://docspace.lhsystems.com/display/NLC/Role+Registration?src=contextnavpagetreemode
  */
  PERFORM_ROLE_EDITING = 'performRoleEditing',
}

/* #NOTIFICATIONS Roster change Notification details selection mode
  https://docspace.lhsystems.com/display/NLC/Roster+Change+Confirmation?src=contextnavpagetreemode
 */
export enum SelectionMode {
  ALL = 'ALL',
  NONE = 'NONE',
  CURRENT_PERIOD = 'CURRENT_PERIOD',
}

/* #CREW_ON_LEG possible crew member attributes Crew On Leg Details
  https://docspace.lhsystems.com/display/NLC/Leg+Detail+Info?src=contextnavpagetreemode
  */
export enum CrewMemberAttributes {
  ADD_QUALIF = 'ADD_QUALIF',
  ASSIGNED_RANK = 'ASSIGNED_RANK',
  CONTRACT_CODE = 'CONTRACT_CODE',
  CREWCODE = 'CREWCODE',
  EMAIL = 'EMAIL',
  HOMEBASE = 'HOMEBASE',
  PAIRING_CLASSIFICATION = 'PAIRING_CLASSIFICATION',
  PAIRING_NUMBER = 'PAIRING_NUMBER',
  PHONE = 'PHONE',
  RANK = 'RANK',
  SENIORITY = 'SENIORITY',
  SEX = 'SEX',
}

export type CustomerProfile = {
  /* Specifies which profile features are enabled */
  enablings: {
    [profileFeature in ProfileFeature]: boolean;
  };

  /* Airline logo image to show on login page */
  logo: string;

  /* #EVENT Show the pairing number in Duty list and calendar.
     US/EU specific setting: pairing number is not used in EU
    https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showPairingNumber: boolean;

  /* #EVENT Show home base in Duty list and calendar
    https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showHomeBase: boolean;

  /* #EVENT Show aircraft type codes of PAR in Duty list and calendar
  https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showAircraftTypeCodeOfPairing: boolean;

  /* #EVENT Show (assigned) rank in Duty list and calendar for parings
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showRank: boolean;

  /* It enables cookie banner */
  showCookieBanner: boolean;

  /* #TIMES It enables showing UTC time
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
   */
  showUTCTime: boolean;

  /* #TIMES It enables showing local time
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showLocalTime: boolean;

  /* #TIMES Optional postfix for local times
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  localTimePostfix: string;

  /* #TIMES Optional postfix for UTC times
    https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  utcTimePostfix: string;

  /*  #OFFLINE Set the event day range to cache the services
    https://docspace.lhsystems.com/display/NLC/Offline+Working?src=contextnavpagetreemode
   */
  cacheBeforeDaysNo: number;

  /* #OFFLINE Set the event day range to cache the services
   https://docspace.lhsystems.com/display/NLC/Offline+Working?src=contextnavpagetreemode
  */
  cacheAfterDaysNo: number;

  /* #OFFLINE Set the event day range to cache additional flight info
   https://docspace.lhsystems.com/display/NLC/Offline+Working?src=contextnavpagetreemode
  */
  cacheFlightInfoDaysNo: number;

  /* #OFFLINE Set if print cache size after cache process
   https://docspace.lhsystems.com/display/NLC/Offline+Working?src=contextnavpagetreemode
   */
  printCacheSize: boolean;

  /* #CALENDAR If true, the week starts on Monday, otherwise on Sunday. US/EU specific parameter. */
  calendarWeekStartMonday: boolean;

  /* #NOTIFICATION Show roster change type in Duty list */
  showRosterChanges: boolean;

  /* #NOTIFICATION If true, the icon in calendar for roster change notification (SUSP) event is large */
  showLargeSuspIcon: boolean;

  /* #EVENT Whether to show ground activity code in calendar
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showGroundActivityCodeInCalendar: boolean;

  /* #CALENDAR Whether to open calendar after user logged in
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showCalendarAfterLogin: boolean;

  /* #REFRESH Determines whether automatic refreshing of Duty list is enabled by default
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
   */
  autoRefreshEnabledByDefault: boolean;

  /* #BLOCKMONTH Show the code (description) of the block month in the Block month switcher
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showBlockMonthCode: boolean;

  /* #BLOCKMONTH Show the interval of the Block month in the Block month switcher
    https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
   */
  showBlockMonthInterval: boolean;

  /* #EVENT Show the aircraft type code of LEG in Duty list
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showAircraftTypeCodeOfLeg: boolean;

  /* #EVENT Show the aircraft subtype of LEG in Duty list
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
   */
  showAircraftSubType: boolean;

  /* #EVENT Show Fin number of LEG in Duty List
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showFinNumber: boolean;

  /* #EVENT Show the gate number of LEG in Duty list
    https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showGateInfo: boolean;

  /* #EVENT Show the registration (tail number) of LEG in Duty list
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showRegistration: boolean;

  /*
   * #EVENT
   * true: show the arrival airport code of pairing always in Duty Plan
   * false: do not show the arrival airport code of pairing if it is same as departure airport
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
   */
  showArrivalAirportOfPairing: boolean;

  /* #EVENT Show the classification of PAR in Duty list
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  showPairingClassification: boolean;

  /*  #ADDITIONAL_FLIGHT_DETAILS
    Information collapsibles are collapsed or encollapsed on Flight Details page
    https://docspace.lhsystems.com/display/NLC/Additional+Flight+Details?src=contextnavpagetreemode
   */
  flightDetailsEncollapsed: boolean;

  // For all possible event types icons on Duty list are configurable
  icons: {
    [eventType: string]: React.ElementType;
  };

  /* #EVENT Icons used on Duty list */
  buttonIcons: {
    ExpandMore: React.ElementType;
    Info: React.ElementType;
  };

  /* #NEWS Specify icons and colors used on News page
    https://docspace.lhsystems.com/display/NLC/News+Page?src=contextnavpagetreemode
   */
  newsListing: {
    checkIn: {
      Icon?: React.ElementType;
      color?: string;
    };
    rosterChange: {
      Icon?: React.ElementType;
      color?: string;
    };
    message: {
      Icon?: React.ElementType;
      color?: string;
    };
    delete: {
      Icon?: React.ElementType;
    };
  };

  /* #EVENT In calendar event tag this text is displayed for the given event types when specified
    https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  typeCodesCalendar: {
    [eventType: string]: string;
  };

  /* #EVENT In Duty list when no icon specified for given event type this text is displayed
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  typeCodesDutyList: {
    [eventType: string]: string;
  };

  /* #CALENDAR Date/time formats
   https://docspace.lhsystems.com/display/NLC/Duty+Plan+IADP?src=contextnavpagetreemode
  */
  dateFormats: {
    DATE: string;
    SHORT_DATE: string;
    SHORT_DATE_SEPARATED: string;
    TIME: string;
    DATE_TIME: string;
    SHORT_DATE_TIME: string;
  };

  /* #NOTIFICATIONS The default auto selection method when user opens Roster changes page.
   https://docspace.lhsystems.com/display/NLC/Roster+Change+Confirmation?src=contextnavpagetreemode
   */
  notificationConfirmationSelectionMode: SelectionMode;

  /* #CHECK_IN Determines what info to shown in the title of the Check in details page
    https://docspace.lhsystems.com/pages/viewpage.action?pageId=418342087&src=contextnavpagetreemode
   */
  checkInTitle: CheckInTitle;

  /* #HOTEL The fields on Hotel details page which should not be shown
    https://docspace.lhsystems.com/display/NLC/Hotel+Detail+Info?src=contextnavpagetreemode
   */
  disabledHotelFields: HotelFields[];

  // #MYDATA Default value for the Country of Issue field in IADP
  countryIssueDefaultValue: CountryIssueDefaultValues;

  // #SUMMARY The titles on the IADP Summary page that should be completely hidden in case of no corresponding data
  hiddenHeadersIfNoData: SummaryHeaders[];

  /* #SUMMARY On Summary page in given groups dates in given date format can be highlighed if expired
   https://docspace.lhsystems.com/display/NLC/Summary+Page?src=contextnavpagetreemode
  */
  summary?: {
    dateFormat: string;
    groupsToHighlightExpiration: string[];
  };

  /* #NEWS On News Settings page when user adds phone number, the field is prefilled with this value
    https://docspace.lhsystems.com/display/NLC/Roster+Change+Confirmation?src=contextnavpagetreemode
   */
  preDefinedPhoneValue: string;

  /* #NEWS Agreement texts to show on News Settings page.
  if key (e.g. pushNotification, sms) is missing, there is no agreement text shown
  https://docspace.lhsystems.com/display/NLC/Roster+Change+Confirmation?src=contextnavpagetreemode
  */
  agreementTexts: {
    pushNotification?: {
      en: string;
      fr?: string;
    };
    sms?: {
      en: string;
      fr?: string;
    };
  };

  /* #CREW_ON_LEG Attributes of crew member to show on 'Crew on leg' or 'Crew on Check in' pages
   https://docspace.lhsystems.com/display/NLC/Leg+Detail+Info?src=contextnavpagetreemode
  */
  attributesCrewMember: {
    en: {
      [attribute in CrewMemberAttributes]: string;
    };
    fr?: {
      [attribute in CrewMemberAttributes]?: string;
    };
  };

  /* #MYDATA Phone type codes to choose from when creating/editing phone data */
  phoneTypeCodes: string[];

  /* #MYDATA List of the fields on the Personal data My Data page which should be disabled, so not editable
   https://docspace.lhsystems.com/display/NLC/My+Data?src=contextnavpagetreemode
   */
  disabledPersonalDataFields: PersonalDataFieldPaths[];

  // #MYDATA List of travel document types which are enabled in My Data
  enabledTravelDocTypes: MyDataTravelDocType[];

  /*
   * #MYDATA List of readonly myData categories
   * @type MyDataCategory[]
   * @property PERSONAL
   * @property CONTACT
   * @property ADDRESS
   * @property TRAVEL
   * @property VISA
   * @property PASSPORT
   * @property ALIEN_RESIDENT_NUMBER
   * @property IDENTITY
   * @property DRIVER_LICENCE
   * @property RAIC
   * https://docspace.lhsystems.com/display/NLC/My+Data?src=contextnavpagetreemode
  */
  readonlyMyDataCategories: MyDataCategory[];

  // List of available languages
  availableLanguages: AvailableLanguages[];
};
