import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ spacing, palette }: Theme) => createStyles({
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: spacing(1.5),
  },
  infoIcon: {
    color: palette.text.secondaryLabel,
    width: '16px',
    height: '16px',
  },
  infoText: {
    paddingLeft: '5px',
    color: palette.text.secondaryLabel,
    fontSize: '12px',
    letterSpacing: 0,
  },
  form: {
    paddingTop: spacing(2),
  },
  formContent: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(2),
  },
  formLine: {
    display: 'flex',
    gap: spacing(2),
  },
});
