export default ({ palette }: Theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: 24,
    },
  },
  label: {
    margin: '0 16px 32px',
  },
  list: {
    margin: '0 16px',
    padding: 0,
  },
  listItem: {
    marginBottom: 8,
    borderBottom: `1px solid ${palette.iadp.borderColor}`,
    paddingTop: 0,
  },
  listItemText: {
    margin: 0,
  },
  listItemLabel: {
    fontSize: 10,
  },
  listValue: {
    color: palette.text.primary,
  },
});
