import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import {
  useDutiesModel, useFeature, useMultiColumn, useShowRosterTags,
} from '@crew-webui/common/hooks';
import { NoContentMessage, DesktopHeader } from '@crew-webui/common/view/components';
import { DutyList } from '@crew-webui/common/view';

import { Feature } from 'consts';
import Calendar from './Calendar/Calendar';
import styles from './Duties.styles';

const useStyles = makeStyles(styles, { name: 'Duties' });

const Duties = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isFeatureEnabled = useFeature();
  const {
    confirmDuties, dutyList, eventsHaveLoaded,
  } = useDutiesModel();
  const { state: { loadDuties: confirmResult } = {} } = useLocation<{ loadDuties: any[] }>();
  const multiColumn = useMultiColumn();
  const { rosterTagsShown } = useShowRosterTags();
  const enabledFeatures = {
    checkInEnabled: isFeatureEnabled(Feature.VIEW_CHECK_IN),
    deadheadEnabled: isFeatureEnabled(Feature.VIEW_DEADHEAD),
    eventRemarksEnabled: isFeatureEnabled(Feature.VIEW_EVENT_REMARKS),
    hotelEnabled: isFeatureEnabled(Feature.VIEW_HOTEL),
    legEnabled: isFeatureEnabled(Feature.VIEW_LEG),
    notificationsEnabled: isFeatureEnabled(Feature.VIEW_NOTIFICATIONS),
    pairingEnabled: isFeatureEnabled(Feature.VIEW_PAIRING),
    buddyLegDeadHeadInfoEnabled: isFeatureEnabled(Feature.IDP_BUDDY_LEG_DEADHEAD_INFO_ACCESS),
    buddyPairingInfoEnabled: isFeatureEnabled(Feature.IDP_BUDDY_PAIRING_INFO_ACCESS),
  };

  useEffect(() => {
    if (confirmResult) {
      confirmDuties(confirmResult);
    }
  }, [confirmResult, confirmDuties]);

  return (
    <div className={classes.root}>
      {multiColumn ? <DesktopHeader title={t('duties.main.title')} /> : <Calendar />}

      <div className={classes.contentWrapper}>
        <div className={classes.dutyWrapper}>
          {!dutyList.length && eventsHaveLoaded ? <NoContentMessage>{t('duties.main.noEvents')}</NoContentMessage> : (
            <DutyList events={dutyList} showRosterTags={rosterTagsShown} enabledFeatures={enabledFeatures} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Duties;
