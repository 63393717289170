import { useTranslation } from 'react-i18next';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import { useIadpParams } from '@crew-webui/common/api';
import { EventContext } from '@crew-webui/common/consts';
import { useFeature, useMultiColumn, usePairingDetails } from '@crew-webui/common/hooks';
import { RawEventDetails } from '@crew-webui/common/view/components';
import { DutyList } from '@crew-webui/common/view';

import { Feature } from 'consts';
import { IadpPage } from 'view/components';
import styles from './PairingDetails.styles';

const useStyles = makeStyles(styles, { name: 'PairingDetails' });

const PairingDetails = () => {
  const classes = useStyles();
  const { context, crmId, logicalId } = useIadpParams<{ context: EventContext; crmId: string; logicalId: string }>();
  const multiColumn = useMultiColumn();
  const logicalEventId = decodeURIComponent(logicalId);
  const { details, events, title } = usePairingDetails(context, crmId, logicalEventId);
  const isFeatureEnabled = useFeature();
  const enabledFeatures = {
    checkInEnabled: isFeatureEnabled(Feature.VIEW_CHECK_IN),
    deadheadEnabled: isFeatureEnabled(Feature.VIEW_DEADHEAD),
    eventRemarksEnabled: isFeatureEnabled(Feature.VIEW_EVENT_REMARKS),
    hotelEnabled: isFeatureEnabled(Feature.VIEW_HOTEL),
    legEnabled: isFeatureEnabled(Feature.VIEW_LEG),
    notificationsEnabled: isFeatureEnabled(Feature.VIEW_NOTIFICATIONS),
    pairingEnabled: isFeatureEnabled(Feature.VIEW_PAIRING),
    buddyLegDeadHeadInfoEnabled: isFeatureEnabled(Feature.IDP_BUDDY_LEG_DEADHEAD_INFO_ACCESS),
    buddyPairingInfoEnabled: isFeatureEnabled(Feature.IDP_BUDDY_PAIRING_INFO_ACCESS),
  };
  const { t } = useTranslation();

  return (
    <IadpPage
      contentClass={clsx({ [classes.multiColumn]: multiColumn })}
      dataLoaded={!!events.length}
      headerProps={{ title: t('duties.main.title'), subtitle: title, showBackButton: true }}
    >
      <RawEventDetails className={details?.length ? classes.details : undefined} items={details} />
      <div className={classes.pairingList}>
        <DutyList detailView events={events} enabledFeatures={enabledFeatures} />
      </div>
    </IadpPage>
  );
};

export default PairingDetails;
