import {
  useCallback, useContext, useMemo, useState,
} from 'react';

import { services } from '../../api';
import { AdditionalFlightDetails, Event, EventFromServer } from '../../consts';
import { convertEvent } from '../../utils';
import { useCrewAuth } from '../CrewAuth';
import { useDomain } from '../Domain';
import { useProfile } from '../Profile';
import { useDutiesModel } from '../DutiesModel';
import EventDetailsContext, { FetchEventDetailsParam } from './EventDetailsContext';

type EventResponse = PemsResponse<EventFromServer>;

const EventDetailsProvider = ({ children }: { children: React.ReactNode }) => {
  const { crmId } = useCrewAuth();
  const { pemsDomain } = useDomain();
  const { profile } = useProfile();
  const { buddyMode } = useDutiesModel();

  const [eventDetails, setEventDetails] = useState<{ [key: string]: Event }>({});
  const [additionalFlightDetails, setAdditionalFlightDetails] = useState<AdditionalFlightDetails>();

  const fetchEventDetails = useCallback(
    (param: FetchEventDetailsParam) => {
      const srvByLogicalEventId = buddyMode ? services.getBuddyEventByLogicalEventId : services.getEventByLogicalEventId;
      const srvByEventId = buddyMode ? services.getBuddyEventByEventId : services.getEventByEventId;
      const srv = param.logicalEventId ? srvByLogicalEventId : srvByEventId;
      srv({
        crmId,
        pemsDomain,
        ...param,
        ...(!!buddyMode && { crmId: buddyMode.buddyCrmId, buddyCrmId: crmId }),
      }).then(({ data }: { data: EventResponse }) => {
        const { result, success } = data;

        if (success && result) {
          const value = convertEvent(result, profile);
          const id = ('eventId' in param ? param.eventId : param.logicalEventId) as string;
          setEventDetails((prevEventDetails) => ({ ...prevEventDetails, [id]: value }));
        }
      });
    },
    [buddyMode, crmId, pemsDomain, profile],
  );

  const fetchAdditionalFlightDetails = useCallback((logicalEventId: string) => {
    services.getFlightInfo({ data: [logicalEventId] }).then(({ data }) => {
      const { result, success } = data;

      if (success && result && result.length > 0) {
        setAdditionalFlightDetails(result[0]);
      }
    });
  }, []);

  const resetAdditionalFlightDetails = useCallback(() => {
    setAdditionalFlightDetails(undefined);
  }, []);

  const ctx = useMemo(() => (
    {
      eventDetails,
      additionalFlightDetails,
      fetchEventDetails,
      fetchAdditionalFlightDetails,
      resetAdditionalFlightDetails,
    }), [
    eventDetails,
    additionalFlightDetails,
    fetchEventDetails,
    fetchAdditionalFlightDetails,
    resetAdditionalFlightDetails,
  ]);

  return <EventDetailsContext.Provider value={ctx}>{children}</EventDetailsContext.Provider>;
};

export const useEventDetails = () => useContext(EventDetailsContext);

export default EventDetailsProvider;
