import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ breakpoints, palette, spacing }: Theme) => createStyles({
  root: {
    flexGrow: 0,
    flexShrink: 0,
  },
  title: {
    color: 'inherit',
    textTransform: 'none',

    [breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  profileCaption: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: palette.grey[500],
    fontWeight: 500,
  },
  profileLinkIcon: {
    marginLeft: spacing(1),
    color: palette.common.white,
  },
});
