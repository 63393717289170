import { useEffect, useRef, ChangeEvent } from 'react';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { Checkbox, FormControlLabel, makeStyles } from '@lsy-netline-ui/netline-ui';
import { useTranslation } from 'react-i18next';
import { dropWhile, dropRightWhile } from 'lodash';

import { useProfile } from '@crew-webui/common/hooks';
import { RawEventDetails } from '@crew-webui/common/view/components';

import { SelectionMode } from 'consts';
import styles from './RosterChange.styles';

const predicate = (value: string) => value.replace(/(<([^>]+)>)/gi, '').trim() === '';
const dropEmptyLines = (array: string[]) => dropWhile(dropRightWhile(array, predicate), predicate);

const useStyles = makeStyles(styles, { name: 'RosterChange' });

type Props = {
  className?: string;
  change: {
    items: any[];
    confirmable: boolean;
    date: string;
  };
  checked: boolean;
  value: string;
  onCheckBoxChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabledCheckBox: boolean;
  selectedEventDate: string;
  verticalView: boolean;
};

const RosterChange = ({
  className = '',
  change: { items, confirmable, date }, checked, value, onCheckBoxChange, disabledCheckBox, selectedEventDate, verticalView,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const freeTexts = items
    .map((item) => item.freeText && item.freeText.trimEnd())
    .filter((item) => item)
    .join('\n');
  const {
    profile: {
      notificationConfirmationSelectionMode,
    },
  } = useProfile();
  const dateRef = useRef<any>(null);
  const utcSelectedEventDate = DateTime.fromISO(selectedEventDate).toUTC();
  const utcDate = DateTime.fromISO(date).toUTC();

  useEffect(() => {
    if ((notificationConfirmationSelectionMode !== SelectionMode.ALL) && dateRef.current && (utcSelectedEventDate.equals(utcDate))) {
      dateRef.current.scrollIntoView({ block: 'nearest' });
    }
  }, [notificationConfirmationSelectionMode, utcSelectedEventDate, utcDate]);

  return (
    <div className={clsx(classes.root, className)} data-test-id="roster-change">
      <div className={classes.dateAndSelect} ref={dateRef}>
        <div className={classes.date} data-test-id="roster-change-date">{t('date.dateMonthText', { date: utcDate })}</div>
        {(notificationConfirmationSelectionMode !== SelectionMode.ALL && confirmable && (
          <FormControlLabel
            className={classes.select}
            classes={{ label: classes.selectLabel }}
            control={(
              <Checkbox
                data-test-id="roster-change-checkbox"
                disabled={disabledCheckBox}
                checked={checked}
                value={value}
                onChange={onCheckBoxChange}
                color="default"
                classes={{ root: classes.checkBox }}
              />
            )}
            data-test-id="roster-change-checkbox-label"
            label={t(checked ? 'notificationConfirmation.selected' : 'notificationConfirmation.notSelected')}
          />
        ))}
      </div>

      {freeTexts && (
        <>
          <div className={classes.dutyAge} data-test-id="roster-change-message-title">
            {t('notificationConfirmation.schedulerMessage')}
          </div>
          <pre className={classes.freeText} data-test-id="roster-change-free-text">{freeTexts}</pre>
        </>
      )}

      <div className={verticalView ? undefined : classes.horizontalView}>
        <div>
          <div className={classes.dutyAge} data-test-id="roster-change-old-duty-title">
            {t('notificationConfirmation.oldDuty')}
          </div>
          <RawEventDetails
            className={clsx(classes.dutyDetails, classes.old)}
            items={dropEmptyLines(items.map((item) => item.old))}
          />
        </div>

        <div>
          <div className={classes.dutyAge} data-test-id="roster-change-new-duty-title">
            {t('notificationConfirmation.newDuty')}
          </div>
          <RawEventDetails
            className={clsx(classes.dutyDetails, classes.new)}
            items={dropEmptyLines(items.map((item) => item.new))}
          />
        </div>
      </div>
    </div>
  );
};

export default RosterChange;
