import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  root: {
    'margin': '20px 0',

    '&:first-child': {
      marginTop: 0,
    },
  },
  dateAndSelect: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: '0 0 48px',
    marginRight: -9 /* checkbox padding */,
  },
  date: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
    color: palette.text.primary,
  },
  select: {
    height: 24,
    flexDirection: 'row-reverse',
    margin: '0 0 0 8px',
  },
  selectLabel: {
    fontSize: 13,
    color: palette.iadp.primaryLabelTextColor,
  },
  dutyAge: {
    fontSize: 12,
    color: palette.iadp.primaryLabelTextColor,
    margin: '16px 0 8px',
    fontWeight: 500,
    textTransform: 'uppercase',
    lineHeight: '16px',
  },
  dutyDetails: {
    flex: 1,
    padding: '12px 0 12px 12px',
    fontSize: 12,
    borderRadius: 4,
    overflowX: 'initial',
  },
  old: {
    backgroundColor: palette.calendar.day.backgroundColor,
    color: palette.iadp.rosterChangeDetails.oldDutyTextColor,
  },
  new: {
    backgroundColor: palette.background.secondary,
    color: palette.iadp.rosterChangeDetails.newDutyTextColor,
    boxShadow: '0, 2, 8, 0, rgba(0,0,0,0.2)',
  },
  checkBox: {
    color: palette.iadp.checkboxColors.onEditRoles.backgroundColor,
  },
  freeText: {
    margin: 0,
    fontFamily: 'Roboto, monospace',
    fontSize: 14,
    fontWeight: 500,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    lineHeight: '24px',
  },
  dutyDetailsText: {
    margin: 0,
  },
  horizontalView: {
    'display': 'inline-flex',
    'overflow': 'auto',
    'maxWidth': '95vw',
    '& div:nth-child(1)': {
      marginRight: 10,
    },
  },
});
