import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, palette: { iadp: { primaryLabelTextColor, borderColor } } }: Theme) => createStyles({
  root: {
    'flexShrink': 0,
    'color': palette.text.primary,
    'borderBottom': `1px solid ${borderColor}`,
    'padding': '16px 0',
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      paddingBottom: 0,
      border: 0,
    },
  },
  noBorder: {
    borderBottom: 'none',
  },
  title: {
    color: primaryLabelTextColor,
    fontSize: 12,
    fontWeight: 'bold',
    margin: '8px 0',
    textTransform: 'uppercase',
    lineHeight: '20px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
});
