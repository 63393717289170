import { makeStyles, DialogActions as NuiDialogActions } from '@lsy-netline-ui/netline-ui';
import { DialogActionsProps as MuiDialogActionsProps } from '@material-ui/core/DialogActions';
import clsx from 'clsx';

import styles from './DialogActions.styles';

export const useStyles = makeStyles(styles, { name: 'DialogActions' });

const DialogActions = (props: MuiDialogActionsProps) => {
  const classes = useStyles();

  return (
    <NuiDialogActions data-test-id="dialog-actions" {...props} className={clsx(classes.root, props.className)} />
  );
};

export default DialogActions;
