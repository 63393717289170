import { MyDataContactType } from '@crew-webui/common/consts';

import {
  CustomerProfile, MAX_CONTACT_PRIO, MyDataField,
} from 'consts';
import {
  getAccessProperties,
  getEmailProperties,
  getPrioProperties,
  getRegionCodeProperties,
  getTelProperties,
  getTextFieldProperties,
} from 'utils';

export const getEmailFields = (
  t: T,
  { buddyModeEnabled }: { buddyModeEnabled?: boolean } = {},
): MyDataField[] => [
  getPrioProperties(t, { max: MAX_CONTACT_PRIO }),
  {
    ...getEmailProperties(t),
    label: t('myData.contactInfo.emailAddress'),
    valuePath: 'contact',
  },
  getAccessProperties(t, { buddyModeEnabled }),
  {
    ...getTextFieldProperties(t, { maxLength: 20 }),
    label: t('myData.fields.remark'),
    valuePath: 'remark',
  },
];

export const getPhoneFields = (
  t: T,
  profile: CustomerProfile,
  { buddyModeEnabled }: { buddyModeEnabled?: boolean } = {},
): MyDataField[] => [
  getPrioProperties(t, { max: 99 }),
  {
    ...getTextFieldProperties(t, { required: true }),
    valueTransform: (value: any) => {
      if (value === undefined) {
        return undefined;
      }
      return value === MyDataContactType.MOBILE ? t('common.yes') : t('common.no');
    },
    options: [
      { value: MyDataContactType.MOBILE, label: t('common.yes') },
      { value: MyDataContactType.PHONE, label: t('common.no') },
    ],
    label: t('myData.fields.isMobile'),
    valuePath: 'natKey.type',
    editOnlyWhenCreating: true,
  },
  {
    ...getRegionCodeProperties(t),
    label: t('myData.fields.regionCode'),
    valuePath: 'phoneNumber.regionCode',
    fieldSize: 2,
  },
  {
    ...getTelProperties(t, { maxLength: 20 }),
    label: t('myData.fields.areaCode'),
    valuePath: 'phoneNumber.areaCode',
    fieldSize: 2,
  },
  {
    ...getTelProperties(t, { maxLength: 40 }),
    label: t('myData.fields.localNo'),
    valuePath: 'phoneNumber.localNo',
    fieldSize: 2,
  },
  {
    ...getTextFieldProperties(t, { required: true }),
    label: t('myData.fields.type'),
    valuePath: 'phoneTypeCode',
    options: profile.phoneTypeCodes.map((value) => ({ value, label: value })),
  },
  getAccessProperties(t, { buddyModeEnabled }),
  {
    ...getTextFieldProperties(t, { maxLength: 20 }),
    label: t('myData.fields.remark'),
    valuePath: 'remark',
  },
];
