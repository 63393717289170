import { useCallback, useMemo } from 'react';

import { useUserPreferences } from '@crew-webui/common/hooks';
import { simpleHash } from '@crew-webui/common/utils';

const useMarkingAsRead = (localStorageId: string) => {
  const { [localStorageId]: readIds = [], setPref } = useUserPreferences();

  const markAsRead = useCallback((id: string, textToHash: string) => {
    const text = simpleHash(textToHash);
    const itemIndex = readIds.findIndex((item: any) => item.id === id);
    if (itemIndex !== -1) {
      // If the ID already exists, update the date
      readIds[itemIndex].text = text;
    } else {
      // If the ID doesn't exist, add it with the provided date
      readIds.push({ id, text });
    }
    setPref(localStorageId, readIds);
  }, [localStorageId, readIds, setPref]);

  return useMemo(() => ({ readIds, markAsRead }), [readIds, markAsRead]);
};

export default useMarkingAsRead;
