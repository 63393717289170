export * from './dutiesTypes';
export * from './myDataTypes';

export const isModule = `${process.env.REACT_APP_ISMODULE}` === 'true';

export const COOKIE_ACCEPT = 'cookie-accept';

export const CSRF_TOKEN_NAME = 'x-csrf-token';

export const COOKIE_MORE_INFORMATION_LINK = 'resources/cookiesInformation/EN/index.html';

export const BUDDY_URL_SEARCH_PARAM = 'buddyCrmId';
export const FOREIGN_LEG_SEARCH_PARAM = 'isForeign';

export enum PemsDomain {
  IADP = 'idp',
  NLWS = 'nlws',
  OT = 'ot',
}

export enum CrewComDomain {
  IADP = 'IADP',
  OT = 'OT',
}

export enum FeatureAccess {
  WRITE = 'WRITE',
  READ = 'READ',
  NONE = 'NONE',
}

export enum CalendarState {
  CLOSED = 'CLOSED',
  HALFOPEN = 'HALFOPEN',
  OPEN = 'OPEN',
}

export enum AvailableLanguages {
  EN = 'en',
  FR = 'fr',
}

export const IMAGE_SOURCES = 'resources/images/';

export enum LocalStorageKeys {
  SHOW_ROSTER_TAGS = 'showRosterTags',
  SHOW_PAIRING_COUNT = 'showPairingCount',
  LOCATION_ALWAYS_POPUP_SHOWN = 'locationNeededShown',
  LAST_CHECKIN_DIALOG_ID = 'lastCheckinDialogId',
  LAST_READ_NEWS_TIMESTAMP = 'newsLastRead',
  LAST_READ_DAILY_REMARK = 'lastReadDailyRemarks',
  LAST_READ_BIDS_TIMESTAMP = 'lastReadBidsTimestamp',
  SHOWN_DAILY_REMAK_IDS = 'shownDailyRemarkIds',
  READ_DAILY_REMARK_IDS = 'readDailyRemarkIds',
  SHOWN_NEWS_IDS = 'shownNewsIds',
  SHOWN_BIDS_IDS = 'shownBidsIds',
  ALREADY_LOGGED_IN = 'alreadyLoggedIn',
  CACHE_PREFIX = 'cache',
  SERVER_CONFIG = 'iadpServerConfig',
  AUTO_REFRESH_ENABLED = 'autoRefreshEnabled',
  SMS_AGREEMENT_CHECKED = 'smsAgreementChecked',
  PUSH_NOTIFICATION_AGREEMENT_CHECKED = 'pushNotificationAgreementChecked',
  LAST_HANDLED_PUSH_MESSAGE_ID = 'lastHandledPushMessageId',
  ONLINE_AUTHORIZED = 'onlineAuthorized',
  CACHE_ON_CHECK_IN = 'cacheOnCheckin',
  CACHE_ON_LOG_IN = 'cacheOnLogin',
  HAS_PREV_PENDING_CHANGE = 'hasPrevPendingChange',
}

export const LogLevel = {
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  OFF: 'OFF',
};

export const NEWS_HIDING_TIMEOUT = 300;

export const NewsStatus = {
  READ: 'READ',
  EXPIRED: 'EXPIRED',
};

export const NewsType = {
  Broadcast: 'BROADCAST',
  CheckInAlert: 'CHECKINALERT',
  RosterChange: 'ROSTERCHANGE',
};

export enum PersistTypes {
  // use salt to encrypt rest response
  SaltedPersist = 'SaltedPersist',
  // use password to encrypt rest response
  PasswordPersist = 'PasswordPersist',
  // handle logout in offline mode
  LogoutPersist = 'LogoutPersist',
  // handle renew in offline mode
  RenewPersist = 'RenewPersist',
  // handle login in offline mode
  LoginPersist = 'LoginPersist',
  // handle authentication in offline mode
  AuthPersist = 'AuthPersist',
}

export interface ServerEnabling {
  featureId: string;
  value: string;
}

export type FilterSettings = {
  filterName: string;
  enabled: boolean;
};

export type DesktopHeaderProps = {
  actions?: React.ReactNode;
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  showBackButton?: boolean;
  actionsNewLine?: boolean;
  bottomContent?: React.ReactNode;
  onBack?: Function;
};

// source: https://stackoverflow.com/a/49139933
export const SmartPunctationSymbols = {
  '\u2018': '\'',
  '\u2019': '\'',
  '\u201B': '\'',
  '\u2032': '\'',
  '\u2035': '\'',

  '\u201C': '"',
  '\u201D': '"',
  '\u201F': '"',
  '\u2033': '"',
  '\u2036': '"',

  '\u2013': '-',
  '\u2014': '-',
};

export const THEME_BREAKPOINTS = {
  values: {
    xs: 0, sm: 720, md: 960, lg: 1280, xl: 1920,
  },
} as const;

export const CALENDAR_SPACING_TOP = 56;
