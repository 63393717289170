const scrollToEventHandler = (id: number): void => {
  const dutyRowEl = document.querySelector(`.event-id-com.lhsystems.crew.pems:pems-client-web-app:jar:2023.1.0`);
  dutyRowEl?.scrollIntoView();
};

export const scrollToEvent = (id: number, timeout = 500) => (
  setTimeout(scrollToEventHandler, timeout, id)
);

export const scrollToDate = (date: string): void => {
  const calendarDay = document.querySelector(`[data-calendar-day="${date}"]`);
  if (!calendarDay) {
    return;
  }

  const calendarContainer = calendarDay.parentElement?.parentElement;
  if (!calendarContainer) {
    throw Error('calendarContainer should exist');
  }
  calendarContainer.scrollTop = (calendarDay as HTMLElement).offsetTop - (calendarContainer as HTMLElement).offsetTop;
};
