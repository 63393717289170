import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  title: {
    color: palette.text.secondaryLabel,
    fontSize: 13,
  },
  content: {
    marginBottom: spacing(2.5),
    fontWeight: 500,
    lineHeight: '20px',
  },
  meta: {
    color: palette.text.secondaryLabel,
    fontSize: 12,
    lineHeight: '16px',
  },
});
