import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing, classPrefix = '' }: Theme) => createStyles({
  root: {
    margin: 0,
    marginTop: spacing(2.5),
    marginBottom: spacing(3),
    padding: 0,
    listStyle: 'none',
  },
  item: {
    'display': 'flex',
    'minHeight': spacing(8),
    'borderTop': `1px solid ${palette.divider}`,

    '&:last-child': {
      borderBottom: `1px solid ${palette.divider}`,
    },
  },
  text: {
    color: palette.text.label,
  },
  profileLink: {
    'flexGrow': 1,
    'marginRight': -spacing(1.5),
    'marginLeft': -spacing(1.5),
    'padding': spacing(1.5),
    'textTransform': 'none',

    '&:hover': {
      backgroundColor: 'rgba(17, 17, 17, 0.04)',
    },
  },
  profileLinkEndIcon: {
    marginRight: spacing(2),
    color: palette.text.secondary,
  },
  profileLinkLabel: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControlLabel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginRight: spacing(0.5),
    marginLeft: 0,
  },
  formControlLabelLabel: {
    fontWeight: 500,
  },
  select: {
    [`& .${classPrefix}MuiFormLabel-root .${classPrefix}MuiTypography-root`]: {
      fontWeight: 500,
    },
  },
});
