import services from '../services';
import ServerLogger, { DEFAULT_FLUSH_INTERVAL, DEFAULT_FLUSH_LIMIT } from './ServerLogger';

const { NODE_ENV } = process.env;

type Logger = Console & {
  assignCustomData: (customData: any) => void;
  setLogLevel: (logLevel: string) => void;
  setOffline?: (offline: boolean) => void;
};

const logger: Logger = new (ServerLogger as any)({
  transport: (logRequest: any) => services.sendLog(logRequest),
  flushInterval: NODE_ENV !== 'test' ? DEFAULT_FLUSH_INTERVAL : null,
  flushLimit: NODE_ENV !== 'test' ? DEFAULT_FLUSH_LIMIT : null,
  useConsole: NODE_ENV === 'development' || process.env.REACT_APP_CONSOLE_LOG,
});

export default logger;
