import { MyDataFieldType, PersonalDataFieldPaths } from 'consts';
import {
  getCountryCodeProperties,
  getDateProperties,
  getGenderProperties,
  getStateCodeProperties,
  getTextFieldProperties,
  getYesNoProperties,
} from 'utils';

export default (t: T): MyDataFieldType[] => [
  {
    ...getTextFieldProperties(t, { required: true, maxLength: 30 }),
    label: t('myData.fields.firstName'),
    valuePath: PersonalDataFieldPaths.firstName,
  },
  {
    ...getTextFieldProperties(t, { maxLength: 30 }),
    label: t('myData.fields.middleName'),
    valuePath: PersonalDataFieldPaths.middleName,
  },
  {
    ...getTextFieldProperties(t, { required: true, maxLength: 30 }),
    label: t('myData.fields.lastName'),
    valuePath: PersonalDataFieldPaths.lastName,
  },
  {
    ...getDateProperties(t),
    label: t('myData.fields.dateOfBirth'),
    valuePath: PersonalDataFieldPaths.birthDate,
    fieldSize: 3,
  },
  {
    ...getTextFieldProperties(t, { required: true, maxLength: 25 }),
    label: t('myData.fields.placeOfBirth'),
    valuePath: PersonalDataFieldPaths.birthPlace,
    fieldSize: 3,
  },
  {
    ...getCountryCodeProperties(t),
    label: t('myData.fields.countryOfBirth'),
    valuePath: PersonalDataFieldPaths.birthCountry,
    fieldSize: 3,
  },
  {
    ...getTextFieldProperties(t, { maxLength: 25 }),
    ...getStateCodeProperties(
      t,
      { countryCodePath: PersonalDataFieldPaths.birthCountry, stateCodePath: PersonalDataFieldPaths.birthState },
    ),
    label: t('myData.fields.stateOfBirth'),
    fieldSize: 3,
  },
  {
    ...getCountryCodeProperties(t),
    label: t('myData.fields.nationality'),
    valuePath: PersonalDataFieldPaths.nationality,
  },
  {
    ...getGenderProperties(t),
    valuePath: PersonalDataFieldPaths.gender,
    fieldSize: 3,
  },
  {
    ...getTextFieldProperties(t, { required: true }),
    ...getYesNoProperties(t),
    label: t('myData.fields.smoking'),
    valuePath: PersonalDataFieldPaths.isSmoker,
    fieldSize: 3,
  },
];
