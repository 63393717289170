import { createContext } from 'react';

import { BlockMonth } from '../../consts';

export type BlockMonthDataContextType = {
  getBlockMonthData: (blockMonth: BlockMonth) => { label: string; value: string }[] | undefined;
};

const BlockMonthDataContext = createContext<BlockMonthDataContextType>({
  getBlockMonthData: () => undefined,
});

export default BlockMonthDataContext;
