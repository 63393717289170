import { createStyles } from '@lsy-netline-ui/netline-ui';
import { darken } from '@lsy-netline-ui/netline-ui/styles';

export default ({ palette }: Theme) => createStyles({
  root: {
    'opacity': 0.4,
    'border': 0,
    'backgroundColor': 'transparent',
    'cursor': 'pointer',
    'width': '100%',

    '&:hover $inner': {
      '&::before, &::after': {
        backgroundColor: darken(palette.calendar.toggleButtonBackgroundColor, 0.1),
      },
    },
  },
  inner: {
    'position': 'relative',
    'height': 4,
    'width': 48,
    'margin': '8px auto',

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 25,
      height: 4,
      borderRadius: 2,
      backgroundColor: palette.calendar.toggleButtonBackgroundColor,
      transition: 'transform .5s',
    },
    '&::before': {
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
    },
    '&::after': {
      right: 0,
      borderTopRightRadius: 2,
      borderBottomRightRadius: 2,
    },
  },
  arrowUp: {
    '& $inner': {
      '&::before': { transform: 'rotate(-15deg)' },
      '&::after': { transform: 'rotate(15deg)' },
    },
  },
  arrowDown: {
    '& $inner': {
      '&::before': { transform: 'rotate(15deg)' },
      '&::after': { transform: 'rotate(-15deg)' },
    },
  },
});
