import { CalendarState } from '../../../../consts';
import { useDutiesPresentation, useRemember, useSwipeable } from '../../../../hooks';

const useCalendarToggle = (fullyOpenStateEnabled: boolean) => {
  const { calendarState, setCalendarState } = useDutiesPresentation();
  const { clearData } = useRemember();

  const toggleCalendar = (direction?: 'up' | 'down' | 'left' | 'right') => {
    if ([CalendarState.CLOSED, CalendarState.OPEN].includes(calendarState)) {
      setCalendarState(CalendarState.HALFOPEN);
    } else if (direction === 'down' || (!direction && fullyOpenStateEnabled)) {
      setCalendarState(CalendarState.OPEN);
    } else {
      clearData('rosterPeriodContainer');
      setCalendarState(CalendarState.CLOSED);
    }
  };
  const handlers = useSwipeable({
    onSwipeSuccess: toggleCalendar,
    upAllowed: calendarState !== CalendarState.CLOSED,
    downAllowed: calendarState === CalendarState.CLOSED || (fullyOpenStateEnabled && calendarState === CalendarState.HALFOPEN),
    animateFrom: Infinity, // do not animate
    onClick: () => toggleCalendar(),
  });

  return handlers;
};

export default useCalendarToggle;
