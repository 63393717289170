import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  root: {
    'white-space': 'pre-wrap',
    'wordBreak': 'break-word',

    '& a': {
      color: palette.primary.main,
      textDecoration: 'none',
    },
    '& > *:first-child': {
      marginTop: 0,
    },
  },
});
