import {
  useCallback, useEffect, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Check as CheckIcon } from '@material-ui/icons';
import {
  makeStyles, Button, Collapse, Typography,
} from '@lsy-netline-ui/netline-ui';

import { NewsType, NEWS_HIDING_TIMEOUT } from '../../consts';
import {
  useCrewAuth, useDialog, useDomain, useProfile, useSwipeable,
} from '../../hooks';
import styles from './NewsCard.styles';

const useStyles = makeStyles(styles, { name: 'NewsCard' });

type Props = {
  Icon?: React.ElementType;
  color?: string;
  deleting: boolean;
  checkInLogicalId?: string;
  expired?: boolean;
  id?: string;
  markAsRead: Function;
  properties?: {
    fromDateTimeUTC?: string;
    toDateTimeUTC?: string;
  };
  read?: boolean;
  remove: Function;
  subtitle?: string;
  title?: string;
  type?: string;
};

const NewsCard = ({
  Icon,
  checkInLogicalId,
  color,
  deleting,
  expired,
  id,
  markAsRead,
  properties,
  read,
  remove,
  subtitle,
  title,
  type,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { crmId } = useCrewAuth();
  const timeoutId = useRef();
  const { showDialog } = useDialog();
  const { routePrefix } = useDomain();
  const { profile: { newsListing: { delete: { Icon: DeleteIcon } } } } = useProfile();

  // clear up when unmounting
  useEffect(() => () => clearTimeout(timeoutId.current), []);

  const handleDelete = () => {
    remove(id);
  };

  const handleClick = useCallback(() => {
    // For roster changes and check in alerts READ state will be set from backend
    if (!read && !expired && type === NewsType.Broadcast) {
      markAsRead(id);
    }

    if (expired) {
      showDialog({
        title: t('common.info'),
        content: t('news.expiredDialogText'),
        buttons: t('button.ok'),
      });
      return;
    }

    if (type === NewsType.CheckInAlert) {
      history.push(`${routePrefix}/check-in/${crmId}/${encodeURIComponent(checkInLogicalId!)}`);
    }
    if (type === NewsType.RosterChange) {
      history.push({
        pathname: `${routePrefix}/notifications/${crmId}`,
        state: {
          fromDateTimeUTC: properties?.fromDateTimeUTC, toDateTimeUTC: properties?.toDateTimeUTC, redirectTo: '/news',
        },
      });
    }
    if (type === NewsType.Broadcast) {
      history.push(`${routePrefix}/news/com.lhsystems.crew.pems:pems-client-web-app:jar:2023.1.0`);
    }
  }, [
    checkInLogicalId, crmId, expired, history, id, markAsRead, properties?.fromDateTimeUTC, properties?.toDateTimeUTC, read, routePrefix, showDialog, t, type]);

  const handlers = useSwipeable({
    onClick: handleClick,
    onSwipeSuccess: handleDelete,
    rightAllowed: true,
  });

  return (
    <Collapse className={classes.root} in={!deleting} timeout={NEWS_HIDING_TIMEOUT}>
      <div className={classes.wrapper} data-test-id="news-item">
        <DeleteIcon className={classes.deleteIcon} />
        <Button
          {...handlers}
          className={clsx(classes.button, { [classes.read]: read || expired })}
          data-test-id="news-button"
          disableRipple
        >
          {Icon ? (
            <Icon
              className={classes.icon}
              data-test-id="news-item-icon"
              style={color ? { backgroundColor: color } : undefined}
            />
          ) : (
            <div className={classes.icon} />
          )}
          <div className={classes.content}>
            <Typography className={classes.title} data-test-id="news-item-title">{title}</Typography>
            <Typography className={classes.subtitle} data-test-id="news-item-subtitle">{subtitle}</Typography>
          </div>
          {read || expired ? (
            <CheckIcon className={classes.readMark} data-test-id="news-item-read-mark" fontSize="small" />
          ) : (
            <div className={classes.unReadMark} data-test-id="news-item-unread-mark" />
          )}
        </Button>
      </div>
    </Collapse>
  );
};

export default NewsCard;
