import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { services } from '../../api';
import { checkResponseSuccess } from '../Auth';
import { convertEvent, debounce } from '../../utils';
import { useTodayOnServer } from '../common';
import { TimeMode, EventFromServer, BUDDY_URL_SEARCH_PARAM } from '../../consts';
import { useCrewAuth } from '../CrewAuth';
import { useDomain } from '../Domain';
import { useProfile } from '../Profile';
import DutiesModelContext, {
  BuddyDetails, DutiesModelState, EventsResponse, NotificationPeriod,
} from './DutiesModelContext';
import * as dutiesModelUtils from './dutiesModelUtils';

const debouncedLoadDuties = debounce(services.loadDutyData);

const DutiesModelProvider = ({ children, useOpenPairing = false }: { children: React.ReactNode; useOpenPairing?: boolean }) => {
  const { crmId, authenticated } = useCrewAuth();
  const { pemsDomain } = useDomain();
  const { profile, profile: { calendarWeekStartMonday, showLocalTime, showUTCTime } } = useProfile();
  const getTodayOnServer = useTodayOnServer();
  const history = useHistory();
  const { search: locationSearch } = useLocation();

  const [state, setState] = useState<DutiesModelState>({
    activeBlockMonthId: undefined,
    events: [],
    flightSearchLoading: false,
    flightSearchResult: [],
    openPairingStatistics: [],
    blockMonthList: [],
    rosterPeriod: undefined,
    hasLoaded: false,
  });

  const [showParingCount, setShowPairingCount] = useState<boolean>(false);
  const [buddyMode, setBuddyMode] = useState<BuddyDetails>();

  const {
    activeBlockMonthId, events, blockMonthList, hasLoaded, rosterPeriod, openPairingStatistics, flightSearchLoading, flightSearchResult,
  } = state;

  const useUtc = useMemo(() => !showLocalTime && showUTCTime, [showLocalTime, showUTCTime]);

  const loadPairingCountPerDay = useCallback(() => setShowPairingCount(true), []);

  const handleResponse = useCallback(({ data }: { data: EventsResponse }) => {
    const { result } = data;
    if (!result) {
      return undefined;
    }

    const newState = dutiesModelUtils.transformEventsResponse(result, profile);
    setState((prevState) => ({
      ...newState,
      flightSearchResult: prevState.flightSearchResult,
      activeBlockMonthId: (
      // keep the old value if already set
        prevState.activeBlockMonthId ??
          // set it to the one which contains the current date
          dutiesModelUtils.getCurrentBlockMonth(newState.blockMonthList, getTodayOnServer())?.id ??
          // set it to the last one
          newState.blockMonthList[newState.blockMonthList.length - 1]?.id
      ),
    }));
    return newState.events;
  }, [getTodayOnServer, profile]);

  const activeBlockMonth = blockMonthList.find(({ id }) => id === activeBlockMonthId);

  const activeBlockMonthIndex = blockMonthList.findIndex(({ id }) => id === activeBlockMonthId);

  const canGoToNextBlockMonth = activeBlockMonthIndex !== undefined && activeBlockMonthIndex < blockMonthList.length - 1;

  const canGoToPrevBlockMonth = activeBlockMonthIndex !== undefined && activeBlockMonthIndex > 0;

  const intervalsToShowInCalendar = useMemo(
    () => blockMonthList.map(
      (blockMonth) => dutiesModelUtils.calculateIntervalToShowInCalendar(events, calendarWeekStartMonday, blockMonth),
    ),
    [blockMonthList, calendarWeekStartMonday, events],
  );

  const intervalToShowInCalendar = intervalsToShowInCalendar[activeBlockMonthIndex];

  const wholeIntervalToShowInCalendar = useMemo(
    () => {
      if (!intervalsToShowInCalendar.length) {
        return undefined;
      }
      const firstInterval = intervalsToShowInCalendar[0];
      const lastInterval = intervalsToShowInCalendar[intervalsToShowInCalendar.length - 1];
      return { from: firstInterval.from, to: lastInterval.to };
    },
    [intervalsToShowInCalendar],
  );

  const daysToShowInCalendar = useMemo(
    () => intervalsToShowInCalendar.map(dutiesModelUtils.getDaysToShowInCalendar),
    [intervalsToShowInCalendar],
  );

  const dutyList = useMemo(
    () => dutiesModelUtils.getDutyList(events, intervalToShowInCalendar),
    [events, intervalToShowInCalendar],
  );

  // GETTERS / SETTERS
  const getCurrentBlockMonth = useCallback(
    () => dutiesModelUtils.getCurrentBlockMonth(blockMonthList, getTodayOnServer()),
    [blockMonthList, getTodayOnServer],
  );

  const getCurrentBlockMonthIndex = useCallback(() => {
    const currentBlockMonth = getCurrentBlockMonth();
    return blockMonthList.findIndex(({ id }) => id === currentBlockMonth?.id);
  }, [blockMonthList, getCurrentBlockMonth]);

  const loadDuties = useCallback(() => (debouncedLoadDuties({
    crmId,
    pemsDomain,
    ...(!!buddyMode?.buddyCrmId && { crmId: buddyMode.buddyCrmId, buddyCrmId: crmId }),
  }) as any).then(handleResponse), [crmId, handleResponse, pemsDomain, buddyMode?.buddyCrmId]);

  const confirmDuties = useCallback(
    (confirmResult: NotificationPeriod[]) => services.loadDutyDataAfterConfirm({ crmId, confirmResult })
      .then(handleResponse),
    [crmId, handleResponse],
  );

  const resetState = useCallback(() => {
    setState((prev) => ({
      ...prev,
      events: [],
      flightSearchResult: [],
      openPairingStatistics: [],
      flightSearchLoading: false,
      hasLoaded: false,
    }));
  }, []);

  const goToNextBlockMonth = useCallback(() => {
    if (activeBlockMonthIndex < blockMonthList.length - 1) {
      setState((prevState) => ({ ...prevState, activeBlockMonthId: blockMonthList[activeBlockMonthIndex + 1].id }));
    }
  }, [activeBlockMonthIndex, blockMonthList]);

  const goToPrevBlockMonth = useCallback(() => {
    if (activeBlockMonthIndex > 0) {
      setState((prevState) => ({ ...prevState, activeBlockMonthId: blockMonthList[activeBlockMonthIndex - 1].id }));
    }
  }, [activeBlockMonthIndex, blockMonthList]);

  const goToCurrentBlockMonth = useCallback(() => {
    const currentBlockMonthIndex = getCurrentBlockMonthIndex();

    setState((prevState) => ({ ...prevState, activeBlockMonthId: blockMonthList[currentBlockMonthIndex]?.id }));
  }, [blockMonthList, getCurrentBlockMonthIndex]);

  const runFlightSearch = useCallback((flightSearchParams: any) => {
    setState((prev) => ({ ...prev, flightSearchLoading: true }));

    services.flightSearch({
      crmId,
      timeMode: useUtc ? TimeMode.UTC : TimeMode.LocalAtAirport,
      ...flightSearchParams,
      flightNumber: parseInt(flightSearchParams.flightNumber, 10),
    }).then(({ data }: { data: PemsResponse<EventFromServer[]> }) => {
      const { success, result } = data;
      if (success && result) {
        setState((prev) => ({
          ...prev,
          flightSearchLoading: false,
          flightSearchResult: result.map((event: EventFromServer) => convertEvent(event, profile, true)),
        }));
      }
    });
  }, [crmId, profile, useUtc]);

  const resetFlightSearchResult = useCallback(() => {
    setState((prev) => ({
      ...prev,
      flightSearchResult: [],
    }));
  }, []);

  const reloadBuddyDetails = useCallback((buddyId: string) => {
    services.searchBuddyRoster({ crmId, value: buddyId }).then(({ data }) => {
      const { success, result } = data;
      if (result && success) {
        const buddy = result.crewMemberList.find(
          (crewMember: { crewId: string; firstName: string; lastName: string }) => crewMember.crewId === buddyId,
        );
        if (buddy) {
          setBuddyMode({
            buddyCrmId: buddyId,
            buddyName: `${buddy.firstName} ${buddy.lastName}`,
          });
        } else {
          throw new Error(`Not found buddy to ${buddyId}`);
        }
      }
    });
  }, [crmId]);

  const enterBuddyMode = useCallback((buddy: BuddyDetails, rootPath: string) => {
    if (!authenticated) {
      return;
    }
    const searchParams = new URLSearchParams(locationSearch);
    searchParams.set(BUDDY_URL_SEARCH_PARAM, buddy.buddyCrmId);
    history.push({
      pathname: rootPath,
      search: searchParams.toString(),
    });
    resetState();
    setBuddyMode({ ...buddy });
  }, [authenticated, resetState, history, locationSearch]);

  const exitBuddyMode = useCallback(() => {
    resetState();
    setBuddyMode(undefined);
  }, [resetState]);

  useEffect(() => {
    if (crmId) {
      loadDuties();
    }
  }, [crmId, loadDuties]);

  useEffect(() => {
    const buddyCrmId = (new URLSearchParams(locationSearch)).get(BUDDY_URL_SEARCH_PARAM);
    if (buddyCrmId && buddyMode?.buddyCrmId !== buddyCrmId) {
      setBuddyMode({ buddyCrmId, buddyName: '' });
    }
  }, [locationSearch, buddyMode?.buddyCrmId]);

  useEffect(() => {
    const buddyCrmId = (new URLSearchParams(locationSearch)).get(BUDDY_URL_SEARCH_PARAM);
    if (!!buddyMode && !buddyCrmId) {
      exitBuddyMode();
    }
  }, [locationSearch, buddyMode, exitBuddyMode]);

  useEffect(() => {
    if (!authenticated) {
      return;
    }
    if (buddyMode?.buddyCrmId && !buddyMode.buddyName) {
      // set buddy name if buddyCrmId from query parameter
      reloadBuddyDetails(buddyMode.buddyCrmId);
    }
  }, [authenticated, buddyMode, reloadBuddyDetails]);

  useEffect(() => {
    if (!showParingCount || !rosterPeriod || !useOpenPairing) {
      return;
    }
    services.loadOpenPairingStatistic({
      crmId,
      fromDate: getTodayOnServer(useUtc).toISODate(),
      toDate: rosterPeriod.crmPublishedEndDateTime.toISODate(),
      timeMode: useUtc ? TimeMode.UTC : TimeMode.LocalAtAirport,
    }).then((response: any) => {
      checkResponseSuccess(response);
      const { data: { result } } = response;
      setState((prev) => ({
        ...prev,
        openPairingStatistics: result,
      }));
    });
  }, [crmId, getTodayOnServer, showParingCount, rosterPeriod, useUtc, useOpenPairing]);

  const ctx = useMemo(() => ({
    activeBlockMonth,
    activeBlockMonthIndex,
    allEvents: events,
    blockMonthList,
    buddyMode,
    canGoToNextBlockMonth,
    canGoToPrevBlockMonth,
    daysToShowInCalendar,
    dutyList,
    eventsHaveLoaded: hasLoaded,
    flightSearchLoading,
    flightSearchResult,
    openPairingStatistics,
    rosterPeriod,
    wholeIntervalToShowInCalendar,

    confirmDuties,
    enterBuddyMode,
    exitBuddyMode,
    getCurrentBlockMonth,
    getCurrentBlockMonthIndex,
    goToNextBlockMonth,
    goToPrevBlockMonth,
    goToCurrentBlockMonth,
    loadPairingCountPerDay,
    loadDuties,
    runFlightSearch,
    resetFlightSearchResult,
  }), [
    activeBlockMonth,
    activeBlockMonthIndex,
    blockMonthList,
    buddyMode,
    canGoToNextBlockMonth,
    canGoToPrevBlockMonth,
    confirmDuties,
    daysToShowInCalendar,
    dutyList,
    enterBuddyMode,
    exitBuddyMode,
    events,
    flightSearchLoading,
    flightSearchResult,
    getCurrentBlockMonth,
    getCurrentBlockMonthIndex,
    goToNextBlockMonth,
    goToPrevBlockMonth,
    goToCurrentBlockMonth,
    hasLoaded,
    loadPairingCountPerDay,
    loadDuties,
    openPairingStatistics,
    resetFlightSearchResult,
    rosterPeriod,
    runFlightSearch,
    wholeIntervalToShowInCalendar,
  ]);

  return <DutiesModelContext.Provider value={ctx}>{children}</DutiesModelContext.Provider>;
};

export const useDutiesModel = () => useContext(DutiesModelContext);

export default DutiesModelProvider;
