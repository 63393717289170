/* eslint-disable react/prop-types */
import { forwardRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBarProps } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { AppBar, IconButton, makeStyles } from '@lsy-netline-ui/netline-ui';

import { isModule } from '../../../consts';
import ModuleSwitchButton from '../AppHeader/ModuleSwitchButton';
import styles from './MobileHeader.styles';

const useStyles = makeStyles(styles, { name: 'MobileHeader' });

export type MobileHeaderProps = AppBarProps & {
  actions?: React.ReactNode;
  bottomContent?: React.ReactNode;
  title: React.ReactNode;
  showBackButton?: boolean;
  onBack?: Function;
  actionsNewLine?: boolean;
  position?: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky';
};

const MobileHeader = forwardRef<any, MobileHeaderProps>(
  // eslint-disable-next-line prefer-arrow-callback
  function MobileHeader({
    actions,
    bottomContent,
    title,
    showBackButton = false,
    onBack,
    actionsNewLine,
    position = 'sticky',
    ...otherProps
  }: MobileHeaderProps, ref) {
    const classes = useStyles();
    const history = useHistory();

    const backArrowClickHandler = useCallback(() => {
      if (onBack) {
        onBack();
      } else {
        history.goBack();
      }
    }, [history, onBack]);

    return (
      <AppBar ref={ref} className={classes.root} data-test-id="header" position={position} {...otherProps}>
        <div className={classes.content}>
          <div className={classes.leftIcons}>
            {showBackButton && (
              <IconButton className={classes.backIcon} data-test-id="back-link" onClick={backArrowClickHandler}>
                <ArrowBackIcon />
              </IconButton>
            )}
            {!showBackButton && isModule && <ModuleSwitchButton />}
          </div>
          <span data-test-id="header-title" className={classes.title}>{title}</span>
          {actions && !actionsNewLine && <div className={classes.rightIcons}>{actions}</div>}
        </div>
        {actions && actionsNewLine && <div className={classes.newLineIcons}>{actions}</div>}
        {bottomContent}
      </AppBar>
    );
  },
);

export default MobileHeader;
