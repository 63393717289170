/* eslint-disable react/prop-types */
import { forwardRef } from 'react';
import { Button, makeStyles } from '@lsy-netline-ui/netline-ui';
import { ButtonProps } from '@lsy-netline-ui/netline-ui/Button';

import styles from './SubmitButton.styles';

const useStyles = makeStyles(styles, { name: 'SubmitButton' });

export type SubmitButtonProps = ButtonProps & {
  classes?: any;
  'data-test-id'?: string;
};

const SubmitButton = forwardRef<any, SubmitButtonProps>(
  // eslint-disable-next-line prefer-arrow-callback
  function SubmitButton({ classes: classesProp, children, ...otherProps }: SubmitButtonProps, ref: any) {
    const classes = useStyles({ classes: classesProp });

    return (
      <Button
        ref={ref}
        classes={classes}
        color="primary"
        disableElevation
        variant="contained"
        {...otherProps}
      >
        {children}
      </Button>
    );
  },
);

export default SubmitButton;
