import { createRoot } from 'react-dom/client';

import '@crew-webui/common/commonSideEffects';
import { ServerConfigHandler } from '@crew-webui/common/api';

import IndexIadp from './index-iadp';

const renderApp = () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<IndexIadp />);
};

const startApp = async () => {
  // To disable the font size setting from operation system in native application
  // https://github.com/phonegap/phonegap-mobile-accessibility#mobileaccessibilityusepreferredtextzoomboolean
  if (window.MobileAccessibility) {
    window.MobileAccessibility.usePreferredTextZoom(false);
  }

  await ServerConfigHandler.initServerConfig();
  renderApp();

  if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept('view/App', () => renderApp());
  }
};

if (window.cordova) {
  document.addEventListener('deviceready', startApp);
} else {
  startApp();
}
