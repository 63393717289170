import { createContext } from 'react';
import { identity } from 'lodash';

import { initialState } from './reducer';

const AuthContext = createContext({
  ...initialState,
  loadConfig: identity,
  authorize: identity,
  authenticate: identity,
  validate: identity,
  logout: () => new Promise(() => {}),
  login: identity,
  getToken: identity,
  completeSsoLogout: identity,
});

export default AuthContext;
