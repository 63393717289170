import {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { PERSIST_CACHE } from '../../api';
import { LocalStorageKeys } from '../../consts';
import ProgressDialog, { ProgressProcess } from '../../view/components/Dialog/ProgressDialog';
import { useConnection } from '../Connection';
import { useCrewAuth } from '../CrewAuth';
import { useDutiesModel } from '../DutiesModel';
import { useUserPreferences } from '../UserPreferences';
import { useProfile } from '../Profile';
import PerformCachingContext from './PerformCachingContext';

enum CacheProgress {
  NoCaching,
  CachingWithoutEventsList,
  CachingWithEventsList,
}

type Props = {
  children: React.ReactNode;
  useCache: (
    withoutEvents: boolean,
    printCacheSize: boolean,
    cacheBeforeDaysNo: number,
    cacheAfterDaysNo: number,
    cacheFlightInfoDaysNo: number
  ) => ProgressProcess;
};

const PerformCachingProvider = ({ children, useCache }: Props) => {
  const { t } = useTranslation();
  const { online } = useConnection();
  const { authenticated } = useCrewAuth();
  const { eventsHaveLoaded } = useDutiesModel();
  const {
    profile: {
      cacheBeforeDaysNo, cacheAfterDaysNo, cacheFlightInfoDaysNo, printCacheSize,
    },
  } = useProfile();
  const { [LocalStorageKeys.CACHE_ON_LOG_IN]: cacheOnLogin, hasLoaded } = useUserPreferences();

  const prevAuthenticated = useRef<boolean>();
  const [cacheState, setCacheState] = useState<CacheProgress>(CacheProgress.NoCaching);
  const startCache = useCallback((withoutEvents = false) => (
    setCacheState(withoutEvents ? CacheProgress.CachingWithoutEventsList : CacheProgress.CachingWithEventsList)
  ), []);
  const endCache = useCallback(() => setCacheState(CacheProgress.NoCaching), []);

  const cacheProcess = useCache(
    cacheState === CacheProgress.CachingWithoutEventsList,
    printCacheSize,
    cacheBeforeDaysNo,
    cacheAfterDaysNo,
    cacheFlightInfoDaysNo,
  );

  // start caching when user logs in
  useEffect(() => {
    if (!hasLoaded || !eventsHaveLoaded) {
      return;
    }

    if (PERSIST_CACHE && authenticated && online && !prevAuthenticated.current && cacheOnLogin) {
      startCache(true);
    }
    prevAuthenticated.current = authenticated;
  }, [authenticated, cacheOnLogin, eventsHaveLoaded, hasLoaded, online, startCache]);

  const ctx = startCache;

  return (
    <PerformCachingContext.Provider value={ctx}>
      <ProgressDialog
        endCache={endCache}
        inProgress={cacheState !== CacheProgress.NoCaching}
        progressProcess={cacheProcess}
        title={t('cache.dialogTitle')}
      />

      {children}
    </PerformCachingContext.Provider>
  );
};

export const usePerformCaching = () => useContext(PerformCachingContext);

export default PerformCachingProvider;
