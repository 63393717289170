import { forwardRef } from 'react';
import { SvgIcon } from '@lsy-netline-ui/netline-ui';

const SettingsWithExclamation = forwardRef<any>((props, ref) => (
  <SvgIcon ref={ref} {...props}>
    <g transform="translate(0,2)">
      <path d="M17.14,10.936 C17.176,10.636 17.2,10.324 17.2,10 C17.2,9.676 17.176,9.364 17.128,9.064 L19.156,7.48 C19.336,7.336 19.384,7.072 19.276,6.868 L17.356,3.544 C17.236,3.328 16.984,3.256 16.768,3.328 L14.38,4.288 C13.876,3.904 13.348,3.592 12.76,3.352 L12.4,0.808 C12.364,0.568 12.16,0.4 11.92,0.4 L8.08,0.4 C7.84,0.4 7.648,0.568 7.612,0.808 L7.252,3.352 C6.664,3.592 6.124,3.916 5.632,4.288 L3.244,3.328 C3.028,3.244 2.776,3.328 2.656,3.544 L0.736,6.868 C0.616,7.084 0.664,7.336 0.856,7.48 L2.884,9.064 C2.836,9.364 2.8,9.688 2.8,10 C2.8,10.312 2.824,10.636 2.872,10.936 L0.844,12.52 C0.664,12.664 0.616,12.928 0.724,13.132 L2.644,16.456 C2.764,16.672 3.016,16.744 3.232,16.672 L5.62,15.712 C6.124,16.096 6.652,16.408 7.24,16.648 L7.6,19.192 C7.648,19.432 7.84,19.6 8.08,19.6 L11.92,19.6 C12.16,19.6 12.364,19.432 12.388,19.192 L12.748,16.648 C13.336,16.408 13.876,16.084 14.368,15.712 L16.756,16.672 C16.972,16.756 17.224,16.672 17.344,16.456 L19.264,13.132 C19.384,12.916 19.336,12.664 19.144,12.52 L17.14,10.936 L17.14,10.936 Z M10,13.6 C8.02,13.6 6.4,11.98 6.4,10 C6.4,8.02 8.02,6.4 10,6.4 C11.98,6.4 13.6,8.02 13.6,10 C13.6,11.98 11.98,13.6 10,13.6 Z" />
      <path d="M22.1474438,14.0768916 L22.3306748,0.4 L19.7916155,0.4 L19.9617587,14.0768916 L22.1474438,14.0768916 Z M21.1265849,19.6 C21.5977505,19.6 21.9554874,19.471302 22.1997955,19.2139059 C22.4441036,18.9565099 22.5662577,18.6314928 22.5662577,18.2388548 C22.5662577,17.8462168 22.4441036,17.5168371 22.1997955,17.2507157 C21.9554874,16.9845944 21.5977505,16.8515337 21.1265849,16.8515337 C20.6554192,16.8515337 20.2998637,16.9845944 20.0599182,17.2507157 C19.8199727,17.5168371 19.7,17.8462168 19.7,18.2388548 C19.7,18.6314928 19.8199727,18.9565099 20.0599182,19.2139059 C20.2998637,19.471302 20.6554192,19.6 21.1265849,19.6 Z" />
    </g>
  </SvgIcon>
));

export default SettingsWithExclamation;
