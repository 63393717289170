import { PersistTypes } from '../consts';
import createServices from './provider';
import { getServerUrl, ServiceConfig } from './api-helper';

export const BASE_API_PATH = 'rest/pems';
export const CREWCOM_API_PATH = '/api/communication/v2';

const pemsServices: Record<string, ServiceConfig> = {
  getRoleInfo: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/role/{legId}`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      legId: 'url',
    },
  },
  updateRoles: {
    method: 'put',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/roleUpdate`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      logicalId: 'body',
      roles: 'body',
    },
  },
  getRemarks: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/details/daily_remark`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: ['/daily-remarks', '/daily-remarks/:id'],
    paramTypes: {
      crmId: 'url',
      fromDtUtc: 'query',
      toDtUtc: 'query',
    },
  },
  createRemark: {
    method: 'post',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/details/daily_remark`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      data: 'data',
    },
  },
  deleteRemark: {
    method: 'delete',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/details/daily_remark`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      date: 'query',
    },
  },
  getRemarkTypes: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/details/daily_remark/types`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
    },
  },
  updateEvent: {
    method: 'put',
    url: `${BASE_API_PATH}/idp/usr/roster/{crmId}/events/{eventId}`,
    paramTypes: {
      crmId: 'url',
      eventId: 'url',
      event: 'data',
    },
  },
  checkin: {
    method: 'post',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/checkin`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      op: 'query',
      ltype: 'query',
      checkInLogicalId: 'body',
    },
  },
  getEventByEventId: {
    method: 'post',
    url: `${BASE_API_PATH}/{pemsDomain}/usr/{context}/{crmId}/eventById`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    cacheWithReqBody: true,
    paramTypes: {
      context: 'url',
      crmId: 'url',
      pemsDomain: 'url',
      eventId: 'body',
    },
  },
  getBuddyEventByEventId: {
    method: 'post',
    url: `${BASE_API_PATH}/{pemsDomain}/usr/{context}/{crmId}/eventById`,
    showProgress: true,
    paramTypes: {
      context: 'url',
      crmId: 'url',
      pemsDomain: 'url',
      eventId: 'body',
      buddyCrmId: 'query',
    },
  },
  getEventByLogicalEventId: {
    method: 'post',
    url: `${BASE_API_PATH}/{pemsDomain}/usr/{context}/{crmId}/eventById`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    cacheWithReqBody: true,
    paramTypes: {
      context: 'url',
      crmId: 'url',
      pemsDomain: 'url',
      logicalEventId: 'body',
    },
  },
  getBuddyEventByLogicalEventId: {
    method: 'post',
    url: `${BASE_API_PATH}/{pemsDomain}/usr/{context}/{crmId}/eventById`,
    showProgress: true,
    paramTypes: {
      context: 'url',
      crmId: 'url',
      pemsDomain: 'url',
      logicalEventId: 'body',
      buddyCrmId: 'query',
    },
  },
  getFlightInfo: {
    method: 'post',
    url: `${BASE_API_PATH}/idp/user/roster/flight-info`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    cacheWithReqBody: true,
    paramTypes: {
      data: 'data',
    },
  },
  getHotel: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/hotel/{hotelId}`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      hotelId: 'url',
    },
  },
  getLegCrewMembers: {
    method: 'get',
    url: `${BASE_API_PATH}/{pemsDomain}/user/roster/{crmId}/leg/{legId}/crew`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      legId: 'url',
      pemsDomain: 'url',
    },
  },
  getBuddyLegCrewMembers: {
    method: 'get',
    url: `${BASE_API_PATH}/{pemsDomain}/user/roster/{crmId}/leg/{legId}/crew`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      legId: 'url',
      pemsDomain: 'url',
      buddyCrmId: 'query',
    },
  },
  loadDutyData: {
    method: 'get',
    url: `${BASE_API_PATH}/{pemsDomain}/user/roster/{crmId}/events`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      pemsDomain: 'url',
      buddyCrmId: 'query',
    },
  },
  loadDutyDataAfterConfirm: {
    method: 'post',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/events`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      confirmResult: 'data',
    },
  },
  loadSummaryData: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/summary`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      start: 'query',
      end: 'query',
    },
  },
  loadCheckinData: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/checkin`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      checkInLogicalId: 'query',
      ltype: 'query',
    },
  },
  checkIn: {
    method: 'post',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/checkin/{checkInLogicalId}?op=confirm&ltype=mobile`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      checkInLogicalId: 'url',
    },
  },
  loadCheckinConfiguration: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/checkin/configuration`,
    persistType: PersistTypes.PasswordPersist,
    paramTypes: {
      crmId: 'url',
    },
  },
  loadRosterChangeData: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/notif?ltype=mobile`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
    },
  },
  confirmRosterChange: {
    method: 'post',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/notif?ltype=mobile&op=confirm`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      confirmData: 'data',
    },
  },
  checkIadpUpdates: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/updates`,
    persistType: PersistTypes.PasswordPersist,
    skipRepeated: true,
    paramTypes: {
      crmId: 'url',
    },
  },
  checkOtUpdates: {
    method: 'get',
    url: `${BASE_API_PATH}/ot/user/v2/all/{crmId}/updates`,
    persistType: PersistTypes.PasswordPersist,
    skipRepeated: true,
    paramTypes: {
      crmId: 'url',
    },
  },
  dropEvents: {
    method: 'post',
    url: `${BASE_API_PATH}/ot/user/roster/{crmId}/drop`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      eventIds: 'body',
      awardingInfo: 'body',
      actionSubType: 'body',
    },
  },
  addEvents: {
    method: 'post',
    url: `${BASE_API_PATH}/ot/user/otherOffers/{crmId}/events/add`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      eventIds: 'body',
      awardingInfo: 'body',
      actionSubType: 'body',
    },
  },
  swapEvents: {
    method: 'post',
    url: `${BASE_API_PATH}/ot/user/otherOffers/{crmId}/events/swap`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      addEventIds: 'body',
      dropEventIds: 'body',
      awardingInfo: 'body',
      actionSubType: 'body',
    },
  },
  login: {
    method: 'post',
    url: `${BASE_API_PATH}/login`,
    persistType: PersistTypes.LoginPersist,
    showProgress: true,
  },
  loadMasterData: {
    method: 'get',
    url: `${BASE_API_PATH}/crm/notification/masterData`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      domain: 'query',
    },
  },
  loadMyData: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/{crmId}/basicdata/{type}`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      type: 'url',
    },
  },
  deleteMyData: {
    method: 'delete',
    url: `${BASE_API_PATH}/idp/user/{crmId}/basicdata/{type}`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      type: 'url',
      natKey: 'data',
    },
  },
  createMyData: {
    method: 'post',
    url: `${BASE_API_PATH}/idp/user/{crmId}/basicdata/{type}`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      type: 'url',
      data: 'data',
    },
  },
  updateMyData: {
    method: 'put',
    url: `${BASE_API_PATH}/idp/user/{crmId}/basicdata/{type}`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      type: 'url',
      originalNatKey: 'body',
      updatedData: 'body',
    },
  },
  loadAccessRightsMode: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/{crmId}/buddy-access-rights/mode`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
    },
  },
  updateAccessRightsMode: {
    method: 'put',
    url: `${BASE_API_PATH}/idp/user/{crmId}/buddy-access-rights/mode`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      buddyAccessRightsMode: 'body',
    },
  },
  getBuddyExcludeList: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/{crmId}/buddy-access-rights`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
    },
  },
  changeBuddyExcludeList: {
    method: 'put',
    url: `${BASE_API_PATH}/idp/user/{crmId}/buddy-access-rights`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      exceptionList: 'body',
      operation: 'body',
      crewId: 'body',
    },
  },
  searchAccessRights: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/{crmId}/buddy-access-rights/search-buddy`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      value: 'query',
    },
  },
  loadPublicConfig: {
    method: 'get',
    url: `${BASE_API_PATH}/public/bootstrap/configuration`,
    persistType: PersistTypes.SaltedPersist,
    paramTypes: {
      domain: 'query',
    },
  },
  checkVersion: {
    method: 'get',
    url: `${BASE_API_PATH}/v1/public/version/{version}`,
    persistType: PersistTypes.SaltedPersist,
  },
  sendLog: {
    method: 'post',
    url: `${BASE_API_PATH}/{pemsDomain}/log`,
    persistType: PersistTypes.SaltedPersist,
    showProgress: false,
    ignoreErrors: true,
    paramTypes: {
      pemsDomain: 'url',
      crmId: 'body',
      device: 'body',
      version: 'body',
      messages: 'body',
    },
  },
  loadContext: {
    method: 'get',
    url: `${BASE_API_PATH}/ot/user/infos/{crmId}/context`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
    },
  },
  loadOperationEnablings: {
    method: 'get',
    url: `${BASE_API_PATH}/ot/user/{crmId}/operationEnablings/activity`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
    },
  },
  loadUserBids: {
    method: 'get',
    url: `${BASE_API_PATH}/ot/user/userOffers/{crmId}/activities`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
    },
  },
  loadUserBidActivity: {
    method: 'get',
    url: `${BASE_API_PATH}/ot/user/userOffers/{crmId}/activities/{id}`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      id: 'url',
    },
  },
  loadUserBidActivityNotes: {
    method: 'get',
    url: `${BASE_API_PATH}/ot/user/userOffers/{crmId}/activities/{bidId}/notes`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      bidId: 'url',
    },
  },
  createUserBidActivityNote: {
    method: 'post',
    url: `${BASE_API_PATH}/ot/user/userOffers/{crmId}/activities/{bidId}/createNote`,
    showProgress: true,
    paramTypes: {
      bidId: 'url',
      note: 'data',
    },
  },
  withdrawUserOffers: {
    method: 'post',
    url: `${BASE_API_PATH}/ot/user/userOffers/{crmId}/activities/{id}/withdraw`,
    showProgress: true,
    paramTypes: {
      id: 'url',
      crmId: 'url',
      activity: 'data',
    },
  },
  loadFilterProperties: {
    method: 'get',
    url: `${BASE_API_PATH}/ot/user/v1/otherOffers/{crmId}/filter/customProperties`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
    },
  },
  loadAvailablePairings: {
    method: 'post',
    url: `${BASE_API_PATH}/ot/user/otherOffers/{crmId}/events`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      data: 'data',
      context: 'query',
    },
  },
  loadOpenPairingStatistic: {
    method: 'get',
    url: `${BASE_API_PATH}/ot/user/roster/v1/{crmId}/open-pairing-statistic`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      fromDate: 'query',
      toDate: 'query',
      timeMode: 'query',
    },
  },
  loadOtMasterData: {
    method: 'get',
    url: `${BASE_API_PATH}/ot/user/v1/{crmId}/master-data`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
    },
  },
  loadBlockMonthData: {
    method: 'post',
    url: `${BASE_API_PATH}/{pemsDomain}/roster/v1/statistic/search`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      pemsDomain: 'url',
      crmId: 'body',
      periods: 'body',
    },
  },
} as const;

const crewComServices: Record<string, ServiceConfig> = {
  getNews: {
    method: 'post',
    url: `${CREWCOM_API_PATH}/notification/myInternalMessages`,
    persistType: PersistTypes.PasswordPersist,
    cacheWithReqBody: true,
    showProgress: ['/news', '/news/:id'],
    paramTypes: {
      domain: 'body',
    },
  },
  deleteNews: {
    method: 'delete',
    url: `${CREWCOM_API_PATH}/notification/recipient/{id}`,
    showProgress: true,
    paramTypes: {
      id: 'url',
    },
  },
  updateNewsStatus: {
    method: 'put',
    url: `${CREWCOM_API_PATH}/notification/{id}/{status}`,
    persistType: PersistTypes.PasswordPersist,
    paramTypes: {
      id: 'url',
      status: 'url',
    },
  },
  loadCrewComConfig: {
    method: 'get',
    url: `${CREWCOM_API_PATH}/notification-tag-settings/{crewComDomain}`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crewComDomain: 'url',
    },
  },
  updateCrewComConfig: {
    method: 'put',
    url: `${CREWCOM_API_PATH}/notification-tag-settings/{crewComDomain}`,
    paramTypes: {
      crewComDomain: 'url',
      subscriptions: 'body',
    },
    showProgress: true,
  },
  loadContactInfo: {
    method: 'get',
    url: `${CREWCOM_API_PATH}/contactinformation`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
  },
  updateContactInfo: {
    method: 'put',
    url: `${CREWCOM_API_PATH}/contactinformation`,
    showProgress: true,
    paramTypes: {
      email: 'body',
      phone: 'body',
    },
  },
  loadPreferredLanguage: {
    method: 'get',
    url: `${CREWCOM_API_PATH}/preferredLanguage`,
    persistType: PersistTypes.PasswordPersist,
    responseType: 'text',
  },
  updatePreferredLanguage: {
    method: 'put',
    url: `${CREWCOM_API_PATH}/preferredLanguage/{languageCode}`,
    persistType: PersistTypes.PasswordPersist,
    paramTypes: {
      languageCode: 'url',
    },
  },
  loadDevices: {
    method: 'get',
    url: `${CREWCOM_API_PATH}/device/{crewComDomain}`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crewComDomain: 'url',
    },
  },
  updateDevice: {
    method: 'post',
    url: `${CREWCOM_API_PATH}/device/{crewComDomain}`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crewComDomain: 'url',
      deviceName: 'body',
      deviceToken: 'body',
      uuid: 'body',
    },
  },
  removeDevice: {
    method: 'delete',
    url: `${CREWCOM_API_PATH}/device/{crewComDomain}/{uuid}`,
    persistType: PersistTypes.PasswordPersist,
    showProgress: true,
    paramTypes: {
      crewComDomain: 'url',
      uuid: 'url',
    },
  },
  resetBadge: {
    method: 'put',
    url: `${CREWCOM_API_PATH}/device/resetBadge/{crewComDomain}/{uuid}`,
    persistType: PersistTypes.PasswordPersist,
    paramTypes: {
      crewComDomain: 'url',
      uuid: 'url',
    },
  },
  flightSearch: {
    method: 'post',
    url: `${BASE_API_PATH}/idp/user/roster/{crmId}/flight/search`,
    showProgress: true,
    paramTypes: {
      crmId: 'url',
      timeMode: 'body',
      carrier: 'body',
      flightNumber: 'body',
      flightDepartureDate: 'body',
      depAp: 'body',
      arrAp: 'body',
    },
  },
  searchBuddyRoster: {
    method: 'get',
    url: `${BASE_API_PATH}/idp/user/{crmId}/buddy-access-rights/search-buddy-roster`,
    paramTypes: {
      crmId: 'url',
      value: 'query',
    },
  },
} as const;

export const serverConfigs: Record<string, ServiceConfig> = { ...pemsServices, ...crewComServices };

export default createServices(serverConfigs, { serverUrl: getServerUrl });
