import {
  useCallback, useContext, useMemo, useState,
} from 'react';

import { services } from '../../api';
import { BlockMonth } from '../../consts';
import { useOnlineAuthorized } from '../common';
import { useCrewAuth } from '../CrewAuth';
import { useDomain } from '../Domain';
import { useDutiesModel } from '../DutiesModel';
import { useProfile } from '../Profile';
import BlockMonthDataContext from './BlockMonthDataContext';
import { convertBlockMonthProperty, BlockMonthDataProperty } from './blockMonthDataUtils';

type BlockMonthData = {
  period: {
    from: string;
    to: string;
  };
  properties?: BlockMonthDataProperty[];
};

type BlockMonthDataResponse = PemsResponse<BlockMonthData[]>;

type BlockMonthDataProviderProps = {
  children: React.ReactNode;
  enabled: boolean;
};

const BlockMonthDataProvider = ({ children, enabled }: BlockMonthDataProviderProps) => {
  const { crmId } = useCrewAuth();
  const { pemsDomain } = useDomain();
  const { blockMonthList } = useDutiesModel();
  const { profile } = useProfile();

  const [blockMonthData, setBlockMonthData] = useState<BlockMonthData[]>([]);

  useOnlineAuthorized(() => {
    if (!enabled || !blockMonthList.length) {
      return;
    }

    const periods = blockMonthList.map(({ from, to }) => ({ from: from.toISODate(), to: to.toISODate() }));

    services.loadBlockMonthData({ crmId, pemsDomain, periods }).then(({ data }: { data: BlockMonthDataResponse }) => {
      const { result, success } = data;
      if (!result || !success) {
        return;
      }

      setBlockMonthData(result);
    });
  }, [blockMonthList, crmId, enabled, pemsDomain]);

  const getBlockMonthData = useCallback((blockMonth: BlockMonth) => {
    const item = blockMonthData.find(({ period }) => (
      period.from === blockMonth.from.toISODate() && period.to === blockMonth.to.toISODate()
    ));
    return item?.properties?.map((property) => convertBlockMonthProperty(property, profile));
  }, [blockMonthData, profile]);

  const ctx = useMemo(() => ({ getBlockMonthData }), [getBlockMonthData]);

  return <BlockMonthDataContext.Provider value={ctx}>{children}</BlockMonthDataContext.Provider>;
};

export const useBlockMonthData = () => useContext(BlockMonthDataContext);

export default BlockMonthDataProvider;
