import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@lsy-netline-ui/netline-ui';

import styles from './BroadcastContent.styles';

const useStyles = makeStyles(styles, { name: 'BroadcastContent' });

type Props = {
  message: {
    date?: string;
    sender?: string;
    subtitle?: string;
  };
};

const BroadcastContent = ({ message }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title} data-test-id="broadcast-label">{t('news.broadcastMessage')}</Typography>
      <Typography className={classes.content} data-test-id="broadcast-content">{message.subtitle}</Typography>
      <Typography className={classes.meta} data-test-id="broadcast-meta">
        {t('news.newsMeta', { sender: message.sender, date: message.date })}
      </Typography>
    </>
  );
};

export default BroadcastContent;
