import CryptoJS from 'crypto-js';
import { DateTime } from 'luxon';

import { LocalStorageKeys } from '../../consts';
import { getCacheUrl } from '../api-helper';
import logger from '../logger';
import {
  asyncStorage, syncStorage, clearLastCaches, appendTimeStamp, cutTimeStamp,
} from '../storage';

type PasswordProvider = {
  getPassword: () => string;
  setPassword: (pwd: string) => void;
};

type TimestampProvider = {
  getTimestamp: () => number;
};

type ServerTimestampProvider = TimestampProvider & {
  setServerTime: (refTimestamp: string) => void;
};

const emptyTimestampProvider: TimestampProvider = {
  getTimestamp: () => 0,
};

export const createServerTimestampProvider = (): ServerTimestampProvider => {
  let loginTime: DateTime;
  let serverTime: DateTime;

  return {
    getTimestamp: () => {
      if (!loginTime || !serverTime) {
        return 0;
      }
      const timeSinceLogin = loginTime.diffNow();
      return serverTime.minus(timeSinceLogin).toMillis();
    },

    setServerTime: (refTimestamp) => {
      loginTime = DateTime.now().toUTC();
      serverTime = DateTime.fromISO(refTimestamp);
    },
  };
};

type Cache = {
  get: (key: string) => Promise<string | undefined>;
  set: (key: string, value: string) => Promise<void>;
} | Map<string, string>;

export type ResponseCache = {
  set: <T>(config: any, response: T) => Promise<T> | T;
  get: (config: any, error?: any) => Promise<any>;
};

const SALT_KEY = 'salt';

/**
 * Password provider, to set and get password
 * @returns Returns a new password provider
 */
export const createPasswordProvider = (): PasswordProvider => {
  let password: string;
  return {
    getPassword: () => password,
    setPassword: (pwd: string) => {
      password = pwd;
    },
  };
};

/**
 * Converts the input key to a prefixed key which will be used to store the data.
 * @param {String} key
 */
const cacheKey = (key: string) => `${LocalStorageKeys.CACHE_PREFIX}_${key}`;

export const createSuccessCacheResponse = (config: any, data: any) => ({
  config,
  data,
  status: 200,
  statusText: 'OK',
  fromCache: true,
});

/**
 * Encrypted storage cache
 * Encrypt, and decrypt by password provider, and use storage cache to store data.
 * @param passwordProvider The password provider
 * @returns {an encrypted storage cache}
 */
export const createEncryptedStorageCache = (
  passwordProvider: PasswordProvider,
  timestampProvider: TimestampProvider = emptyTimestampProvider,
  fallbackCache?: Cache,
): Cache => ({
  set: async (key: string, value: string) => {
    if (!passwordProvider.getPassword()) {
      if (!fallbackCache) {
        logger.error(`Cannot store data - no password defined to key: ${key}!`);
        return;
      }

      await fallbackCache.set(key, value);
      return;
    }

    const encrypted = CryptoJS.AES.encrypt(value, passwordProvider.getPassword()).toString();

    const storeItem = () => asyncStorage.setItem(cacheKey(key), appendTimeStamp(encrypted, timestampProvider.getTimestamp()));

    const success = await storeItem();
    if (!success) {
      await clearLastCaches();
      await storeItem();
    }
  },
  get: async (key: string) => {
    if (!passwordProvider.getPassword()) {
      if (!fallbackCache) {
        logger.error(`Password not defined to key: ${key}!`);
        return undefined;
      }
      return fallbackCache.get(key);
    }

    const encrypted = await asyncStorage.getItem(cacheKey(key));
    if (typeof encrypted !== 'string') {
      logger.warn(`Cached response not found to key: ${key}!`);
      return undefined;
    }
    try {
      return CryptoJS.AES.decrypt(cutTimeStamp(encrypted), passwordProvider.getPassword()).toString(CryptoJS.enc.Utf8);
    } catch (error) {
      logger.error('Decryption failed!', error);
      return undefined;
    }
  },
});

/**
 * Create and cache salt
 * @returns the salt
 */
const createSalt = () => {
  const salt = CryptoJS.lib.WordArray.random(32).toString();
  syncStorage.setItem(cacheKey(SALT_KEY), salt, true);
  return salt;
};

/**
 * Get salt if already generated, or create a new, and store it in storage cache.
 * @returns the salt
 */
export const getOrGenerateSalt = (): string => syncStorage.getItem(cacheKey(SALT_KEY), true) || createSalt();

/**
 * Create a response cache, to store rest responses
 * @param cache The used cache, with simple set and get method.
 * @returns {a new response cache}
 */
export const createResponseCache = (cache: Cache): ResponseCache => ({
  set: async (config: any, response: any) => {
    await cache.set(getCacheUrl(config), response.data);
    return response;
  },
  get: async (config: any) => {
    const data = await cache.get(getCacheUrl(config));
    if (!data) {
      return undefined;
    }
    return createSuccessCacheResponse(config, data);
  },
});
