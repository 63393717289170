import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ breakpoints, palette, spacing }: Theme) => createStyles({
  root: {
    'position': 'relative',
    'flexGrow': 0,
    'flexShrink': 0,
    'backgroundColor': palette.common.white,
    'minHeight': 0,
    'alignItems': 'center',
    'boxShadow': '0 -2px 4px 0 rgba(0,0,0,0.24)',
    '& $indicator': {
      display: 'block',
      height: 3,
      top: 0,
    },
  },
  mergedItemsMenu: {
    minWidth: 10,
  },
  multiColumn: {
    '& $iconLabelWrapper': {
      flexDirection: 'row',
      fontWeight: 500,
    },
    '& $tab': {
      paddingTop: spacing(1.5) - 1,
      paddingBottom: spacing(1.5),
    },
    '& $text': {
      marginLeft: spacing(1),
      fontSize: 13,
      lineHeight: '20px',
      textTransform: 'uppercase',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      [breakpoints.down('sm')]: {
        marginLeft: 0,
        maxWidth: '65px',
      },
    },
  },
  indicator: {
    display: 'none',
  },
  tab: {
    flex: '1 1 0',
    maxWidth: 'unset',
    minWidth: 0,
    padding: `6px ${spacing(1)}px 2px`,
    lineHeight: '20px',
    minHeight: 0,
    [breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  tabHided: {
    display: 'none',
  },
  iconLabelWrapper: {
    'justifyContent': 'center',
    'display': 'flex',
    'fontWeight': 400,

    '&& > *:first-child': {
      marginBottom: 0,
    },
  },
  text: {
    fontSize: 10,
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    textTransform: 'none',
  },

});
