import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: spacing(1.5),
    color: palette.text.secondary,
  },
  noConnectionInfo: {
    marginLeft: spacing(2),
  },
});
