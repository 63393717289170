export default ({ palette }: Theme) => ({
  success: {
    color: palette.success.main,
  },
  error: {
    color: palette.error.main,
  },
  primary: {
    color: palette.primary.main,
  },
});
