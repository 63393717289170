import {
  CheckInTitle, CustomerProfile, ProfileFeature, SelectionMode, SummaryHeaders,
} from 'consts';

import defaultProfileSettings from './defaultProfile';

const ryrProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  enablings: {
    ...defaultProfileSettings.enablings,
    [ProfileFeature.VIEW_BLOCKMONTH_SWITCH_ON_SUMMARY]: false,
    [ProfileFeature.VIEW_CHECK_IN]: false,
    [ProfileFeature.PERFORM_CHECK_IN]: false,
    [ProfileFeature.PERFORM_ROLE_EDITING]: false,
  },
  hiddenHeadersIfNoData: [
    SummaryHeaders.LICENCES,
  ],
  showPairingNumber: false,
  showAircraftTypeCodeOfPairing: false,
  showRank: false,
  showCookieBanner: false,
  calendarWeekStartMonday: true,
  showRosterChanges: false,
  showGroundActivityCodeInCalendar: true,
  showAircraftSubType: false,
  icons: {
    ...defaultProfileSettings.icons,
  },
  checkInTitle: CheckInTitle.NO_PAIRING_NUMBER,
  notificationConfirmationSelectionMode: SelectionMode.ALL,
  agreementTexts: {
    ...defaultProfileSettings.agreementTexts,
    sms: {
      en: 'I agree with the following terms: Ryanair text message (SMS) service sends text messages to your mobile device for Ryanair related services such as flight information at no cost. Message and data rates may apply.',
    },
  },
};

export default ryrProfileSettings;
