import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { logger, services } from '../../api';
import { CrewComDomain } from '../../consts';
import { useOnlineAuthorized } from '../common';
import { useCrewAuth } from '../CrewAuth';
import { useDialog } from '../Dialog';
import { useDomain } from '../Domain';
import DeviceContext, { Device } from './DeviceContext';

const { CsiFirebase } = window;
const EVENT = 'firebase_token_refresh';

export const DeviceProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { authenticated } = useCrewAuth();
  const { showDialog } = useDialog();
  const { crewComDomain } = useDomain();

  const [devices, setDevices] = useState<Device[] | undefined>();
  const [shouldCheckToken, setShouldCheckToken] = useState(true);

  const isPushEnabled = useMemo(() => !!window.cordova && crewComDomain === CrewComDomain.IADP && !!CsiFirebase, [crewComDomain]);

  const deviceAttrs = useMemo(() => {
    if (isPushEnabled) {
      const attrs = CsiFirebase!.initialize({ logger });
      logger.info('Firebase module initialized with attributes', attrs);
      return attrs;
    }
    return {
      deviceId: '',
      deviceName: '',
    };
  }, [isPushEnabled]);

  const isCurrentDeviceRegistered = useCallback(
    () => !!devices?.some(({ uuid }) => uuid === deviceAttrs.deviceId),
    [deviceAttrs.deviceId, devices],
  );

  const loadDevices = useCallback(() => {
    if (!isPushEnabled) {
      setDevices([]);
      return;
    }

    services.loadDevices({ crewComDomain }).then(({ data }: { data: Device[] }) => {
      if (!data) {
        logger.warn('Unable to communication module device list: service failed or returned empty data - using empty defaults!', data);
        setDevices([]);
        return;
      }
      setDevices(data);
    });
  }, [crewComDomain, isPushEnabled]);

  const updateDeviceRequest = useCallback(async () => {
    const token = await CsiFirebase!.getToken();

    if (!token) {
      showDialog({
        title: t('common.error'),
        content: t('settings.noToken'),
        buttons: t('button.ok'),
        type: 'error',
      });
      return Promise.reject();
    }

    await services.updateDevice({
      crewComDomain, deviceName: deviceAttrs.deviceName, deviceToken: token, uuid: deviceAttrs.deviceId,
    });
    return loadDevices();
  }, [crewComDomain, deviceAttrs, loadDevices, showDialog, t]);

  const updateDevice = useCallback(async () => {
    await CsiFirebase!.deleteInstanceId();
    return updateDeviceRequest();
  }, [updateDeviceRequest]);

  const removeDevice = useCallback(async () => {
    await services.removeDevice({ crewComDomain, uuid: deviceAttrs.deviceId });
    setDevices((prevDevices) => prevDevices?.filter((device) => device.uuid !== deviceAttrs.deviceId));
    CsiFirebase!.deleteInstanceId();
  }, [crewComDomain, deviceAttrs.deviceId]);

  useEffect(() => {
    if (isPushEnabled && authenticated) {
      CsiFirebase!.deleteInstanceId();
    }
  }, [authenticated, isPushEnabled]);

  useOnlineAuthorized(() => {
    loadDevices();
  }, [loadDevices]);

  useEffect(() => {
    if (isPushEnabled && shouldCheckToken && devices) {
      // if the device is registered but with a different token, refresh the token on server side
      CsiFirebase!.getToken().then((token) => {
        if (token && devices.some(({ deviceToken, uuid }) => uuid === deviceAttrs.deviceId && deviceToken !== token)) {
          updateDeviceRequest();
        }
      });
      setShouldCheckToken(false);
    }
  }, [shouldCheckToken, devices, deviceAttrs.deviceId, isPushEnabled, updateDeviceRequest]);

  useEffect(() => {
    const handleTokenRefresh = () => {
      setShouldCheckToken(true);
    };
    window.addEventListener(EVENT, handleTokenRefresh);
    return () => window.removeEventListener(EVENT, handleTokenRefresh);
  }, []);

  const ctx = useMemo(() => ({
    deviceId: deviceAttrs.deviceId,
    devices,
    isCurrentDeviceRegistered,
    isPushEnabled,
    loadDevices,
    removeDevice,
    updateDevice,
  }), [deviceAttrs.deviceId, devices, isCurrentDeviceRegistered, isPushEnabled, loadDevices, removeDevice, updateDevice]);

  return <DeviceContext.Provider value={ctx}>{children}</DeviceContext.Provider>;
};

export const useDevice = () => useContext(DeviceContext);

export default DeviceProvider;
