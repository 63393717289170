import { LogCollector } from '@crew-webui/common/api';

const ViewLog = () => {
  const logs = LogCollector!.getLogs();
  return (
    <>
      {logs.map((log: any, idx: number) => (<span key={idx}> {log.type}-{log.values} <br /></span>))}
    </>
  );
};

export default ViewLog;
