import { useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ChatBubbleOutline as ChatBubbleOutlineIcon } from '@material-ui/icons';
import { makeStyles, Collapse, Fab } from '@lsy-netline-ui/netline-ui';

import {
  Briefing, BUDDY_URL_SEARCH_PARAM, Event, EventContext, FOREIGN_LEG_SEARCH_PARAM,
} from '../../../consts';
import {
  useCrewAuth, useDomain, useDutiesModel, useDutiesPresentation, useEventDetails, useMultiColumn, useProfile,
} from '../../../hooks';
// eslint-disable-next-line import/no-cycle
import DutyList from '../DutyList/DutyList';
import DutyRowIcon from './DutyRowIcon';
import DutyRowToggle from './DutyRowToggle';
import useDutyRowExpanded from './useDutyRowExpanded';
import styles from './DutyRow.styles';

const useStyles = makeStyles(styles, { name: 'DutyRow' });

const eventTypesToShowAirport = ['PAR', 'LEG', 'HOT', 'FPR', 'GPR', 'GROUND', 'OTHER'];

const SEPARATOR = '  ';

type Props = {
  availableEventsView?: boolean;
  crewMemberPartOfFlight?: boolean;
  currentEvent: boolean;
  detailView?: boolean;
  drawline?: boolean;
  DutyRowSelection?: React.ComponentType<{ context: EventContext; event: Event; parentId?: number }>;
  event: Event;
  enabledFeatures: Record<string, boolean>;
  handleExpandedLocally?: boolean;
  pairingListItem?: boolean;
  parentId?: number;
  rowKey: number;
  selectionView?: boolean;
  subevents?: boolean;
};

const DutyRow = ({
  availableEventsView,
  crewMemberPartOfFlight = true,
  currentEvent,
  detailView,
  drawline,
  DutyRowSelection,
  event,
  enabledFeatures,
  handleExpandedLocally,
  pairingListItem,
  parentId,
  rowKey,
  selectionView,
  subevents,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { crmId } = useCrewAuth();
  const { fetchEventDetails } = useEventDetails();
  const { routePrefix } = useDomain();
  const {
    highlightCurrentEvent,
    highlightedEvent,
    setHighlightedDay,
    setHighlightedEvent,
  } = useDutiesPresentation();
  const multiColumn = useMultiColumn();
  const {
    profile: {
      buttonIcons, localTimePostfix, showArrivalAirportOfPairing, utcTimePostfix,
    },
    theme,
  } = useProfile();
  const { expanded, toggleExpanded } = useDutyRowExpanded(!!handleExpandedLocally, event.id, !!detailView);
  const { search } = useLocation();
  const { buddyMode } = useDutiesModel();

  const context = useMemo(() => {
    if (availableEventsView) {
      return EventContext.OtherOffers;
    }
    if (crewMemberPartOfFlight) {
      return EventContext.Roster;
    }
    return EventContext.Flight;
  }, [availableEventsView, crewMemberPartOfFlight]);

  useEffect(() => {
    const { events } = event;

    if (availableEventsView && expanded && !events) {
      fetchEventDetails({ context: EventContext.OtherOffers, logicalEventId: event.logicalId });
    }
  }, [availableEventsView, event, expanded, fetchEventDetails]);

  const getHistorySearchParams = useCallback(() => {
    const searchParams = new URLSearchParams(search);
    if (buddyMode) {
      searchParams.set(BUDDY_URL_SEARCH_PARAM, buddyMode.buddyCrmId);
    }
    if (!crewMemberPartOfFlight) {
      searchParams.set(FOREIGN_LEG_SEARCH_PARAM, 'true');
    }

    return { search: searchParams.toString() };
  }, [buddyMode, crewMemberPartOfFlight, search]);

  const getNavigateTo = useCallback(() => {
    const {
      checkinCheckoutControlled = false, hotelCode, id, logicalId, originalEventType, remark, fromDateTimeUTC, toDateTimeUTC,
    } = event;

    if (originalEventType === 'PAR' && enabledFeatures.pairingEnabled) {
      return ({
        pathname: `${routePrefix}/pairing/${context}/${crmId}/${encodeURIComponent(logicalId!)}`,
      });
    }

    if (originalEventType === 'C_I' && enabledFeatures.checkInEnabled && checkinCheckoutControlled) {
      return ({ pathname: `${routePrefix}/check-in/${crmId}/${encodeURIComponent(logicalId!)}` });
    }

    if (
      (originalEventType === 'LEG' && enabledFeatures.legEnabled) ||
      (originalEventType === 'FPR' && enabledFeatures.deadheadEnabled)
    ) {
      const eventId = (context === EventContext.Roster) || (context === EventContext.Flight) ? logicalId : id;
      return ({
        pathname: `${routePrefix}/leg/${context}/${crmId}/${encodeURIComponent(eventId)}`,
      });
    }

    if (originalEventType === 'HOT' && !!hotelCode && enabledFeatures.hotelEnabled) {
      return ({ pathname: `${routePrefix}/hotel/${crmId}/com.lhsystems.crew.pems:pems-client-web-app:jar:2023.1.0` });
    }

    if (originalEventType === 'SUSP') {
      return ({
        pathname: `${routePrefix}/notifications/${crmId}`,
        state: { fromDateTimeUTC, toDateTimeUTC, redirectTo: '/' },
      });
    }

    if (enabledFeatures.eventRemarksEnabled && remark?.trim()) {
      return ({ pathname: `${routePrefix}/event/${crmId}/com.lhsystems.crew.pems:pems-client-web-app:jar:2023.1.0`, state: { event } });
    }

    return null;
  }, [
    context,
    crmId,
    enabledFeatures,
    event,
    routePrefix]);

  const handleClick = () => {
    const navigateTo = getNavigateTo();

    // Do not select event when text is selected
    if (window.getSelection()?.toString()) {
      return;
    }
    setHighlightedEvent(event.id);
    setHighlightedDay(event.fromDateAtCalendarCropped!);

    if (event.originalEventType === 'SUSP' && enabledFeatures.notificationsEnabled && navigateTo && !buddyMode?.buddyCrmId) {
      history.push({ ...navigateTo, ...(!!buddyMode && getHistorySearchParams()) });
    }
  };

  const getInfoIcon = () => {
    const { Info } = buttonIcons;
    const { originalEventType } = event;
    const { buddyLegDeadHeadInfoEnabled, buddyPairingInfoEnabled, flightInfoEnabled } = enabledFeatures;

    const navigateTo = getNavigateTo();

    // Filetering Info button based on fetaure config in case of Buddy mode enabled
    if (buddyMode && (
      (!buddyLegDeadHeadInfoEnabled && !buddyPairingInfoEnabled) ||
      (
        (buddyLegDeadHeadInfoEnabled || buddyPairingInfoEnabled) &&
        ![
          ...(buddyLegDeadHeadInfoEnabled ? ['LEG', 'FPR'] : []),
          ...(buddyPairingInfoEnabled ? ['PAR'] : []),
        ].includes(originalEventType))
    )) {
      return null;
    }

    if (originalEventType === 'SUSP' || detailView || !navigateTo || (!crewMemberPartOfFlight && !flightInfoEnabled)) {
      return null;
    }

    return (
      <Fab
        className={classes.detailsButton}
        data-test-id="details-page-button"
        onClick={() => history.push({ ...navigateTo, ...((!!buddyMode || !crewMemberPartOfFlight) && getHistorySearchParams()) })}
      >
        <Info />
      </Fab>
    );
  };

  const getContent = () => {
    const {
      originalEventType,
      events = [],
      fromDate,
      toDate,
      fromLocalTime,
      toLocalTime,
      fromTimeUtc = '',
      toTimeUtc = '',
      fromAirport = '',
      toAirport = '',
      onlyArrival = false,
      attributes = [],
      eventHeader = [],
      eventDescription = {},
      hotelName = '',
      fromDateTimeSched,
      toDateTimeSched,
      fromDateTime,
      toDateTime,
      simBriefing,
      simDebriefing,
    } = event;

    const onlyDeparture = originalEventType === 'SUSP' ? true : event.onlyDeparture || false;
    const translatedEventHeader = eventHeader.map((item) => (typeof item === 'function' ? item(t) : item));

    const displayTime = (time: string, postfix: string) => (postfix ? `${time} ${postfix}` : time);

    const displayTimes = (local: string | undefined, utc: string | undefined) => (
      [
        local && displayTime(local, localTimePostfix),
        utc && (local ? `(${displayTime(utc, utcTimePostfix)})` : `${displayTime(utc, utcTimePostfix)}`),
      ].filter(Boolean).join(SEPARATOR)
    );

    const displayBriefing = (label: string, { dateLocal, localTime, timeUtc }: Briefing) => (
      <div className={classes.secondaryText}>
        {[`${label}:`, dateLocal, displayTimes(localTime, timeUtc)].join(SEPARATOR)}
      </div>
    );

    const highlighted = (
      (availableEventsView && !subevents) ||
      (highlightedEvent === event.id && !selectionView) ||
      expanded ||
      (highlightCurrentEvent && currentEvent)
    );

    const clickDisabled = subevents || detailView || selectionView || availableEventsView;

    const headerText = translatedEventHeader.reduce((acc, curr) => {
      if (acc.length && curr[0] === '/') {
        acc[acc.length - 1] = `${acc[acc.length - 1]}${curr}`;
      } else if (window.location.href.indexOf('flight-search-result') !== -1) {
        acc.push(`${curr}/${fromDate}`);
      } else {
        acc.push(curr);
      }
      return acc;
    }, [] as string[]).join(`${SEPARATOR}${SEPARATOR}`);

    const showToggle = (
      (events?.length || (availableEventsView && originalEventType === 'PAR')) &&
      !detailView
    );

    const content = (
      <>
        <div
          className={clsx(classes.eventRowContent, { highlighted, toggled: expanded })}
          onClick={clickDisabled ? undefined : handleClick}
          role={clickDisabled ? undefined : 'button'}
        >
          <div className={classes.eventRowIcons}>
            <div><DutyRowIcon event={event} pairingListItem={pairingListItem} /></div>
            {showToggle && <DutyRowToggle className={classes.toggleIcon} expanded={expanded} toggle={toggleExpanded} />}
          </div>
          <div
            className={classes.eventRowDetails}
            data-test-id="duty-row-details"
          >
            <div className={classes.secondaryText}>
              {headerText}
              {enabledFeatures.eventRemarksEnabled && event.remark && <ChatBubbleOutlineIcon className={classes.eventRemarkIcon} />}
            </div>
            {!onlyArrival && (
              <div className={classes.departureArrival}>
                {fromAirport && eventTypesToShowAirport.includes(originalEventType) && (
                  <span className={clsx(classes.highlighted, classes.airportCode)}>{`${fromAirport} `}</span>
                )}
                {fromDate && <span className={classes.highlighted}>{`${fromDate}${SEPARATOR}`}</span>}
                {originalEventType !== 'SUSP' && (
                  <span
                    className={clsx({
                      [classes.delayedDepartureArrival]: fromDateTimeSched! < fromDateTime!,
                      [classes.earlyDepartureArrival]: fromDateTimeSched! > fromDateTime!,
                    })}
                  >
                    {displayTimes(fromLocalTime, fromTimeUtc)}
                  </span>
                )}
                {originalEventType === 'SUSP' && <span className={classes.highlighted}>{`- ${toDate}`}</span>}
              </div>
            )}
            {!onlyDeparture && (
              <div className={classes.departureArrival}>
                {toAirport && eventTypesToShowAirport.includes(originalEventType) && (
                  <span
                    className={
                      clsx(
                        classes.highlighted,
                        classes.airportCode,
                        { [classes.hidden]: originalEventType === 'PAR' && fromAirport === toAirport && !showArrivalAirportOfPairing },
                      )
                    }
                  >
                    {`${toAirport} `}
                  </span>
                )}
                {hotelName && <span>{hotelName}</span>}
                {originalEventType !== 'HOT' && (
                  <>
                    {toDate && <span className={classes.highlighted}>{`${toDate}${SEPARATOR}`}</span>}
                    <span
                      className={clsx({
                        [classes.delayedDepartureArrival]: toDateTimeSched! < toDateTime!,
                        [classes.earlyDepartureArrival]: toDateTimeSched! > toDateTime!,
                      })}
                    >
                      {displayTimes(toLocalTime, toTimeUtc)}
                    </span>
                  </>
                )}
              </div>
            )}
            {simBriefing?.dateLocal && displayBriefing(t('duties.main.briefing'), simBriefing)}
            {simDebriefing?.dateLocal && displayBriefing(t('duties.main.debriefing'), simDebriefing)}
            {!!attributes.length && (
              <div className={classes.secondaryText}>{attributes.join(`${SEPARATOR}${SEPARATOR}`)}</div>
            )}
          </div>
          <div className={classes.rightIcons}>
            {getInfoIcon()}
            {DutyRowSelection && <DutyRowSelection context={context} event={event} parentId={parentId} />}
          </div>
        </div>
        {events && !!events.length && (
          <Collapse in={expanded}>
            <DutyList
              availableEventsView={availableEventsView}
              subevents
              DutyRowSelection={DutyRowSelection}
              events={events}
              parentId={event.id}
              enabledFeatures={enabledFeatures}
              pairingList
              selectionView={selectionView}
            />
          </Collapse>
        )}
      </>
    );

    if (subevents) {
      return (
        <div
          className={clsx(classes.subEventRow, { selected: rowKey === 0 && detailView })}
          data-event-type={originalEventType}
          data-test-id="duty-row-subevent"
        >
          {content}
        </div>
      );
    }

    const { groundActivityCode } = eventDescription;
    const usedStyleForGroundActivityCode = groundActivityCode && theme.palette.events[groundActivityCode.toLowerCase()];
    const usedStyleForEventType = originalEventType && theme.palette.events[originalEventType.toLocaleLowerCase()];

    return (
      <div
        className={clsx(classes.row, { [classes.detailView]: detailView, [classes.extendedPairingRow]: expanded })}
        data-event-type={originalEventType}
        data-test-id="duty-row-event"
        style={originalEventType !== 'SUSP' ? {
          borderColor: usedStyleForGroundActivityCode?.backgroundColor || usedStyleForEventType?.backgroundColor,
        } : {
          borderColor: usedStyleForEventType?.borderColor,
          backgroundColor: usedStyleForEventType?.backgroundColor,
        }}
      >
        {!crewMemberPartOfFlight && <div className={classes.rowBroken} />}
        {content}
      </div>
    );
  };

  return (
    <div
      className={
        clsx(
          classes.root,
          `event-id-${event.id}`,
          {
            [classes.availableEventsView]: availableEventsView && !subevents && !detailView,
            [classes.line]: drawline,
            [classes.multiColumn]: multiColumn,
          },
        )
      }
      data-test-id="duty-row"
    >
      {getContent()}
    </div>
  );
};

export default DutyRow;
