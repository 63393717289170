/* eslint-disable no-param-reassign */
import { groupBy } from 'lodash';

import { EVENT_BORDER_RADIUS } from './Event.styles';

export const activeMonthAttribute = 'data-active-month';

export const Attributes = {
  eventId: {
    js: 'eventId',
    html: 'data-event-id',
  },
  eventTagItems: {
    js: 'eventTagItems',
    html: 'data-event-tag-items',
  },
};

/**
 * Show only the parts of the text in the event tag which do not overflow
 */
const truncateEventTag = (eventTag: HTMLElement, maxVisibleWidth: number) => {
  (eventTag.firstChild as HTMLElement).style.display = 'block';
  const items = Array.from(eventTag.querySelector(`[${Attributes.eventTagItems.html}]`)!.children) as HTMLElement[];
  eventTag.style.maxWidth = `${maxVisibleWidth}px`;
  const { width } = eventTag.getBoundingClientRect();
  let widthSum = 0;

  items.forEach((item) => {
    item.style.display = 'block';
    const itemWidth = item.getBoundingClientRect().width;
    if (widthSum + itemWidth > width) {
      item.style.display = 'none';
    }
    widthSum += itemWidth;
  });
};

const hideUnneededParts = (parts: HTMLElement[]) => {
  // show all the parts
  parts.forEach((part) => {
    part.style.display = 'flex';
  });

  // only the last part should be shown in the last row and the first part in other rows of the event tag,
  // hide other parts
  const indexToShow: number[] = [];
  parts.forEach((part, index) => {
    if (
      // first part
      index === 0 ||
      // last part and multiple rows
      (index === parts.length - 1 && part.getBoundingClientRect().y !== parts[0].getBoundingClientRect().y) ||
      // first part in a middle row
      (
        part.getBoundingClientRect().y !== parts[parts.length - 1].getBoundingClientRect().y &&
        part.getBoundingClientRect().y !== parts[index - 1].getBoundingClientRect().y
      )
    ) {
      indexToShow.push(index);
    }
  });
  parts.forEach((part, index) => {
    part.style.display = indexToShow.includes(index) ? 'flex' : 'none';
  });

  return indexToShow;
};

const alignEventTagParts = (parts: HTMLElement[], container: HTMLElement) => {
  const containerRect = container.getBoundingClientRect();

  const indexToShow = hideUnneededParts(parts);

  const firstPart = parts[0];
  const lastPart = parts[parts.length - 1];
  const scrollBarWidth = containerRect.width - container.clientWidth;
  const firstPartMaxVisibleWidth = containerRect.right - scrollBarWidth - firstPart.getBoundingClientRect().left;
  const lastPartMaxVisibleWidth = lastPart.getBoundingClientRect().right - containerRect.left;

  indexToShow.forEach((index) => {
    (parts[index]!.firstChild as HTMLElement).style.display = 'none';
  });

  if (indexToShow.length === 1) {
    truncateEventTag(firstPart, firstPartMaxVisibleWidth);
    if (!firstPart.dataset.croppedAtEnd) {
      firstPart.style.borderTopRightRadius = `${EVENT_BORDER_RADIUS}px`;
      firstPart.style.borderBottomRightRadius = `${EVENT_BORDER_RADIUS}px`;
    }
  } else if (indexToShow.length === 2) {
    if (firstPartMaxVisibleWidth > lastPartMaxVisibleWidth) {
      truncateEventTag(firstPart, firstPartMaxVisibleWidth);
    } else {
      truncateEventTag(lastPart, lastPartMaxVisibleWidth);
    }
  } else {
    truncateEventTag(parts[indexToShow[1]], container.clientWidth);
  }
};

/**
 * Position coloured event tags and the text in them.
 * Called after each render and also when the container component is resized
 */
const alignEventTags = () => {
  const container: HTMLElement | null = document.querySelector(`[${activeMonthAttribute}]`);

  if (!container) {
    return;
  }
  const eventTags: HTMLElement[] = Array.from(container.querySelectorAll(`[${Attributes.eventId.html}]`));
  // if an event is multiple days long, the event tag is placed on each day, group them by id
  const eventTagsById = groupBy(eventTags, (eventTag) => eventTag.dataset[Attributes.eventId.js]);

  // call the function for every group
  Object.values(eventTagsById).forEach((parts) => alignEventTagParts(parts, container));
};

export default alignEventTags;
