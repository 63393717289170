import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  root: {
    position: 'relative',
    overflow: 'auto',
    margin: 16,
    backgroundColor: palette.common.white,
    borderRadius: 4,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.2)',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '12px 16px 8px',
  },
  delete: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});
