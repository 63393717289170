import {
  CustomerProfile,
  CheckInTitle,
  SelectionMode,
  HotelFields,
} from 'consts';
import defaultProfileSettings from './defaultProfile';

const lotProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  calendarWeekStartMonday: true,
  showHomeBase: false,
  showPairingNumber: false,
  showPairingClassification: true,
  showRegistration: true,
  showUTCTime: true,
  showLocalTime: false,
  checkInTitle: CheckInTitle.NO_PAIRING_NUMBER,
  notificationConfirmationSelectionMode: SelectionMode.ALL,
  disabledHotelFields: [
    HotelFields.REMARK,
    HotelFields.TRANSFER_TIME,
    HotelFields.TRANSPORT_COMPANY_ADDRESS,
    HotelFields.TRANSPORT_COMPANY_TYPE,
    HotelFields.TYPE,
  ],
};

export default lotProfileSettings;
