import {
  useCallback, useContext, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { PERSIST_CACHE, clearEncryptedCache, isCacheWorking } from '../../api';
import { getTodayOnServerByParams } from '../common';
import { useDialog } from '../Dialog';
import { useProfile } from '../Profile';
import { usePublicConfig } from '../PublicConfig';
import { useServerConfig } from '../ServerConfig';
import { AuthState } from '../Auth';

import CrewAuthContext from './CrewAuthContext';
import AuthContext from '../Auth/AuthContext';

/**
 * The list of authState values which the main view must be displayed.
 */
const authenticatedStates = [
  AuthState.AUTHORIZED, AuthState.VALIDATION_ERROR, AuthState.SESSION_EXPIRED,
];

/**
 * The list of authState values which the main or login view must be displayed.
 */
const readyStates = [
  AuthState.AUTHENTICATING, AuthState.AUTHENTICATED, AuthState.UNAUTHORIZED,
  ...authenticatedStates,
];

const CrewAuthProvider = ({ children, useWithPems } : { children: React.ReactNode; useWithPems: boolean }) => {
  const { t } = useTranslation();
  const { showServiceErrorMessage } = useDialog();
  const publicConfig = usePublicConfig();
  const { handleLogin: handleLoginOnServerConfig, handleLogout: handleLogoutOnServerConfig } = useServerConfig();
  const { profile: { cacheBeforeDaysNo } } = useProfile();
  const { customerCode = '', onAuthStateChanged } = publicConfig || {};

  const auth = useContext(AuthContext);

  const {
    authState,
    config,
    loginClientTimestamp,
    user,
    user: {
      firstName, lastName, credentials: { userId }, crmId, refTimestamp, refTimestampLtAtHb,
    } = { credentials: {} },
  } = auth;

  const logout = useCallback(() => {
    handleLogoutOnServerConfig();
    return auth.logout();
  }, [handleLogoutOnServerConfig, auth]);

  useEffect(() => {
    if (crmId) {
      handleLoginOnServerConfig();
    }
  }, [handleLoginOnServerConfig, crmId]);

  useEffect(() => {
    if (PERSIST_CACHE && authState === AuthState.AUTHORIZED) {
      const utcDateTime: DateTime = useWithPems ? getTodayOnServerByParams(loginClientTimestamp, refTimestamp, refTimestampLtAtHb, true) :
        DateTime.now();
      const checkStart = utcDateTime.minus({ days: cacheBeforeDaysNo }).startOf('day');
      const maxDate = checkStart.toMillis();
      clearEncryptedCache(maxDate);
    }
  }, [authState, cacheBeforeDaysNo, loginClientTimestamp, refTimestamp, refTimestampLtAtHb, useWithPems]);

  useEffect(() => {
    onAuthStateChanged(authState);
  }, [onAuthStateChanged, authState]);

  useEffect(() => {
    if (authState === AuthState.AUTHORIZED && !isCacheWorking()) {
      showServiceErrorMessage(t('errorMap.cacheError'));
    }
  }, [authState, showServiceErrorMessage, t]);

  const ctx = useMemo(() => {
    const authenticated = publicConfig && authenticatedStates.includes(authState);
    const ready = publicConfig && readyStates.includes(authState);

    return {
      ...auth,
      logout,
      oidcEnabled: auth.oidcEnabled,
      config: {
        ...config,
        ...(publicConfig || {}),
        userName: userId,
      },
      user: {
        ...user,
        userName: `${firstName} ${lastName}`,
      },
      customerCode,
      authenticated,
      ready,
      crmId,
    };
  }, [auth, authState, user, crmId, customerCode, config, userId, firstName, lastName, logout, publicConfig]);

  return <CrewAuthContext.Provider value={ctx}>{children}</CrewAuthContext.Provider>;
};

export const useCrewAuth = () => useContext(CrewAuthContext);

export default CrewAuthProvider;
