import { useCallback, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles, TextField } from '@lsy-netline-ui/netline-ui';
import { Info as InfoIcon } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DatePicker, DATE_FORMAT } from '@crew-webui/common/view/components';
import {
  useFlightSearch, useConnection, useCrewAuth, useDutiesModel, useTodayOnServer,
} from '@crew-webui/common/hooks';
import { useUtils } from '@lsy-netline-ui/netline-ui/pickers';
import { useHistory } from 'react-router-dom';

import { routePaths } from 'consts';
import { IadpPage } from 'view/components';
import styles from './FlightSearch.styles';

const useStyles = makeStyles(styles);

const formId = 'flightSearchForm';
const flightNumberPattern = /^[0-9]*$/;

const FlightSearch = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { online } = useConnection();

  const { user } = useCrewAuth();
  const history = useHistory();
  const utils = useUtils();
  const { rosterPeriod } = useDutiesModel();
  const currentDate = useTodayOnServer()();
  const { runFlightSearch, resetFlightSearchResult } = useDutiesModel();
  const { editFlightSearchFormData } = useFlightSearch();

  const flightDepMinDate = currentDate.minus({ days: user.configuration?.keepHistoryNumberOfDays }).toISODate();

  const schema = useMemo(
    () => yup.object().shape({
      carrier: yup.string(),
      flightDepartureDate: yup.date().required(),
      flightNumber: yup.string(),
      depAp: yup.string(),
      arrAp: yup.string(),
    }).test('at-least-one', '"depAp", "arrAp" or "flightNumber" must be filled', (values) => {
      const { depAp, arrAp, flightNumber } = values;
      return !!depAp || !!arrAp || !!flightNumber;
    }),
    [],
  );

  const { control, handleSubmit, formState: { isValid } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback((formData: any) => {
    editFlightSearchFormData(formData);

    const clonedData = { ...formData };

    Object.keys(clonedData).forEach((key) => {
      if (!clonedData[key]) {
        delete clonedData[key];
      }
    });

    const searchParams = new URLSearchParams(clonedData);

    const day = clonedData.flightDepartureDate.getDate().toString().padStart(2, '0');
    const month = clonedData.flightDepartureDate.toLocaleString('default', { month: 'short' });
    searchParams.set('flightDepartureDate', `${day}${month}`);

    runFlightSearch({
      ...formData,
      flightDepartureDate: utils.format(clonedData.flightDepartureDate, DATE_FORMAT),
    });
    history.push({
      pathname: routePaths.flightSearchResult,
      search: searchParams.toString(),
    });
  }, [editFlightSearchFormData, history, runFlightSearch, utils]);

  useEffect(() => {
    resetFlightSearchResult();
  }, [resetFlightSearchResult]);

  return (
    <IadpPage
      dataLoaded={online}
      headerProps={{ title: t('flightSearch.formTitle') }}
      submitButtonProps={{
        'children': t('flightSearch.searchButton'),
        'data-test-id': 'run-search-button',
        'form': formId,
        'type': 'submit',
        'disabled': !isValid || !online,
      }}
    >
      <div className={classes.infoContainer}>
        <InfoIcon className={classes.infoIcon} />
        <span className={classes.infoText}>{t('flightSearch.infoText')}</span>
      </div>
      <form id={formId} className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.formContent}>
          <div className={classes.formLine}>
            <Controller
              control={control}
              name="carrier"
              defaultValue=""
              render={({ field: { value, onChange } }) => (
                <TextField
                  label={t('flightSearch.fields.airline')}
                  value={value}
                  onChange={(event) => onChange(event.target.value.toUpperCase())}
                  placeholder={t('flightSearch.fields.airlinePlaceholder')}
                  inputProps={{ maxLength: 3 }}
                />
              )}
            />
            <Controller
              control={control}
              name="flightNumber"
              render={({ field: { value, onChange } }) => (
                <TextField
                  label={t('flightSearch.fields.flightNumber')}
                  value={value || ''}
                  onChange={(event) => {
                    if (flightNumberPattern.test(event.target.value)) {
                      onChange(event.target.value || undefined);
                    }
                  }}
                  placeholder={t('flightSearch.fields.flightNumberPlaceholder')}
                  inputProps={{ maxLength: 4 }}
                />
              )}
            />
            <Controller
              control={control}
              name="flightDepartureDate"
              defaultValue={currentDate.toJSDate()}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  label={t('flightSearch.fields.flightDepDate')}
                  value={value}
                  minDate={flightDepMinDate}
                  maxDate={rosterPeriod?.to.toISODate()}
                  onChange={(newValue) => onChange(utils.parse(newValue, DATE_FORMAT))}
                />
              )}
            />
          </div>
          <div className={classes.formLine}>
            <Controller
              control={control}
              name="depAp"
              render={({ field: { value, onChange } }) => (
                <TextField
                  label={t('flightSearch.fields.dep')}
                  value={value || ''}
                  onChange={(event) => onChange(event.target.value.toUpperCase())}
                  placeholder={t('flightSearch.fields.depPlaceholder')}
                  inputProps={{ maxLength: 3 }}
                />
              )}
            />
            <Controller
              control={control}
              name="arrAp"
              render={({ field: { value, onChange } }) => (
                <TextField
                  label={t('flightSearch.fields.arr')}
                  value={value || ''}
                  onChange={(event) => onChange(event.target.value.toUpperCase())}
                  placeholder={t('flightSearch.fields.arrPlaceholder')}
                  inputProps={{ maxLength: 3 }}
                />
              )}
            />
          </div>
        </div>
      </form>
    </IadpPage>
  );
};

export default FlightSearch;
