import { FeatureAccess } from '@crew-webui/common/consts';

import {
  AccessRights,
  AddressDetails,
  Addresses,
  BroadcastMessage,
  BuddyRosterSearch,
  ContactDetails,
  Contacts,
  DailyRemarks,
  Duties,
  BuddyExcludeList,
  MyData,
  NewDailyRemark,
  News,
  NewsSettings,
  NotificationConfirmation,
  PersonalData,
  Profile,
  ReadDailyRemark,
  Summary,
  TravelDocDetails,
  TravelDocs,
  FlightSearch,
  FlightSearchResult,
} from 'view';
import {
  AdditionalFlightDetails,
  Checkin,
  GeneralEventDetails,
  Hotel,
  LegDetails,
  PairingDetails,
} from 'view/Duties';

import {
  Feature, ProfileFeature, ServerFeature, routePaths,
} from '.';

type Route = {
  path: string;
  component: React.ComponentType<any>;
  feature?: ProfileFeature | ServerFeature;
  featureAccess?: FeatureAccess;
  singleColumn?: boolean;
};

const routes : Route[] = [
  { path: routePaths.root, component: Duties },
  { path: routePaths.profile, component: Profile },
  { path: routePaths.notificationSettings, component: NewsSettings, feature: Feature.VIEW_NOTIF_SETTINGS },
  { path: routePaths.accessRights, component: AccessRights, feature: Feature.IDP_BUDDY_ACCESS_RIGHTS },
  { path: `${routePaths.buddyExcludeList}`, component: BuddyExcludeList, feature: Feature.IDP_BUDDY_ACCESS_RIGHTS },
  { path: routePaths.summary, component: Summary, feature: Feature.VIEW_SUMMARY },
  { path: routePaths.dailyRemarks, component: DailyRemarks, feature: Feature.VIEW_DAILY_REMARKS },
  {
    path: routePaths.newRemarks,
    component: NewDailyRemark,
    feature: Feature.VIEW_DAILY_REMARKS,
    featureAccess: FeatureAccess.WRITE,
  },
  { path: `${routePaths.dailyRemarks}/:id`, component: ReadDailyRemark, feature: Feature.VIEW_DAILY_REMARKS },
  { path: routePaths.news, component: News, feature: Feature.VIEW_NEWS },
  { path: `${routePaths.news}/:id`, component: BroadcastMessage, feature: Feature.VIEW_NEWS },
  { path: `${routePaths.checkIn}/:crmId/:logicalId`, component: Checkin, feature: Feature.VIEW_CHECK_IN },
  { path: `${routePaths.hotel}/:crmId/:eventId`, component: Hotel, feature: Feature.VIEW_HOTEL },
  { path: `${routePaths.leg}/:context/:crmId/:logicalId`, component: LegDetails, feature: Feature.VIEW_LEG },
  { path: `${routePaths.leg}/:context/:crmId/:logicalId/edit-roles`, component: LegDetails, feature: Feature.PERFORM_ROLE_EDITING },
  {
    path: `${routePaths.leg}/:context/:crmId/:logicalId/additional-flight-details`,
    component: AdditionalFlightDetails,
    feature: Feature.OPERATIONAL_AND_FLIGHT_INFORMATION_ENABLED,
  },
  { path: `${routePaths.pairing}/:context/:crmId/:logicalId`, component: PairingDetails, feature: Feature.VIEW_PAIRING },
  { path: `${routePaths.event}/:crmId/:eventId`, component: GeneralEventDetails },
  { path: `${routePaths.notifications}/:crmId`, component: NotificationConfirmation, feature: Feature.VIEW_NOTIFICATIONS },
  {
    path: routePaths.myData,
    component: MyData,
    feature: Feature.IDP_MY_DATA,
  },
  {
    path: `${routePaths.myData}/personal`,
    component: PersonalData,
    feature: Feature.IDP_MY_DATA,
  },
  {
    path: `${routePaths.myData}/contacts`,
    component: Contacts,
    feature: Feature.IDP_MY_DATA,
  },
  {
    path: `${routePaths.myData}/contacts/:type`,
    component: ContactDetails,
    feature: Feature.IDP_MY_DATA,
    featureAccess: FeatureAccess.WRITE,
  },
  {
    path: `${routePaths.myData}/contacts/:type/:id`,
    component: ContactDetails,
    feature: Feature.IDP_MY_DATA,
  },
  {
    path: `${routePaths.myData}/addresses`,
    component: Addresses,
    feature: Feature.IDP_MY_DATA,
  },
  {
    path: `${routePaths.myData}/addresses/details`,
    component: AddressDetails,
    feature: Feature.IDP_MY_DATA,
    featureAccess: FeatureAccess.WRITE,
  },
  {
    path: `${routePaths.myData}/addresses/details/:id`,
    component: AddressDetails,
    feature: Feature.IDP_MY_DATA,
  },
  {
    path: `${routePaths.myData}/traveldocs`,
    component: TravelDocs,
    feature: Feature.IDP_MY_DATA,
  },
  {
    path: `${routePaths.myData}/traveldocs/:type`,
    component: TravelDocDetails,
    feature: Feature.IDP_MY_DATA,
    featureAccess: FeatureAccess.WRITE,
  },
  {
    path: `${routePaths.myData}/traveldocs/:type/:id`,
    component: TravelDocDetails,
    feature: Feature.IDP_MY_DATA,
  },
  {
    path: routePaths.flightSearch,
    component: FlightSearch,
    feature: Feature.IDP_FLIGHT_SEARCH,
    featureAccess: FeatureAccess.READ,
  },
  {
    path: routePaths.flightSearchResult,
    component: FlightSearchResult,
    feature: Feature.IDP_FLIGHT_SEARCH,
    featureAccess: FeatureAccess.READ,
  },
  {
    path: routePaths.buddyRosterSearch,
    component: BuddyRosterSearch,
    feature: Feature.IDP_BUDDY_ACCESS_RIGHTS,
  },
];

export default routes;
