import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  subtitle: {
    margin: `${spacing(2.5)}px ${spacing(2)}px 0`,
    marginTop: spacing(2),
    fontWeight: 500,
  },
  crewOnLeg: {
    backgroundColor: palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  sectionText: {
    color: palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
    wordBreak: 'break-word',
  },
  content: {
    padding: 0,
  },
});
