import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  accordion: {
    'backgroundColor': 'inherit',
    'boxShadow': 'none',
    'borderBottom': `1px solid ${palette.legDetails.borderColor}`,
    '&:first-of-type': {
      borderTop: `1px solid ${palette.legDetails.borderColor}`,
    },
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
    '&.MuiAccordion-rounded': {
      borderRadius: 0,
    },
  },
  detailsContainer: {
    marginTop: 12,
  },
  subtitle: {
    margin: '16px 16px 0px',
  },
  crewOnLeg: {
    width: '100%',
    backgroundColor: palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  rolesContainer: {
    flexShrink: 0,
  },
  remarksContainer: {
    'flexShrink': 0,
    'white-space': 'pre-wrap',
    'wordBreak': 'break-word',
  },
  sectionTitle: {
    color: palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  section: {
    'padding': 16,
    'borderBottom': `1px solid ${palette.legDetails.borderColor}`,
    '&:last-of-type': {
      borderBottom: 0,
    },
  },
  roles: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  role: {
    borderRadius: 4,
    backgroundColor: palette.legDetails.darkBackgroundColor,
    color: palette.legDetails.lightTextColor,
    fontSize: 14,
    lineHeight: '24px',
    padding: '2px 8px',
    marginBottom: 16,
    marginRight: 8,
  },
  sectionText: {
    color: palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
    wordBreak: 'break-word',
  },
  linkElement: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    cursor: 'pointer',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
  },
  linkElementButton: {
    padding: spacing(1.5),
    marginRight: spacing(1.5) * -1,
    color: palette.text.secondary,
  },

});
