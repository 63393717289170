import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { IconButton } from '@lsy-netline-ui/netline-ui';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { get, merge, set } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import { useEncodedParams } from '@crew-webui/common/api';

import {
  FeatureAccess, MyDataCategory, MyDataContactType, MyDataType,
} from '@crew-webui/common/consts';
import {
  useAppCommon,
  useConnection,
  useCrewAuth,
  useDialog,
  useFeature,
  useMyData,
  useOnlineAuthorized,
  useProfile,
} from '@crew-webui/common/hooks';
import { NoContentMessage, OfflineEditInfo } from '@crew-webui/common/view/components';

import {
  Feature, MAX_CONTACT_PRIO, routePaths, titles,
} from 'consts';
import { getUniqueContactId, getValidationSchema } from 'utils';
import { IadpPage } from 'view/components';
import MyDataList from '../MyDataList/MyDataList';
import MyDataFields from '../MyDataFields/MyDataFields';
import { getEmailFields, getPhoneFields } from './contactFields';
import { getSubmitButtonLabel, getTitle } from './contactUtils';

const formId = 'my-data-contact-form';

const ContactDetails = () => {
  const { t } = useTranslation();
  const { crmId } = useCrewAuth();
  const { profile } = useProfile();
  const {
    contacts, createMyData, deleteMyData, loadMyData, updateMyData,
  } = useMyData();
  const { id, type } = useEncodedParams<{ id: string; type: MyDataContactType }>();
  const isFeatureEnabled = useFeature();
  const { online } = useConnection();
  const { setDirty, clearDirtyAndRedirect } = useAppCommon();
  const { showConfirmDialog } = useDialog();

  const buddyModeEnabled = isFeatureEnabled(Feature.IDP_BUDDY_ACCESS_RIGHTS);

  const fields = useMemo(
    () => (type === MyDataContactType.EMAIL ?
      getEmailFields(t, { buddyModeEnabled }) : getPhoneFields(t, profile, { buddyModeEnabled })),
    [buddyModeEnabled, profile, t, type],
  );

  const editingEnabled = isFeatureEnabled(Feature.IDP_MY_DATA, FeatureAccess.WRITE) &&
    !profile.readonlyMyDataCategories.includes(MyDataCategory.CONTACT);
  const newContact = id === undefined && editingEnabled;
  const contact = contacts?.find((item) => getUniqueContactId(item) === id);
  const dataLoaded = !!contacts;

  // Init form state
  const {
    control,
    formState: {
      errors, isDirty, isSubmitting,
    },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(getValidationSchema(fields)),
  });

  // Fetch data
  useOnlineAuthorized(() => {
    if (!dataLoaded) {
      loadMyData(MyDataType.CONTACT);
    }
  }, [dataLoaded, loadMyData]);

  // Synchronize "dirty" global state
  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty, setDirty]);

  // Reset values
  useEffect(() => {
    const newDefaultValues = {};

    fields.forEach(({ valuePath }) => {
      set(newDefaultValues, valuePath, get(contact, valuePath, ''));
    });

    if (dataLoaded && newContact) {
      // prefill Priority field with the max existing + 1
      const maxPrio = contacts
        .filter((item) => (
          item.natKey.type === type ||
          (type === MyDataContactType.PHONE && item.natKey.type === MyDataContactType.MOBILE)
        ))
        .reduce((acc, curr) => Math.max(acc, curr.natKey.prio), -1);
      if (maxPrio !== MAX_CONTACT_PRIO) {
        set(newDefaultValues, 'natKey.prio', maxPrio + 1);
      }

      // prefill "Is mobile" with true
      if (type !== MyDataContactType.EMAIL) {
        set(newDefaultValues, 'natKey.type', MyDataContactType.MOBILE);
      }
    }

    reset(newDefaultValues);
  }, [fields, contact, contacts, dataLoaded, newContact, reset, type]);

  // Redirect to parent page when "type" is not allowed
  useEffect(() => {
    if (
      !Object.values(MyDataContactType).includes(type) ||
      // do not allow to create MOBILE type, just PHONE
      (type === MyDataContactType.MOBILE && newContact)
    ) {
      clearDirtyAndRedirect(`${routePaths.myData}/contacts`);
    }
  });

  // Handle form submission
  const onSubmit = async (data: any) => {
    const mergedContact = merge(
      {
        natKey: { crewCode: crmId, type },
      },
      contact,
      data,
    );

    // convert to number
    mergedContact.natKey.prio = parseInt(mergedContact.natKey.prio, 10);

    if (type !== MyDataContactType.EMAIL) {
      mergedContact.contact = undefined;
    }

    if (contact) {
      await updateMyData(MyDataType.CONTACT, mergedContact, contact);
    } else {
      await createMyData(MyDataType.CONTACT, mergedContact);
    }
    clearDirtyAndRedirect(`${routePaths.myData}/contacts`);
  };

  const title = getTitle(contact, newContact, type, t);
  const submitButtonLabel = getSubmitButtonLabel(newContact, type, t);

  const rightIconProps = editingEnabled && contact && (
    <IconButton
      disabled={!online}
      onClick={() => {
        const handleConfirm = () => {
          deleteMyData(MyDataType.CONTACT, contact).then(() => clearDirtyAndRedirect(`${routePaths.myData}/contacts`));
        };
        showConfirmDialog(t('myData.contactInfo.deleteContactDialogTitle'), handleConfirm);
      }}
    >
      <DeleteIcon />
    </IconButton>
  );

  const showNoContentMessage = dataLoaded && !newContact && !contact;

  return (
    <IadpPage
      dataLoaded={dataLoaded}
      headerProps={{
        actions: rightIconProps,
        showBackButton: true,
        title: t(titles.profile),
        subtitle: title,
      }}
      submitButtonProps={!editingEnabled ? undefined : {
        'children': submitButtonLabel,
        'data-test-id': 'submit-contact-button',
        'disabled': !online || !isDirty || isSubmitting || !!Object.keys(errors).length,
        'form': formId,
        'type': 'submit',
      }}
    >
      {showNoContentMessage && <NoContentMessage>{t('myData.contactInfo.noContact')}</NoContentMessage>}

      {contact && !editingEnabled && <MyDataList data={contact} list={fields} />}
      {editingEnabled && (contact || newContact) && (
        <form id={formId} noValidate onSubmit={handleSubmit(onSubmit)}>
          <OfflineEditInfo checkCrewCom />
          <MyDataFields
            control={control}
            editing={!newContact}
            errors={errors}
            fields={fields}
          />
        </form>
      )}
    </IadpPage>
  );
};

export default ContactDetails;
