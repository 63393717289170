import { createContext } from 'react';
import { DateTime } from 'luxon';

import {
  BlockMonth, Event, EventFromServer, OpenTimePairingsPerDay, RosterInterval, RosterPeriod,
} from '../../consts';

export interface EventsResult {
  rosterPeriod: {
    from?: string;
    to?: string;
    fromLocalAtHb?: string;
    toLocalAtHb?: string;
    crmPublishedEndDateTime?: string;
    crmPuplishedEndDateTimeLocalAtHb?: string;
  };
  blockMonthList: any[];
  events: EventFromServer[];
}

export interface BuddyDetails {
  buddyCrmId: string;
  buddyName: string;
}

export interface NotificationPeriod {
  begin: string;
  end: string;
}

export type EventsResponse = PemsResponse<EventsResult>;

export type DutiesModelState = {
  activeBlockMonthId?: string;
  events: Event[];
  flightSearchLoading: boolean;
  flightSearchResult: Event[];
  blockMonthList: BlockMonth[];
  openPairingStatistics: OpenTimePairingsPerDay[];
  rosterPeriod?: RosterPeriod;
  hasLoaded: boolean;
};

export type DutiesModelContextType = {
  activeBlockMonth?: BlockMonth;
  activeBlockMonthIndex?: number;
  allEvents: Event[];
  blockMonthList: BlockMonth[];
  buddyMode?: BuddyDetails;
  canGoToNextBlockMonth: boolean;
  canGoToPrevBlockMonth: boolean;
  daysToShowInCalendar: DateTime[][];
  dutyList: Event[];
  eventsHaveLoaded: boolean;
  flightSearchLoading: boolean;
  flightSearchResult: Event[];
  openPairingStatistics: OpenTimePairingsPerDay[];
  rosterPeriod?: RosterPeriod;
  wholeIntervalToShowInCalendar?: RosterInterval;

  // GETTERS
  getCurrentBlockMonth: () => BlockMonth | undefined;
  getCurrentBlockMonthIndex: () => number | undefined;

  // SETTERS
  confirmDuties: (confirmResult: NotificationPeriod[]) => Promise<Event[] | undefined>;
  enterBuddyMode: (buddy: BuddyDetails, rootPath: string) => void;
  exitBuddyMode: () => void;
  goToNextBlockMonth: () => void;
  goToPrevBlockMonth: () => void;
  goToCurrentBlockMonth: () => void;
  loadPairingCountPerDay: () => void;
  loadDuties: () => Promise<Event[] | undefined>;
  resetFlightSearchResult: () => void;
  runFlightSearch: (flightSearchParams: any) => void;
};

export const defaultValue = {
  activeBlockMonth: undefined,
  activeBlockMonthIndex: undefined,
  allEvents: [],
  blockMonthList: [],
  buddyMode: undefined,
  canGoToNextBlockMonth: false,
  canGoToPrevBlockMonth: false,
  daysToShowInCalendar: [],
  dutyList: [],
  enterBuddyMode: () => {},
  exitBuddyMode: () => {},
  eventsHaveLoaded: false,
  flightSearchLoading: false,
  flightSearchResult: [],
  openPairingStatistics: [],
  rosterPeriod: undefined,
  wholeIntervalToShowInCalendar: undefined,

  getCurrentBlockMonth: () => undefined,
  getCurrentBlockMonthIndex: () => undefined,
  goToNextBlockMonth: () => {},
  goToPrevBlockMonth: () => {},
  goToCurrentBlockMonth: () => {},
  loadPairingCountPerDay: () => {},
  confirmDuties: () => new Promise<undefined>(() => {}),
  loadDuties: () => new Promise<undefined>(() => {}),
  resetFlightSearchResult: () => {},
  runFlightSearch: () => {},
};

const DutiesModelContext = createContext<DutiesModelContextType>(defaultValue);

export default DutiesModelContext;
