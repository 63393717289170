import { makeStyles, Button } from '@lsy-netline-ui/netline-ui';

import { ReactComponent as ErrorEmpty } from './illustration_empty-error_state.svg';
import styles from './ErrorInfo.styles';

const useStyles = makeStyles(styles, { name: 'ErrorInfo' });

type Props = {
  header: React.ReactNode;
  info?: React.ReactNode;
  onContinueClicked?: () => void;
};

const ErrorInfo = ({ header, info, onContinueClicked }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} data-test-id="error-info">
      <ErrorEmpty width="100%" />
      <div className={classes.errorHeader} data-test-id="error-info-header">{header}</div>
      <div className={classes.errorInfo} data-test-id="error-info-content">{info}</div>
      {onContinueClicked && <Button variant="outlined" onClick={onContinueClicked}>Continue</Button>}
    </div>
  );
};

export default ErrorInfo;
