import defaultTheme from './defaultTheme';
import { CustomerTheme } from './themeTypes';

const mainTheme: CustomerTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
  },
};

export default mainTheme;
