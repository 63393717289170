import { useCallback } from 'react';

import { ServerEnabling } from '../../consts';
import { useCrewAuth } from '../CrewAuth';
import { useProfile } from '../Profile';

const useFeature = () => {
  const { user } = useCrewAuth();
  const { profile: { enablings: profileEnablings = {} } } = useProfile();

  const isFeatureEnabled = useCallback((featureId: string, value?: string) => {
    if (!featureId) {
      throw new Error('isFeatureEnabled is called without featureId provided');
    }

    // if this feature is controlled in profile
    if (profileEnablings[featureId] !== undefined) {
      return value ? profileEnablings[featureId] === value : !!profileEnablings[featureId];
    }

    const serverEnablings: ServerEnabling[] = user?.configuration?.enablings || [];
    const featureConfig = serverEnablings.find((feature) => feature.featureId === featureId);

    return !!featureConfig && featureConfig.value !== 'NO' && (!value || featureConfig.value === value);
  }, [user, profileEnablings]);

  return isFeatureEnabled;
};

export default useFeature;
