import { useState } from 'react';

import { services } from '../../api';
import { PemsDomain } from '../../consts';
import { useOnlineAuthorized } from '../common';
import { useDevice } from '../Device';

export type PemsSubscription = {
  defaultSelected?: boolean;
  editable?: boolean;
  label: string;
  tag: {
    key?: string;
    value: string;
  };
};

interface MasterData {
  domain?: string;
  emailSubscriptionEnabled?: boolean;
  pushSubscriptionEnabled?: boolean;
  smsSubscriptionEnabled?: boolean;
  subscriptions: PemsSubscription[];
}
export type MasterDataResponse = PemsResponse<MasterData>;

const useMasterData = (domain: PemsDomain) => {
  const [state, setState] = useState<MasterData | undefined>();
  const { isPushEnabled } = useDevice();

  useOnlineAuthorized(() => {
    services.loadMasterData({ domain }).then(({ data }: { data: MasterDataResponse }) => {
      const { result } = data;

      if (!result) {
        return;
      }

      // push notification enabled only in cordova
      result.pushSubscriptionEnabled = isPushEnabled && result.pushSubscriptionEnabled;
      setState(result);
    });
  }, [isPushEnabled]);

  return state;
};

export default useMasterData;
