import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import { useTranslation } from 'react-i18next';

import { PERSIST_CACHE, services, useIadpParams } from '@crew-webui/common/api';
import { EventContext, LocalStorageKeys } from '@crew-webui/common/consts';
import {
  useAppCommon,
  useConnection,
  useDomain,
  useDutiesModel,
  useFeature,
  useOnlineAuthorized,
  usePerformCaching,
  useProfile,
  useUserPreferences,
} from '@crew-webui/common/hooks';
import { OfflineEditInfo, CrewMember, Page } from '@crew-webui/common/view/components';

import { CheckInPermission, Feature } from 'consts';
import { useCheckin } from 'hooks';
import { IadpPage } from 'view/components';
import { CheckinStatus, getCheckinStatus, getCheckinSubtitle } from './checkinUtils';

import styles from './Checkin.styles';

const useStyles = makeStyles(styles, { name: 'Checkin' });

const { Geofence } = window;

type CheckInData = {
  checkedIn: boolean;
  checkInPermission: CheckInPermission | undefined;
  positions: any[];
};

const Checkin = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showFooter, hideFooter } = useAppCommon();
  const { pemsDomain } = useDomain();
  const { crmId, logicalId } = useIadpParams<{ crmId: string; logicalId: string }>();
  const logicalEventId = decodeURIComponent(logicalId);
  const { profile } = useProfile();
  const isFeatureEnabled = useFeature();
  const {
    geofenceEnabled, geofenceStatus, checkinInfo, reloadCheckinInfo, isInRange,
  } = useCheckin();
  const { dutyList } = useDutiesModel();
  const { [LocalStorageKeys.CACHE_ON_CHECK_IN]: cacheOnCheckin } = useUserPreferences();
  const startCache = usePerformCaching();

  const [checkinData, setCheckinData] = useState<CheckInData>({
    checkedIn: false,
    checkInPermission: undefined,
    positions: [],
  });
  const [checkinEvent, setCheckinEvent] = useState({
    id: undefined,
    departure: { airportCode: '' },
  });
  const { checkedIn, checkInPermission, positions } = checkinData;

  const {
    id: eventId, departure,
  } = checkinEvent;
  const { online } = useConnection();
  const extraTitle = useMemo(
    () => (eventId ? getCheckinSubtitle({
      airportCode: departure.airportCode,
      checkInTitle: profile.checkInTitle,
      dutyList,
      eventId,
      t,
    }) : ''),
    [departure.airportCode, profile.checkInTitle, dutyList, eventId, t],
  );

  const performCheckInEnabled = isFeatureEnabled(Feature.PERFORM_CHECK_IN);

  // TODO consider using the convertEvent function from the selectore (as in pairing details)
  const loadCheckinData = useCallback(() => {
    services
      .loadCheckinData({ crmId, checkInLogicalId: logicalEventId, ltype: 'mobile' })
      .then(({ data }) => {
        const { success, result } = data;

        if (!success) {
          return;
        }
        setCheckinData(result);
      });
  }, [crmId, logicalEventId]);

  const onCheckinClick = useCallback(() => {
    if (!reloadCheckinInfo) {
      return;
    }
    // TODO handle server config!
    services
      .checkin({
        crmId, checkInLogicalId: logicalEventId, op: 'confirm', ltype: 'mobile',
      })
      .then(({ data: { success } }) => {
        reloadCheckinInfo();
        loadCheckinData();

        if (success && PERSIST_CACHE && cacheOnCheckin) {
          startCache();
        }
      })
      .catch((error) => {
        const { items = [] } = error || {};

        // Reload the details in case of error-type=APP and id=ERR_ALREADY_CHECKED_IN
        if (items.length === 1) {
          const { errId, errType } = items[0];

          if (errId === 'ERR_ALREADY_CHECKED_IN' && errType === 'APP') {
            reloadCheckinInfo();
            loadCheckinData();
          }
        }
      });
  }, [cacheOnCheckin, crmId, logicalEventId, reloadCheckinInfo, startCache, loadCheckinData]);

  useEffect(() => {
    hideFooter();
    return () => showFooter();
  }, [showFooter, hideFooter]);

  useEffect(() => {
    if (reloadCheckinInfo) {
      reloadCheckinInfo();
    }
  }, [reloadCheckinInfo]);

  useOnlineAuthorized(() => {
    if (!logicalId) {
      return;
    }
    loadCheckinData();
  }, [logicalId, loadCheckinData]);

  useOnlineAuthorized(() => {
    services
      .getEventByLogicalEventId({
        context: EventContext.Roster, crmId, logicalEventId, pemsDomain,
      })
      .then(({ data }) => {
        const { success, result } = data;

        if (!success) {
          return;
        }

        setCheckinEvent(result);
      });
  }, [crmId, logicalEventId, pemsDomain]);

  const checkinStatus = useMemo(() => (checkInPermission ? getCheckinStatus({
    logicalEventId, checkedIn, checkInPermission, geofenceEnabled, geofenceStatus, checkinInfo, isInRange,
  }) : undefined), [logicalEventId, checkedIn, checkInPermission, geofenceEnabled, geofenceStatus, checkinInfo, isInRange]);

  const checkinLabel = useMemo(() => {
    if (!checkinStatus) {
      return '';
    }
    const key = (checkinStatus === CheckinStatus.PERMISSION_DENIED) ?
      `checkin.status.PERMISSION_DENIED_${Geofence?.isPlatformAndroid() ? 'ANDROID' : 'IOS'}` : `checkin.status.${checkinStatus}`;
    return t(key);
  }, [t, checkinStatus]);

  const showSubmitButton = performCheckInEnabled && checkinStatus === CheckinStatus.EXPECTED_TO_CHECKIN;
  const submitButton = showSubmitButton ? {
    'children': t('button.checkin'),
    'data-test-id': 'checkin-button',
    'disabled': !online,
    'onClick': onCheckinClick,
  } : undefined;

  return (
    <IadpPage
      dataLoaded={!!checkinEvent?.id}
      headerProps={{ title: t('duties.main.title'), subtitle: t('duties.checkin.title'), showBackButton: true }}
      submitButtonProps={submitButton}
    >
      {extraTitle && <Page.Title>{extraTitle}</Page.Title>}
      {performCheckInEnabled && <OfflineEditInfo text={t('duties.checkin.offlineEditInfo')} />}
      {online && <div className={classes.checkinStatusLabel} data-test-id="checkin-status">{checkinLabel}</div>}
      <div className={classes.crewMemberLabel} data-test-id="crew-members-label">{t('duties.checkin.crewMembersLabel')}</div>
      {positions.map(({ assignment, checkedIn: status }) => (
        <CrewMember key={assignment.name} crewMember={assignment} checkedIn={status} />
      ))}
    </IadpPage>
  );
};

export default Checkin;
