import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  list: {
    margin: 0,
  },
  label: {
    padding: 0,
    color: palette.iadp.primaryLabelTextColor,
    fontSize: 13,
    lineHeight: '24px',
  },
  text: {
    'marginBottom': 24,
    'marginLeft': 0,
    'color': palette.text.primary,
    'fontSize': 14,
    'fontWeight': 500,
    'lineHeight': '20px',
    'white-space': 'pre-wrap',
    'word-break': 'break-word',

    '& p': {
      margin: 0,
    },
  },
  received: {
    color: palette.iadp.primaryLabelTextColor,
    fontSize: 12,
    lineHeight: '16px',
  },
});
