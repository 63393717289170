import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  searchForm: {
    width: '100%',
  },
  cardUpdate: {
    padding: 0,
  },
  noBackground: {
    backgroundColor: palette.background.default,
  },
  crewCard: {
    display: 'flex',
    gap: spacing(2),
    alignItems: 'center',
  },
  successColor: {
    color: palette.success.main,
  },
  action: {
    marginLeft: 'auto',
  },
  inputLabel: {
    color: palette.text.secondaryLabel,
    fontSize: 13,
    letterSpacing: 0,
    lineHeight: '20px',
  },
  resultsFor: {
    marginTop: spacing(3),
    marginBottom: spacing(3),
    textTransform: 'uppercase',
    color: palette.text.secondaryLabel,
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '20px',
  },
  cardLabel: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
  resultItem: {
    paddingTop: spacing(1.5),
    paddingBottom: spacing(1.5),
    borderTop: `1px solid ${palette.divider}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  listContainer: {
  },
  listItem: {
    paddingTop: spacing(1.5),
    paddingBottom: spacing(1.5),
    borderTop: `1px solid ${palette.divider}`,
    borderBottom: `1px solid ${palette.divider}`,
    marginTop: '-1px',
    backgroundColor: palette.background.secondary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  crewName: {
    color: palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '30px',
  },
  crewId: {
    color: palette.text.secondaryLabel,
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '24px',
  },
});
