import { createContext } from 'react';

import defaultPublicConfig from './defaultPublicConfig.json';
import { AuthState } from '../Auth';

export interface PublicConfigResult {
  customerCode: string;
  logLevel: string;
  defaultSecurityLoaded: boolean;
  publicConfigLoaded: boolean;
  security?: SecurityConfig;
  hideForgotYourPasswordLink: boolean;
  isNetlineAppframe: boolean;
  isCsiAppframe: boolean;
  logoUrl: string;
  securityErr?: string;
  publicConfigErr?: string;
  appFrameServices?: any;
}

export interface PublicConfig extends PublicConfigResult {
  onAuthStateChanged: (authState: AuthState | undefined) => void;
}

export type PublicConfigResponse = PemsResponse<PublicConfigResult>;

type PublicConfigContextType = PublicConfig;

const PublicConfigContext = createContext<PublicConfigContextType>({
  ...defaultPublicConfig,
  onAuthStateChanged: () => {},
});

export default PublicConfigContext;
