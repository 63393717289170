import { makeStyles } from '@lsy-netline-ui/netline-ui';

import styles from './SummaryTable.styles';

const useStyles = makeStyles(styles, { name: 'SummaryTable' });

type SummaryTableProp = {
  tableData: {
    name: string;
    value?: {
      columns: {
        key: string;
        label: string;
      }[];
      data: any[];
    };
  };
};

const SummaryTable = ({ tableData = { name: '' } }: SummaryTableProp) => {
  const classes = useStyles();
  const { value } = tableData;
  if (!value) {
    return (<div className={classes.root} />);
  }
  return (
    <div className={classes.root}>
      <div className={classes.title} data-test-id="summary-table-title">{tableData.name}</div>
      <table className={classes.table} data-test-id="summary-table">
        <thead>
          <tr>
            {value.columns.map((column) => (
              <th key={column.key}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {value.data.map((data, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={idx}>
              {value.columns.map((column) => (
                <td key={column.key}>{data[column.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SummaryTable;
