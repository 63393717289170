import { reduce, set } from 'lodash';

import { logger } from '@crew-webui/common/api';

import createTheme from 'theme/createTheme';
import themes from 'theme';
import * as profiles from 'profile';

const ProfileMap = reduce(profiles, (result, profile, name) => {
  const themeKey = name === 'default' ? 'defaultTheme' : name;
  if (!themes[themeKey]) {
    logger.warn(`No theme found for profile '${themeKey}', so 'default' theme is used! Please check!`);
  }

  return set(result, name, {
    theme: createTheme(themes[name] || themes.defaultTheme),
    profile,
  });
}, {});

export default ProfileMap;
