import { makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import CardContent from './CardContent';
import styles from './Card.styles';

const useStyles = makeStyles(styles, { name: 'Card' });

type CardProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const Card = ({ className, ...otherProps }: CardProps) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      role={otherProps.onClick ? 'button' : undefined}
      {...otherProps}
    />
  );
};

Card.Content = CardContent;

export default Card;
