import { makeStyles, Dialog as NuiDialog } from '@lsy-netline-ui/netline-ui';
import { DialogProps as NuiDialogProps } from '@lsy-netline-ui/netline-ui/Dialog';

import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import DialogTitle from './DialogTitle';
import styles from './Dialog.styles';

export const useStyles = makeStyles(styles, { name: 'Dialog' });

const Dialog = (props: NuiDialogProps) => {
  const classes = useStyles();

  return (
    <NuiDialog
      data-test-id="dialog"
      {...props}
      classes={{ paper: classes.paper, paperFullScreen: classes.paperFullScreen, ...props.classes }}
    />
  );
};

Dialog.Actions = DialogActions;
Dialog.Content = DialogContent;
Dialog.Title = DialogTitle;

export default Dialog;
