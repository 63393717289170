import { CustomerProfile } from 'consts';
import defaultProfileSettings from './defaultProfile';

const mainProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  icons: {
    ...defaultProfileSettings.icons,
  },
  logo: 'lsy-logo2.png',
};

export default mainProfileSettings;
