import { createStyles } from '@lsy-netline-ui/netline-ui';

export default (({ palette }: Theme) => createStyles({
  root: {
    'display': 'flex',
    'flexDirection': 'column',
    'flex': '1 1 0',
    'overflow': 'hidden',

    '&:first-child': {
      '& $subtitle': {
        marginTop: 0,
      },
    },
    '&:not(:last-child) $list': {
      marginBottom: 7,
    },
  },
  subtitleContainer: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: palette.iadp.primaryLabelTextColor,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '18px',
    textTransform: 'uppercase',
  },
  subtitle: {
    margin: 16,
  },
  list: {
    overflow: 'auto',
  },
}));
