import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Keycloak from 'keycloak-js';

import { getUrlWithoutHash, reload, JwtTokenInterceptor } from '../../api';
import { useDialog } from '../Dialog';

const baseInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: 'login-required',
  checkLoginIframe: false,
  enableLogging: false,
  adapter: window.cordova ? 'cordova' : 'default',
  pkceMethod: 'S256',
};

/**
 * @param oidcConfig The keycloak auth service configuration
 * @param initConfig The keycloak initialize configuration
 * @param logger The logger to use
 * @param autoLogin Login automatic or not
 * @returns {{authenticated: any, keycloakLogout: (function(): Keycloak.KeycloakPromise<void, void>),
 * loadUserProfile: (function(): Keycloak.KeycloakPromise<Keycloak.KeycloakProfile, void>),
 * idToken: any, keycloakLogin: (function(): Keycloak.KeycloakPromise<void, void>),
 * keycloakInitialized: any, initializeError: any}}
 */
type KeycloakState = {
  keycloakInitialized: boolean;
  authenticated?: boolean;
  idToken?: string;
  accessToken?: string;
  initializeError?: any;
  skipLogin?: boolean;
};

const useKeycloakService = (
  oidcConfig: OidcConfigType,
  initConfig: Keycloak.KeycloakInitOptions,
  online: boolean,
  logger = console,
  skipLogin = false,
  autoLogin = false,
) => {
  const { showDialog } = useDialog();

  const [{
    keycloakInitialized, authenticated, idToken, initializeError,
  }, setState] = useState<KeycloakState>({ keycloakInitialized: false });
  const [unhandledLoginError, setUnhandledLoginError] = useState<any>();

  useEffect(() => {
    if (!online && !authenticated) {
      // this will trigger reload
      window.HandleInAppBrowser?.closeBrowser();
    }
  }, [authenticated, online]);

  const keycloak = useMemo(() => {
    if (skipLogin) {
      return undefined;
    }
    const instance = new Keycloak(oidcConfig);
    // because we cannot pass parameters to login when it runs automatically at start
    // see: https://stackoverflow.com/a/56338011
    const kcLogin = instance.login;
    instance.login = (options): any => {
      // eslint-disable-next-line no-param-reassign
      const loginOptions = options || {};
      if (oidcConfig.idpHint) {
        loginOptions.idpHint = oidcConfig.idpHint;
      }
      // eslint-disable-next-line no-param-reassign
      loginOptions.cordovaOptions = { ...options?.cordovaOptions, toolbar: 'no' };
      return kcLogin(loginOptions)
        .catch((error) => {
          setUnhandledLoginError({ error });
        });
    };

    return instance;
  }, [oidcConfig, skipLogin]);

  const getToken = useCallback(async () => {
    if (skipLogin) {
      return Promise.resolve('mockToken');
    }
    if (!keycloak) {
      return Promise.resolve(undefined);
    }
    try {
      await keycloak.updateToken(30);
    } catch (err) {
      logger.info('token update failed', err);
    }
    return keycloak.token;
  }, [keycloak, logger, skipLogin]);

  useEffect(() => {
    JwtTokenInterceptor.setTokenPromise(getToken);
  }, [getToken]);

  useEffect(() => {
    if (unhandledLoginError) {
      // offline mode not handle keycloak errors
      if (online && navigator.onLine) {
        if (unhandledLoginError.error?.reason === 'closed_by_user') {
          reload();
        } else {
          logger.error('Unsuccessful Keycloak login, reason is unknown', unhandledLoginError);
          showDialog({
            title: 'Login was unsuccessful',
            content: 'Press OK to reload the app',
            buttons: { text: 'OK', onClick: reload },
          });
        }
      }

      setUnhandledLoginError(undefined);
    }
  }, [logger, online, showDialog, unhandledLoginError]);

  const dispatchState = useCallback(() => {
    const state = {
      keycloakInitialized: !!keycloak, authenticated: keycloak ? keycloak.authenticated : true, idToken: keycloak?.idToken,
    };
    setState(state);
    logger.debug('Dispatch new keycloak state', state);
  }, [keycloak, logger]);

  const keycloakLogin = useCallback(() => keycloak?.login(), [keycloak]);

  const keycloakLogout = useCallback(() => keycloak?.logout({ redirectUri: getUrlWithoutHash() }), [keycloak]);

  const loadUserInfo = useCallback(() => keycloak?.loadUserInfo(), [keycloak]);

  const initKeycloak = useCallback(() => {
    if (!keycloak) {
      throw new Error('Keycloak must define if not using mock service!');
    }
    keycloak.onAuthSuccess = () => {
      logger.info('keycloak event onAuthSuccess');
      dispatchState();
    };

    const config = {
      ...baseInitConfig,
      ...initConfig,
    };

    return keycloak.init(config)
      .then((auth) => {
        keycloak.loginRequired = false;
        if (!auth && autoLogin) {
          keycloakLogin();
        } else if (!auth) {
          dispatchState();
        }
      })
      .catch((err) => {
        logger.error('Error while initialize keycloak', err);
        setState({ keycloakInitialized: false, initializeError: err });
      });
  }, [autoLogin, dispatchState, initConfig, keycloak, keycloakLogin, logger]);

  return {
    authenticated,
    idToken,
    getToken,
    initKeycloak,
    initializeError,
    keycloakInitialized,
    keycloakLogout,
    keycloakLogin,
    loadUserInfo,
  };
};

export default useKeycloakService;
