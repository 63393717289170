import { createStyles } from '@lsy-netline-ui/netline-ui';

export default () => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    flexShrink: 1,
  },
  nowrap: {
    '&, & *': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& *': {
      overflow: 'hidden',
    },
  },
  text: {
    'marginBottom': 4,
    'wordBreak': 'break-word',

    '&:last-child': {
      marginBottom: 0,
    },
  },
});
