import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  makeStyles, IconButton, Radio, RadioGroup, Typography,
} from '@lsy-netline-ui/netline-ui';
import { Settings } from '@material-ui/icons';
import { services } from '@crew-webui/common/api';
import { useConnection, useCrewAuth, useOnlineAuthorized } from '@crew-webui/common/hooks';
import { OfflineEditInfo } from '@crew-webui/common/view/components';

import { routePaths, titles } from 'consts';
import { IadpPage } from 'view/components';
import Fieldset from '../Fieldset/Fieldset';
import styles from './AccessRights.styles';

const useStyles = makeStyles(styles, { name: 'AccessRights' });

export enum AccessRightMode {
  ALLOW_ALL = 'ALLOW_ALL',
  RESTRICT_ALL = 'RESTRICT_ALL',
}

type BuddyAccessRightsMode = {
  buddyAccessRightsMode: AccessRightMode;
  numberOfCrewMembersInAllowAllExceptionList: number;
  numberOfCrewMembersInRestrictAllExceptionList: number;
};

type AccessRightsModeResponse = PemsResponse<BuddyAccessRightsMode>;

const AccessRights = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { crmId } = useCrewAuth();
  const { user } = useCrewAuth();
  const { online } = useConnection();

  const [fetching, setFetching] = useState(false);
  const [mode, setMode] = useState<BuddyAccessRightsMode>(
    {
      buddyAccessRightsMode: AccessRightMode.RESTRICT_ALL,
      numberOfCrewMembersInAllowAllExceptionList: 0,
      numberOfCrewMembersInRestrictAllExceptionList: 0,
    },
  );

  useOnlineAuthorized(() => {
    services.loadAccessRightsMode({ crmId }).then(({ data }: { data: AccessRightsModeResponse }) => {
      const { result, success } = data;

      if (success && result) {
        setMode(result);
      }
    });
  }, [crmId]);

  const RadioLabel = useCallback(({
    label,
    exceptionValue,
  }: {
    label: string;
    exceptionValue: number;
  }) => (
    <div className={classes.radioLabel}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography>{exceptionValue < 1 ?
        t('accessRights.noExceptions') :
        `${exceptionValue} ${t('accessRights.noExceptionList')}`}
      </Typography>
    </div>
  ), [classes.label, classes.radioLabel, t]);

  const items = [
    {
      'value': AccessRightMode.ALLOW_ALL,
      'label': <RadioLabel
        label={t('accessRights.includeTitle')}
        exceptionValue={mode.numberOfCrewMembersInAllowAllExceptionList}
      />,
      'data-test-id': 'include-all-radio',
    },
    {
      'value': AccessRightMode.RESTRICT_ALL,
      'label': <RadioLabel
        label={t('accessRights.excludeTitle')}
        exceptionValue={mode.numberOfCrewMembersInRestrictAllExceptionList}
      />,
      'data-test-id': 'exclude-all-radio',
    },
  ];

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const prevMode = mode;

    setMode((prevState) => ({
      ...prevState,
      buddyAccessRightsMode: value as AccessRightMode,
    }));

    setFetching(true);

    services.updateAccessRightsMode({ crmId, buddyAccessRightsMode: value })
      .then(({ data }: { data: AccessRightsModeResponse }) => {
        const { result, success } = data;

        if (success && result) {
          setMode(result);
          return;
        }

        setMode(prevMode);
      })
      .catch(() => setMode(prevMode))
      .finally(() => setFetching(false));
  }, [setFetching, crmId, mode]);

  const manageBuddies = useCallback(() => {
    history.push(routePaths.buddyExcludeList);
  }, [history]);

  const dataLoaded = !!mode;

  const subtitle = t('accessRights.title');

  return (
    <IadpPage
      dataLoaded={dataLoaded}
      headerProps={{ showBackButton: true, subtitle, title: t(titles.profile) }}
    >
      <OfflineEditInfo />
      {dataLoaded && (
        <Fieldset className={classes.fieldset} noBorder title={t('accessRights.subtitle')}>
          <RadioGroup className={classes.radioGroup} value={mode.buddyAccessRightsMode} onChange={handleChange}>
            {items.map((item) => (
              <div className={classes.inlineRadio} key={item.value}>
                <Radio
                  disabled={fetching || !online}
                  LabelProps={{ classes: { root: classes.labelRoot } }}
                  {...item}
                />
                {!user.configuration?.idpBuddyAccessRightsSimplifiedConfiguration && (
                  <IconButton
                    data-test-id="open-sample-range-button"
                    onClick={manageBuddies}
                    disabled={mode.buddyAccessRightsMode !== item.value || !online}
                  >
                    <Settings />
                  </IconButton>
                )}
              </div>
            ))}
          </RadioGroup>
        </Fieldset>
      )}
    </IadpPage>
  );
};

export default AccessRights;
