import { useTranslation } from 'react-i18next';
import { Error as ErrorIcon } from '@material-ui/icons';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import { useConnection } from '../../../hooks';
import WarningBar from '../WarningBar/WarningBar';
import styles from './NoConnection.styles';

const useStyles = makeStyles(styles, { name: 'NoConnection' });

const NoConnection = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { online, crewComOnline } = useConnection();

  if (online && crewComOnline) {
    return null;
  }
  return (
    <WarningBar>
      <ErrorIcon fontSize="small" />
      <span className={classes.noConnection} data-test-id="no-connection-message">
        {t(!online ? 'error.noConnection' : 'error.noCrewComConnection')}
      </span>
    </WarningBar>
  );
};

export default NoConnection;
