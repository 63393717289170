import colors from '@lsy-netline-ui/netline-ui/theme/colors';

import defaultTheme from './defaultTheme';
import { CustomerTheme } from './themeTypes';

const gafTheme: CustomerTheme = {
  ...defaultTheme,

  palette: {
    ...defaultTheme.palette,
    calendar: {
      ...defaultTheme.palette.calendar,
      day: {
        ...defaultTheme.palette.calendar.day,
        selectedDayBackgroundColor: '#b4bfce',
      },
      headerBackgroundColor: '#49596e',
    },
    dutyList: {
      ...defaultTheme.palette.dutyList,
      selectedRowBackgroundColor: '#b4bfce',
      headerBackgroundColor: '#54667e',
    },
    events: {
      ...defaultTheme.palette.events,
      neutral: {
        ...defaultTheme.palette.events.neutral,
        backgroundColor: '#E9B700',
      },
      other: {
        ...defaultTheme.palette.events.neutral,
        backgroundColor: '#E9B700',
      },
      sick: {
        ...defaultTheme.palette.events.sick,
        backgroundColor: '#EF6C00',
      },
      sim: {
        ...defaultTheme.palette.events.sim,
        backgroundColor: '#871000',
      },
      ground: {
        ...defaultTheme.palette.events.ground,
        backgroundColor: '#3E9D3D',
      },
      par: {
        ...defaultTheme.palette.events.par,
        backgroundColor: '#177FD2',
      },
      leg: {
        ...defaultTheme.palette.events.leg,
        backgroundColor: '#177FD2',
      },
      off: {
        ...defaultTheme.palette.events.off,
        backgroundColor: '#9E9E9E',
      },
      vac: {
        ...defaultTheme.palette.events.vac,
        backgroundColor: '#212121',
      },
      sby: {
        backgroundColor: '#7138CA',
        textColor: '#FFFFFF',
      },
    },
    iadp: {
      ...defaultTheme.palette.iadp,
      checkboxColors: {
        ...defaultTheme.palette.iadp.checkboxColors,
        onEditRoles: '#697e9a',
        onRosterChanges: {
          color: defaultTheme.palette.iadp.button.textColor,
          background: '#697e9a',
        },
      },
    },
  },
  overrides: {
    MuiSwitch: {
      track: {
        '$checked$checked + &': {
          backgroundColor: colors.lynch.controller,
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        'color': colors.lynch.hover,
        '&$checked&.IADP-MuiRadio-root': {
          color: colors.lynch.controller,
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        'color': colors.lynch.hover,
        '&$checked&.IADP-MuiCheckbox-root': {
          color: colors.lynch.controller,
        },
      },
    },
  },
};

export default gafTheme;
