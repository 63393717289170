import { createContext } from 'react';

type ConnectionContextType = {
  crewComOnline: boolean;
  online: boolean;

  setCrewComOnline: (online: boolean) => void;
  setOnline: (online: boolean) => void;
};

const ConnectionContext = createContext<ConnectionContextType>({
  crewComOnline: true,
  online: true,

  setCrewComOnline: () => {},
  setOnline: () => {},
});

export default ConnectionContext;
