import { useCallback, useMemo } from 'react';
import {
  AccessTime as AccessTimeIcon,
  Event as EventIcon,
} from '@material-ui/icons';

import { IconButton, makeStyles } from '@lsy-netline-ui/netline-ui';

import { useDomain, useMultiColumn, usePublicConfig } from '../../../hooks';
import { MODULE_CHOICE_POPUP_EVENT, PemsDomain } from '../../../consts';
import ModuleAvatar from './ModuleAvatar';

const useStyles = makeStyles(() => ({
  moduleAvatarButton: {
    padding: '9px',
  },
}));

const ModuleSwitchButton = () => {
  const { pemsDomain } = useDomain();
  const classes = useStyles();
  const { isNetlineAppframe, appFrameServices } = usePublicConfig();
  const multiColumn = useMultiColumn();

  const isSingleApp = useMemo(
    () => (isNetlineAppframe ? appFrameServices.appFrame.isSingleApp() : false),
    [appFrameServices, isNetlineAppframe],
  );

  const switchModule = useCallback(() => {
    if (isNetlineAppframe) {
      appFrameServices.appFrame.mobileNavigation.open();
    } else {
      window.dispatchEvent(new Event(MODULE_CHOICE_POPUP_EVENT));
    }
  }, [isNetlineAppframe, appFrameServices]);

  if (multiColumn || isSingleApp) {
    return null;
  }
  return (
    <IconButton onClick={switchModule} className={classes.moduleAvatarButton} size="small">
      <ModuleAvatar>
        {pemsDomain === PemsDomain.IADP ? <EventIcon /> : <AccessTimeIcon />}
      </ModuleAvatar>
    </IconButton>
  );
};

export default ModuleSwitchButton;
