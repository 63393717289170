import { createContext } from 'react';

import { ServerEnabling } from '../../consts';
import { AuthState, initialState } from '../Auth';

interface CrewAuthContextInterface {
  authenticated: boolean;
  authState: AuthState;
  crmId?: string;
  loginClientTimestamp?: string;
  oidcEnabled: boolean;
  ready: boolean;
  user: {
    configuration?: {
      languageServiceAvailable?: boolean;
      enablings: ServerEnabling[];
      defaultPageSize?: number;
      otUserOtherOffersPageSize?: number;
      keepHistoryNumberOfDays: number;
      idpBuddyAccessRightsSimplifiedConfiguration : boolean;
    };
    crmId: string | undefined;
    firstName: string;
    lastName: string;
    refTimestamp?: string;
    refTimestampLtAtHb?: string;
    userName: string;
  };

  authenticate: ({ username, password }: { username: string; password: string }) => Promise<any>;
  logout: (param?: { suppressNotification: boolean }) => Promise<unknown>;
}

const CrewAuthContext = createContext<CrewAuthContextInterface>({
  ...initialState,
  ready: true,

  authenticate: () => new Promise(() => {}),
  logout: () => new Promise(() => {}),
});

export default CrewAuthContext;
