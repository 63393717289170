import { CustomerTheme } from './themeTypes';
import createCustomTheme from './createCustomTheme';

const btiDefault = createCustomTheme('bti');

const btiTheme: CustomerTheme = {
  ...btiDefault,
  palette: {
    ...btiDefault.palette,
    calendar: {
      ...btiDefault.palette.calendar,
      suspendedDay: {
        ...btiDefault.palette.calendar.suspendedDay,
        textAndIconColor: '#FFE579',
      },
    },
    events: {
      ...btiDefault.palette.events,
      par: {
        backgroundColor: '#9AA426',
        textColor: '#FFFFFF',
      },
      leg: {
        backgroundColor: '#9AA426',
        textColor: '#FFFFFF',
      },
      rtg: {
        backgroundColor: '#9AA426',
        textColor: '#FFFFFF',
      },
      sby: {
        backgroundColor: '#177FD2',
        textColor: '#FFFFFF',
      },
      fpr: {
        backgroundColor: '#6532B4',
        textColor: '#FFFFFF',
      },
      gpr: {
        backgroundColor: '#905CE3',
        textColor: '#FFFFFF',
      },
      vac: {
        backgroundColor: '#54667E',
        textColor: '#FFFFFF',
      },
      sick: {
        backgroundColor: '#08969B',
        textColor: '#FFFFFF',
      },
      ground: {
        backgroundColor: '#D0A400',
        textColor: '#FFFFFF',
      },
      sim: {
        backgroundColor: '#B71600',
        textColor: '#FFFFFF',
      },
      off: {
        backgroundColor: '#7E91AA',
        textColor: '#FFFFFF',
      },
      other: {
        backgroundColor: '#7E7162',
        textColor: '#FFFFFF',
      },
      susp: {
        backgroundColor: '#FFE579',
        borderColor: '#000000',
        textColor: '#000000',
      },
    },
  },
};

export default btiTheme;
