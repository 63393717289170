import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';

import { services } from '../../api';
import { PemsDomain } from '../../consts';
import { useCrewAuth } from '../CrewAuth';
import { useDomain } from '../Domain';
import UpdatesContext, { defaultStateValue, UpdatesResponse } from './UpdatesContext';
import { transformApiResponse } from './updatesUtils';

const POLLING_TIMEOUT = 15000; // in ms

const updatesServices = {
  [PemsDomain.IADP]: services.checkIadpUpdates,
  [PemsDomain.OT]: services.checkOtUpdates,
};

const UpdatesProvider = ({ children }: { children: React.ReactNode }) => {
  const { crmId, authenticated } = useCrewAuth();
  const { pemsDomain } = useDomain();

  const [lastUpdates, setLastUpdates] = useState(defaultStateValue);

  // Fetch updates endpoint
  const fetchUpdates = useCallback(
    (): Promise<any> => {
      if (!crmId || pemsDomain === PemsDomain.NLWS) {
        return Promise.resolve();
      }
      return updatesServices[pemsDomain]({ crmId })
        .then(({ data }: { data: UpdatesResponse }) => {
          const { result } = data;

          if (result) {
            setLastUpdates(transformApiResponse(result));
          }
        });
    },
    [crmId, pemsDomain],
  );

  // Poll the updates endpoint
  useEffect(() => {
    fetchUpdates();
    const intervalId = authenticated ? setInterval(fetchUpdates, POLLING_TIMEOUT) : undefined;

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [fetchUpdates, authenticated]);

  const ctx = useMemo(() => ({ ...lastUpdates, fetchUpdates }), [fetchUpdates, lastUpdates]);

  return <UpdatesContext.Provider value={ctx}>{children}</UpdatesContext.Provider>;
};

export const useUpdates = () => useContext(UpdatesContext);

export default UpdatesProvider;
