import sha512 from 'crypto-js/sha512';

import { asyncStorage } from '../storage';

import {
  getOrGenerateSalt,
  createResponseCache,
  createEncryptedStorageCache,
  createPasswordProvider,
  createSuccessCacheResponse,
  ResponseCache, createServerTimestampProvider,
} from './cacheProviderUtil';

const LOGIN_FAILED_KEY = 'authentication.failed';
const LOGIN_NOT_STORED_KEY = 'authentication.not.stored';
const LOCAL_STORAGE_PWD_PREFIX = 'pwd_';
const USE_PASSWORD = !!window.cordova;
export const PERSIST_CACHE = !!window.cordova || process.env.NODE_ENV === 'development';

/**
 * Simple map cache
 */
const mapCache = new Map<string, string>();
/**
 * The used salt
 */
const salt = getOrGenerateSalt();

/**
 * Use salt as password provider value
 */
const saltProvider = {
  getPassword: () => salt,
  setPassword: () => {
    throw new Error('should not be called');
  },
};

/**
 * Create a throwable object for errornuous response.
 *
 * @param config     The initial rest configuration.
 * @param error      The error object which will serve as a base data.
 * @param messageKey The message which will be added in the response data.
 * @returns Returns the generated error object.
 */
const createError = (config: any, error: any, messageKey: any) => ({
  ...error,
  response: {
    headers: {
      'content-type': 'application/json',
    },
    config,
    status: 401,
    data: {
      success: false,
      messages: [{ severity: 'ERROR', messageKey }],
    },
  },
});

const renewCache = {
  set: () => {},
  get: (config: any) => {
    const username = config.url.substr(config.url.lastIndexOf('/') + 1);
    const data = {
      authUrl: '',
      cookieMaxAge: '-1',
      certValidity: '-1',
      remainingCertValidity: '500',
      user: username,
      applicationLockTimeout: 0,
    };
    return createSuccessCacheResponse(config, data);
  },
};

/**
 * Must call initCache before createLoginHandler
 */
let cacheInitialized = false;

export const isCacheWorking = () => !PERSIST_CACHE || asyncStorage.isAsynchCacheWorking();

export const initCache = async () => {
  cacheInitialized = true;
  if (PERSIST_CACHE) {
    const isWorking = await asyncStorage.initAndTestCache();
    return isWorking;
  }

  return true;
};

const createCacheHandler = () => {
  if (!cacheInitialized) {
    throw Error('Call initCache before createLoginHandler');
  }
  let isLoggedIn = false;
  // salt provider
  const simpleSaltCache = PERSIST_CACHE ? createEncryptedStorageCache(saltProvider) : mapCache;
  // cache responses until no password
  const saltedCache = createResponseCache(simpleSaltCache);

  // password provider
  const passwordHolder = createPasswordProvider();

  // server timestamp provider
  const serverTimestampHolder = createServerTimestampProvider();

  // cache responses after password exists
  const passwordCache = createResponseCache(PERSIST_CACHE ? createEncryptedStorageCache(passwordHolder, serverTimestampHolder, simpleSaltCache) : mapCache);

  // create authentication response cache setter and getter
  const authCache: ResponseCache = {
    set: async (config, response: any) => {
      const username = config.url.substr(config.url.lastIndexOf('/') + 1);
      const { password } = config.headers;
      const data = JSON.parse(response.data);
      if (data.success) {
        isLoggedIn = true;
        // if use password, set the password to provider in online mode
        if (USE_PASSWORD) {
          passwordHolder.setPassword(password);
          await passwordCache.set(config, response);
        } else {
          await saltedCache.set(config, response);
        }
        // cache the transformed password, to check it in offline mode
        await simpleSaltCache.set(LOCAL_STORAGE_PWD_PREFIX + username, sha512(password).toString());
      }
      return response;
    },
    get: async (config, error) => {
      const username = config.url.substr(config.url.lastIndexOf('/') + 1);
      const { password } = config.headers;
      const cachedPwd = await simpleSaltCache.get(LOCAL_STORAGE_PWD_PREFIX + username);
      // check password if correct
      if (!cachedPwd) {
        throw createError(config, error, LOGIN_NOT_STORED_KEY);
      }
      isLoggedIn = sha512(password).toString() === cachedPwd;
      if (!isLoggedIn) {
        // if not correct send failed response
        throw createError(config, error, LOGIN_FAILED_KEY);
      }

      // if use password, set the password to provider in offline mode
      if (USE_PASSWORD) {
        passwordHolder.setPassword(password);
      }
      // Returns the success response in offline mode
      return USE_PASSWORD ? passwordCache.get(config) : saltedCache.get(config);
    },
  };

  // create logout response cache setter and getter
  const logoutCache: ResponseCache = {
    set: (config, response) => {
      isLoggedIn = false;
      return passwordCache.set(config, response);
    },
    get: (config) => {
      isLoggedIn = false;
      return passwordCache.get(config);
    },
  };
  // create login cache getter and setter
  const loginCache: ResponseCache = {
    set: async (config, response: any) => {
      const data = JSON.parse(response.data);
      if (data.success) {
        isLoggedIn = true;
        const { crmId, refTimestamp } = data.result;
        // if not use password, then set crmId as password in online mode
        if (!USE_PASSWORD && !passwordHolder.getPassword()) {
          passwordHolder.setPassword(crmId);
        }
        if (!serverTimestampHolder.getTimestamp()) {
          serverTimestampHolder.setServerTime(refTimestamp);
        }
        // cache the response if data success
        return saltedCache.set(config, response);
      }
      return response;
    },
    get: async (config) => {
      // `config.offlineAuth` will indicates if the user has used biometric or PIN-code authentication in offline mode
      // recommended to introduce an `isOfflineAuth` method provided by the app
      const response: any = await saltedCache.get(config);
      if (!USE_PASSWORD && !passwordHolder.getPassword()) {
        // if not use password, then set crmId as password in offline mode
        const data = JSON.parse(response.data);
        if (data?.result?.crmId) {
          passwordHolder.setPassword(data.result.crmId);
        }
      }
      // Returns the cached response in offline mode
      return response;
    },
  };
  return {
    saltedCache,
    passwordCache,
    renewCache,
    authCache,
    loginCache,
    logoutCache,
  };
};

export default createCacheHandler;
