export default () => ({
  root: {
    'marginBottom': 16,
    'width': '100%',

    '&.size-2': {
      'width': 'calc(33% - 6px)',

      '& .IADP-MuiFormLabel-root': {
        // hack to show more from the label text for very short fields
        width: 'calc(100% + 9px)',
      },
    },
    '&.size-3': {
      width: 'calc(50% - 6px)',
    },
    '&.size-4': {
      width: 'calc(66% - 6px)',
    },
  },
});
