import { makeStyles } from '@lsy-netline-ui/netline-ui';

import { BlockMonth } from '../../../../consts';
import { useBlockMonthData } from '../../../../hooks';
import styles from './BlockMonthData.styles';

const useStyles = makeStyles(styles, { name: 'BlockMonthData' });

type BlockMonthDataProps = {
  blockMonth: BlockMonth;
};

const BlockMonthData = ({ blockMonth }: BlockMonthDataProps) => {
  const classes = useStyles();
  const { getBlockMonthData } = useBlockMonthData();

  const data = getBlockMonthData(blockMonth);

  if (!data) {
    return null;
  }

  return (
    <div className={classes.root}>
      {data.map(({ label, value }) => (
        <div key={label} className={classes.item}>
          <div className={classes.label}>{label}</div>
          <div>{value}</div>
        </div>
      ))}
    </div>
  );
};

export default BlockMonthData;
