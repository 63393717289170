import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import styles from './Fieldset.styles';

const useStyles = makeStyles(styles, { name: 'Fieldset' });

type Props = {
  className?: string;
  title?: string;
  noBorder: boolean;
  children: React.ReactNode;
};

const Fieldset = ({
  className = '', noBorder, title, children, ...props
}: Props) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className, { [classes.noBorder]: noBorder })} {...props}>
      {title && <div className={clsx(classes.title)} data-test-id="settings-section-title">{title}</div>}
      {children}
    </div>
  );
};

export default Fieldset;
