import {
  List, ListItem, ListItemText, Typography, makeStyles,
} from '@lsy-netline-ui/netline-ui';
import { get } from 'lodash';
import { DateTime } from 'luxon';

import { useProfile } from '@crew-webui/common/hooks';

import styles from './MyDataList.styles';
import { MyDataFieldType } from 'consts/myDataConsts';

const useStyles = makeStyles(styles, { name: 'MyDataList' });

type MyDataListProps = {
  data: any;
  label?: string;
  list: MyDataFieldType[];
};

const MyDataList = ({ data, label, list }: MyDataListProps) => {
  const classes = useStyles();
  const { profile } = useProfile();

  const items = list.map((item) => {
    let value = get(data, item.valuePath);

    if (value && item.date) {
      value = DateTime.fromISO(value).toFormat(profile.dateFormats.DATE);
    } else if (item.valueTransform) {
      value = item.valueTransform(value);
    }

    return {
      label: item.label,
      value: value?.toString().trim() || '-',
    };
  });

  return (
    <div className={classes.root}>
      {label && (
        <Typography className={classes.label} data-test-id="my-data-section-title" variant="h6">
          {label}
        </Typography>
      )}
      <List className={classes.list}>
        {items.map((item) => (
          <ListItem key={item.label} className={classes.listItem} disableGutters>
            <ListItemText
              className={classes.listItemText}
              primary={item.label}
              primaryTypographyProps={{
                'data-test-id': 'my-data-list-label', 'variant': 'overline', 'className': classes.listItemLabel,
              } as any}
              secondary={item.value}
              secondaryTypographyProps={{
                'className': classes.listValue,
                'data-test-id': 'my-data-list-value',
                'variant': 'h5',
              } as any}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MyDataList;
