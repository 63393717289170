import { makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import { CalendarState } from '../../../../consts';
import { useDutiesPresentation } from '../../../../hooks';
import useCalendarToggle from './useCalendarToggle';
import styles from './CalendarToggleButton.styles';

const useStyles = makeStyles(styles, { name: 'CalendarToggleButton' });

type CalendarToggleButtonProps = {
  fullyOpenStateEnabled: boolean;
};

const CalendarToggleButton = ({ fullyOpenStateEnabled }: CalendarToggleButtonProps) => {
  const classes = useStyles();
  const buttonSwipeHandlers = useCalendarToggle(fullyOpenStateEnabled);
  const { calendarState } = useDutiesPresentation();

  return (
    <button
      {...buttonSwipeHandlers}
      className={
        clsx(classes.root, {
          [classes.arrowDown]: calendarState === CalendarState.CLOSED,
          [classes.arrowUp]: (
            calendarState === CalendarState.OPEN ||
              (!fullyOpenStateEnabled && calendarState === CalendarState.HALFOPEN)
          ),
        })
      }
      data-test-id="toggle-calendar-button"
      type="button"
    >
      <div className={classes.inner} />
    </button>
  );
};

export default CalendarToggleButton;
