import { Event } from '@crew-webui/common/consts';

import { CheckInPermission, CheckInTitle } from 'consts';

const { Geofence } = window;

export enum CheckinStatus {
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  ALREADY_CHECKED_IN = 'ALREADY_CHECKED_IN',
  EXPECTED_TO_CHECKIN = 'EXPECTED_TO_CHECKIN',
  EXPECTED_TO_CHECKIN_BUT_NOT_ALLOWED = 'EXPECTED_TO_CHECKIN_BUT_NOT_ALLOWED',
  EXPECTED_TO_CHECKIN_BUT_PENDING = 'EXPECTED_TO_CHECKIN_BUT_PENDING',
  EXPECTED_TO_CHECKIN_IN_APP = 'EXPECTED_TO_CHECKIN_IN_APP',
  NOT_EXPECTED_TO_CHECKIN = 'NOT_EXPECTED_TO_CHECKIN',
  OUT_OF_CHECKIN_ZONE = 'OUT_OF_CHECKIN_ZONE',
}

export const getCheckinStatus = ({
  logicalEventId, checkedIn, checkInPermission, geofenceEnabled, geofenceStatus, checkinInfo, isInRange,
}: {
  checkedIn: boolean;
  checkinInfo: any;
  checkInPermission: CheckInPermission;
  isInRange: boolean;
  geofenceEnabled: boolean;
  geofenceStatus?: string;
  logicalEventId: string;
}) => {
  if (!checkInPermission && !checkedIn) {
    return undefined;
  }
  if (checkedIn) {
    return CheckinStatus.ALREADY_CHECKED_IN;
  }

  if (checkInPermission === CheckInPermission.CHECK_IN_NOT_ALLOWED_BECAUSE_OF_RESTRICTED_IP_ADDRESS) {
    return CheckinStatus.EXPECTED_TO_CHECKIN_BUT_NOT_ALLOWED;
  }

  if (geofenceEnabled) {
    if (checkInPermission === CheckInPermission.CHECK_IN_NOT_ALLOWED) {
      return CheckinStatus.NOT_EXPECTED_TO_CHECKIN;
    }

    if (checkInPermission === CheckInPermission.CHECK_IN_PENDING) {
      return CheckinStatus.EXPECTED_TO_CHECKIN_BUT_PENDING;
    }

    if (!Geofence && checkInPermission === CheckInPermission.CHECK_IN_ALLOWED) {
      return CheckinStatus.EXPECTED_TO_CHECKIN_IN_APP;
    }

    if (Geofence && geofenceStatus === Geofence?.Status.PERMISSION_DENIED) {
      return CheckinStatus.PERMISSION_DENIED;
    }

    if (checkinInfo?.logicalId === logicalEventId && isInRange) {
      return CheckinStatus.EXPECTED_TO_CHECKIN;
    }

    return CheckinStatus.OUT_OF_CHECKIN_ZONE;
  }

  if (checkInPermission === CheckInPermission.CHECK_IN_PENDING) {
    return CheckinStatus.EXPECTED_TO_CHECKIN_BUT_PENDING;
  }

  return checkInPermission === CheckInPermission.CHECK_IN_ALLOWED ?
    CheckinStatus.EXPECTED_TO_CHECKIN :
    CheckinStatus.NOT_EXPECTED_TO_CHECKIN;
};

export const getCheckinSubtitle = ({
  airportCode, checkInTitle, dutyList, eventId, t,
}: {
  airportCode?: string;
  checkInTitle: CheckInTitle;
  dutyList: Event[];
  eventId: number;
  t: T;
}) => {
  const checkinParentEvent = dutyList.find((parentEvent) => {
    const subEvents = parentEvent.events || [];
    return subEvents.some((event) => event.id === eventId);
  });

  const events: Event[] = checkinParentEvent ? checkinParentEvent.events! : dutyList;
  const checkinEventIdx = events.findIndex((event) => event.id === eventId);
  const nextEvent = checkinEventIdx >= 0 ? events[checkinEventIdx + 1] : null;

  if (!nextEvent) {
    return '';
  }

  const pairingNumber = checkinParentEvent?.eventDescription?.pairingNumber;

  // display only the pairing number in the title of Checkin page
  if (checkinParentEvent && checkInTitle === CheckInTitle.SHOW_ONLY_PAIRING_NUMBER) {
    return pairingNumber;
  }

  // display the pairing number + event description infos + departure airport code in the title of Checkin page
  if (checkinParentEvent && checkInTitle === CheckInTitle.ADD_PAIRING_NUMBER) {
    switch (nextEvent.originalEventType) {
      case 'LEG':
      case 'FPR':
        // pairingNumber (Airline+FlightNo+Suffix "from" Departure Airport)
        return t('duties.checkin.checkInForFromWithPairingNo', {
          pairingNumber,
          event: `${nextEvent.eventDescription?.flightNumber}${nextEvent.eventDescription?.flightSuffix || ''}`,
          airportCode,
        });
      case 'GPR':
        // pairingNumber (ground transport code "from" Departure Airport)
        return t('duties.checkin.checkInForFromWithPairingNo', {
          pairingNumber,
          event: nextEvent.eventDescription?.groundTransportCode,
          airportCode,
        });
      case 'ABS':
      case 'SBY':
        // pairingNumber (ground activity code "from" Departure Airport)
        return t('duties.checkin.checkInForFromWithPairingNo', {
          pairingNumber,
          event: nextEvent.eventDescription?.groundActivityCode,
          airportCode,
        });
      default:
        return pairingNumber;
    }
  }

  // checkInTitle is NO_PAIRING_NUMBER:
  // display some event description infos + departure airport code in the title of Checkin page
  switch (nextEvent.originalEventType) {
    case 'LEG':
    case 'FPR':
      // departure date / Airline+FlightNo+Suffix "from" Departure Airport
      return t('duties.checkin.checkInForFrom', {
        event: `${nextEvent.fromDate} / ${nextEvent.eventDescription?.flightNumber}${nextEvent.eventDescription?.flightSuffix || ''}`,
        airportCode,
      });
    case 'GPR':
      // departure date / ground transport code "from" Departure Airport
      return t('duties.checkin.checkInForFrom', {
        event: `${nextEvent.fromDate} / ${nextEvent.eventDescription?.groundTransportCode}`,
        airportCode,
      });
    case 'ABS':
    case 'SBY':
      // departure date / activity code "from"  Departure Airport
      return t('duties.checkin.checkInForFrom', {
        event: `${nextEvent.fromDate} / ${nextEvent.eventDescription?.groundActivityCode}`,
        airportCode,
      });
    default:
      return '';
  }
};
