import { useHistory, useLocation } from 'react-router-dom';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterList as FilterListIcon,
  Check as CheckIcon,
} from '@material-ui/icons';
import {
  Button, makeStyles, IconButton, MenuItem,
} from '@lsy-netline-ui/netline-ui';

import { isModule } from '../../../../consts';
import {
  useMultiColumn, useShowRosterTags, useShowPairingCount, useDutiesPresentation, useDutiesModel, useDomain,
} from '../../../../hooks';

import { BlockMonthSwitch, DropdownMenu, ModuleSwitchButton } from '../../../components';
import RefreshDutyList from '../../RefreshDutyList/RefreshDutyList';
import styles from './CalendarHeader.styles';

const useStyles = makeStyles(styles, { name: 'CalendarHeader' });

type Props = {
  classes?: Record<string, any>;
  showPairingsPerDayCalendarFilter?: boolean;
  showRosterTagsCalendarFilter?: boolean;
};

const CalendarHeader = ({ classes: classesProp, showPairingsPerDayCalendarFilter = false, showRosterTagsCalendarFilter = false }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { routePrefix } = useDomain();
  const classes = useStyles({ classes: classesProp });
  const { rosterTagsShown, toggleRosterTags } = useShowRosterTags();
  const { pairingCountShown, toggleShowPairings } = useShowPairingCount();
  const { highlightToday } = useDutiesPresentation();
  const {
    goToCurrentBlockMonth, activeBlockMonthIndex, getCurrentBlockMonthIndex,
  } = useDutiesModel();
  const multiColumn = useMultiColumn();
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement>();
  const showSeparateTitleRow = !multiColumn;
  const currentBlockMonthIndex = getCurrentBlockMonthIndex();
  const todayButtonPressed: any = useRef();
  const { search } = useLocation();

  const toggleFilter = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
  }, []);

  const closeFilter = useCallback(() => {
    setFilterAnchorEl(undefined);
  }, []);

  const handleToggleRostertagClick = useCallback(() => {
    toggleRosterTags();
    closeFilter();
  }, [closeFilter, toggleRosterTags]);

  const handleTogglePairingCountClick = useCallback(() => {
    toggleShowPairings();
    closeFilter();
  }, [closeFilter, toggleShowPairings]);

  const handleClick = useCallback(() => {
    history.push({ pathname: routePrefix, search });
    if (currentBlockMonthIndex === activeBlockMonthIndex) {
      highlightToday();
    } else {
      goToCurrentBlockMonth();
      todayButtonPressed.current = true;
    }
  }, [activeBlockMonthIndex, currentBlockMonthIndex, goToCurrentBlockMonth, highlightToday, history, routePrefix, search]);

  useEffect(() => {
    if (todayButtonPressed.current && currentBlockMonthIndex === activeBlockMonthIndex) {
      highlightToday();
      todayButtonPressed.current = false;
    }
  }, [activeBlockMonthIndex, currentBlockMonthIndex, highlightToday]);

  const title = showSeparateTitleRow ? (
    <span className={classes.calendarInfo}>{t('duties.main.singleColumnTitle')}</span>
  ) : (
    <BlockMonthSwitch className={classes.calendarInfo} />
  );

  const todayButton = currentBlockMonthIndex !== -1 && (
    <Button secondary size="small" onClick={handleClick}>{t('duties.main.todayButton')}</Button>
  );

  return (
    <div className={classes.root}>
      <div className={classes.titleRow}>
        <span className={classes.leftIcons}>
          {isModule && <ModuleSwitchButton />}
          <RefreshDutyList />
        </span>
        <span className={classes.title}>
          {!showSeparateTitleRow && todayButton}
          {title}
        </span>
        <span className={classes.rightIcons}>
          {(showRosterTagsCalendarFilter || showPairingsPerDayCalendarFilter) && (
            <IconButton data-test-id="open-dropdown-button" onClick={toggleFilter} size="small">
              <FilterListIcon />
            </IconButton>
          )}
          <DropdownMenu anchorEl={filterAnchorEl} onClose={closeFilter}>
            {showRosterTagsCalendarFilter && (
              <MenuItem
                className={classes.filterMenuItem}
                data-test-id="toggle-roster-tags-button"
                onClick={handleToggleRostertagClick}
              >
                <span className={classes.filterIcon}>
                  {rosterTagsShown && <CheckIcon />}
                </span>
                {t('duties.filter.roster')}
              </MenuItem>
            )}
            {showPairingsPerDayCalendarFilter && (
              <MenuItem
                className={classes.filterMenuItem}
                data-test-id="toggle-pairing-count-button"
                onClick={handleTogglePairingCountClick}
              >
                <span className={classes.filterIcon}>
                  {pairingCountShown && <CheckIcon />}
                </span>
                {t('duties.filter.pairingsPerDay')}
              </MenuItem>
            )}
          </DropdownMenu>
        </span>
      </div>
      {showSeparateTitleRow && (
        <div className={classes.periodRow}>
          {todayButton}
          <BlockMonthSwitch />
        </div>
      )}
    </div>
  );
};

export default CalendarHeader;
