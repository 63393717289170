import { alpha } from '@material-ui/core/styles';

import { THEME_BREAKPOINTS } from '@crew-webui/common/consts';
import {
  primaryColors, neutralColors, trafficLightColors, CustomThemes,
} from '@crew-webui/common/consts/themeColors';

function createCustomTheme(themeName: CustomThemes) {
  const themeColors = { ...primaryColors[themeName] };

  const text = {
    primary: neutralColors.grey7,
    secondary: neutralColors.grey4,
    label: neutralColors.grey6,
    secondaryLabel: neutralColors.grey5,
  };

  const button = {
    backgroundColor: themeColors.color3,
    textColor: neutralColors.white,
    disabledColor: alpha(text.secondary, 0.2),
    hover: themeColors.color4,
    active: themeColors.color5,
  };

  return {
    classPrefix: 'IADP-',
    breakpoints: THEME_BREAKPOINTS,
    palette: {
      background: {
        default: neutralColors.grey1,
        secondary: neutralColors.white,
      },
      text: {
        primary: neutralColors.grey7,
        secondary: neutralColors.grey4,
        label: neutralColors.grey6,
        secondaryLabel: neutralColors.grey5,
      },
      primary: {
        main: themeColors.color3,
        contrastText: neutralColors.white,
      },
      error: {
        main: trafficLightColors.red,
      },
      success: {
        main: trafficLightColors.green,
      },
      divider: neutralColors.grey3,
      unreadCardBackgroundColor: themeColors.color1,
      messageStatusIconColor: themeColors.color3,
      notificationBadgeColor: trafficLightColors.red,
      placeholderColor: neutralColors.grey3,
      greyIconColor: neutralColors.grey3,
      calendar: {
        toggleButtonBackgroundColor: neutralColors.grey3,
        day: {
          backgroundColor: neutralColors.grey2,
          textColor: neutralColors.black,
          selectedDayBackgroundColor: themeColors.color1,
          selectedIntervalBackgroundColor: themeColors.color1,
        },
        suspendedDay: {
          backgroundColor: text.secondary,
          textAndIconColor: '#FFC901',
        },
        headerBackgroundColor: themeColors.color6,
        columnBackgroundColor: neutralColors.grey2,
      },
      dutyList: {
        selectedRowBackgroundColor: themeColors.color1,
        toggleIconBackground: neutralColors.grey3,
        defaultIconColor: text.secondary,
        subeventHighlightColor: alpha(themeColors.color3, 0.1),
        earlyTimeColor: trafficLightColors.green,
        lateTimeColor: trafficLightColors.red,
        subeventIconOutlineColor: neutralColors.grey2,
        headerBackgroundColor: themeColors.color5,
      },
      events: {
        par: {
          backgroundColor: themeColors.color4,
          textColor: '#FFFFFF',
        },
        leg: {
          backgroundColor: themeColors.color4,
          textColor: '#FFFFFF',
        },
        rtg: {
          backgroundColor: '#198EEB',
        },
        sby: {
          backgroundColor: '#60AE60',
          textColor: '#FFFFFF',
        },
        hot: {
          backgroundColor: '#630090',
          textColor: '#ffffff',
        },
        abs: {
          backgroundColor: '#D3D3D3',
          textColor: '#000000',
        },
        fpr: {
          backgroundColor: '#60AE60',
          textColor: '#000000',
        },
        gpr: {
          backgroundColor: '#34E012',
          textColor: '#000000',
        },
        susp: {
          backgroundColor: '#FFC901',
          borderColor: '#000000',
          textColor: '#000000',
        },
        vac: {
          backgroundColor: '#198EEB',
          textColor: '#FFFFFF',
        },
        sick: {
          backgroundColor: '#AAAAAA',
          textColor: '#FFFFFF',
        },
        ground: {
          backgroundColor: '#AAAAAA',
          textColor: '#FFFFFF',
        },
        sim: {
          backgroundColor: '#AAAAAA',
          textColor: '#FFFFFF',
        },
        off: {
          backgroundColor: '#AAAAAA',
          textColor: '#FFFFFF',
        },
        other: {
          backgroundColor: '#AAAAAA',
          textColor: '#FFFFFF',
        },
        neutral: {
          backgroundColor: '#AAAAAA',
          textColor: '#FFFFFF',
        },
        /*
             * If an event type has no special icon, we use this appearance in duty list view.
             */
        textIcon: {
          backgroundColor: '#AAAAAA',
          textColor: '#FFFFFF',
        },
      },
      deadheadTextColor: {
        light: themeColors.color3,
        dark: themeColors.color4,
      },
      iadp: {
        darkBackgroundColor: neutralColors.grey4,
        lightTextColor: neutralColors.white,
        primaryLabelTextColor: text.secondaryLabel,
        secondaryLabelTextColor: neutralColors.grey4,
        borderColor: neutralColors.grey3,
        rowBackgroundColor: neutralColors.grey2,
        button,
        rosterChangeDetails: {
          oldDutyTextColor: text.primary,
          newDutyTextColor: text.primary,
        },
        checkboxColors: {
          onEditRoles: text.secondary,
          onRosterChanges: {
            color: button.textColor,
            background: button.backgroundColor,
          },
        },
      },
      legDetails: {
        darkBackgroundColor: neutralColors.grey4,
        borderColor: neutralColors.grey3,
        lightTextColor: neutralColors.white,
      },
    },
    typography: {
      h4: {
        color: text.primary,
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '20px',
      },
      h5: {
        color: text.primary,
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 0.15,
        lineHeight: '24px',
      },
      h6: {
        color: text.secondaryLabel,
        fontSize: 12,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '20px',
        textTransform: 'uppercase' as 'uppercase',
      },
      body1: {
        color: text.primary,
        fontSize: 14,
        letterSpacing: 0,
        lineHeight: '18px',
      },
      body2: {
        color: text.secondary,
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
      },
      caption: {
        color: text.secondaryLabel,
        fontSize: 12,
        letterSpacing: 0,
        lineHeight: '16px',
      },
      overline: {
        color: text.secondary,
        fontSize: 10,
        fontWeight: 500,
        letterSpacing: 1.5,
        lineHeight: '16px',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          '&$disabled': {
            color: button.disabledColor,
          },
        },
        contained: {
          '&, &&:hover': {
            'backgroundColor': button.hover,
            '&&$disabled': {
              backgroundColor: '#ccc',
              opacity: 1,
              color: 'rgba(0, 0, 0, 0.26)',
            },
            '&&:active': {
              backgroundColor: button.active,
            },
          },
        },
        sizeLarge: {
          paddingTop: 10,
          paddingBottom: 10,
          fontSize: 14,
          lineHeight: '16px',
        },
      },
      MuiBottomNavigationAction: {
        root: {
          '&$iconOnly': {
            paddingTop: 8,
          },
        },
      },
      MuiIconButton: {
        root: {
          'color': text.secondary,

          '&$disabled': {
            color: button.disabledColor,
          },
        },
      },
      MuiSvgIcon: {
        fontSizeSmall: {
          fontSize: 16,
        },
      },
      MuiFormHelperText: {
        root: {
          '&$disabled': {
            color: '#BDBDBD',
          },
        },
      },
      MuiCheckbox: {
        root: {
          'color': themeColors.color3,
          '&&$checked': {
            color: themeColors.color3,
          },
        },
      },
      MuiRadio: {
        root: {
          'color': themeColors.color3,
          '&&$checked': {
            color: themeColors.color3,
          },
        },
      },
      MuiInput: {
        root: {
          '&$focused': {
            boxShadow: `0 0 0 2px ${themeColors.color2}`,
          },
        },
      },
    },
  };
}

export default createCustomTheme;
