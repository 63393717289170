import { createContext } from 'react';
import { DateTime, Interval } from 'luxon';

import { CalendarState, Event } from '../../consts';

export type DutiesPresentationContextType = {
  calendarState: CalendarState;
  setCalendarState: (state: CalendarState) => void;

  eventToScroll?: number;
  setEventToScroll: (id?: number) => void;

  openedEventBlocks: number[];
  toggleEventBlock: (id: number, closeOthers?: boolean) => void;

  highlightedEvent?: number;
  setHighlightedEvent: (id: number) => void;

  highlightedDateInterval?: Interval;
  setHighlightedDateInterval: (day: Interval) => void;
  setHighlightedDay: (date: DateTime) => void;

  highlightCurrentEvent: boolean;
  setHighlightCurrentEvent: (value: boolean) => void;

  selectEventForHighlighting: (event?: Event) => void;

  highlightToday: () => void;
};

const defaultValue = {
  calendarState: CalendarState.CLOSED,
  setCalendarState: () => {},

  eventToScroll: undefined,
  setEventToScroll: () => {},

  openedEventBlocks: [],
  toggleEventBlock: () => {},

  highlightedEvent: undefined,
  setHighlightedEvent: () => {},

  highlightedDateInterval: undefined,
  setHighlightedDateInterval: () => {},
  setHighlightedDay: () => {},

  highlightCurrentEvent: false,
  setHighlightCurrentEvent: () => {},

  selectEventForHighlighting: () => {},

  highlightToday: () => {},
};

const DutiesPresentationContext = createContext<DutiesPresentationContextType>(defaultValue);

export default DutiesPresentationContext;
