import { CustomerProfile } from 'consts';
import defaultProfileSettings from './defaultProfile';

const sxsProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  icons: {
    ...defaultProfileSettings.icons,
  },
  logo: 'sunexpress.png',
};

export default sxsProfileSettings;
