import { DateTime } from 'luxon';

import { Event, EventDescription, EventFromServer } from '../consts';
import { formatTime as formatTimeUtil } from './helper';

type EventHeader = (string | ((t: T) => string))[];
const getEventHeader = (
  originalEventType: string,
  eventDescription: EventDescription,
  profile: Record<string, any>,
  rosterChangeType?: string[],
  ignoreShowRank?: boolean,
): EventHeader => {
  if (originalEventType === 'C_I') {
    return [(t: T) => t('event.checkIn')];
  }

  if (originalEventType === 'C_O') {
    return [(t: T) => t('event.checkOut')];
  }

  if (originalEventType === 'RTG') {
    return [eventDescription.rosterTagCode || ''];
  }

  if (['SBY', 'VAC', 'SICK', 'GROUND', 'OTHER', 'OFF', 'SIM'].includes(originalEventType)) {
    return [eventDescription.groundActivityCode || ''];
  }

  if (originalEventType === 'FPR') {
    const eventHeader: EventHeader = [];
    if (eventDescription.flightNumber) {
      eventHeader.push(eventDescription.flightNumber);
    }
    if (eventDescription.acDepGate) {
      eventHeader.push((t: T) => t('event.gateInfo', { gateInfo: eventDescription.acDepGate }));
    }
    return eventHeader;
  }

  if (originalEventType === 'GPR') {
    const eventHeader: EventHeader = [];
    if (eventDescription.groundTransportCode) {
      eventHeader.push(eventDescription.groundTransportCode);
    }
    if (eventDescription.flightNumber) {
      eventHeader.push(eventDescription.flightNumber);
    }
    return eventHeader;
  }

  if (originalEventType === 'SUSP') {
    if (profile.showRosterChanges && rosterChangeType?.length) {
      return [rosterChangeType.join(', ')];
    }
    return [(t: T) => t('event.changePeriodInfo')];
  }

  if (originalEventType === 'LEG') {
    const eventHeader: EventHeader = [];
    if (eventDescription.flightNumber) {
      eventHeader.push(`${eventDescription.flightNumber}${eventDescription.flightSuffix || ''}`);
    }
    if (profile.showAircraftSubType && eventDescription.aircraftSubType) {
      eventHeader.push(eventDescription.aircraftSubType);
    }
    if (profile.showAircraftTypeCodeOfLeg && eventDescription.aircraftTypeCode) {
      eventHeader.push(eventDescription.aircraftTypeCode);
    }
    if (profile.showRegistration && eventDescription.registration) {
      eventHeader.push(eventDescription.registration);
    }
    if (profile.showFinNumber && eventDescription.acReg) {
      eventHeader.push((t: T) => t('event.finNumber', { finNumber: eventDescription.acReg }));
    }
    if (profile.showGateInfo && eventDescription.acDepGate) {
      eventHeader.push((t: T) => t('event.gateInfo', { gateInfo: eventDescription.acDepGate }));
    }
    return eventHeader;
  }

  if (originalEventType === 'PAR') {
    const eventHeader: EventHeader = [];

    if (profile.showPairingNumber && eventDescription.pairingNumber) {
      const pairingNumberParts = eventDescription.pairingNumber.split('/');
      if (pairingNumberParts.length === 2) {
        eventHeader.push(pairingNumberParts[0]);
        eventHeader.push(`/${pairingNumberParts[1]}`);
      } else {
        eventHeader.push(eventDescription.pairingNumber);
      }
    }
    if (profile.showHomeBase && eventDescription.homebase) {
      eventHeader.push(eventDescription.homebase);
    }
    if (profile.showAircraftTypeCodeOfPairing && eventDescription.aircraftTypeCodes) {
      eventHeader.push(eventDescription.aircraftTypeCodes.join(', '));
    }
    if (profile.showRank) {
      if (!ignoreShowRank) {
        eventHeader.push((t: T) => t('event.rank', { rank: eventDescription.rank }));
      }
    }
    if (profile.showPairingClassification && eventDescription.pairingClassifications) {
      eventHeader.push((t: T) => t('event.pairingClassification', { classif: eventDescription.pairingClassifications }));
    }

    return eventHeader;
  }

  return [];
};

const convertEvent = (event: EventFromServer, profile: Record<string, any>, ignoreShowRank?: boolean): Event => {
  const {
    events,
    originalEventType = '',
    activityProgress,
    details,
    id,
    logicalId,
    remark,
    roles = [],
    departure = {},
    arrival = {},
    checkinCheckoutControlled,
    fromDateTimeLtAtAirport,
    toDateTimeLtAtAirport,
    fromDateTimeLtAtHb,
    toDateTimeLtAtHb,
    fromDateTimeUTC,
    toDateTimeUTC,
    fromDateTimeSchedUTC,
    toDateTimeSchedUTC,
    eventDescription = {},
    customAttributes = [],
    rosterChangeType = [],
    selectable,
    simBriefingDateTimeUTC,
    simBriefingDateTimeLtAtHb,
    simDebriefingDateTimeUTC,
    simDebriefingDateTimeLtAtHb,
    crewMemberPartOfFlight,
  } = event;

  const {
    showUTCTime, showLocalTime, dateFormats = {},
  } = profile;

  const formatDate = (timestamp?: string) => (
    timestamp ? DateTime.fromISO(timestamp).toUTC().toFormat(dateFormats.SHORT_DATE) : undefined
  );
  const formatTime = (timestamp: string) => formatTimeUtil(timestamp, dateFormats.TIME);

  const localTimeDisplayed = showLocalTime || !showUTCTime;

  const fromDateTimeLocal: DateTime = DateTime.fromISO(fromDateTimeLtAtAirport).toUTC();
  const toDateTimeLocal: DateTime = DateTime.fromISO(toDateTimeLtAtAirport).toUTC();

  const fromDateTime: DateTime = DateTime.fromISO(fromDateTimeUTC).toUTC();
  const toDateTime: DateTime = DateTime.fromISO(toDateTimeUTC).toUTC();
  const fromTimeUtc: string = showUTCTime ? fromDateTime.toFormat(dateFormats.TIME) : '';
  const toTimeUtc: string = showUTCTime ? toDateTime.toFormat(dateFormats.TIME) : '';

  const fromDateLocal: string | undefined = formatDate(fromDateTimeLtAtAirport);
  const toDateLocal: string | undefined = formatDate(toDateTimeLtAtAirport);
  const fromLocalTime: string | undefined = showLocalTime && fromDateTimeLtAtAirport ? formatTime(fromDateTimeLtAtAirport) : '';
  const toLocalTime: string | undefined = showLocalTime && toDateTimeLtAtAirport ? formatTime(toDateTimeLtAtAirport) : '';

  const fromDateTimeSched: DateTime = DateTime.fromISO(fromDateTimeSchedUTC || '').toUTC();
  const toDateTimeSched: DateTime = DateTime.fromISO(toDateTimeSchedUTC || '').toUTC();

  // formatted date in local at airport or UTC timezone
  const fromDate: string | undefined = formatDate(localTimeDisplayed ? fromDateTimeLtAtAirport : fromDateTimeUTC);
  const toDate: string | undefined = formatDate(localTimeDisplayed ? toDateTimeLtAtAirport : toDateTimeUTC);

  // date object in local at homebase or UTC timezone
  const fromDateAtCalendar: DateTime = localTimeDisplayed ? DateTime.fromISO(fromDateTimeLtAtHb).toUTC() : fromDateTime;
  let toDateAtCalendar: DateTime = localTimeDisplayed ? DateTime.fromISO(toDateTimeLtAtHb).toUTC() : toDateTime;

  if (toDateAtCalendar.toMillis() === toDateAtCalendar.startOf('day').toMillis()) {
    toDateAtCalendar = toDateAtCalendar.minus({ seconds: 1 });
  }

  const { airportCode: fromAirport } = departure;
  const { airportCode: toAirport } = arrival;

  const eventHeader = getEventHeader(originalEventType, eventDescription, profile, rosterChangeType, ignoreShowRank);

  const rolesJoined = roles.map(({ name }) => name).join(', ');
  const rolesStringArray = rolesJoined ? [rolesJoined] : undefined;

  const commonFields = {
    id,
    logicalId,
    originalEventType,
    remark,
    eventDescription,
    eventHeader,
    fromAirport,
    toAirport,
    activityProgress,
    selectable,
    attributes: rolesStringArray,
    roles,
    crewMemberPartOfFlight,

    // dates, times
    fromDate,
    fromDateAtCalendar,
    fromDateLocal,
    fromDateTime,
    fromDateTimeLocal,
    fromLocalTime,
    fromTimeUtc,
    toDate,
    toDateAtCalendar,
    toDateLocal,
    toDateTime,
    toDateTimeLocal,
    toLocalTime,
    toTimeUtc,
  };

  if (originalEventType === 'PAR') {
    return {
      ...commonFields,
      attributes: customAttributes,
      details,
      events: events?.map((subEvent) => convertEvent(subEvent, profile)),
    };
  }

  if (['FPR', 'GPR', 'LEG'].includes(originalEventType)) {
    return {
      ...commonFields,
      fromDateTimeSched,
      toDateTimeSched,
    };
  }

  if (originalEventType === 'C_I') {
    return {
      ...commonFields,
      checkinCheckoutControlled,
      onlyDeparture: true,
    };
  }

  if (originalEventType === 'C_O') {
    return {
      ...commonFields,
      onlyArrival: true,
    };
  }

  if (originalEventType === 'HOT') {
    const { hotelName, hotelCode } = eventDescription;

    return {
      ...commonFields,
      hotelCode,
      hotelName,
      onlyArrival: true,
    };
  }

  if (originalEventType === 'SUSP') {
    return {
      ...commonFields,
      fromDateTimeUTC,
      onlyDeparture: fromDateLocal === toDateLocal,
      toDateTimeUTC,
    };
  }

  if (originalEventType === 'SIM') {
    const simBriefing = {
      dateLocal: formatDate(simBriefingDateTimeLtAtHb),
      localTime: showLocalTime && simBriefingDateTimeLtAtHb ? formatTime(simBriefingDateTimeLtAtHb) : '',
      timeUtc: showUTCTime && simBriefingDateTimeUTC ? formatTime(simBriefingDateTimeUTC) : '',
    };
    const simDebriefing = {
      dateLocal: formatDate(simDebriefingDateTimeLtAtHb),
      localTime: showLocalTime && simDebriefingDateTimeLtAtHb ? formatTime(simDebriefingDateTimeLtAtHb) : '',
      timeUtc: showUTCTime && simDebriefingDateTimeUTC ? formatTime(simDebriefingDateTimeUTC) : '',
    };

    return {
      ...commonFields,
      simBriefing,
      simDebriefing,
    };
  }

  return commonFields;
};

export default convertEvent;
