import { useMemo } from 'react';

import { EventContext } from '../../consts';
import { useEventDetails } from '../EventDetails';
import { useOnlineAuthorized } from '../common';

const usePairingDetails = (context: EventContext, crmId: string, logicalEventId: string) => {
  const { eventDetails, fetchEventDetails } = useEventDetails();

  useOnlineAuthorized(() => {
    fetchEventDetails({ context, logicalEventId });
  }, [context, logicalEventId]);

  const pairing = eventDetails[logicalEventId];

  return useMemo(() => {
    const details = pairing?.details;

    const events = pairing ? [pairing] : [];

    const {
      fromAirport = '', toAirport = '', fromDate = '', toDate = '',
    } = pairing || {};
    const title = fromAirport && fromDate ? `${fromAirport} ${fromDate} - ${toAirport} ${toDate}` : '';

    return { details, events, title };
  }, [pairing]);
};

export default usePairingDetails;
