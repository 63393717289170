import { useCallback, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import { DateTime, Interval } from 'luxon';
import clsx from 'clsx';
import EmailIcon from '@material-ui/icons/Email';

import { Event as EventType } from '../../../../consts';
import {
  useDomain,
  useMultiColumn,
  useProfile,
} from '../../../../hooks';
import { Attributes } from './alignEventTags';
import styles from './Event.styles';

const useStyles = makeStyles(styles, { name: 'Event' });

type Props = {
  date: DateTime;
  events: EventType[];
  inBlockMonth: boolean;
  inRosterPeriod: boolean;
  pairingCount: number;
  rosterTags: EventType[];
  showDailyRemarks: boolean;
  today: boolean;
  highlightedDateInterval: Interval | undefined;
};

const Event = ({
  date, events, inBlockMonth, inRosterPeriod, pairingCount, rosterTags, showDailyRemarks,
  today, highlightedDateInterval,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const multiColumn = useMultiColumn();
  const { routePrefix } = useDomain();
  const { profile, theme } = useProfile();
  const { search } = useLocation();

  const {
    icons, showPairingNumber, showLargeSuspIcon, showGroundActivityCodeInCalendar, typeCodesCalendar,
  } = profile;

  const getIcon = useCallback((iconType: string) => {
    const IconComponent = icons[iconType];
    return IconComponent ? <IconComponent /> : undefined;
  }, [icons]);

  const isIntervalInDay = useMemo(() => highlightedDateInterval && highlightedDateInterval.start.hasSame(highlightedDateInterval.end, 'day'), [highlightedDateInterval]);
  const isIntervaleContainsDate = useMemo(() => highlightedDateInterval && highlightedDateInterval.contains(date), [highlightedDateInterval, date]);
  const highlightedInterval = useMemo(() => isIntervaleContainsDate && !isIntervalInDay, [isIntervaleContainsDate, isIntervalInDay]);
  const highlightedDay = useMemo(() => isIntervaleContainsDate && isIntervalInDay, [isIntervaleContainsDate, isIntervalInDay]);

  const getRosterTags = useCallback(() => rosterTags.map((rosterTag) => {
    let eventStart = false;
    let eventEnd = false;
    let hoursBeforeStart = 0;
    let eventHours = 24;
    const {
      originalEventType, renderIdx, fromDateAtCalendarCropped, toDateAtCalendarCropped, id,
    } = rosterTag;
    const tagType = originalEventType.toLowerCase();
    const rosterTagClass = (classes as any)[tagType] || classes.neutral;

    if (date.hasSame(fromDateAtCalendarCropped!, 'day') && date.hasSame(toDateAtCalendarCropped!, 'day')) {
      hoursBeforeStart = fromDateAtCalendarCropped!.get('hour');
      eventHours = Interval.fromDateTimes(fromDateAtCalendarCropped!, toDateAtCalendarCropped!).length('hour');
      eventStart = true;
      eventEnd = true;
    } else {
      if (date.hasSame(fromDateAtCalendarCropped!, 'day')) {
        hoursBeforeStart = fromDateAtCalendarCropped!.get('hour');
        eventHours = 24 - hoursBeforeStart;
        eventStart = true;
      }

      if (date.hasSame(toDateAtCalendarCropped!, 'day')) {
        hoursBeforeStart = 0;
        eventHours = toDateAtCalendarCropped!.get('hour');
        eventEnd = true;
      }
    }

    return (
      <div
        key={id}
        aria-label="tag"
        className={clsx(
          { [classes.rosterTag]: true },
          { [rosterTagClass]: true },
          { [classes.eventStart]: eventStart },
          { [classes.eventEnd]: eventEnd },
        )}
        data-test-id="calendar-roster-tag"
        style={{
          width: `calc(100% / 24 * ${eventHours})`,
          marginLeft: `calc(100% / 24 * ${hoursBeforeStart})`,
          transform: `translateY(calc(${renderIdx! * 100}% + ${renderIdx}px))`,
        }}
      />
    );
  }), [classes, date, rosterTags]);

  const getEventTags = useCallback(() => events
    .filter(({ originalEventType }) => originalEventType !== 'DREM')
    .map((event) => {
      const {
        croppedAtEnd,
        croppedAtStart,
        eventDescription = {},
        eventHeader,
        fromDateAtCalendarCropped,
        id,
        originalEventType,
        toDateAtCalendarCropped,
      } = event;
      let eventStart = false;
      let eventEnd = false;
      let hoursBeforeStart = 0;
      let eventHours = 24;
      const convertedEventType = typeCodesCalendar[originalEventType] || originalEventType;
      const { groundActivityCode, pairingNumber } = eventDescription;
      const isPairingEvent = originalEventType === 'PAR';
      const eventTagText = isPairingEvent ? showPairingNumber && pairingNumber : convertedEventType;
      let icon = getIcon(originalEventType);
      const translatedEventHeader = eventHeader?.map((item) => (typeof item === 'function' ? item(t) : item));
      let text = isPairingEvent ? translatedEventHeader : [convertedEventType];
      const eventClassification = originalEventType.toLowerCase();
      let eventWrapper = true;
      let right;

      // Determine the event length (one day / multi-day ; start / end)
      if (date.hasSame(fromDateAtCalendarCropped!, 'day') && date.hasSame(toDateAtCalendarCropped!, 'day')) {
        hoursBeforeStart = fromDateAtCalendarCropped!.get('hour');
        eventStart = true;
        eventEnd = true;
        text = !isPairingEvent ? text : [];
      } else {
        if (date.hasSame(fromDateAtCalendarCropped!, 'day')) {
          hoursBeforeStart = fromDateAtCalendarCropped!.get('hour');
          eventHours = 24 - hoursBeforeStart;
          eventStart = true;
          text = !isPairingEvent && eventHours > 12 ? [eventTagText] : text;
        }

        if (date.hasSame(toDateAtCalendarCropped!, 'day')) {
          hoursBeforeStart = 0;
          right = 24 - toDateAtCalendarCropped!.get('hour');
          eventEnd = true;
        }
      }

      eventHours = Interval.fromDateTimes(fromDateAtCalendarCropped!, toDateAtCalendarCropped!).length('hour');

      const rosterChange = originalEventType === 'SUSP';
      if (rosterChange) {
        text = [];
        hoursBeforeStart = 0;
        eventHours = 24;
        eventEnd = false;
        eventStart = false;
        icon = getIcon(originalEventType);
        eventWrapper = false;
      }

      if (groundActivityCode && showGroundActivityCodeInCalendar) {
        text = [groundActivityCode];
      }

      const isEventSusp = eventClassification === 'susp';
      const usedStyleForGroundActivityCode = groundActivityCode && theme.palette.events[groundActivityCode.toLowerCase()];
      const usedStyleForEventType = originalEventType && theme.palette.events[eventClassification];

      return (
        <div
          key={id}
          {...{ [Attributes.eventId.html]: rosterChange ? undefined : id }}
          className={clsx(classes.eventTag, classes.neutral, {
            [classes.susp]: isEventSusp,
            [classes.suspLarge]: showLargeSuspIcon && isEventSusp,
            [classes.eventStart]: eventStart,
            [classes.eventEnd]: eventEnd,
          })}
          data-event-type={originalEventType}
          data-test-id="calendar-event-tag"
          data-cropped-at-start={croppedAtStart}
          data-cropped-at-end={croppedAtEnd}
          style={{
            width: isEventSusp ? undefined : `calc(100% / 24 * ${eventHours})`,
            left: right || isEventSusp ? undefined : `calc(100% / 24 * ${hoursBeforeStart})`,
            right: right && !isEventSusp ? `calc(100% / 24 * ${right})` : undefined,
            backgroundColor: !isEventSusp ? usedStyleForGroundActivityCode?.backgroundColor || usedStyleForEventType?.backgroundColor : 'transparent',
            color: !isEventSusp ? usedStyleForGroundActivityCode?.textColor || usedStyleForEventType?.textColor : undefined,
          }}
        >
          <span className={classes.eventTextWrapper}>
            <span
              {...{ [Attributes.eventTagItems.html]: true }}
              className={clsx(
                { [`com.lhsystems.crew.pems:pems-client-web-app:jar:2023.1.0-eventTextWrapperContent`]: eventWrapper },
                { [classes.eventTextWrapperContent]: true },
              )}
            >
              <span className={classes.eventIcon} data-test-id="calendar-event-icon">{icon}</span>
              {text?.map((item) => (
                <span key={item} className={clsx(classes.eventText, { [classes.noSpacing]: item[0] === '/' })}>
                  {item}
                </span>
              ))}
            </span>
          </span>
        </div>
      );
    }), [classes, date, events, getIcon, showGroundActivityCodeInCalendar, showLargeSuspIcon, showPairingNumber, t, theme.palette.events, typeCodesCalendar]);

  const dayNum = useMemo(() => date.get('day'), [date]);
  const isSusp = useMemo(() => events.some((item) => item.originalEventType === 'SUSP'), [events]);
  const isMessage = useMemo(() => events.some((item) => item.originalEventType === 'DREM'), [events]);
  const eventTags = useMemo(() => getEventTags(), [getEventTags]);

  return (
    <Link
      className={clsx(classes.root, {
        [classes.highlight]: highlightedDay,
        [classes.highlightInterval]: highlightedInterval,
        [classes.suspendedDay]: isSusp,
        [classes.ownColumn]: multiColumn,
        [classes.outsideBlockMonth]: !inBlockMonth,
        [classes.outsideRosterPeriod]: !inRosterPeriod,
        [classes.noEvent]: !eventTags.length && !rosterTags.length,
        [classes.today]: today,
        [classes.mobile]: isMobileOnly,
      })}
      data-test-id="calendar-day"
      data-calendar-day={date.toISODate()}
      draggable={false}
      role="button"
      to={{ pathname: `${routePrefix}/`, search }}
    >
      {showDailyRemarks && isMessage && (
        <span className={classes.emailIcon}><EmailIcon data-test-id="calendar-daily-remark" /></span>
      )}
      <div className={classes.calendarEventBackground} />
      <div className={classes.dateNumber} data-test-id="calendar-date">
        <span>{dayNum}</span>
        <span className={classes.pairingCount}>{pairingCount || ''}</span>
      </div>
      <div className={classes.rosterTags}>{getRosterTags()}</div>
      {eventTags}
    </Link>
  );
};

export default Event;
