import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logger, services } from '@crew-webui/common/api';
import { useCrewAuth, useDialog, useOnlineAuthorized } from '@crew-webui/common/hooks';

interface DailyRemarkType {
  id: string;
  name?: string;
  groupName?: boolean;
}

interface RemarkTypesFromServer {
  remarkType: string;
  text: string;
  remarkGroup?: string;
}

interface RemarkTypesResult {
  remarkTypes: RemarkTypesFromServer[];
  remarkGroups?: { id: string; name?: string }[];
}

type RemarkTypesResponse = PemsResponse<RemarkTypesResult>;

const convertRemarkType = ({ remarkGroup, remarkType, text }: RemarkTypesFromServer) => (
  { id: remarkType, name: text, group: remarkGroup }
);

export const convertRemarkTypes = ({ remarkGroups, remarkTypes }: RemarkTypesResult) => {
  const convertedTypes = remarkTypes.map(convertRemarkType);

  if (!remarkGroups?.length) {
    return convertedTypes;
  }

  const items = remarkGroups
    .map(({ id, name }) => {
      const types = convertedTypes.filter(({ group }) => group === id);
      return types ? [{ id, name, groupName: true }, ...types] : [];
    })
    .flat();

  return items[0]?.name ? items : items.slice(1);
};

const useDailyRemarkTypes = () => {
  const { t } = useTranslation();
  const { crmId } = useCrewAuth();
  const { showServiceErrorMessage } = useDialog();

  const [dailyRemarkTypes, setDailyRemarkTypes] = useState<DailyRemarkType[]>([]);

  useOnlineAuthorized(() => {
    services.getRemarkTypes({ crmId })
      .then(({ data }: { data: RemarkTypesResponse }) => {
        const { result } = data;

        if (!result) {
          return;
        }

        try {
          setDailyRemarkTypes(convertRemarkTypes(result));
        } catch (err) {
          showServiceErrorMessage(t('errorMap.remarkTypesParseError'));
          logger.error(
            `Could not parse remark types response, error: ${(err instanceof Error) ? err.message : ''}, result: ${JSON.stringify(result)}`,
          );
        }
      });
  }, [crmId, showServiceErrorMessage]);

  return dailyRemarkTypes;
};

export default useDailyRemarkTypes;
