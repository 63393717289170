import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Block as BlockIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';
import { makeStyles, Typography, Button } from '@lsy-netline-ui/netline-ui';

import { NoContentMessage, SearchTextPage } from '@crew-webui/common/view/components';
import { services } from '@crew-webui/common/api';
import { useCrewAuth, useOnlineAuthorized } from '@crew-webui/common/hooks';

import { Card, CardContainer } from 'view/components';
import { titles } from 'consts';
import { AccessRightMode } from './AccessRights';

import styles from './BuddyExcludeList.styles';

const formId = 'exception';

const useStyles = makeStyles(styles);

type CrewMember = {
  'crewId': string;
  'firstName': string;
  'lastName': string;
};

type SearchMember = {
  'crewId': string;
  'firstName': string;
  'lastName': string;
  'addedToAllowAllExceptionList': boolean;
  'addedToRestrictAllExceptionList': boolean;
  'accessGrantedForMe': boolean;
};

type BuddyExceptionsListResponse = {
  buddyAccessRightsMode: AccessRightMode;
  exceptionList: Array<CrewMember>;
};

type SearchBuddiesListResponse = {
  crewMemberList: Array<SearchMember>;
};

type BuddyExceptionsListPemsResponse = PemsResponse<BuddyExceptionsListResponse>;

type SearchBuddiesPemsResponse = PemsResponse<SearchBuddiesListResponse>;

const BuddyExcludeList = () => {
  const { t } = useTranslation();
  const { crmId } = useCrewAuth();
  const classes = useStyles();
  const [exceptions, setExceptions] = useState<BuddyExceptionsListResponse>();
  const [searchResults, setSearchResults] = useState<SearchBuddiesListResponse>();
  const [searchValue, setSearchValue] = useState<string>();

  const handleBuddyExceptionsResult = useCallback(({ data }: { data: BuddyExceptionsListPemsResponse }) => {
    const { result, success } = data;
    if (!success) {
      throw Error('Error while load buddies');
    }
    setExceptions(result);
  }, []);

  useOnlineAuthorized(() => {
    services.getBuddyExcludeList({ crmId }).then(handleBuddyExceptionsResult);
  }, [crmId, handleBuddyExceptionsResult]);

  const resetSearch = useCallback(() => {
    setSearchValue('');
    setSearchResults({ crewMemberList: [] });
  }, []);

  const onSubmit = useCallback((value: string) => {
    if (!value) {
      resetSearch();
      return Promise.resolve();
    }
    return services.searchAccessRights({ crmId, value }).then(({ data }: { data: SearchBuddiesPemsResponse }) => {
      setSearchValue(value);
      const { success, result } = data;
      if (!success) {
        throw Error('Error while search buddies');
      }
      setSearchResults(result);
    });
  }, [crmId, resetSearch]);

  const isAccessMode = useMemo(() => (exceptions?.buddyAccessRightsMode === AccessRightMode.ALLOW_ALL.toString()), [exceptions?.buddyAccessRightsMode]);

  const subtitle = useMemo(() => t(isAccessMode ? 'accessRights.includeTitle' : 'accessRights.excludeTitle'), [isAccessMode, t]);
  const listTitle = useMemo(() => t(isAccessMode ? 'accessRights.includeListTitle' : 'accessRights.excludeListTitle'), [isAccessMode, t]);
  const removeButtonTitle = useMemo(() => t(isAccessMode ? 'accessRights.unblock' : 'accessRights.block'), [isAccessMode, t]);

  const changeMemberState = useCallback((crewMember: CrewMember, remove: boolean) => {
    services.changeBuddyExcludeList({
      crmId,
      exceptionList: exceptions?.buddyAccessRightsMode,
      operation: remove ? 'REMOVE' : 'ADD',
      crewId: crewMember.crewId,
    }).then(handleBuddyExceptionsResult).then(resetSearch);
  }, [exceptions?.buddyAccessRightsMode, handleBuddyExceptionsResult, crmId, resetSearch]);

  const renderSearchButton = useCallback((crewMember: SearchMember) => {
    const blockable = (isAccessMode && !crewMember.addedToAllowAllExceptionList) || (!isAccessMode && crewMember.addedToRestrictAllExceptionList);
    const isRemoveMode = (isAccessMode && crewMember.addedToAllowAllExceptionList) || (!isAccessMode && crewMember.addedToRestrictAllExceptionList);

    return (
      <Button variant="outlined" onClick={() => changeMemberState(crewMember, isRemoveMode)}>{t(blockable ? 'accessRights.block' : 'accessRights.unblock')}</Button>
    );
  }, [isAccessMode, changeMemberState, t]);

  if (!exceptions) {
    return null;
  }

  return (
    <SearchTextPage
      headerProps={{ title: t(titles.profile), subtitle, showBackButton: true }}
      formId={formId}
      searchLabel={t('accessRights.searchLabel')}
      searchPlaceholder={t('accessRights.searchPlaceholder')}
      onSubmit={onSubmit}
      defaultValue={searchValue}
    >
      {searchValue && (
        <CardContainer label={`${t('buddy.buddyRosterSearch.resultsFor')} "${searchValue}"`}>
          <div>
            {(!searchResults?.crewMemberList || !searchResults?.crewMemberList.length) ? (
              <Typography className={classes.crewId}>{t('accessRights.noSearchResult')}</Typography>
            ) : (
              searchResults?.crewMemberList.map((crewMember) => (
                <Card key={crewMember.crewId} className={classes.noBackground}>
                  <div className={classes.crewCard}>
                    <div>
                      <Typography className={classes.crewName}>{`${crewMember.firstName} ${crewMember.lastName}`}</Typography>
                      <Typography className={classes.crewId}>{crewMember.crewId}</Typography>
                    </div>
                    <div className={classes.action}>
                      {renderSearchButton(crewMember)}
                    </div>
                  </div>
                </Card>
              ))
            )}
          </div>
        </CardContainer>
      )}
      {!searchValue && (
        <CardContainer label={listTitle}>
          <div>
            {(!exceptions.exceptionList || !exceptions.exceptionList.length) ? (
              <NoContentMessage>{t('myData.noItemMsg', { type: t('accessRights.noExceptionList') })}</NoContentMessage>
            ) : (
              exceptions.exceptionList.map((crewMember) => (
                <Card key={crewMember.crewId}>
                  <div className={classes.crewCard}>
                    {isAccessMode ? <BlockIcon color="error" /> : <CheckCircleIcon className={classes.successColor} />}
                    <div>
                      <Typography className={classes.crewName}>{`${crewMember.firstName} ${crewMember.lastName}`}</Typography>
                      <Typography className={classes.crewId}>{crewMember.crewId}</Typography>
                    </div>
                    <div className={classes.action}>
                      <Button variant="outlined" onClick={() => changeMemberState(crewMember, true)}>{removeButtonTitle}</Button>
                    </div>
                  </div>
                </Card>
              ))
            )}
          </div>
        </CardContainer>
      )}
    </SearchTextPage>
  );
};

export default BuddyExcludeList;
