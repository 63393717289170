import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import {
  Button, IconButton, makeStyles, Typography,
} from '@lsy-netline-ui/netline-ui';
import { get, set } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useEncodedParams } from '@crew-webui/common/api';
import {
  FeatureAccess, MyDataType, MyDataAccess, Visa, MyDataTravelDocType, MyDataCategory,
} from '@crew-webui/common/consts';
import {
  useAppCommon,
  useConnection,
  useCrewAuth,
  useDialog,
  useFeature,
  useMyData,
  useOnlineAuthorized,
  useProfile,

} from '@crew-webui/common/hooks';
import { NoContentMessage, OfflineEditInfo } from '@crew-webui/common/view/components';

import {
  Feature, MyDataFieldType, routePaths, titles,
} from 'consts';
import { getUniqueTravelDocId, getUniqueVisaId, getValidationSchema } from 'utils';
import { IadpPage } from 'view/components';
import MyDataList from '../MyDataList/MyDataList';
import MyDataFields from '../MyDataFields/MyDataFields';
import VisaCard from './VisaCard';
import getTravelDocFields, { getVisaFields } from './travelDocFields';
import {
  convertTravelDocForAPI,
  convertVisaForAPI,
  getLabel,
  getSubmitButtonLabel,
  getTitle,
} from './travelDocUtils';
import styles from './TravelDocDetails.styles';

const useStyles = makeStyles(styles, { name: 'TravelDocDetails' });

const formId = 'my-data-traveldoc-form';

const TravelDocDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { crmId } = useCrewAuth();
  const {
    createMyData, deleteMyData, loadMyData, updateMyData, passports, personalData, visas,
  } = useMyData();
  const { id, type } = useEncodedParams<{ id: string; type: string }>();
  const isFeatureEnabled = useFeature();
  const { online } = useConnection();
  const { setDirty, clearDirtyAndRedirect } = useAppCommon();
  const { showConfirmDialog } = useDialog();
  const { profile } = useProfile();
  const { readonlyMyDataCategories, enabledTravelDocTypes } = profile;
  const [newVisaIndexes, setNewVisaIndexes] = useState<number[]>([]);
  const [visaIndexesToDelete, setVisaIndexesToDelete] = useState<number[]>([]);

  const travelDocFields = useMemo(() => getTravelDocFields(t), [t]);
  const visaFields = useMemo(() => getVisaFields(t), [t]);

  const editingEnabled = isFeatureEnabled(Feature.IDP_MY_DATA, FeatureAccess.WRITE) &&
    !readonlyMyDataCategories.includes(MyDataCategory.TRAVEL) &&
    !(type === MyDataTravelDocType.RESTRICTED_AREA_INDENTITY_CARD &&
      readonlyMyDataCategories.includes(MyDataCategory.RAIC)) &&
    !(type === MyDataTravelDocType.DRIVER_LICENCE &&
      readonlyMyDataCategories.includes(MyDataCategory.DRIVER_LICENCE)) &&
    !(type === MyDataTravelDocType.IDENTITY &&
      readonlyMyDataCategories.includes(MyDataCategory.IDENTITY)) &&
    !(type === MyDataTravelDocType.ALIEN_RESIDENT_NUMBER &&
      readonlyMyDataCategories.includes(MyDataCategory.ALIEN_RESIDENT_NUMBER)) &&
    !(type === MyDataTravelDocType.PASSPORT &&
      readonlyMyDataCategories.includes(MyDataCategory.PASSPORT));

  const newTravelDoc = id === undefined && editingEnabled;

  const travelDoc = useMemo(() => passports?.find((passport) => getUniqueTravelDocId(passport) === id), [passports, id]);

  const number = travelDoc?.natKey.number;
  const attachedVisas = useMemo(
    () => visas?.filter((visa) => visa.natKey.passportNumber === number) || [],
    [number, visas],
  );

  const dataLoaded = (!!passports && !!visas) || newTravelDoc;

  // Init form state
  const validationSchema = yup.object().shape({
    travelDoc: getValidationSchema(travelDocFields),
    visas: yup.array().of(getValidationSchema(visaFields)),
  });
  const {
    control,
    formState: {
      errors,
      isDirty: areFieldsDirty,
      isSubmitting,
    },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const isDirty = areFieldsDirty || !!visaIndexesToDelete.length;

  // Fetch data
  useOnlineAuthorized(() => {
    if (!newTravelDoc && (!passports || !visas)) {
      loadMyData(MyDataType.PASSPORT);
      loadMyData(MyDataType.VISA);
    }
  }, [newTravelDoc, loadMyData, passports, visas]);
  useOnlineAuthorized(() => {
    if (newTravelDoc) {
      loadMyData(MyDataType.INFO);
    }
  }, [loadMyData]);

  // Synchronize "dirty" global state
  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty, setDirty]);

  // Reset values
  useEffect(() => {
    const newDefaultValues = {};

    const addValue = (document: any, prefix: string) => ({ date, valuePath } : MyDataFieldType) => {
      let value = get(document, valuePath, date ? null : '');
      if (typeof value === 'boolean') {
        value = value.toString();
      }
      set(newDefaultValues, `${prefix}.${valuePath}`, value);
    };

    travelDocFields.forEach(addValue(travelDoc, 'travelDoc'));
    attachedVisas.forEach((visa, index) => {
      visaFields.forEach(addValue(visa, `visas[${index}]`));
    });

    // Prepopulate some fields based on personal data of crew member when creating new item
    if (newTravelDoc && personalData) {
      const fields = ['firstName', 'middleName', 'lastName'];
      fields.forEach((field: string) => {
        set(newDefaultValues, `travelDoc.${field}`, personalData[field]);
      });

      set(newDefaultValues, 'travelDoc.access', MyDataAccess.INTERNAL);

      // Get today's date
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      set(newDefaultValues, 'travelDoc.issueDate', `${year}-${month}-${day}`);

      if (profile.countryIssueDefaultValue) {
        set(newDefaultValues, 'travelDoc.issueCountry', `${profile.countryIssueDefaultValue}`);
      }
    }

    reset(newDefaultValues);
  }, [attachedVisas, newTravelDoc, personalData, profile, reset, travelDoc, travelDocFields, visaFields]);

  // Add new visa to form
  const addVisaToList = useCallback(() => {
    setNewVisaIndexes((prevNewVisaIndexes) => {
      const maxIndex = prevNewVisaIndexes.reduce((acc, curr) => Math.max(acc, curr), attachedVisas.length - 1);
      return [...prevNewVisaIndexes, maxIndex + 1];
    });
  }, [attachedVisas]);

  // Delete visa from form
  const deleteVisaFromList = useCallback((index: number) => {
    setVisaIndexesToDelete((indexes: number[]) => [...indexes, index]);
  }, []);

  // Redirect to parent page when "type" is not allowed
  useEffect(() => {
    if (!enabledTravelDocTypes?.includes(type)) {
      clearDirtyAndRedirect(`${routePaths.myData}/traveldocs`);
    }
  }, [enabledTravelDocTypes, type, clearDirtyAndRedirect]);

  // Handle form submission
  const onSubmit = useCallback(async (data: any) => {
    const defaultTravelDoc = {
      access: MyDataAccess.EXTERNAL,
      natKey: {
        crewCode: crmId,
        docType: type,
        employeeId: '',
        type: 'C',
      },
    };
    const mergedTravelDoc = convertTravelDocForAPI(defaultTravelDoc, travelDoc, data.travelDoc);
    if (travelDoc) {
      await updateMyData(MyDataType.PASSPORT, mergedTravelDoc, travelDoc);
    } else {
      await createMyData(MyDataType.PASSPORT, mergedTravelDoc);
    }
    const defaultVisa = {
      access: MyDataAccess.EXTERNAL,
      natKey: {
        crewCode: crmId,
        passportDocType: type,
        passportEmployeeId: '',
        passportNumber: mergedTravelDoc.natKey.number,
        passportType: 'C',
      },
    };

    const visaCreateAndUpdateRequests = (data.visas as Visa[] || []).map((visaInForm, index) => {
      const visa = attachedVisas[index];
      const mergedVisa = convertVisaForAPI(defaultVisa, visa, visaInForm);
      if (visa) {
        return updateMyData(MyDataType.VISA, mergedVisa, visa);
      }
      return createMyData(MyDataType.VISA, mergedVisa);
    });

    const visaDeleteRequests = visaIndexesToDelete.map((index) => {
      const visa = attachedVisas[index];
      return visa ? deleteMyData(MyDataType.VISA, visa) : null;
    });

    try {
      // insert/update/delete attached visas
      await Promise.all([...visaCreateAndUpdateRequests, ...visaDeleteRequests]);
    } catch (error) {
      // refetch data if error happened
      loadMyData(MyDataType.PASSPORT);
      loadMyData(MyDataType.VISA);
      // redirect to editing page when new travel document created
      setDirty(false);
      history.push(`${routePaths.myData}/traveldocs/${type}/${getUniqueTravelDocId(mergedTravelDoc)}`);
      return;
    }

    clearDirtyAndRedirect(`${routePaths.myData}/traveldocs`);
  }, [crmId, travelDoc, loadMyData, attachedVisas, createMyData, deleteMyData, history, setDirty,
    type, updateMyData, visaIndexesToDelete, clearDirtyAndRedirect]);

  const label = getLabel(type, t);
  const title = getTitle(travelDoc, newTravelDoc, type, t);
  const submitButtonLabel = getSubmitButtonLabel(newTravelDoc, type, t);

  // Delete travel document
  const deleteTravelDocument = async () => {
    try {
      // delete attached visas
      const visaDeleteRequests = attachedVisas.map((visa) => deleteMyData(MyDataType.VISA, visa));
      await Promise.all(visaDeleteRequests);
    } catch (error) {
      // refetch visas if error happened
      loadMyData(MyDataType.VISA);
      return;
    }
    if (travelDoc) {
      // delete travel document
      await deleteMyData(MyDataType.PASSPORT, travelDoc);
    }
    clearDirtyAndRedirect(`${routePaths.myData}/traveldocs`);
  };

  const rightIconProps = editingEnabled && travelDoc && (
    <IconButton
      disabled={!online}
      onClick={() => {
        showConfirmDialog(t('myData.travelDoc.deleteDialogTitle'), deleteTravelDocument);
      }}
    >
      <DeleteIcon />
    </IconButton>
  );

  const visaIndexesToShow = useMemo(() => [...attachedVisas.map((visa, index) => index), ...newVisaIndexes]
    .filter((index) => !visaIndexesToDelete.includes(index)), [attachedVisas, newVisaIndexes, visaIndexesToDelete]);

  const showNoContentMessage = dataLoaded && !newTravelDoc && !travelDoc;

  return (
    <IadpPage
      dataLoaded={dataLoaded}
      headerProps={{
        actions: rightIconProps,
        showBackButton: true,
        title: t(titles.profile),
        subtitle: title,
      }}
      submitButtonProps={!editingEnabled ? undefined : {
        'children': submitButtonLabel,
        'data-test-id': 'submit-traveldoc-button',
        'disabled': !online || !isDirty || isSubmitting || !!Object.keys(errors).length,
        'form': formId,
        'type': 'submit',
      }}
    >
      {showNoContentMessage && <NoContentMessage>{t('myData.travelDoc.notFound')}</NoContentMessage>}

      {travelDoc && !editingEnabled && (
        <>
          <MyDataList data={travelDoc} label={label} list={travelDocFields} />
          {attachedVisas.map((visa) => (
            <MyDataList key={getUniqueVisaId(visa)} data={visa} label={t('myData.travelDoc.visa')} list={visaFields} />
          ))}
        </>
      )}

      {editingEnabled && (travelDoc || newTravelDoc) && (
        <form id={formId} noValidate onSubmit={handleSubmit(onSubmit)}>
          <OfflineEditInfo />
          <MyDataFields
            control={control}
            editing={!newTravelDoc}
            errors={errors}
            fields={travelDocFields}
            namePrefix="travelDoc."
          />
          { type === MyDataTravelDocType.PASSPORT && (!readonlyMyDataCategories.includes(MyDataCategory.VISA) ? (
            <>
              <Typography className={classes.label} data-test-id="my-data-section-title" variant="h6">
                <span>{t('myData.travelDoc.visas')}</span>
                <Button
                  className={classes.addNewBtn}
                  data-test-id="add-new-button"
                  disabled={!online || isSubmitting}
                  endIcon={<AddIcon />}
                  onClick={addVisaToList}
                >
                  {t('myData.travelDoc.addVisa')}
                </Button>
              </Typography>
              {!visaIndexesToShow.length && (
                <NoContentMessage>{t('myData.travelDoc.noVisas')}</NoContentMessage>
              )}
              {visaIndexesToShow.map((index) => (
                // eslint-disable-next-line react/no-array-index-key
                <VisaCard key={index} disableDelete={!online || isSubmitting} onDeleteClick={() => deleteVisaFromList(index)}>
                  <MyDataFields
                    control={control}
                    editing={!newTravelDoc && index < attachedVisas.length}
                    errors={errors}
                    fields={visaFields}
                    namePrefix={`visas[${index}].`}
                  />
                </VisaCard>
              )).reverse()}
            </>
          ) : (
            <>
              {attachedVisas.map((visa) => (
                <MyDataList key={getUniqueVisaId(visa)} data={visa} label={t('myData.travelDoc.visa')} list={visaFields} />
              ))}
            </>
          ))}
        </form>
      )}
    </IadpPage>
  );
};

export default TravelDocDetails;
