import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  DialogContentText,
  LinearProgress,
  Typography,
  makeStyles,
} from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import Dialog from './Dialog';
import styles from './ProgressDialog.styles';

const useStyles = makeStyles(styles, { name: 'ProgressDialog' });

export type ProgressProcess = {
  successNo: number;
  allNo: number;
  failedNo: number;
  interruptedNo: number;
  startProcess: Function;
  stopProcess: Function;
  actualInfo: string;
  footerText: string;
};

type Props = {
  endCache: () => void;
  inProgress: boolean;
  progressProcess: ProgressProcess;
  title: string;
  withPercent?: boolean;
};

const getMinutes = ((sec: number) => Math.floor(sec / 60));
const getSeconds = ((sec: number) => sec % 60);
const parseMinSecFormat = ((sec: number) => `${getMinutes(sec)}:${String(getSeconds(sec)).padStart(2, '0')}`);

const ProgressDialog = ({
  endCache, inProgress, title, progressProcess, withPercent = false,
}: Props) => {
  const [startTime, setStartTime] = useState(0);
  const prevInProgress = useRef(false);

  const { t } = useTranslation();
  const classes = useStyles();

  const {
    allNo, successNo, actualInfo, failedNo, footerText, interruptedNo, startProcess, stopProcess,
  } = progressProcess;

  const processedNo = successNo + failedNo + interruptedNo;
  const progress = allNo > 0 ? Math.round((processedNo * 100) / allNo) : 0;

  const remainingTime = useMemo(() => {
    if (progress <= 0) {
      return '-';
    }
    if (progress >= 100) {
      return '0:00';
    }
    const diff = new Date().getTime() - startTime;
    const remainingSec = Math.floor(((100 - progress) * (diff / progress)) / 1000) + 1;
    return parseMinSecFormat(remainingSec);
  }, [progress, startTime]);

  const interrupt = useCallback(() => {
    stopProcess();
  }, [stopProcess]);

  const closeDialog = useCallback(() => {
    endCache();
  }, [endCache]);

  useEffect(() => {
    if (inProgress && !prevInProgress.current) {
      setStartTime(new Date().getTime());
      startProcess();
    }
    prevInProgress.current = inProgress;
  }, [inProgress, startProcess]);

  const finishedContent = useMemo(() => {
    if (processedNo >= allNo && allNo > 0) {
      const diff = Math.floor((new Date().getTime() - startTime) / 1000);
      const min = getMinutes(diff);
      const sec = getSeconds(diff);
      return t((!failedNo && !interruptedNo) ? 'cache.finishedText' : 'cache.finishedWithErrorText', { min, sec });
    }
    return '';
  }, [allNo, failedNo, interruptedNo, processedNo, startTime, t]);

  return (
    <Dialog data-test-id="progress-dialog" open={inProgress} fullWidth>
      <Dialog.Title data-test-id="progress-dialog-title">{title}</Dialog.Title>
      <Dialog.Content data-test-id="progress-dialog-content">
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Box>
            {withPercent && (
              <Typography variant="body2" color="textSecondary" noWrap>
                { progress }%
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary" noWrap>
              { remainingTime }
            </Typography>
          </Box>
        </Box>
        <div className={clsx(classes.topSpace, classes.summary)}>
          <Box mr={1}>
            {(processedNo < allNo || allNo <= 0) ? actualInfo : finishedContent}
          </Box>
          <Box>
            <Typography variant="body2" color="textSecondary">
              { !!interruptedNo && (<span>{t('cache.interrupted')}&nbsp;{interruptedNo},&nbsp;</span>)}
              { !!failedNo && (<span>{t('cache.failed')}&nbsp;{failedNo},&nbsp;</span>)}
              { (!!failedNo || !!interruptedNo) && (<span>{t('cache.success')}&nbsp;</span>)}
              { allNo > 0 && (<span>{successNo} / {allNo}</span>)}
            </Typography>
          </Box>
        </div>
        {!!failedNo && <DialogContentText className={classes.topSpace}>{t('cache.failNote')}</DialogContentText>}
        <DialogContentText className={classes.topSpace}>{footerText}</DialogContentText>
      </Dialog.Content>
      <Dialog.Actions data-test-id="progress-dialog-actions">
        {processedNo < allNo && allNo > 0 && (<Button onClick={interrupt} primary>{t('common.cancel')}</Button>)}
        {processedNo >= allNo && allNo > 0 && (<Button onClick={closeDialog} secondary>{t('button.done')}</Button>)}
      </Dialog.Actions>
    </Dialog>
  );
};

export default ProgressDialog;
