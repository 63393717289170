import defaultTheme from './defaultTheme';
import { CustomerTheme } from './themeTypes';

const lotTheme: CustomerTheme = {
  ...defaultTheme,

  palette: {
    ...defaultTheme.palette,
    calendar: {
      ...defaultTheme.palette.calendar,
      day: {
        ...defaultTheme.palette.calendar.day,
        selectedDayBackgroundColor: '#B3E8FC',
        selectedIntervalBackgroundColor: '#B3E8FC',
      },
    },
    dutyList: {
      ...defaultTheme.palette.dutyList,
      selectedRowBackgroundColor: '#B3E8FC',
      defaultIconColor: '#60AE60',
    },
    events: {
      ...defaultTheme.palette.events,
      par: {
        ...defaultTheme.palette.events.par,
        backgroundColor: '#198EEB',
      },
      leg: {
        ...defaultTheme.palette.events.leg,
        backgroundColor: '#198EEB',
      },
      sby: {
        ...defaultTheme.palette.events.sby,
        backgroundColor: '#6532B4',
      },
      fpr: {
        ...defaultTheme.palette.events.fpr,
        backgroundColor: '#177FD2',
        textColor: '#FFF',
      },
      gpr: {
        ...defaultTheme.palette.events.gpr,
        backgroundColor: '#177FD2',
        textColor: '#FFF',
      },
      vac: {
        ...defaultTheme.palette.events.vac,
        backgroundColor: '#000',
      },
      sick: {
        ...defaultTheme.palette.events.sick,
        backgroundColor: '#646464',
      },
      ground: {
        ...defaultTheme.palette.events.ground,
        backgroundColor: '#D0A400',
      },
      sim: {
        ...defaultTheme.palette.events.sim,
        backgroundColor: '#7E7162',
      },
      off: {
        ...defaultTheme.palette.events.off,
        backgroundColor: '#999',
      },
      other: {
        ...defaultTheme.palette.events.neutral,
        backgroundColor: '#60AE60',
      },
      neutral: {
        ...defaultTheme.palette.events.neutral,
        backgroundColor: '#60AE60',
      },
      textIcon: {
        ...defaultTheme.palette.events.textIcon,
        backgroundColor: '#60AE60',
      },
    },
  },
};

export default lotTheme;
