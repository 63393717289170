import defaultTheme from './defaultTheme';
import { CustomerTheme } from './themeTypes';

const abxTheme: CustomerTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    calendar: {
      ...defaultTheme.palette.calendar,
      day: {
        ...defaultTheme.palette.calendar.day,
        selectedDayBackgroundColor: '#FFE2DD',
        selectedIntervalBackgroundColor: '#D0E2F1',
      },
    },
    dutyList: {
      ...defaultTheme.palette.dutyList,
      selectedRowBackgroundColor: '#FFE2DD',
    },
    events: {
      ...defaultTheme.palette.events,
      par: {
        backgroundColor: '#CF3B3B',
        textColor: '#FFFFFF',
      },
      leg: {
        backgroundColor: '#CF3B3B',
        textColor: '#FFFFFF',
      },
    },
  },
};

export default abxTheme;
