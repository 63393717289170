import {
  MouseEvent, useMemo, useState, useEffect,
} from 'react';
import {
  Link, matchPath, useLocation, useHistory,
} from 'react-router-dom';
import {
  makeStyles, Tab, Tabs, Menu as MoreMenu, MenuItem, ListItemIcon, ListItemText,
} from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import { MoreVert } from '@material-ui/icons';

import { useMultiColumn } from '../../../hooks';
import styles from './Menu.styles';

const useStyles = makeStyles(styles, { name: 'Menu' });

type TabItem = {
  value: string;
  exact?: boolean;
  match?: string[];
  icon: React.ReactNode;
  label: string;
  disabled?: boolean;
};

type MenuProps = {
  tabItems: TabItem[];
  className?: string;
  disableCurrentItemIndicator?: boolean;
};

const MAX_SINGLE_MENU_ITEM = 4;

const Menu = ({ tabItems, className, disableCurrentItemIndicator }: MenuProps) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const multiColumn = useMultiColumn();
  const activeItem = tabItems.find(
    ({ exact, match, value }) => matchPath(location.pathname, { exact, path: match || value }),
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const footerMenuItems = useMemo(() => ({
    visibleItems: tabItems.map((item, index) => (
      { ...item, hidden: !(index < MAX_SINGLE_MENU_ITEM) }
    )),
    mergedItems: tabItems.slice(MAX_SINGLE_MENU_ITEM),
  }), [tabItems]);

  useEffect(() => {
    if (multiColumn) {
      setAnchorEl(undefined);
    }
  }, [multiColumn]);

  const handleMoreMenuClose = () => {
    setAnchorEl(undefined);
  };

  const handleMoreMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreMenuSelect = (value: string) => {
    history.push(value);
    handleMoreMenuClose();
  };

  return (
    <>
      <Tabs
        classes={{ indicator: classes.indicator }}
        className={clsx(classes.root, className, { [classes.multiColumn]: multiColumn })}
        value={(!disableCurrentItemIndicator && activeItem?.value) || false}
        indicatorColor="primary"
        textColor="primary"
      >
        {footerMenuItems.visibleItems.map(({
          icon, label: labelProp, onClick, value, hidden,
        }: any) => {
          const commonProps = {
            key: value,
            classes: { wrapper: classes.iconLabelWrapper },
            className: hidden ? classes.tabHided : classes.tab,
            label: <span className={classes.text}>{labelProp}</span>,
            icon,
            value,
          };
          if (onClick) {
            return <Tab {...commonProps} onClick={onClick} />;
          }
          return <Tab {...commonProps} component={Link} to={value} />;
        })}

        { !!footerMenuItems.mergedItems.length && (<Tab className={classes.mergedItemsMenu} icon={<MoreVert />} onClick={handleMoreMenuClick} />) }

      </Tabs>

      {!!footerMenuItems.mergedItems.length && (
        <MoreMenu
          anchorEl={anchorEl}
          open={!!anchorEl}
          getContentAnchorEl={null}
          onClose={handleMoreMenuClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          {footerMenuItems.mergedItems?.map(({
            value, icon, label, onClick,
          }: any) => (
            <MenuItem
              key={value}
              onClick={() => {
                if (onClick) {
                  onClick();
                }
                handleMoreMenuSelect(value);
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </MoreMenu>
      )}

    </>
  );
};
export default Menu;
