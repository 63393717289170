import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100vh',
    background: palette.background.default,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: '48px',
    textAlign: 'center',
    background: palette.calendar.headerBackgroundColor,
    color: palette.common.white,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    letterSpacing: '0.5px',
    fontWeight: 400,
    lineHeight: '48px',
    fontSize: '16px',
  },
  subHeader: {
    background: '#E9B700',
    padding: '8px 24px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  choiceHeader: {
    marginTop: '8px',
    padding: '8px 24px',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  userChoices: {
    padding: '8px 24px',
    overflow: 'auto',
  },
  testChoices: {
    padding: '8px 24px',
  },
  testChoiceItem: {
    cursor: 'pointer',
    borderRadius: '8px',
    padding: '8px 0px 8px 8px',
    marginBottom: '8px',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nameWithIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  emptyIcon: {
    width: '24px',
  },
  choiceItem: {
    background: palette.common.white,
    border: `1px solid ${palette.primary.main}`,
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.08)',
  },
  buttonContainer: {
    flexDirection: 'column',
    gap: '5px',
  },
  popupButton: {
    width: '100%',
    overflow: 'hidden',
    alignItems: 'left',
  },
});
