// this file should be imported in the main index.js file of the package

import '@fontsource/roboto/latin.css';
import '@fontsource/roboto/latin-ext.css'; // it contains 'ő', 'ű' letters
import '@fontsource/roboto-mono/latin.css';
import '@fontsource/roboto-mono/latin-ext.css';

import { Settings } from 'luxon';

import './utils/contextMenu';

import './index.css';

Settings.defaultLocale = 'en';

window.VERSION = process.env.REACT_APP_VERSION;
