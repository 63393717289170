import { Trans } from 'react-i18next';
import * as yup from 'yup';

import { printableRuleWithAccents } from '@crew-webui/common/utils';
import { MyDataAccess } from '@crew-webui/common/consts';

import {
  allCountryCodes, getAccess, hasCountryStates, listedCountries, phoneCodes,
} from './myDataUtils';

export const getPrioProperties = (t: T, { max }: { max: number }) => ({
  label: t('myData.fields.pirority'),
  valuePath: 'natKey.prio',
  type: 'number',
  // error messages are empty strings for validation to show the `helperText`
  validation: yup.number()
    .typeError('')
    .integer('')
    .min(0, '')
    .max(max, ''),
  min: 0,
  max,
  helperText: <Trans i18nKey="myData.validation.priorityHelper" values={{ min: 0, max }} />,
  editOnlyWhenCreating: true,
  required: true,
});

export const getTextFieldProperties = (
  t: T,
  { required = false, maxLength }: { required?: boolean; maxLength?: number } = {},
) => {
  let validation = yup.string()
    .trim()
    .matches(printableRuleWithAccents.regex, printableRuleWithAccents.errorMessageCreator(t));

  if (required) {
    validation = validation.required(t('myData.validation.required'));
  }
  if (maxLength) {
    validation = validation.max(maxLength, t('myData.validation.maxLength'));
  }

  return { validation, maxLength, required };
};

export const getAccessProperties = (
  t: T,
  { buddyModeEnabled }: { buddyModeEnabled?: boolean } = {},
) => ({
  ...getTextFieldProperties(t, { required: true }),
  label: t('myData.fields.access'),
  valuePath: 'access',
  valueTransform: (accessCode: MyDataAccess | undefined) => getAccess(accessCode, t),
  options: Object.values(MyDataAccess)
    .filter((accessCode) => accessCode !== MyDataAccess.BUDDY || buddyModeEnabled)
    .map((accessCode) => ({
      value: accessCode,
      label: getAccess(accessCode, t),
    })),
});

export const getGenderProperties = (t: T) => {
  const options = [
    { value: 'M', label: t('myData.personalData.male') },
    { value: 'F', label: t('myData.personalData.female') },
  ];

  return {
    ...getTextFieldProperties(t, { required: true }),
    label: t('myData.fields.gender'),
    valueTransform: (value: any) => (options.find((item) => item.value === value)?.label),
    options,
  };
};

export const getYesNoProperties = (t: T) => ({
  valueTransform: (value: boolean) => {
    if (value === undefined) {
      return undefined;
    }
    return value ? t('common.yes') : t('common.no');
  },
  options: [
    { value: 'true', label: t('common.yes') },
    { value: 'false', label: t('common.no') },
  ],
});

export const getCountryCodeProperties = (t: T) => ({
  validation: yup.string()
    .required(t('myData.validation.required'))
    .transform((value) => value.toUpperCase())
    .oneOf(allCountryCodes, t('myData.validation.countryCode')),
  maxLength: 3,
  helperText: t('myData.validation.countryCode'),
  autocomplete: true,
  options: listedCountries,
  filterFromStart: true,
  required: true,
});

export const getStateCodeProperties = (
  t: T,
  { countryCodePath, stateCodePath }: { countryCodePath: string; stateCodePath: string },
) => ({
  ...getTextFieldProperties(t, { required: true, maxLength: 25 }),
  validation: yup.string()
    .max(25, t('myData.validation.maxLength'))
    // required when the selected country has states
    .when(
      countryCodePath,
      (countryCode: any, schema: any) => (hasCountryStates(countryCode) ? schema.required(t('myData.validation.required')) : schema),
    ),
  valuePath: stateCodePath,
});

export const getDateProperties = (t: T, { required = true, dateWithTime = false } = {}) => {
  let validation = yup.string().trim().nullable();

  if (required) {
    validation = validation.required(t('myData.validation.required'));
  }

  return {
    validation,
    date: true,
    required,
    dateWithTime,
  };
};

export const getEmailProperties = (t: T) => {
  const { validation, ...other } = getTextFieldProperties(t, { required: true, maxLength: 80 });

  return {
    ...other,
    validation: validation.email(t('myData.validation.email')),
  };
};

export const getTelProperties = (t: T, { maxLength }: { maxLength: number }) => ({
  validation: yup.string()
    .trim()
    .required(t('myData.validation.required'))
    .matches(/^\d+$/, t('myData.validation.onlyNumber'))
    .max(maxLength, t('myData.validation.maxLength')),
  maxLength,
  type: 'tel',
  required: true,
});

export const getRegionCodeProperties = (t: T) => ({
  ...getTelProperties(t, { maxLength: 20 }),
  validation: yup.string()
    .required(t('myData.validation.required'))
    .oneOf(phoneCodes.map(({ value }) => value), t('myData.validation.outsideSet')),
  autocomplete: true,
  options: phoneCodes,
});
