import {
  COUNTRY_CODE_PATH, MAX_ADDRESS_PRIO, MyDataField, STATE_CODE_PATH,
} from 'consts';
import {
  getAccessProperties,
  getCountryCodeProperties,
  getPrioProperties,
  getStateCodeProperties,
  getTextFieldProperties,
} from 'utils';

export default (t: T): MyDataField[] => [
  getPrioProperties(t, { max: MAX_ADDRESS_PRIO }),
  {
    ...getCountryCodeProperties(t),
    label: t('myData.fields.countryCode'),
    valuePath: 'countryCode',
    fieldSize: 2,
  },
  {
    ...getStateCodeProperties(t, { countryCodePath: COUNTRY_CODE_PATH, stateCodePath: STATE_CODE_PATH }),
    label: t('myData.fields.stateCode'),
    fieldSize: 2,
  },
  {
    ...getTextFieldProperties(t, { required: true, maxLength: 10 }),
    label: t('myData.fields.zipCode'),
    valuePath: 'zipCode',
    fieldSize: 2,
  },
  {
    ...getTextFieldProperties(t, { required: true, maxLength: 25 }),
    label: t('myData.fields.city'),
    valuePath: 'cityName',
  },
  {
    ...getTextFieldProperties(t, { required: true, maxLength: 30 }),
    label: t('myData.fields.street'),
    valuePath: 'street',
  },
  getAccessProperties(t),
  {
    ...getTextFieldProperties(t, { maxLength: 1024 }),
    label: t('myData.fields.remark'),
    valuePath: 'remark',
    multiline: true,
  },
];
