import {
  useCallback, useState, MouseEvent, TouchEvent,
} from 'react';
import { DateTime } from 'luxon';

interface CalendarEventState {
  mouseDownDate: DateTime | null;
  mouseDownTime: number;
}

const eventInitState: CalendarEventState = {
  mouseDownDate: null,
  mouseDownTime: 0,
};

const getDateFromPosition = (clientX: number, clientY: number) => {
  let anchor = document.elementFromPoint(clientX, clientY);
  while (anchor && anchor.tagName !== 'A') {
    anchor = anchor.parentNode as Element;
  }
  return anchor ? anchor.getAttribute('data-calendar-day') : null;
};

const getDateFromTouchEvent = (e: TouchEvent) => {
  const touch = e.changedTouches.item(0);
  return touch ? getDateFromPosition(touch.clientX, touch.clientY) : null;
};

/**
 * Use calendar events to select date or date range
 * @param onDateSelected Callback when clicked on date
 * @param onDateRangeSelected Callback when mouseDown on date, and mouseUp an other, and time between bigger then time tolerance.
 * @param timeTolerance Check if exist and if onDateRangeSelected event occurred. If not defined, dateRange event not possible.
 */
const useCalendarEventHandlers = (
  onDateSelected: (date: DateTime) => void,
  onDateRangeSelected: (dateFrom: DateTime, dateTo: DateTime) => void,
  timeTolerance?: number,
) => {
  const [eventState, setEventState] = useState<CalendarEventState>(eventInitState);

  const setFromDate = useCallback((isoDate: string | null) => {
    if (!isoDate) {
      return;
    }
    setEventState({ mouseDownDate: DateTime.fromISO(isoDate, { zone: 'utc' }), mouseDownTime: Date.now() });
  }, []);

  const setToDate = useCallback((isoDate: string | null) => {
    if (!isoDate || !eventState.mouseDownDate) {
      setEventState(eventInitState);
      return;
    }
    const toDate = DateTime.fromISO(isoDate, { zone: 'utc' });
    if (toDate.hasSame(eventState.mouseDownDate, 'day')) {
      onDateSelected(toDate);
    } else if (!timeTolerance) {
      // do nothing range select not enabled
    } else if (Date.now() - eventState.mouseDownTime > timeTolerance) {
      const dates = [toDate, eventState.mouseDownDate].sort();
      onDateRangeSelected(dates[0].startOf('day'), dates[1].endOf('day'));
    }
    setEventState(eventInitState);
  }, [eventState, timeTolerance, onDateSelected, onDateRangeSelected]);

  const onMouseDown = useCallback((e: MouseEvent) => setFromDate(getDateFromPosition(e.clientX, e.clientY)), [setFromDate]);
  const onTouchStart = useCallback((e: TouchEvent) => setFromDate(getDateFromTouchEvent(e)), [setFromDate]);
  const onTouchEnd = useCallback((e: TouchEvent) => setToDate(getDateFromTouchEvent(e)), [setToDate]);
  const onMouseUp = useCallback((e: MouseEvent) => setToDate(getDateFromPosition(e.clientX, e.clientY)), [setToDate]);

  return {
    onMouseDown,
    onMouseUp,
    onTouchStart,
    onTouchEnd,
  };
};

export default useCalendarEventHandlers;
