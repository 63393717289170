import {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { DateTime, Interval } from 'luxon';

import {
  getEventToSelect, getEventsForDay, getRosterTagsForDay, isEventCurrent,
} from '../../utils';

import { useTodayOnServer } from '../common';
import { CalendarState, Event } from '../../consts';
import { useDutiesModel } from '../DutiesModel';
import { useProfile } from '../Profile';
import { useShowRosterTags } from '../UserPreferences';

import DutiesPresentationContext from './DutiesPresentationContext';

const HIGHLIGHT_DURATION = 5000;

const DutiesPresentationProvider = ({ children }: { children: React.ReactNode }) => {
  const [calendarState, setCalendarState] = useState<CalendarState>(CalendarState.CLOSED);
  const [eventToScroll, setEventToScroll] = useState<number | undefined>(undefined);
  const [openedEventBlocks, setOpenedEventBlocks] = useState<number[]>([]);
  const [highlightedEvent, setHighlightedEvent] = useState<number | undefined>(undefined);
  const [highlightedDateInterval, setHighlightedDateInterval] = useState<Interval | undefined>(undefined);
  const [highlightCurrentEvent, setHighlightCurrentEvent] = useState(false);

  const { profile: { showLocalTime, showUTCTime } } = useProfile();
  const { dutyList } = useDutiesModel();
  const getTodayOnServer = useTodayOnServer();
  const { rosterTagsShown } = useShowRosterTags();

  const toggleEventBlock = useCallback((id: number) => {
    const expanded = openedEventBlocks.includes(id);
    if (expanded) {
      setOpenedEventBlocks(openedEventBlocks.filter((item) => item !== id));
      if (highlightedEvent === id) {
        setHighlightedEvent(undefined);
      }
    } else {
      setOpenedEventBlocks([...openedEventBlocks, id]);
    }
  }, [highlightedEvent, openedEventBlocks]);

  const setHighlightedDay = useCallback((date: DateTime) => setHighlightedDateInterval(
    Interval.fromDateTimes(date.startOf('day'), date.endOf('day')),
  ), [setHighlightedDateInterval]);

  const selectEventForHighlighting = useCallback((event?: Event) => {
    const id = event?.id;
    setHighlightedEvent(id);
    setEventToScroll(id);
    setOpenedEventBlocks(id && event?.events?.length ? [id] : []);
  }, []);

  const highlightToday = useCallback(() => {
    // Enable highlighting the current subevent for a while
    setHighlightCurrentEvent(true);
    setTimeout(() => {
      setHighlightCurrentEvent(false);
    }, HIGHLIGHT_DURATION);

    // Highlight current day in calendar
    const currentTime = getTodayOnServer(!showLocalTime && showUTCTime);
    setHighlightedDay(currentTime);

    // Select the current event in Duty list
    const topEvents = dutyList
      .filter(({ originalEventType }) => (
        originalEventType !== 'DREM' && (rosterTagsShown || originalEventType !== 'RTG')
      ));
    const currentTopEvent = topEvents
      .filter(({ originalEventType }) => !['RTG', 'DREM', 'SUSP'].includes(originalEventType))
      .find((event) => {
        if (!showLocalTime && showUTCTime) {
          return event.fromDateTime! < currentTime && event.toDateTime! > currentTime;
        }
        return event.fromDateTimeLocal! < currentTime && event.toDateTimeLocal! > currentTime;
      });

    if (currentTopEvent) {
      const subevents = currentTopEvent.events;
      selectEventForHighlighting(currentTopEvent);

      // Scroll to subevent
      const subEventToScroll = subevents && (
        // Currently happening subevent
        subevents?.find((event, idx) => isEventCurrent(currentTime, event, subevents![idx + 1])) ||

        // First subevent which hasn't started yet
        subevents?.find((event) => ((!showLocalTime && showUTCTime) ? event.fromDateTime! : event.fromDateTimeLocal!) > currentTime) ||

        // Next top event
        topEvents[topEvents.indexOf(currentTopEvent) + 1]
      );
      if (subEventToScroll) {
        setEventToScroll(subEventToScroll.id);
      }
    } else {
      const events = getEventsForDay(currentTime, dutyList);
      const rosterTags = getRosterTagsForDay(currentTime, dutyList);
      selectEventForHighlighting(getEventToSelect(currentTime, events, rosterTags));
    }
  }, [
    dutyList,
    getTodayOnServer,
    rosterTagsShown,
    selectEventForHighlighting,
    setEventToScroll,
    setHighlightCurrentEvent,
    setHighlightedDay,
    showLocalTime,
    showUTCTime,
  ]);

  const ctx = useMemo(() => ({
    calendarState,
    setCalendarState,

    eventToScroll,
    setEventToScroll,

    openedEventBlocks,
    toggleEventBlock,

    highlightedEvent,
    setHighlightedEvent,

    highlightedDateInterval,
    setHighlightedDateInterval,
    setHighlightedDay,

    highlightCurrentEvent,
    setHighlightCurrentEvent,

    selectEventForHighlighting,

    highlightToday,
  }), [
    calendarState,
    eventToScroll,
    highlightedDateInterval,
    highlightCurrentEvent,
    highlightToday,
    highlightedEvent,
    openedEventBlocks,
    selectEventForHighlighting,
    setCalendarState,
    setEventToScroll,
    setHighlightCurrentEvent,
    setHighlightedDateInterval,
    setHighlightedDay,
    setHighlightedEvent,
    toggleEventBlock,
  ]);

  return <DutiesPresentationContext.Provider value={ctx}>{children}</DutiesPresentationContext.Provider>;
};

export const useDutiesPresentation = () => useContext(DutiesPresentationContext);

export default DutiesPresentationProvider;
