import { useTranslation } from 'react-i18next';

import { PemsDomain } from '@crew-webui/common/consts';
import {
  useConnection, useNewsSettings, useProfile,
} from '@crew-webui/common/hooks';
import { NewsSettingsFormContent } from '@crew-webui/common/view';
import { useSnackbar } from '@crew-webui/common/view/components';

import { titles } from 'consts';
import { IadpPage } from 'view/components';

const NewsSettings = () => {
  const { t } = useTranslation();
  const { crewComOnline, online: pemsOnline } = useConnection();
  const newsSettingsData = useNewsSettings(PemsDomain.IADP);
  const { profile: { preDefinedPhoneValue } } = useProfile();
  const { enqueueSnackbar } = useSnackbar();

  const online = crewComOnline && pemsOnline;

  const {
    invalid, loaded, sending, updateSettings,
  } = newsSettingsData;

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    await updateSettings();
    enqueueSnackbar(t('components.snackbar.settingsUpdated'), { variant: 'success' });
  };

  const subtitle = t('settings.newsSettings');

  return (
    <IadpPage
      checkCrewComOffline
      dataLoaded={loaded}
      headerProps={{ showBackButton: true, subtitle, title: t(titles.profile) }}
      submitButtonProps={{
        'data-test-id': 'news-settings-submit-button',
        'disabled': invalid || sending || !online,
        'onClick': handleSubmit,
        'children': t('button.saveSettings'),
      }}
    >
      {loaded && (
        <form onSubmit={handleSubmit}>
          <NewsSettingsFormContent {...newsSettingsData} online={online} preDefinedPhoneValue={preDefinedPhoneValue} />
        </form>
      )}
    </IadpPage>
  );
};

export default NewsSettings;
