import { createTheme as createNuiTheme } from '@lsy-netline-ui/netline-ui';

import { THEME_BREAKPOINTS } from '@crew-webui/common/consts';

const createTheme = (customerTheme: any) => createNuiTheme({
  ...customerTheme,
  fontSizeBreakpoint: THEME_BREAKPOINTS.values.sm,
});

export default createTheme;
