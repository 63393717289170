import { createStyles } from '@lsy-netline-ui/netline-ui';

const PAGE_MARGIN = 16;

export default ({ palette }: Theme) => createStyles({
  root: {
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'space-between',
    'marginRight': -PAGE_MARGIN,
    'marginLeft': -PAGE_MARGIN,
    'padding': `6px ${PAGE_MARGIN}px`,
    'color': palette.text.primary,
    'fontSize': 14,
    'lineHeight': '24px',

    '&:nth-child(2n+1)': {
      backgroundColor: palette.iadp.rowBackgroundColor,
    },
    '&:last-child': {
      marginBottom: 16,
    },
  },
  highlighted: {
    color: palette.error.main,
  },
});
