import { createContext } from 'react';

export interface UpdatesResult {
  lastDailyRemarkTimestamp?: string;
  lastNewsTimestamp?: string;
  lastRosterChange?: string; // IADP
  lastRosterChangeTimestamp?: string; // OT
  lastActivityChangeTimestamp?: string;
}

export type UpdatesResponse = PemsResponse<UpdatesResult>;

export type UpdatesState = {
  lastDailyRemarkTimestamp?: number;
  lastNewsTimestamp?: number;
  lastRosterChangeTimestamp?: number;
  lastActivityChangeTimestamp?: number;
};

export type UpdatesContextType = UpdatesState & {
  fetchUpdates: () => void;
};

export const defaultStateValue: UpdatesState = {
  lastDailyRemarkTimestamp: undefined,
  lastNewsTimestamp: undefined,
  lastRosterChangeTimestamp: undefined,
  lastActivityChangeTimestamp: undefined,
};

export const defaultContextValue = {
  ...defaultStateValue,
  fetchUpdates: () => {},
};

const UpdatesContext = createContext<UpdatesContextType>(defaultContextValue);

export default UpdatesContext;
