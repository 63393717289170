import { useDutiesModel, useSwipeable } from '../../../../hooks';

const useSwipeableCalendar = (timeTolerance?: number) => {
  const {
    canGoToNextBlockMonth, canGoToPrevBlockMonth, goToNextBlockMonth, goToPrevBlockMonth,
  } = useDutiesModel();

  const handleSwipeSuccess = (direction: string) => {
    if (direction === 'left') {
      goToNextBlockMonth();
    } else {
      goToPrevBlockMonth();
    }
  };
  const handlers = useSwipeable({
    onSwipeSuccess: handleSwipeSuccess,
    leftAllowed: canGoToNextBlockMonth,
    rightAllowed: canGoToPrevBlockMonth,
    animateFrom: Infinity, // do not animate
    timeTolerance,
  });

  return handlers;
};

export default useSwipeableCalendar;
