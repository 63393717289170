import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    backgroundColor: palette.background.default,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    flexGrow: 1,
    height: 0,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
  },
  subtitle: {
    flexShrink: 0,
    position: 'sticky',
    height: 56,
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
    zIndex: 5,
    backgroundColor: palette.common.white,
  },
  titleNewLine: {
    width: '100%',
    paddingRight: spacing(1),
  },
  subtitleNewLine: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});
