import { createContext } from 'react';

const { Geofence } = window;

export type CheckInInfoType = {
  logicalId: string;
  longitude: number;
  latitude: number;
  radius: number;
  airport: string;
  checkInPermission: string;
  fromDateTimeUTC?: Date;
};

export type CheckInContextType = {
  geofenceStatus?: string;
  checkinInfo?: CheckInInfoType;
  geofenceEnabled: boolean;
  isInRange: boolean;
  reloadCheckinInfo?: () => void;
};

export const initialState: CheckInContextType = {
  geofenceEnabled: false,
  geofenceStatus: Geofence?.Status.NONE,
  checkinInfo: undefined,
  isInRange: false,
};

const CheckinContext = createContext<CheckInContextType>({
  ...initialState,
});

export default CheckinContext;
