import {
  Page, PageProps, SubmitButton, SubmitButtonProps,
} from '@crew-webui/common/view/components';

const IadpPage = (props: PageProps & { submitButtonProps?: SubmitButtonProps }) => {
  const submitButton = props.submitButtonProps && <SubmitButton {...props.submitButtonProps} />;

  return <Page {...props} submitButton={submitButton} />;
};

export default IadpPage;
