import { createContext } from 'react';

export type NewsId = string;

export interface NewsItem {
  category?: string;
  createdAt: string;
  internalMessage: string;
  notificationId: NewsId;
  recipients: {
    status?: string;
  }[];
  sender: { name: string };
}

type NewsContextType = {
  deletingIds: NewsId[];
  hasLoaded: boolean;
  hasNotification: boolean;
  news: NewsItem[];

  clearPushNotifications: () => void;
  loadNews: () => void;
  markAsRead: (id: NewsId) => void;
  removeNewsItem: (id: NewsId) => void;
  removeNotification: () => void;
};

const NewsContext = createContext<NewsContextType>({
  deletingIds: [],
  hasLoaded: false,
  hasNotification: false,
  news: [],

  clearPushNotifications: () => {},
  loadNews: () => {},
  markAsRead: () => {},
  removeNewsItem: () => {},
  removeNotification: () => {},
});

export default NewsContext;
