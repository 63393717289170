import { Collapse, makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import { CalendarState } from '@crew-webui/common/consts';
import {
  useDutiesModel, useDutiesPresentation, useMultiColumn,
} from '@crew-webui/common/hooks';

import CalendarHeader from './CalendarHeader/CalendarHeader';

import CalendarToggleButton from './CalendarToggleButton/CalendarToggleButton';

import Months from './Months/Months';

import styles from './Calendar.styles';

const useStyles = makeStyles(styles, { name: 'Calendar' });

type CalendarProps = {
  enableRangeSelect?: boolean;
  showPairingsPerDayCalendarFilter?: boolean;
  showRosterTagsCalendarFilter?: boolean;
  showDailyRemarks: boolean;
  showBlockmonthSpecificData: boolean;
  children?: React.ReactNode;
};

const Calendar = ({
  enableRangeSelect, showPairingsPerDayCalendarFilter, showRosterTagsCalendarFilter, showDailyRemarks, showBlockmonthSpecificData, children,
}: CalendarProps) => {
  const classes = useStyles();
  const { activeBlockMonth } = useDutiesModel();
  const { calendarState } = useDutiesPresentation();
  const multiColumn = useMultiColumn();

  const headerClasses = { root: classes.headerRoot, titleRow: classes.titleRow };

  const renderMonths = () => {
    if (!activeBlockMonth) {
      return null;
    }

    return (
      <Collapse
        classes={{ wrapper: classes.collapseWrapper }}
        className={classes.collapseContainer}
        in={multiColumn || calendarState !== CalendarState.CLOSED}
      >
        <Months
          className={classes.months}
          showDailyRemarks={showDailyRemarks}
          enableRangeSelect={enableRangeSelect}
          showRosterTagsCalendarFilter={showRosterTagsCalendarFilter}
        />
      </Collapse>
    );
  };

  return (
    <div
      className={
        clsx({
          [classes.ownColumn]: multiColumn,
          [classes.fullyOpen]: !multiColumn && calendarState === CalendarState.OPEN,
        })
      }
    >
      <CalendarHeader
        classes={headerClasses}
        showRosterTagsCalendarFilter={showRosterTagsCalendarFilter}
        showPairingsPerDayCalendarFilter={showPairingsPerDayCalendarFilter}
      />
      <div className={classes.calendarContainer}>
        {renderMonths()}

        {!multiColumn && <CalendarToggleButton fullyOpenStateEnabled={showBlockmonthSpecificData} />}
        {children}
      </div>
    </div>
  );
};

export default Calendar;
