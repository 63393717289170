import { makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';
import { capitalize } from 'lodash';

import { Event } from '../../../consts';
import { useProfile } from '../../../hooks';
import styles from './DutyRowIcon.styles';

const useStyles = makeStyles(styles, { name: 'DutyRowIcon' });

type DutyRowIconProps = {
  event: Event;
  pairingListItem?: boolean;
};

const DutyRowIcon = ({ event, pairingListItem }: DutyRowIconProps) => {
  const classes = useStyles();
  const { profile: { icons, typeCodesDutyList } } = useProfile();

  const { originalEventType } = event;

  const getIcon = () => {
    const IconComponent = icons[originalEventType];

    if (!IconComponent) {
      return typeCodesDutyList[originalEventType] || originalEventType;
    }

    return (
      <IconComponent
        className={clsx(
          { [classes.airplane]: originalEventType === 'PAR' },
          { [classes.rotate]: ['PAR', 'LEG', 'RTG', 'PAR_EMPTY'].includes(originalEventType) },
          { [classes.rosterChangeIcon]: originalEventType === 'SUSP' },
          icons[originalEventType] ? (classes as any)[`icon${capitalize(originalEventType.replace('_', ''))}`] : undefined,
        )}
      />
    );
  };

  const icon = getIcon();
  const textIcon = typeof icon === 'string';

  return (
    <div
      className={clsx(
        classes.root,
        { [classes.iconContainerColor]: icons[originalEventType] },
        { [classes.iconContainerText]: textIcon },
        { [classes.iconContainerParListItem]: pairingListItem },
        { [(classes as any)[`iconContainerParListItem${capitalize(originalEventType.replace('_', ''))}`]]: pairingListItem },
        (classes as any)[originalEventType],
        // only apply iconContainer[EvenType] class if no icon is diplayed
        !icons[originalEventType] ? (classes as any)[`iconContainer${capitalize(originalEventType.replace('_', ''))}`] : undefined,
      )}
      data-test-id="duty-row-icon"
    >
      {icon}
    </div>
  );
};

export default DutyRowIcon;
