export default ({ palette }: Theme) => ({
  unReadBg: {
    backgroundColor: palette.unreadCardBackgroundColor,
  },
  unReadPin: {
    flexShrink: 0,
    marginLeft: 8,
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: palette.messageStatusIconColor,
  },
  readMark: {
    flexShrink: 0,
    marginLeft: 8,
    color: palette.messageStatusIconColor,
  },
});
