import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  text: {
    color: palette.text.label,
  },
  logoutButton: {
    marginBottom: spacing(3),
  },
  version: {
    color: palette.text.secondary,
  },
  cache: {
    marginTop: -spacing(1),
    borderBottom: `1px solid ${palette.divider}`,
    paddingBottom: spacing(3),
    marginBottom: spacing(3),
  },
  cacheButton: {
    whiteSpace: 'nowrap',
  },
});
