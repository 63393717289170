import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles } from '@lsy-netline-ui/netline-ui';

import { getServerUrl, normalizeUrl } from '../../../api';
import { COOKIE_ACCEPT, COOKIE_MORE_INFORMATION_LINK } from '../../../consts';
import { useProfile } from '../../../hooks';
import { getCookie, setCookie } from '../../../utils';

import styles from './CookieBanner.styles';

const useStyles = makeStyles(styles, { name: 'CookieBanner' });

const CookieBanner = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { profile: { showCookieBanner } } = useProfile();

  const [show, setShow] = useState(showCookieBanner && !getCookie(COOKIE_ACCEPT));

  const setAccept = useCallback(() => {
    setCookie(COOKIE_ACCEPT, 'true');
    setShow(false);
  }, []);

  const goToLink = useCallback(
    () => window.open(normalizeUrl(getServerUrl(), COOKIE_MORE_INFORMATION_LINK), '_blank'),
    [],
  );

  const buttonProps = {
    className: classes.button,
    primary: true,
    size: 'small',
  } as const;

  return show && (
    <div className={classes.root} data-test-id="cookie-banner">
      <div className={classes.banner}>
        <span className={classes.text}>
          {t('components.cookieBanner.info')}
        </span>
        <Button data-test-id="cookie-banner-accept-button" onClick={setAccept} {...buttonProps}>
          {t('button.ok')}
        </Button>
        <Button data-test-id="cookie-banner-more-info-button" onClick={goToLink} {...buttonProps}>
          {t('button.moreInfo')}
        </Button>
      </div>
    </div>
  );
};

export default CookieBanner;
