import { makeStyles, DialogContent as NuiDialogContent } from '@lsy-netline-ui/netline-ui';
import { DialogContentProps as MuiDialogContentProps } from '@material-ui/core/DialogContent';
import clsx from 'clsx';

import styles from './DialogContent.styles';

export const useStyles = makeStyles(styles, { name: 'DialogContent' });

const DialogContent = (props: MuiDialogContentProps) => {
  const classes = useStyles();

  return (
    <NuiDialogContent data-test-id="dialog-content" {...props} className={clsx(classes.root, props.className)} />
  );
};

export default DialogContent;
