import { CssBaseline } from '@lsy-netline-ui/netline-ui';

import {
  BlockMonthDataProvider, PageRememberProvider, PerformCachingProvider, useDeleteCookie, useFeature,
} from '@crew-webui/common/hooks';
import { ConfirmationForRedirect, ProgressIndicator } from '@crew-webui/common/view/components';

import { Feature } from 'consts';
import { CheckinProvider, useCache } from 'hooks';
import StartPage from './StartPage/StartPage';
import useIadpConnectionChange from './useIadpConnectionChange';

const App = () => {
  useDeleteCookie();
  useIadpConnectionChange();
  const isFeatureEnabled = useFeature();

  const blockMonthDataEnabled = isFeatureEnabled(Feature.VIEW_BLOCKMONTH_SPECIFIC_DATA);

  return (
    <BlockMonthDataProvider enabled={blockMonthDataEnabled}>
      <PageRememberProvider>
        <CheckinProvider>
          <CssBaseline />
          <ProgressIndicator />
          <ConfirmationForRedirect>
            <PerformCachingProvider useCache={useCache}>
              <StartPage />
            </PerformCachingProvider>
          </ConfirmationForRedirect>
        </CheckinProvider>
      </PageRememberProvider>
    </BlockMonthDataProvider>
  );
};

export default App;
