import {
  makeStyles, NetlineAppBar,
} from '@lsy-netline-ui/netline-ui';

import styles from './DesktopHeader.styles';
import { DesktopHeaderProps } from '../../../consts';

const useStyles = makeStyles(styles, { name: 'DesktopHeader' });

const DesktopHeader = ({
  actions, title, children, bottomContent,
}: DesktopHeaderProps) => {
  const classes = useStyles();

  return (
    <NetlineAppBar
      className={classes.root}
      classes={{ upperPart: classes.container }}
      title={title}
    >
      <span className={classes.leftBlock}>{actions}</span>
      <span className={classes.rightBlock}>
        {children}
        {bottomContent}
      </span>
    </NetlineAppBar>
  );
};

export default DesktopHeader;
