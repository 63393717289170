import { useCallback, useMemo } from 'react';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import {
  useAppCommon,
  useCrewAuth,
  useDutiesModel,
  useFeature,
  useLanguageFetch,
  useMultiColumn,
  useSetupDutyList,
} from '@crew-webui/common/hooks';
import {
  AppHeader, CookieBanner, NoConnection,
} from '@crew-webui/common/view/components';
import { isModule } from '@crew-webui/common/consts';

import routes from 'consts/routes';
import { IadpCalendar } from 'view';
import { IadpFooterMenu } from 'view/components';
import styles from './StartPage.styles';
import { useNotificationPopup } from 'hooks';
import { routePaths } from 'consts';

const useStyles = makeStyles(styles, { name: 'StartPage' });

const StartPage = () => {
  const classes = useStyles();
  const location = useLocation<{ name: string }>();
  const { footerVisible } = useAppCommon();
  const {
    ready, authenticated,
  } = useCrewAuth();
  const multiColumn = useMultiColumn();
  const isFeatureEnabled = useFeature();
  const isSingleColumnPath = routes.some((route) => (route.singleColumn && route.path === location.pathname));
  useSetupDutyList();
  useLanguageFetch();
  useNotificationPopup();
  const { buddyMode } = useDutiesModel();

  const isInModule = useMemo(() => location.pathname.startsWith(routePaths.root), [location]);

  const renderSubpage = useCallback(() => (
    <>
      <Switch>
        {routes.map(({
          path, component, feature, featureAccess,
        }) => (
          (!feature || isFeatureEnabled(feature, featureAccess)) && (
            <Route key={typeof path === 'string' ? path : path[0]} exact path={path} component={component} />
          )
        ))}
        {isInModule && <Redirect to="/" />}
      </Switch>
      {!multiColumn && <NoConnection />}
      {footerVisible && <IadpFooterMenu rightSide={multiColumn && isSingleColumnPath} />}
    </>
  ), [isInModule, footerVisible, isFeatureEnabled, isSingleColumnPath, multiColumn]);

  if (!ready) {
    return <div className="waiting">Initializing application</div>;
  }

  return (
    <div className={classes.outerContainer}>
      {authenticated && (
        <>
          {multiColumn && !isModule && <AppHeader />}
          {!!buddyMode && (
            <div className={classes.buddyBar}>
              Buddy mode {buddyMode.buddyName && `(${buddyMode.buddyName})`}
            </div>
          )}
          <div className={classes.columnContainer}>
            {multiColumn && !isSingleColumnPath && <IadpCalendar />}
            <div className={classes.mainContainer}>
              {renderSubpage()}
            </div>
          </div>
          {!window.cordova && <CookieBanner />}
        </>
      )}
      {!authenticated && (
        !isModule && <AppHeader />
      )}
      {multiColumn && <NoConnection />}
    </div>
  );
};

export default StartPage;
