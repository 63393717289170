import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { get } from 'lodash';

import { useDialog } from '../Dialog';
import { AuthState } from './reducer';
import AuthContext from './AuthContext';
import useKeycloakAuthService from './useKeycloakAuthService';

export const useSessionExpirationHandler = ({
  authState, handleSessionExpired,
} : {
  authState: string; handleSessionExpired: Function;
}) => {
  const { showDialog, hideDialog } = useDialog();
  const { t } = useTranslation();
  useEffect(() => {
    const onAxiosResponse = (response: any, error?: any) => {
      const config = response?.config || error?.config || {};
      const { ignoreErrors, ignoreSessionErrors } = config;
      const { status } = response || {};

      const textHtmlContentType = (
        get(response, 'headers["content-type"]', '').includes('text/html') ||
        get(response, 'headers["Content-Type"]', '').includes('text/html')
      );
      const containsHtmlTag = typeof response?.data === 'string' &&
        (response?.data || '').substr(0, 200).toLowerCase().includes('<html>');

      if (
        !ignoreErrors && !ignoreSessionErrors && authState !== AuthState.SESSION_EXPIRED &&
        (
          // handle session related errors (401 and 403)
          status === 401 || status === 403 ||
          // reathenticate also in this case (currently KLC server behaves like this)
          (status === 200 && textHtmlContentType && containsHtmlTag)
        )
      ) {
        handleSessionExpired();
      }
      return error ? Promise.reject(error) : response;
    };

    // subscribe to all response and error and display messages
    const interceptor = axios.interceptors.response.use(
      (response) => onAxiosResponse(response),
      (error) => onAxiosResponse(error.response, error),
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [authState, hideDialog, handleSessionExpired, showDialog, t]);
};

const KeycloakAuthProvider = ({
  children, loadPemsUser, oidcConfig,
}: { children: React.ReactNode; loadPemsUser?: () => Promise<any>; oidcConfig: Keycloak.KeycloakConfig }) => {
  const auth = useKeycloakAuthService(oidcConfig, loadPemsUser);

  useSessionExpirationHandler(auth);

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default KeycloakAuthProvider;
