import { useEffect } from 'react';

import { COOKIE_ACCEPT } from '../../consts';
import { deleteCookie } from '../../utils';
import { AuthState } from '../Auth';
import { useCrewAuth } from '../CrewAuth';

const useDeleteCookie = () => {
  const { authState } = useCrewAuth();

  useEffect(() => {
    if (authState === AuthState.LOGGED_OUT) {
      deleteCookie(COOKIE_ACCEPT);
    }
  }, [authState]);
};

export default useDeleteCookie;
