import { DateTime } from 'luxon';

import {
  BlockMonth, CrewComDomain, Event, EventContext, MyDataType, PemsDomain, RosterInterval,
} from '@crew-webui/common/consts';
import { serverConfigs, ServiceConfig } from '@crew-webui/common/api';
import { convertDate } from '@crew-webui/common/utils';

import { DateFormat, Feature } from 'consts';

type CacheConfig = {
  name: string;
  services: string[];
  features: string[];
  replaceKeyMap?: Object;
};

export interface CacheServiceConfig extends ServiceConfig {
  replaceKeyMap?: any;
}

const CACHE_PAGES: CacheConfig[] = [
  {
    name: 'Duty Page',
    // loadDutyData will call in init paramaters
    services: ['checkIadpUpdates', 'loadPreferredLanguage'],
    features: [],
  },
  {
    name: 'Pairing Detail',
    services: ['getEventByLogicalEventId'],
    features: [Feature.VIEW_PAIRING],
  },
  {
    name: 'Leg Detail Info',
    services: ['getEventByLogicalEventId', 'getLegCrewMembers', 'getRoleInfo'],
    features: [Feature.VIEW_LEG],
  },
  {
    name: 'Check In Page',
    services: ['getEventByLogicalEventId', 'loadCheckinData', 'loadCheckinConfiguration'],
    features: [Feature.VIEW_CHECK_IN],
  },
  {
    name: 'Hotel Detail Page',
    services: ['getEventByLogicalEventId', 'getHotel'],
    features: [Feature.VIEW_HOTEL],
  },
  {
    name: 'Notifications Page',
    services: ['loadRosterChangeData'],
    features: [Feature.VIEW_NOTIFICATIONS],
  },
  {
    name: 'News Page',
    services: ['getNews'],
    features: [Feature.VIEW_NEWS],
  },
  {
    name: 'Remarks Page',
    services: ['getRemarks', 'getRemarkTypes'],
    features: [Feature.VIEW_DAILY_REMARKS],
  },
  {
    name: 'Summary Page',
    services: ['loadSummaryData'],
    features: [Feature.VIEW_SUMMARY],
  },
  {
    name: 'My Data Info',
    services: ['loadMyData'],
    features: [Feature.IDP_MY_DATA],
  },
  {
    name: 'Additional Flight Details',
    services: ['getEventByLogicalEventId', 'getFlightInfo'],
    features: [Feature.OPERATIONAL_AND_FLIGHT_INFORMATION_ENABLED],
    replaceKeyMap: { data: 'flightInfoEventId' },
  },
  {
    name: 'News Settings',
    services: ['loadMasterData', 'loadContactInfo', 'loadCrewComConfig', 'loadDevices'],
    features: [Feature.VIEW_NOTIF_SETTINGS],
  },
];

export const filterServices = (isFeatureEnabled: (featureId: string, value?: string) => boolean | undefined) => {
  const configs = new Map<string, CacheServiceConfig>();
  CACHE_PAGES.forEach((cacheConf) => {
    cacheConf.services.forEach((srvName) => {
      if (!configs.has(srvName) && !cacheConf.features.some((feature) => !isFeatureEnabled(feature))) {
        configs.set(srvName, { ...serverConfigs[srvName], replaceKeyMap: cacheConf.replaceKeyMap });
      }
    });
  });
  return configs;
};

const buildStaticParamMap = (
  crmId: string,
  wholeIntervalToShowInCalendar: RosterInterval | undefined,
  rosterPeriod: RosterInterval | undefined,
  getCurrentBlockMonth: () => BlockMonth | undefined,
) => {
  const paramMap = new Map<string, string[]>();
  if (!crmId || !wholeIntervalToShowInCalendar || !rosterPeriod) {
    return paramMap;
  }
  paramMap.set('domain', [CrewComDomain.IADP, PemsDomain.IADP]);
  paramMap.set('crewComDomain', [CrewComDomain.IADP]);
  paramMap.set('pemsDomain', [PemsDomain.IADP]);
  paramMap.set('crmId', [crmId]);
  paramMap.set('context', [EventContext.Roster]);
  // used by loadCheckinData
  paramMap.set('ltype', ['mobile']);

  const { from } = wholeIntervalToShowInCalendar || {};
  const { to } = rosterPeriod || {};
  const fromDtUtc = from?.toFormat(DateFormat.DATETIME_ISO);
  const toDtUtc = to?.toFormat(DateFormat.DATETIME_ISO);
  paramMap.set('toDtUtc', [toDtUtc]);
  paramMap.set('fromDtUtc', [fromDtUtc]);

  const blockMonth = getCurrentBlockMonth();
  if (blockMonth) {
    const startDate = blockMonth.from.toISODate();
    const endDate = blockMonth.to.toISODate();
    const start = convertDate(startDate).startOf('day').toFormat(DateFormat.DATETIME_ISO);
    const end = convertDate(endDate).endOf('day').toFormat(DateFormat.DATETIME_ISO);
    paramMap.set('start', [start]);
    paramMap.set('end', [end]);
  }
  paramMap.set('type', [MyDataType.ADDRESS, MyDataType.CONTACT, MyDataType.INFO, MyDataType.PASSPORT, MyDataType.VISA]);

  return paramMap;
};

// filter the events in the given range
const filterEvents = (events: Event[], cacheBeforeDaysNo: number, cacheAfterDaysNo: number, utcDateTime: DateTime) => {
  const checkStart = utcDateTime.minus({ days: cacheBeforeDaysNo }).startOf('day');
  const checkEnd = utcDateTime.plus({ days: cacheAfterDaysNo }).endOf('day');
  return events.filter((event) => (checkEnd.toMillis() > event.fromDateTime!.toMillis()) &&
    (checkStart.toMillis() < event.toDateTime!.toMillis()));
};

// push a value into the array, if not null and not contains it
const pushIfNotNull = (array: string[], value?: any) => {
  if (value && array.indexOf(value) < 0) {
    array.push(value);
  }
};

/**
 * Build parameter map, with static values, and event specific values
 * @type {function(): Map<any, any>}
 */
export const buildCacheParamMap = (
  events: Event[],
  cacheBeforeDaysNo: number,
  cacheAfterDaysNo: number,
  cacheFlightInfoDaysNo: number,
  utcDateTime: DateTime,
  crmId: string,
  wholeIntervalToShowInCalendar: RosterInterval | undefined,
  rosterPeriod: RosterInterval | undefined,
  getCurrentBlockMonth: () => BlockMonth | undefined,
) => {
  const paramMap = buildStaticParamMap(crmId, wholeIntervalToShowInCalendar, rosterPeriod, getCurrentBlockMonth);
  const flightInfoEndDate = utcDateTime.plus({ days: cacheFlightInfoDaysNo }).startOf('day');
  // append event specific parameters
  const logicalEventIds: string[] = [];
  const hotelIds: string[] = [];
  const legIds: string[] = [];
  const checkInLogicalIds: string[] = [];
  const flightInfoEventIds: string[] = [];
  filterEvents(events, cacheBeforeDaysNo, cacheAfterDaysNo, utcDateTime).forEach((event) => {
    pushIfNotNull(logicalEventIds, event.logicalId);
    if (event.events) {
      event.events.forEach((subEvent) => {
        pushIfNotNull(logicalEventIds, subEvent.logicalId);
        if (subEvent.originalEventType === 'HOT') {
          pushIfNotNull(hotelIds, String(subEvent.id));
        }
        if (subEvent.originalEventType === 'LEG' || subEvent.originalEventType === 'FPR') {
          pushIfNotNull(legIds, String(subEvent.id));
          if (subEvent.fromDateTime! > utcDateTime && subEvent.fromDateTime! < flightInfoEndDate) {
            pushIfNotNull(flightInfoEventIds, [subEvent.logicalId]);
          }
        }
        if (subEvent.originalEventType === 'C_I') {
          pushIfNotNull(checkInLogicalIds, subEvent.logicalId);
        }
      });
    }
  });
  paramMap.set('logicalEventId', logicalEventIds);
  paramMap.set('hotelId', hotelIds);
  paramMap.set('legId', legIds);
  paramMap.set('checkInLogicalId', checkInLogicalIds);
  paramMap.set('flightInfoEventId', flightInfoEventIds);
  return paramMap;
};
