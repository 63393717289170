import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Grid } from '@lsy-netline-ui/netline-ui';
import { useUtils } from '@lsy-netline-ui/netline-ui/pickers';

import { useIadpParams } from '@crew-webui/common/api';
import {
  useEventDetails, useMultiColumn, useOnlineAuthorized, useProfile,
} from '@crew-webui/common/hooks';
import { AircraftRotationInfo, FlightDesignator, FlightInfo } from '@crew-webui/common/consts';
import { formatDurationObject, formatTime } from '@crew-webui/common/utils/helper';

import { getSubtitleText } from './LegDetails';
import { IadpPage, Card, CardContainer } from '../../components';
import styles from './AdditionalFlightDetails.styles';

const useStyles = makeStyles(styles, { name: 'AdditionalFlightDetails' });

const AdditionalFlightDetails = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const multicolumn = useMultiColumn();
  const { logicalId } = useIadpParams<{ logicalId: string }>();
  const {
    eventDetails,
    additionalFlightDetails,
    fetchAdditionalFlightDetails,
    resetAdditionalFlightDetails,
  } = useEventDetails();
  const {
    profile: {
      showLocalTime, showUTCTime, localTimePostfix, utcTimePostfix, dateFormats,
    },
  } = useProfile();
  const utils = useUtils();

  const logicalEventId = decodeURIComponent(logicalId);
  const event = eventDetails[logicalEventId];

  const subtitle = useMemo(() => getSubtitleText(event, t), [event, t]);

  const displayTime = useCallback((localTime: string | undefined, utcTime: string | undefined, className?: string) => {
    const local = (showLocalTime && !!localTime && formatTime(localTime, dateFormats.TIME) + localTimePostfix);
    const utc = (showUTCTime && !!utcTime && formatTime(utcTime, dateFormats.TIME) + utcTimePostfix);
    const dayMonth = !!localTime && formatTime(localTime, dateFormats.SHORT_DATE);
    return (
      <span className={className}>
        {t('date.dateMonthText', { date: dayMonth })} {local} {utc && (local ? `(${utc})` : utc)}
      </span>
    );
  }, [showLocalTime, dateFormats.TIME, dateFormats.SHORT_DATE, localTimePostfix, showUTCTime, utcTimePostfix, t]);

  const displayFlightDetailsRow = useCallback(({
    airport,
    gate,
    depDtSchedAtApLt,
    depDtSched,
    actualDepDtAtApLt,
    actualDepDt,
  }: FlightInfo) => {
    const dateDiff = utils.getDiff(utils.date(depDtSched), utils.date(actualDepDt));

    return (
      <>
        <Grid item xs={2}>{airport}</Grid>
        <Grid item xs={2}>{gate}</Grid>
        <Grid item xs={4}>{displayTime(depDtSchedAtApLt, depDtSched)}</Grid>
        <Grid item xs={4}>{displayTime(
          actualDepDtAtApLt,
          actualDepDt,
          clsx({
            [classes.flightEarlier]: dateDiff < 0,
            [classes.flightDelay]: dateDiff > 0,
          }),
        )}
        </Grid>
      </>
    );
  }, [classes, displayTime, utils]);

  const displayDsgn = useCallback(({ carrier, number, suffix }: FlightDesignator) => (`${carrier}${number}${suffix || ''}`), []);

  const displayRotationCard = useCallback(({
    dsgn,
    depAp,
    arrAp,
    actualTimeAtAp,
    actualTimeAtApLt,
  }: AircraftRotationInfo, title: ReactNode) => (
    <Card
      className={classes.card}
    >
      <Card.Content
        title={title}
        titleProps={{ className: classes.cardTitle }}
        content={<>{`${displayDsgn(dsgn)} ${depAp}-${arrAp}`} {displayTime(actualTimeAtApLt, actualTimeAtAp)}</>}
        contentProps={{ className: classes.cardContent }}
      />
    </Card>
  ), [classes, displayDsgn, displayTime]);

  useOnlineAuthorized(() => {
    fetchAdditionalFlightDetails(logicalEventId);
    return () => resetAdditionalFlightDetails();
  }, [logicalId, fetchAdditionalFlightDetails]);

  return (
    <IadpPage
      dataLoaded={!!additionalFlightDetails}
      headerProps={{
        title: t('duties.main.title'),
        subtitle: t('duties.legDetails.additionalFlightDetails.title'),
        showBackButton: true,
      }}
    >

      {!!additionalFlightDetails &&
        (
          <>
            <CardContainer
              label={subtitle}
              className={classes.cardContainer}
            >
              <Card
                className={clsx(classes.scheduleCard, { [classes.scheduleCardMulticolumn]: !multicolumn })}
              >
                <Grid container spacing={2} className={classes.scheduleGrid}>

                  <Grid item xs={2} />
                  <Grid item xs={2}>{t('duties.legDetails.additionalFlightDetails.gate')}</Grid>
                  <Grid item xs={4}>{t('duties.legDetails.additionalFlightDetails.scheduled')}</Grid>
                  <Grid item xs={4}>{t('duties.legDetails.additionalFlightDetails.current')}</Grid>

                  {displayFlightDetailsRow(additionalFlightDetails.departure)}
                  {displayFlightDetailsRow(additionalFlightDetails.arrival)}

                  <Grid item xs={2} />
                  <Grid item xs={2} />
                  <Grid item xs={4}>{formatDurationObject({ minutes: additionalFlightDetails.scheduledDiff }, dateFormats.TIME)}</Grid>
                  <Grid item xs={4}>{formatDurationObject({ minutes: additionalFlightDetails.currentDiff }, dateFormats.TIME)}</Grid>
                </Grid>

                <Card
                  className={classes.scheduleInfoCard}
                >
                  <Card.Content
                    title={t('duties.legDetails.additionalFlightDetails.scheduleInfo')}
                    titleProps={{ className: classes.scheduleInfoLabel }}
                    content={`${t('duties.legDetails.additionalFlightDetails.overSched')} ${
                      formatDurationObject({ minutes: additionalFlightDetails.overScheduled }, dateFormats.TIME)
                    }`}
                    contentProps={{ className: classes.scheduleInfoContent }}
                  />
                </Card>

              </Card>
            </CardContainer>

            <CardContainer
              label={t('duties.legDetails.additionalFlightDetails.aircraftRotation')}
              className={classes.cardContainer}
            >
              {!!additionalFlightDetails.dsgn && (
                <Card className={classes.card}>
                  <Card.Content
                    title={t('duties.legDetails.additionalFlightDetails.aircraft')}
                    titleProps={{ className: classes.cardTitle }}
                    content={`${additionalFlightDetails.acSubType} FIN ${additionalFlightDetails.acReg}`}
                    contentProps={{ className: classes.cardContent }}
                  />
                </Card>
              )}

              {!!additionalFlightDetails.prevAc &&
                displayRotationCard(additionalFlightDetails.prevAc, t('duties.legDetails.additionalFlightDetails.previous'))}
              {!!additionalFlightDetails.nextAc &&
                displayRotationCard(additionalFlightDetails.nextAc, t('duties.legDetails.additionalFlightDetails.next'))}

            </CardContainer>
          </>
        )}

    </IadpPage>
  );
};

export default AdditionalFlightDetails;
