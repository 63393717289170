import { useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import {
  ApiStatusProvider,
  AppCommonProvider,
  ConnectionProvider,
  DeviceProvider,
  DialogProvider,
  DomainContext,
  DutiesModelProvider,
  DutiesPresentationProvider,
  EventDetailsProvider,
  MultiColumnProvider,
  MyDataProvider,
  NewsProvider,
  ProfileAndThemeProvider,
  PublicConfigProvider,
  ServerConfigProvider,
  UpdatesProvider,
  UserPreferencesProvider,
  VersionCheck,
  FlightSearchProvider,
  useAppframeServices,
} from '@crew-webui/common/hooks';
import { initI18next, ServerConfigHandler, syncStorage } from '@crew-webui/common/api';
import { CrewComDomain, PemsDomain } from '@crew-webui/common/consts';
import { ErrorBoundary, MessageDialog, SnackbarProvider } from '@crew-webui/common/view/components';

import { ProfileMap } from 'utils';
import { DailyRemarksProvider } from 'hooks';
import App from 'view/App';
import { languageFiles, routePrefix } from 'consts';

const domainContext = { pemsDomain: PemsDomain.IADP, crewComDomain: CrewComDomain.IADP, routePrefix };

initI18next(languageFiles);
syncStorage.setKeyPrefix('iadp');

const IndexIadp = (moduleProps: SpaModuleProps) => {
  const {
    getToken, getProfile, configs, logout, services,
  } = moduleProps;

  useAppframeServices(moduleProps);

  useEffect(() => {
    // load module config
    if (configs?.iadp) {
      ServerConfigHandler.initServerConfig(configs!.iadp);
    }
  }, [configs]);

  return (
    <DomainContext.Provider value={domainContext}>
      <HashRouter>
        <ProfileAndThemeProvider ProfileMap={ProfileMap} classPrefix="IADP">
          <ErrorBoundary>
            <MultiColumnProvider services={services}>
              <DialogProvider>
                <SnackbarProvider>
                  <ConnectionProvider>
                    <MessageDialog />
                    <ServerConfigProvider>
                      <ApiStatusProvider>
                        <AppCommonProvider>
                          <PublicConfigProvider useWithPems getToken={getToken} getProfile={getProfile} configs={configs} logout={logout} services={services}>
                            <UserPreferencesProvider>
                              <VersionCheck>
                                <DutiesModelProvider>
                                  <DutiesPresentationProvider>
                                    <EventDetailsProvider>
                                      <UpdatesProvider>
                                        <DailyRemarksProvider>
                                          <DeviceProvider>
                                            <NewsProvider>
                                              <MyDataProvider>
                                                <FlightSearchProvider>
                                                  <App />
                                                </FlightSearchProvider>
                                              </MyDataProvider>
                                            </NewsProvider>
                                          </DeviceProvider>
                                        </DailyRemarksProvider>
                                      </UpdatesProvider>
                                    </EventDetailsProvider>
                                  </DutiesPresentationProvider>
                                </DutiesModelProvider>
                              </VersionCheck>
                            </UserPreferencesProvider>
                          </PublicConfigProvider>
                        </AppCommonProvider>
                      </ApiStatusProvider>
                    </ServerConfigProvider>
                  </ConnectionProvider>
                </SnackbarProvider>
              </DialogProvider>
            </MultiColumnProvider>
          </ErrorBoundary>
        </ProfileAndThemeProvider>
      </HashRouter>
    </DomainContext.Provider>
  );
};

export default IndexIadp;
