import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  openPosition: {
    backgroundColor: palette.common.white,
    marginBottom: 4,
    borderRadius: 4,
    padding: 12,
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    'backgroundColor': palette.greyIconColor,
    'width': 40,
    'height': 40,
    'borderRadius': '100%',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'marginRight': 12,
    '& svg': {
      fontSize: 24,
      color: palette.common.white,
    },
  },
  assignment: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    width: 'calc(100% - 52px)',
    color: palette.text.label,
  },
  openPositionDetails: {
    'fontSize': 12,
    'color': palette.text.secondaryLabel,
    'display': 'flex',
    'flexDirection': 'row',
    'flexWrap': 'wrap',
    'marginTop': 8,
    '& span': {
      marginRight: 16,
      marginTop: 2,
      textTransform: 'uppercase',
    },
  },
  deadhead: {
    '& $openPositionDetails': {
      color: palette.deadheadTextColor.light,
    },
  },
});
