import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { DateTime, Info, Interval } from 'luxon';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import { debounce } from 'lodash';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import { BlockMonth } from '../../../../consts';
import {
  useDutiesModel,
  useDutiesPresentation,
  useMultiColumn,
  useProfile,
  useRemember,
  useTodayOnServer,
} from '../../../../hooks';
import {
  getEventsForDay, getEventToSelect, getRosterTagsForDay, rotateArrayRight, scrollToDate,
} from '../../../../utils';
import alignEventTags from '../Event/alignEventTags';
import Month, { horizontalSpacingBetweenMonths } from '../Month/Month';
import useSwipeableCalendar from './useSwipeableCalendar';
import styles from './Months.styles';
import useCalendarEventHandlers from './useCalendarEventHandlers';

const debouncedAlignItems = debounce(alignEventTags, 100);

const useStyles = makeStyles(styles, { name: 'Months' });

// if time less than tolerance than will swipe, otherwise will range select
const RANGE_SELECT_TIME_TOLERANCE = 1000;

type Props = {
  className?: string;
  showDailyRemarks: boolean;
  enableRangeSelect?: boolean;
  showRosterTagsCalendarFilter?: boolean;
};

const Months = ({
  className, showDailyRemarks, enableRangeSelect = false, showRosterTagsCalendarFilter = false,
}: Props) => {
  const classes = useStyles();
  const {
    activeBlockMonth,
    daysToShowInCalendar,
    dutyList: events,
    loadPairingCountPerDay,
    rosterPeriod,
  } = useDutiesModel();
  const multiColumn = useMultiColumn();
  const { profile: { calendarWeekStartMonday } } = useProfile();
  const remember = useRemember();
  const { setHighlightedDateInterval, setHighlightedDay, selectEventForHighlighting } = useDutiesPresentation();
  const { i18n: { language: currentLanguage } } = useTranslation();

  // setup tolerance to check if swipe or rangeSelect occurred.
  const rangeTimeTolerance = enableRangeSelect ? RANGE_SELECT_TIME_TOLERANCE : undefined;

  const onDateSelected = useCallback((date: DateTime) => {
    const inDayEvents = getEventsForDay(date, events);
    const rosterTags = getRosterTagsForDay(date, events);
    selectEventForHighlighting(getEventToSelect(date, inDayEvents, rosterTags));
    setHighlightedDay(date);
  }, [selectEventForHighlighting, setHighlightedDay, events]);

  const onDateRangeSelected = useCallback((dateFrom: DateTime, dateTo: DateTime) => {
    setHighlightedDateInterval(Interval.fromDateTimes(dateFrom, dateTo));
  }, [setHighlightedDateInterval]);
  const calendarEventHandlers = useCalendarEventHandlers(onDateSelected, onDateRangeSelected, rangeTimeTolerance);

  const swipeHandlers = useSwipeableCalendar(rangeTimeTolerance);
  const getTodayOnServer = useTodayOnServer();

  const prevActiveBlockMonth = useRef<BlockMonth>();
  const rosterPeriodContainerRef = useRef<HTMLDivElement>(null);
  const removeScrollListener = useRef<Function>();

  const updateScrollPosition = useCallback(() => {
    if (!rosterPeriodContainerRef.current) {
      return;
    }

    const {
      clearData, restoreScroll, storeScroll, getData,
    } = remember;

    if (removeScrollListener.current) {
      removeScrollListener.current();
    }
    removeScrollListener.current = storeScroll('rosterPeriodContainer', rosterPeriodContainerRef);
    if (activeBlockMonth?.id !== undefined && activeBlockMonth?.id !== prevActiveBlockMonth.current?.id) {
      clearData('rosterPeriodContainer');
      rosterPeriodContainerRef.current.scrollTop = 0;
    } else if (getData('rosterPeriodContainer')) {
      restoreScroll('rosterPeriodContainer', rosterPeriodContainerRef);
    } else {
      scrollToDate(getTodayOnServer().toISODate());
    }
  }, [activeBlockMonth?.id, getTodayOnServer, remember]);

  useEffect(() => {
    alignEventTags();

    updateScrollPosition();
    prevActiveBlockMonth.current = activeBlockMonth;
  });

  useEffect(() => {
    if (showRosterTagsCalendarFilter) {
      loadPairingCountPerDay();
    }
  }, [showRosterTagsCalendarFilter, loadPairingCountPerDay]);

  useEffect(() => {
    window.addEventListener('resize', debouncedAlignItems);
    return () => window.removeEventListener('resize', debouncedAlignItems);
  }, []);

  const weekDaysArray = useMemo(
    () => (calendarWeekStartMonday ?
      Info.weekdays('short', { locale: currentLanguage }) :
      rotateArrayRight(Info.weekdays('short', { locale: currentLanguage }))
    ),
    [calendarWeekStartMonday, currentLanguage],
  );
  const rosterPeriodInterval = useMemo(() => rosterPeriod!.from.startOf('day').until(rosterPeriod!.to.endOf('day')), [rosterPeriod]);
  const today = useMemo(() => getTodayOnServer(), [getTodayOnServer]);

  if (!activeBlockMonth) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className, { [classes.ownColumn]: multiColumn })} data-test-id="calendar">
      <div ref={rosterPeriodContainerRef} className={classes.rosterPeriodContainer}>
        <div
          {...swipeHandlers}
          className={classes.daysContainer}
          style={{
            width: `calc((100% + ${horizontalSpacingBetweenMonths}px) * ${daysToShowInCalendar.length})`,
          }}
        >
          {daysToShowInCalendar.map((days, index) => (
            <Month
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              calendarEventHandlers={calendarEventHandlers}
              days={days}
              index={index}
              rosterPeriodInterval={rosterPeriodInterval}
              showDailyRemarks={showDailyRemarks}
              today={today}
              weekDaysArray={weekDaysArray}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Months;
