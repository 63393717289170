import { makeStyles } from '@lsy-netline-ui/netline-ui';

import styles from './NotificationBadge.styles';

const useStyles = makeStyles(styles, { name: 'NotificationBadge' });

type Props = {
  children: React.ReactNode;
  show: boolean;
};

const NotificationBadge = ({ children, show }: Props) => {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      {children}
      {show && <span className={classes.badge} />}
    </span>
  );
};

export default NotificationBadge;
