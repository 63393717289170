import { createStyles } from '@lsy-netline-ui/netline-ui';

const headerIconSize = 40;

export default ({ breakpoints, palette }: Theme) => createStyles({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    flexShrink: 0,
    zIndex: 10,
    backgroundColor: palette.background.secondary,
    color: palette.text.primary,
    fontWeight: 500,
  },
  calendarInfo: {
    fontSize: 18,
  },
  filterMenuItem: {
    padding: 12,
    fontSize: 13,
  },
  filterIcon: {
    'color': palette.calendar.filterCheckIconColor,
    'width': 24,
    'height': 24,
    'textAlign': 'center',
    'paddingTop': 2,
    'marginRight': 8,
    '& svg': {
      fontSize: 13,
    },
  },
  titleRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  rightIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 2 * headerIconSize,
    [breakpoints.down('sm')]: {
      width: headerIconSize,
    },
  },
  leftIcons: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 2 * headerIconSize,
    [breakpoints.down('sm')]: {
      width: headerIconSize,
    },
  },
  moduleAvatarButton: {
    padding: '9px',
  },
  periodRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -6,
  },
});
