import { useTranslation } from 'react-i18next';
import { Typography, makeStyles } from '@lsy-netline-ui/netline-ui';

import { NoContentMessage } from '@crew-webui/common/view/components';

import { Card } from 'view/components';
import styles from './MyDataSection.styles';

const useStyles = makeStyles(styles, { name: 'MyDataSection' });

const MyDataSection = ({ items, label } : { items: any[]; label?: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getAside = (text: string) => (
    <Typography className={classes.aside} variant="caption">{text}</Typography>
  );

  const getCard = ({
    id, type, doc: { rightText, ...doc }, additionalDocs, ...otherProps
  }: any) => (
    <Card key={id} data-test-id="my-data-card" {...otherProps}>
      <Card.Content aside={getAside(rightText)} className={classes.doc} {...doc as any} />

      {additionalDocs?.map(({ id: additionalDocId, rightText: additionalRightText, ...additionalDoc }: any) => (
        <Card.Content
          key={additionalDocId}
          aside={getAside(additionalRightText)}
          className={classes.additionalDoc}
          {...additionalDoc as any}
        />
      ))}
    </Card>
  );

  return (
    <div className={classes.root} data-test-id="my-data-section">
      {label && (
        <Typography className={classes.label} data-test-id="my-data-section-title" variant="h6">
          {label}
        </Typography>
      )}
      <div className={classes.list}>
        {(!items || !items.length) ? (
          <NoContentMessage>{t('myData.noItemMsg', { type: label })}</NoContentMessage>
        ) : (
          items.map(getCard)
        )}
      </div>
    </div>
  );
};

export default MyDataSection;
