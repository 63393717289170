import { Calendar } from '@crew-webui/common/view';

import { useFeature } from '@crew-webui/common/hooks';

import { Feature } from 'consts';

const IadpCalendar = () => {
  const isFeatureEnabled = useFeature();

  const showRosterTagsCalendarFilter = isFeatureEnabled(Feature.VIEW_ROSTER_TAGS_CALENDAR_FILTER);
  const showDailyRemarks = isFeatureEnabled(Feature.VIEW_DAILY_REMARKS);
  const showBlockmonthSpecificData = isFeatureEnabled(Feature.VIEW_BLOCKMONTH_SPECIFIC_DATA);

  return (
    <Calendar
      showRosterTagsCalendarFilter={showRosterTagsCalendarFilter}
      showDailyRemarks={showDailyRemarks}
      showBlockmonthSpecificData={showBlockmonthSpecificData}
    />
  );
};

export default IadpCalendar;
