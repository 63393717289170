import { useCallback } from 'react';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { IconButton, makeStyles } from '@lsy-netline-ui/netline-ui';
import { Check as CheckIcon, Delete as DeleteIcon } from '@material-ui/icons';

import { useProfile } from '@crew-webui/common/hooks';

import { useDailyRemarkActions, RemarkForListing } from 'hooks';
import { Card } from 'view/components';
import styles from './DailyRemarkMsg.styles';
import { routePaths } from 'consts';

const useStyles = makeStyles(styles, { name: 'DailyRemarkMsg' });

const DailyRemarkMsg = ({ byCrewMember, remark }: { byCrewMember: boolean; remark: RemarkForListing }) => {
  const classes = useStyles();
  const {
    read, remarkTimeStamp, text, date, datetime, logicalIdRelatedEvent,
  } = remark;
  const {
    profile: {
      dateFormats = {},
    },
  } = useProfile();
  const history = useHistory();
  const { deleteDailyRemark, markAsRead } = useDailyRemarkActions();

  const handleClick = useCallback(() => {
    markAsRead(logicalIdRelatedEvent, text);
    history.push(`${routePaths.dailyRemarks}/${logicalIdRelatedEvent}`);
  }, [markAsRead, logicalIdRelatedEvent, text, history]);

  const handleDelete = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    deleteDailyRemark(date);
  }, [date, deleteDailyRemark]);

  const notRead = !read && !byCrewMember;
  const title = DateTime.fromISO(datetime).toUTC().toFormat(dateFormats.SHORT_DATE_SEPARATED);
  const footer = DateTime.fromISO(remarkTimeStamp).toUTC().toFormat(dateFormats.SHORT_DATE_TIME);

  return (
    <Card
      className={clsx({ [classes.unReadBg]: notRead })}
      data-id={logicalIdRelatedEvent}
      data-test-id="remark-button"
      onClick={handleClick}
    >
      <Card.Content
        aside={(
          <>
            {!notRead && !byCrewMember && (
              <CheckIcon className={classes.readMark} data-test-id="remark-read-mark" fontSize="small" />
            )}
            {notRead && <div className={classes.unReadPin} data-test-id="remark-unread-mark" />}
            {byCrewMember && (
              <IconButton data-test-id="remark-delete-button" edge="end" onClick={handleDelete}><DeleteIcon /></IconButton>
            )}
          </>
        )}
        content={text}
        contentProps={{ 'data-test-id': 'remark-text' }}
        footer={footer}
        footerProps={{ 'data-test-id': 'remark-datetime' }}
        nowrap
        title={title}
        titleProps={{ 'data-test-id': 'remark-date' }}
      />
    </Card>
  );
};

export default DailyRemarkMsg;
