import { useEffect, useRef } from 'react';

import { CalendarState } from '../../consts';
import { AuthState } from '../Auth';
import { useCrewAuth } from '../CrewAuth';
import { useDutiesModel } from '../DutiesModel';
import { useProfile } from '../Profile';
import { useDutiesPresentation } from './DutiesPresentationProvider';

const useSetupDutyList = () => {
  const { authState } = useCrewAuth();
  const { eventsHaveLoaded } = useDutiesModel();
  const { highlightToday, setCalendarState } = useDutiesPresentation();
  const { profile: { showCalendarAfterLogin } } = useProfile();

  const prevEventsHaveLoaded = useRef(false);

  const authorized = authState === AuthState.AUTHORIZED;

  useEffect(() => {
    if (!authorized) {
      return;
    }
    if (showCalendarAfterLogin) {
      setCalendarState(CalendarState.HALFOPEN);
    } else {
      setCalendarState(CalendarState.CLOSED);
    }
  }, [authorized, setCalendarState, showCalendarAfterLogin]);

  useEffect(() => {
    if (!prevEventsHaveLoaded.current && eventsHaveLoaded) {
      prevEventsHaveLoaded.current = eventsHaveLoaded;
      highlightToday();
    }
  }, [highlightToday, eventsHaveLoaded]);
};

export default useSetupDutyList;
