import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  isArray, isEqual, reduce, set,
} from 'lodash';
import * as yup from 'yup';

import { PemsDomain, LocalStorageKeys, MyDataType } from '../../consts';
import { logger, services } from '../../api';
import { printableRule } from '../../utils';
import { useOnlineAuthorized } from '../common';
import { useAppCommon } from '../AppCommon';
import { useDevice } from '../Device';
import { useMyData } from '../MyData';
import { useProfile } from '../Profile';
import { useUserPreferences } from '../UserPreferences';
import useMasterData, { PemsSubscription } from './useMasterData';

const PHONE_REGEX = /^\+(?:[0-9]){6,14}[0-9]$/;

const getVisibleNotificationChanelItems = (items?: string[]) => {
  if (!isArray(items)) {
    return [];
  }

  return items.map((contact) => ({ value: contact, text: contact }));
};

type CrewComSubscription = {
  email?: string | null;
  phone?: string | null;
  tags: string[];
  usePrimaryEmail?: boolean;
  usePrimaryPhone?: boolean;
  useAllDevice?: boolean;
};

interface NotificationTagSettings {
  subscriptions: CrewComSubscription[];
}

const useCrewComConfig = (crewComDomain?: string) => {
  const [state, setState] = useState<NotificationTagSettings | undefined>();

  useOnlineAuthorized(() => {
    if (!crewComDomain) {
      return;
    }

    services.loadCrewComConfig({ crewComDomain }).then(({ data }) => {
      if (!data) {
        logger.warn('Unable to communication module configuration: service failed or returned empty data - using empty defaults!', data);
        return;
      }
      setState(data);
    });
  }, [crewComDomain]);

  return state;
};

interface ContactInformation {
  email?: string;
  phone?: string;
}

const getDefaultSelectedDisabledEvents = (subscriptions: PemsSubscription[]) => subscriptions
  .filter(({ editable, defaultSelected }) => !editable && defaultSelected)
  .map(({ tag }) => tag.value)
  .reduce((acc, curr) => ({ ...acc, [curr]: true }), {});

type NewsSettingsState = {
  email: string;
  emailChecked: boolean;
  emailError: false | string;
  emailSubscriptionEnabled: boolean;
  emails: { value: string; text: string }[];
  invalid: boolean;
  loaded: boolean;
  phone: string;
  phoneError: false | string;
  phones: { value: string; text: string }[];
  pushAgreementChecked: boolean;
  pushAgreementText?: string;
  pushNotificationChecked: boolean;
  pushSubscriptionEnabled: boolean;
  selectedSubscriptions: Record<string, boolean>;
  sending: boolean;
  smsAgreementChecked: boolean;
  smsAgreementText?: string;
  smsChecked: boolean;
  smsSubscriptionEnabled: boolean;
  subscriptions: PemsSubscription[];

  onChange: (name: string, value: any) => void;
  toggleSubscription: (name: string, value: boolean) => void;
  updateSettings: () => Promise<any>;
};

const useNewsSettings = (pemsDomain: PemsDomain): NewsSettingsState => {
  const { i18n, t } = useTranslation();
  const { dirty, setDirty } = useAppCommon();
  const {
    devices, isCurrentDeviceRegistered, isPushEnabled, loadDevices, removeDevice, updateDevice,
  } = useDevice();
  const { emails, loadMyData, phones } = useMyData();
  const { profile: { agreementTexts, predefinedPhoneValue } } = useProfile();
  const {
    [LocalStorageKeys.SMS_AGREEMENT_CHECKED]: smsAgreement = false,
    [LocalStorageKeys.PUSH_NOTIFICATION_AGREEMENT_CHECKED]: pushAgreement = false, setPref,
  } = useUserPreferences();

  const [state, setState] = useState<any>({ loaded: false });
  const masterData = useMasterData(pemsDomain);
  const { domain: crewComDomain } = masterData || {};
  const crewComConfig = useCrewComConfig(crewComDomain);
  const [initialContactInfo, setInitialContactInfo] = useState<ContactInformation | undefined>();
  const [sending, setSending] = useState(false);

  const fallbackLng = (i18n.options.fallbackLng as any)[0];
  const pushAgreementText = agreementTexts?.pushNotification?.[i18n.language] || agreementTexts?.pushNotification?.[fallbackLng];
  const smsAgreementText = agreementTexts?.sms?.[i18n.language] || agreementTexts?.sms?.[fallbackLng];

  useOnlineAuthorized(() => {
    services.loadContactInfo().then(({ data }: { data: ContactInformation }) => {
      setInitialContactInfo(data);
    });

    loadDevices();

    loadMyData(MyDataType.CONTACT);
  }, [loadDevices]);

  const {
    emailSubscriptionEnabled, smsSubscriptionEnabled, pushSubscriptionEnabled,
    subscriptions: masterDataSubscriptions,
  } = masterData || {};
  const { subscriptions: crewComConfigSubscriptions } = crewComConfig || {};
  const devicesLoaded = !isPushEnabled || devices;
  const loaded = !!(masterData && emails && phones && crewComConfig && devicesLoaded && initialContactInfo);

  useEffect(() => {
    if (!loaded || state.loaded) {
      return;
    }

    const defaultSelectedDisabledSubscriptions = getDefaultSelectedDisabledEvents(masterDataSubscriptions!);
    const selectedSubscriptions = (crewComConfigSubscriptions || []).reduce((acc, subscription) => (
      // convert array of tag strings ['EVENTTYPE=eventTypeA', 'EVENTTYPE=eventTypeB', ...] ==> { eventTypeA: true, eventTypeB: true, ...}
      (subscription.tags || []).reduce((accSelectedSubscriptions, tag) => {
        const [name, value] = tag.split('=');
        return name === 'EVENTTYPE' && value ? set(accSelectedSubscriptions, value, true) : accSelectedSubscriptions;
      }, acc)
    ), defaultSelectedDisabledSubscriptions);

    const hasStoredSubscriptionWithEmail = (crewComConfigSubscriptions || [])
      .some(({ usePrimaryEmail }) => usePrimaryEmail);
    const hasStoredSubscriptionWithPhone = (crewComConfigSubscriptions || [])
      .some(({ usePrimaryPhone }) => usePrimaryPhone);

    const initState = {
      email: initialContactInfo!.email || '',
      emailChecked: !!initialContactInfo!.email && hasStoredSubscriptionWithEmail,
      emailSubscriptionEnabled: emailSubscriptionEnabled || false,
      emails: getVisibleNotificationChanelItems(emails),
      loaded: true,
      phone: initialContactInfo!.phone || (phones?.length ? '' : predefinedPhoneValue),
      phones: getVisibleNotificationChanelItems(phones),
      pushAgreementChecked: pushAgreement,
      pushNotificationChecked: isCurrentDeviceRegistered(),
      pushSubscriptionEnabled: pushSubscriptionEnabled || false,
      selectedSubscriptions,
      smsAgreementChecked: smsAgreement,
      smsChecked: !!initialContactInfo!.phone && hasStoredSubscriptionWithPhone,
      smsSubscriptionEnabled: smsSubscriptionEnabled || false,
      subscriptions: masterDataSubscriptions || [],
    };

    setState({
      ...initState,
      original: initState,
    });
  }, [
    crewComConfigSubscriptions,
    emailSubscriptionEnabled,
    emails,
    initialContactInfo,
    isCurrentDeviceRegistered,
    isPushEnabled,
    loaded,
    masterDataSubscriptions,
    phones,
    pushAgreement,
    pushSubscriptionEnabled,
    smsAgreement,
    smsSubscriptionEnabled,
    state,
    predefinedPhoneValue,
  ]);

  useEffect(() => {
    const { original, ...resultState } = state;
    setDirty(!!original && !isEqual(resultState, original));
  }, [setDirty, state]);

  const onChange = useCallback((name: string, value: any) => {
    setState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const {
    email,
    emailChecked,
    phone,
    pushAgreementChecked,
    pushNotificationChecked,
    selectedSubscriptions,
    smsAgreementChecked,
    smsChecked,
  } = state;

  const toggleSubscription = useCallback((name: string, value: boolean) => {
    onChange('selectedSubscriptions', { ...selectedSubscriptions, [name]: value });
  }, [onChange, selectedSubscriptions]);

  const updateSettings = useCallback(() => {
    setSending(true);

    const deviceRequest = () => {
      if (!pushSubscriptionEnabled) {
        return null;
      }
      return pushNotificationChecked ? updateDevice() : removeDevice();
    };

    const subscriptions = reduce<any, CrewComSubscription[]>(
      { ...getDefaultSelectedDisabledEvents(masterDataSubscriptions!), ...selectedSubscriptions },
      (acc, checked, name) => {
        if (checked) {
          acc.push({
            tags: [`EVENTTYPE=pems-client-web-app`],
            usePrimaryEmail: emailChecked,
            usePrimaryPhone: smsChecked,
            email: emailChecked ? undefined : null,
            phone: smsChecked ? undefined : null,
            useAllDevice: true,
          });
        }
        return acc;
      },
      [],
    );
    return Promise.all([
      deviceRequest(),
      // if email / phone not checked, send back the original value
      // and set usePrimaryEmail / usePrimaryPhone to false
      services.updateContactInfo({
        email: emailChecked ? email : initialContactInfo?.email,
        phone: smsChecked ? phone : initialContactInfo?.phone,
      }),
      services.updateCrewComConfig({ crewComDomain, subscriptions }),
    ])
      .then(() => {
        setDirty(false);
        setPref(LocalStorageKeys.SMS_AGREEMENT_CHECKED, smsChecked && smsAgreementChecked);
        setPref(LocalStorageKeys.PUSH_NOTIFICATION_AGREEMENT_CHECKED, pushNotificationChecked && pushAgreementChecked);
        setState(({ original, ...prevState }: any) => ({
          ...prevState,
          original: prevState,
        }));
      })
      .finally(() => {
        setSending(false);
      });
  }, [
    crewComDomain,
    email,
    emailChecked,
    initialContactInfo,
    masterDataSubscriptions,
    phone,
    pushAgreementChecked,
    pushNotificationChecked,
    pushSubscriptionEnabled,
    removeDevice,
    selectedSubscriptions,
    setDirty,
    setPref,
    smsAgreementChecked,
    smsChecked,
    updateDevice,
  ]);

  const emailError = useMemo(() => {
    const emailSchema = yup.string()
      .required(t('settings.emailRequired'))
      .email(t('settings.emailNotValid'))
      .matches(printableRule.regex, printableRule.errorMessageCreator(t));

    if (!emailChecked) {
      return false;
    }

    try {
      emailSchema.validateSync(email);
      return false;
    } catch (error) {
      return error instanceof Error ? error.message : '';
    }
  }, [email, emailChecked, t]);

  const phoneError = useMemo(() => (
    (smsChecked && !phone && t('settings.phoneRequired')) ||
    (smsChecked && !PHONE_REGEX.test(phone) && t('settings.phoneNotValid')) ||
    false
  ), [phone, smsChecked, t]);

  const invalid = !!(
    (smsChecked && smsAgreementText && !smsAgreementChecked) ||
    (pushNotificationChecked && pushAgreementText && !pushAgreementChecked) ||
    (emailSubscriptionEnabled && emailChecked && !!emailError) ||
    (smsSubscriptionEnabled && smsChecked && !!phoneError) ||
    !dirty
  );

  const result = useMemo(() => {
    const { ...resultState } = state;
    return {
      ...resultState,
      emailError,
      invalid,
      onChange,
      phoneError,
      pushAgreementText,
      sending,
      smsAgreementText,
      toggleSubscription,
      updateSettings,
    };
  }, [
    emailError,
    invalid,
    onChange,
    phoneError,
    pushAgreementText,
    sending,
    smsAgreementText,
    state,
    toggleSubscription,
    updateSettings,
  ]);

  return result;
};

export default useNewsSettings;
