import { Component, ErrorInfo as ErrInfo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import ErrorInfo from '../ErrorInfo/ErrorInfo';

import { logger } from '../../../api';

type Props = RouteComponentProps & {
  children: React.ReactNode;
  t: T;
};

type State = {
  error: any;
};

class ErrorBoundary extends Component<Props, State> {
  public state: State = { error: undefined };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidMount() {
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentDidCatch(error: Error, errorInfo: ErrInfo) {
    logger.error(error, errorInfo);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    if (!event.cancelable) {
      logger.error(event.reason);
      this.setState({ error: event.reason });
    }
  };

  private continue = () => {
    this.props.history.push('/');
    this.setState({
      error: undefined,
    });
  };

  render() {
    const { t } = this.props;

    if (this.state.error) {
      return <ErrorInfo header={t('components.errorBoundary')} info={this.state.error.toString()} onContinueClicked={this.continue} />;
    }

    return this.props.children;
  }
}

export default withTranslation()(withRouter(ErrorBoundary));
