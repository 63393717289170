import { DateTime, Interval as LuxonInterval } from 'luxon';

import { BlockMonth, Event, RosterInterval } from '../../consts';
import { convertEvent } from '../../utils';
import { DutiesModelState, EventsResult } from './DutiesModelContext';

const mapBlockMonths = (blockMonths: any[]): BlockMonth[] => blockMonths
  // Transform the block month objects
  .map((blockMonth) => ({
    ...blockMonth,
    name: blockMonth.description,
    from: DateTime.fromISO(blockMonth.from, { zone: 'utc' }).toUTC().startOf('day'),
    to: DateTime.fromISO(blockMonth.to, { zone: 'utc' }).toUTC().endOf('day'),
  }))
  // Sort them by start date in ascending order
  .sort((a, b) => a.from.toMillis() - b.from.toMillis());

export const getCurrentBlockMonth = (blockMonths: BlockMonth[], currentDate: DateTime) => blockMonths
  .find(({ from, to }) => (
    from.toMillis() < currentDate.toMillis() &&
    to.toMillis() > currentDate.toMillis()
  ));

export const getEventsCroppedForUnpublished = (events: Event[], period?: RosterInterval) => {
  if (!period) {
    return [];
  }

  const periodEnd = period.to.endOf('day');
  const periodEndInMillis = periodEnd.toMillis();
  // Cut unpublished pairings at the end of the period
  return events.map((event) => {
    const eventEndInMillis = event.toDateAtCalendar.toMillis();
    if (
      event.originalEventType !== 'PAR' ||
          eventEndInMillis <= periodEndInMillis ||
          event.events?.some((subevent) => subevent.toDateAtCalendar.toMillis() === eventEndInMillis)
    ) {
      return {
        ...event,
        toDateAtCalendarCropped: event.toDateAtCalendar,
      };
    }

    return {
      ...event,
      toDateAtCalendarCropped: periodEnd,
      croppedAtEnd: true,
    };
  });
};

const getRenderIndex = (intervalCurr: LuxonInterval, prevEvents: Event[]) => {
  const lastOverlapping = [...prevEvents].reverse()
    .filter(({ originalEventType }) => originalEventType === 'RTG')
    .find((prev) => {
      const intervalPrev = LuxonInterval.fromDateTimes(prev.fromDateTime!, prev.toDateTime!);
      return intervalPrev.overlaps(intervalCurr);
    });

  return lastOverlapping ? lastOverlapping.renderIdx! + 1 : 0;
};

export const addRenderIdForRosterTags = (events: Event[]) => events.reduce((acc: Event[], event) => {
  if (event.originalEventType !== 'RTG') {
    return [...acc, event];
  }

  const intervalCurr = LuxonInterval.fromDateTimes(event.fromDateTime!, event.toDateTime!);
  const renderIdx = getRenderIndex(intervalCurr, acc);

  return [...acc, { ...event, renderIdx }];
}, []);

export const transformEventsResponse = (result: EventsResult, profile: any): DutiesModelState => {
  const blockMonthList = mapBlockMonths(result.blockMonthList);
  const useUtc = !profile.showLocalTime && profile.showUTCTime;

  const rosterPeriod = {
    from: DateTime.fromISO(useUtc ? result.rosterPeriod.from! : result.rosterPeriod.fromLocalAtHb!).toUTC(),
    to: DateTime.fromISO(useUtc ? result.rosterPeriod.to! : result.rosterPeriod.toLocalAtHb!).toUTC(),
    crmPublishedEndDateTime:
      DateTime.fromISO(useUtc ? result.rosterPeriod.crmPublishedEndDateTime! : result.rosterPeriod.crmPuplishedEndDateTimeLocalAtHb!).toUTC(),
  };

  const events = addRenderIdForRosterTags(
    getEventsCroppedForUnpublished(
      result.events.map((event) => convertEvent(event, profile)),
      rosterPeriod,
    ),
  );

  return {
    events,
    blockMonthList,
    flightSearchLoading: false,
    flightSearchResult: [],
    openPairingStatistics: [],
    rosterPeriod,
    hasLoaded: true,
  };
};

export const calculateIntervalToShowInCalendar = (
  events: Event[],
  calendarWeekStartMonday: boolean,
  baseInterval: RosterInterval,
): RosterInterval => {
  // Start with the block month
  let interval = {
    from: baseInterval.from.startOf('day'),
    to: baseInterval.to.endOf('day'),
  };
  const newInterval = { ...interval };

  // Extend the interval with the events which intersect the block month
  events.forEach((event) => {
    const eventStart = event.fromDateAtCalendar.toMillis();
    const eventEnd = event.toDateAtCalendarCropped!.toMillis();
    const intervalStart = interval.from.toMillis();
    const intervalEnd = interval.to.toMillis();

    if (eventStart < intervalStart && eventEnd > intervalStart) {
      newInterval.from = event.fromDateAtCalendar.startOf('day');
    }
    if (eventEnd > intervalEnd && eventStart < intervalEnd) {
      newInterval.to = event.toDateAtCalendarCropped!.endOf('day');
    }
  });
  interval = newInterval;

  const getWeekDay = (date: DateTime) => (calendarWeekStartMonday ?
    date.get('weekday') :
    // n % 7 + 1
    (date.get('weekday') % 7) + 1);

  // Extend the interval to begin on the first day of the first week
  const firstDay = getWeekDay(interval.from);
  const dayCountBeforeFirstDay = firstDay - 1;
  interval.from = interval.from.minus({ days: dayCountBeforeFirstDay });

  // Extend the interval to end on the last day of the last week
  const lastDay = getWeekDay(interval.to);
  const dayCountAfterLastDay = 7 - lastDay;
  interval.to = interval.to.plus({ days: dayCountAfterLastDay });

  return interval;
};

export const getDutyList = (events: Event[], interval?: RosterInterval): Event[] => {
  if (!interval) {
    return [];
  }

  const periodStart = interval.from.toMillis();
  const periodEnd = interval.to.toMillis();

  return events
    // Filter the events to the ones which intersect the shown interval
    .filter((event) => (
      event.toDateAtCalendarCropped!.toMillis() > periodStart &&
        event.fromDateAtCalendar.toMillis() < periodEnd
    ))
    // Crop the events to the part which is inside the shown interval
    .map((event) => {
      const newEvent = { ...event, fromDateAtCalendarCropped: event.fromDateAtCalendar };

      if (event.toDateAtCalendarCropped!.toMillis() > periodEnd) {
        newEvent.toDateAtCalendarCropped = interval.to;
        newEvent.croppedAtEnd = true;
      }
      if (event.fromDateAtCalendar.toMillis() < periodStart) {
        newEvent.fromDateAtCalendarCropped = interval.from;
        newEvent.croppedAtStart = true;
      }

      return newEvent;
    });
};

export const getDaysToShowInCalendar = (interval?: RosterInterval): DateTime[] => {
  if (!interval) {
    return [];
  }

  const days = [];
  let day = interval.from;
  const endInMillis = interval.to.toMillis();
  while (day.toMillis() <= endInMillis) {
    days.push(day);
    day = day.plus({ days: 1 });
  }
  return days;
};
