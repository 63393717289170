import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  root: {
    flexShrink: 0,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.12)',
    backgroundColor: palette.common.white,
    zIndex: 4,
    color: palette.text.primary,
    fontSize: 18,
  },
  content: {
    minHeight: 48,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0 12px',
  },
  backIcon: {
    position: 'relative',
    color: palette.text.secondary,
  },
  leftIcons: {
    left: 0,
    position: 'absolute',
  },
  rightIcons: {
    right: 0,
    position: 'absolute',
    color: palette.text.secondary,
  },
  newLineIcons: {
    marginLeft: spacing(2),
  },
  title: {
    'flexGrow': 1,
    'fontSize': 18,
    'fontWeight': 500,
    'textAlign': 'center',
    'lineHeight': '20px',
    'wordBreak': 'break-word',

    '&:not(:only-child)': {
      paddingRight: 48,
      paddingLeft: 48,
    },
  },
});
