import { RefreshButton } from '../../components';
import useRosterUpdate from './useRosterUpdate';

type RefreshDutyListProps = {
  edge?: 'start';
};

const RefreshDutyList = ({ edge }: RefreshDutyListProps) => {
  const { hasUpdates, updateDuties } = useRosterUpdate();

  return (
    <RefreshButton edge={edge} hasUpdates={hasUpdates} update={updateDuties} size="small" />
  );
};

export default RefreshDutyList;
