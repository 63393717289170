import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ classPrefix = '' } : Theme) => createStyles({
  paper: {
    minWidth: 256,
  },
  paperFullScreen: {
    [`& .${classPrefix}DialogTitle-text`]: {
      'textAlign': 'center',

      // If text is aligned center, button is shown only on the rights side, then centering is not done properly
      '&:first-child:not(:last-child)': {
        marginLeft: 48,
      },
    },
  },
});
