import { Fragment } from 'react';
import { isArray, isPlainObject } from 'lodash';
import { Button, DialogContentText, Typography } from '@lsy-netline-ui/netline-ui';

import { useDialog } from '../../../hooks';
import Dialog from './Dialog';

const MessageDialog = () => {
  const { items, hideDialog } = useDialog();

  const {
    title, type, content, buttons, closeButton, actionsClassName,
  } = items[0] || {};

  const renderContent = () => {
    if (!Array.isArray(content)) {
      return <DialogContentText>{content}</DialogContentText>;
    }

    return content.map((item, idx) => {
      if (!item || typeof item !== 'object' || !('title' in item)) { // because of TS typecheck...
        // eslint-disable-next-line react/no-array-index-key
        return <DialogContentText key={idx}>{item}</DialogContentText>;
      }
      return (
        <Fragment key={item.title}>
          <Typography variant="subtitle2">{item.title}</Typography>
          {/* eslint-disable-next-line react/no-array-index-key */}
          {item.text.map((subitem: string, idx2: number) => <DialogContentText key={idx2}>{subitem}</DialogContentText>)}
        </Fragment>
      );
    });
  };

  return (
    <Dialog open={!!items.length}>
      {!!title && (
        <Dialog.Title onClose={closeButton ? hideDialog : undefined} type={type}>{title}</Dialog.Title>
      )}
      {content && (
        <Dialog.Content>{renderContent()}</Dialog.Content>
      )}
      {!!buttons && (
        <Dialog.Actions className={actionsClassName}>
          {(isArray(buttons) ? buttons : [buttons]).map((button) => {
            // if no button object is specified a simple button is created which will hide/close the dialog automatically
            const buttonObj = isPlainObject(button) ? button : {
              text: button,
              onClick: hideDialog,
            };

            const {
              id, text, onClick, ...buttonProps
            } = buttonObj;

            return (
              <Button
                key={id || text}
                data-test-id="dialog-button"
                onClick={(event: Event) => onClick(button, id, event)}
                {...buttonProps}
              >
                {text}
              </Button>
            );
          })}
        </Dialog.Actions>
      )}
    </Dialog>
  );
};

export default MessageDialog;
