import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, makeStyles } from '@lsy-netline-ui/netline-ui';
import { Close, DateRangeRounded } from '@material-ui/icons';

import { logger, services } from '@crew-webui/common/api';
import { getDateFormatted, convertDate } from '@crew-webui/common/utils';
import clsx from 'clsx';
import {
  useConnection, useCrewAuth, useDutiesModel, useFeature, useOnlineAuthorized, useProfile, useMultiColumn,
} from '@crew-webui/common/hooks';
import { BlockMonthSwitch, DatePicker } from '@crew-webui/common/view/components';

import { DateFormat, Feature } from 'consts';
import { IadpPage } from 'view/components';
import SummaryText from './SummaryText/SummaryText';
import SummaryTable from './SummaryTable/SummaryTable';

import styles from './Summary.styles';

interface TextParameter {
  name: string;
  value: string;
}

interface TableParameter {
  name: string;
  type: 'table';
  value?: {
    columns: {
      key: string;
      label: string;
    }[];
    data: any[];
  };
}

type Parameter = TextParameter | TableParameter;

interface Group {
  name: string;
  parameters: Parameter[];
}

type GroupList = Group;

interface SummaryResult {
  groups: Group[];
}

type SummaryResponse = PemsResponse<SummaryResult>;

const useStyles = makeStyles(styles, { name: 'Summary' });

function Summary() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { online } = useConnection();
  const { crmId } = useCrewAuth();
  const { activeBlockMonth, getCurrentBlockMonth, rosterPeriod } = useDutiesModel();
  const isFeatureEnabled = useFeature();
  const { profile } = useProfile();

  const multiColumn = useMultiColumn();
  const [startDate, setStartDate] = useState<string | null>(null);
  const [pickedStartDate, setPickedStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [pickedEndDate, setPickedEndDate] = useState<string | null>(null);
  const [datePicker, setDatePicker] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]);

  const closeDatePicker = () => setDatePicker(false);
  const toggleDatePicker = () => setDatePicker((prevValue) => !prevValue);

  const setDates = useCallback(() => {
    const showBlockMonthSwitch = isFeatureEnabled(Feature.VIEW_BLOCKMONTH_SWITCH_ON_SUMMARY);
    const blockMonth = showBlockMonthSwitch ? activeBlockMonth : getCurrentBlockMonth();

    if (
      !blockMonth ||
      (!showBlockMonthSwitch && startDate) ||
      (showBlockMonthSwitch && startDate === blockMonth.from.toISODate())
    ) {
      return;
    }

    if (blockMonth) {
      setStartDate(blockMonth.from.toISODate());
      setEndDate(blockMonth.to.toISODate());
      setPickedStartDate(blockMonth.from.toISODate());
      setPickedEndDate(blockMonth.to.toISODate());
    }
  }, [activeBlockMonth, getCurrentBlockMonth, isFeatureEnabled, startDate]);

  const loadData = useCallback(() => {
    if (!startDate || !endDate) {
      return;
    }

    const start = convertDate(startDate).startOf('day').toFormat(DateFormat.DATETIME_ISO);
    const end = convertDate(endDate).endOf('day').toFormat(DateFormat.DATETIME_ISO);

    services
      .loadSummaryData({ crmId, start, end })
      .then(({ data }: { data: SummaryResponse }) => {
        const { result, success } = data;

        if (success && result) {
          setGroups(result.groups);
        }
      })
      .catch((error) => logger.warn('Error occured while loading summary data!', error));
  }, [crmId, endDate, startDate]);

  useEffect(() => {
    setDates();
  }, [setDates]);

  useOnlineAuthorized(() => {
    loadData();
  }, [loadData]);

  const updateSummaryData = () => {
    setDatePicker(false);
    setStartDate(pickedStartDate);
    setEndDate(pickedEndDate);
  };

  const renderParameters = (parameters: Parameter[], highlightExpiration: boolean) => {
    if (!Array.isArray(parameters)) {
      return null;
    }

    return parameters.map((parameter) => {
      if ('type' in parameter && parameter.type === 'table') {
        return <SummaryTable key={parameter.name} tableData={parameter} />;
      }
      if (typeof parameter.value === 'string') {
        return (
          <SummaryText
            key={parameter.name}
            highlightExpiration={highlightExpiration}
            periodEnd={endDate!}
            textData={parameter as TextParameter}
          />
        );
      }
      return null;
    });
  };

  function filterGroups(group: GroupList) {
    if (!group.parameters.length && profile.hiddenHeadersIfNoData.includes(group.name)) {
      return false;
    }

    return true;
  }

  const filteredGroups = groups.filter(filterGroups);

  const renderGroups = () => {
    const { groupsToHighlightExpiration = [] } = profile.summary || {};

    return filteredGroups.map((record: GroupList) => (
      <div key={record.name} className={classes.summarySection} data-test-id="summary-section">
        <div className={classes.summaryRow} data-test-id="summary-section-title">
          <div className={classes.title}>{record.name}</div>
        </div>
        {renderParameters(record.parameters, groupsToHighlightExpiration.includes(record.name))}
      </div>
    ));
  };

  const loaded = !!groups?.length;

  const startDateFormatted = startDate && getDateFormatted(profile, startDate);
  const endDateFormatted = endDate && getDateFormatted(profile, endDate);
  const range = (startDateFormatted && endDateFormatted && `${startDateFormatted} - ${endDateFormatted}`) || '';
  const showBlockMonthSwitch = isFeatureEnabled(Feature.VIEW_BLOCKMONTH_SWITCH_ON_SUMMARY);
  const title = showBlockMonthSwitch ? t('summary.title') : `${t('summary.title')} ${range}`;

  return (
    <IadpPage
      dataLoaded={loaded}
      headerProps={{
        actions: !showBlockMonthSwitch && !datePicker && (
          <IconButton data-test-id="open-sample-range-button" onClick={toggleDatePicker}>
            <DateRangeRounded />
          </IconButton>
        ),
        bottomContent: showBlockMonthSwitch && (
          <BlockMonthSwitch className={clsx(classes.blockMonth, { [classes.blockMonthMobile]: !multiColumn })} />
        ),
        title,
      }}
      submitButtonProps={datePicker && loaded ? {
        'data-test-id': 'sample-range-apply-button',
        'disabled': !online,
        'onClick': updateSummaryData,
        'children': t('button.apply'),
      } : undefined}
    >
      {datePicker && loaded && rosterPeriod && pickedStartDate && pickedEndDate && (
        <div className={classes.calendarContainer}>
          <div className={classes.sampleRangeCalendarHeaderContainer}>
            <IconButton data-test-id="sample-range-close-button" onClick={closeDatePicker}>
              <Close />
            </IconButton>
            <div className={classes.sampleRangeText} data-test-id="sample-range-title">{t('summary.editTitle')}</div>
          </div>

          <div>
            <div className={classes.sampleRangeCalendar}>
              <div className={classes.sampleRangeCalendarLabel} data-test-id="sample-range-from-title">{t('summary.from')}</div>
              <DatePicker
                disableToolbar
                maxDate={pickedEndDate}
                onChange={setPickedStartDate}
                value={pickedStartDate}
                variant="static"
              />
            </div>
            <div className={classes.sampleRangeCalendar}>
              <div className={classes.sampleRangeCalendarLabel} data-test-id="sample-range-to-title">{t('summary.to')}</div>
              <DatePicker
                disableToolbar
                maxDate={rosterPeriod.to.plus({ days: 30 }).toISODate()}
                minDate={pickedStartDate}
                onChange={setPickedEndDate}
                value={pickedEndDate}
                variant="static"
              />
            </div>
          </div>
        </div>
      )}

      {!datePicker && startDate && renderGroups()}
    </IadpPage>
  );
}

export default Summary;
