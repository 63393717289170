import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ breakpoints, spacing }: Theme) => createStyles({
  root: {
    flex: '1',
    overflow: 'auto',
    height: '100%',
    padding: '0 2px 0 10px',
    boxSizing: 'border-box',
  },
  multiColumn: {
    [breakpoints.up('md')]: {
      '&:not($pairingListContainer)': {
        'paddingRight': spacing(2.5),
        'paddingLeft': spacing(2.5),

        '& $dutyRowContainer': {
          paddingBottom: spacing(2.5),
        },
      },
    },
  },
  detailView: {
    '&&, &&& $dutyRowContainer': {
      padding: 0,
    },
  },
  dutyRowContainer: {
    paddingTop: 7,
    paddingBottom: 7,
    height: '100%',
    width: '100%',
  },
  pairingListContainer: {
    padding: 0,
    overflow: 'hidden',
  },
});
