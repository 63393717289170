import { Contact, MyDataContactType, MyDataPhoneTypes } from '../../consts';

export const mapEmailsForNotifications = (contacts: Contact[] | undefined) => contacts
  ?.filter(({ natKey: { type } }) => type === MyDataContactType.EMAIL)
  ?.sort((a, b) => a.natKey.prio - b.natKey.prio)
  ?.map(({ contact }) => contact)
  ?.filter((contact): contact is string => !!contact);

export const mapPhonesForNotifications = (contacts: Contact[] | undefined, preDefinedPhoneValue: string) => contacts
  ?.filter(({ natKey: { type } }) => MyDataPhoneTypes.includes(type))
  ?.sort((a, b) => a.natKey.prio - b.natKey.prio)
  ?.map(({ phoneNumber }) => {
    if (!phoneNumber) {
      return undefined;
    }

    let regionCode = (phoneNumber.regionCode || preDefinedPhoneValue).replace(/^00/, '+');
    if (!regionCode.startsWith('+')) {
      regionCode = `+${regionCode}`;
    }
    return `${regionCode}${phoneNumber.areaCode}${phoneNumber.localNo}`.replace(/[^+0-9]/g, '');
  })
  ?.filter((contact): contact is string => !!contact);
