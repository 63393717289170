import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { get, merge, set } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import { FeatureAccess, MyDataCategory, MyDataType } from '@crew-webui/common/consts';
import {
  useAppCommon, useConnection, useFeature, useMyData, useOnlineAuthorized, useProfile,
} from '@crew-webui/common/hooks';
import { OfflineEditInfo } from '@crew-webui/common/view/components';

import {
  Feature, MyDataFieldType, PersonalDataFieldPaths, titles,
} from 'consts';
import { getValidationSchema, hasCountryStates } from 'utils';
import { IadpPage } from 'view/components';
import MyDataList from '../MyDataList/MyDataList';
import MyDataFields from '../MyDataFields/MyDataFields';
import getPersonalDataFields from './personalDataFields';
import useEnabledPersonalDataFields from './useEnabledPersonalDataFields';

const formId = 'my-data-personal-data-form';

const PersonalData = () => {
  const { t } = useTranslation();
  const isFeatureEnabled = useFeature();
  const { loadMyData, personalData, updateMyData } = useMyData();
  const { online } = useConnection();
  const { setDirty } = useAppCommon();
  const { profile: { disabledPersonalDataFields }, profile } = useProfile();
  const enabledPersonalDataFields = useEnabledPersonalDataFields();

  const [fields, setPersonalDataFields] = useState<MyDataFieldType[]>([]);

  const editingEnabled = isFeatureEnabled(Feature.IDP_MY_DATA, FeatureAccess.WRITE) &&
    !profile.readonlyMyDataCategories.includes(MyDataCategory.PERSONAL);

  // Init form state
  const {
    control,
    formState: {
      errors, isDirty, isSubmitted, isSubmitting,
    },
    handleSubmit,
    reset,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(getValidationSchema(fields)),
  });

  // Set "fields" state
  const birthCountryValue = watch(PersonalDataFieldPaths.birthCountry, false);
  useEffect(() => {
    // revalidate State of birth when Country of birth changes
    if (isSubmitted) {
      trigger(PersonalDataFieldPaths.birthState);
    }
    const newFields = getPersonalDataFields(t)
    // Filter based on server config
      .filter((data) => {
        if (Object.keys(enabledPersonalDataFields).includes(data.valuePath)) {
          return enabledPersonalDataFields[data.valuePath];
        }
        return true;
      })
      .map((field) => {
        if (disabledPersonalDataFields?.includes(field.valuePath)) {
          return {
            ...field, disabled: true, validation: null,
          };
        }
        if (field.valuePath === PersonalDataFieldPaths.birthState) {
          return { ...field, disabled: !hasCountryStates(birthCountryValue), validation: null };
        }
        return field;
      });
    setPersonalDataFields(newFields);
  }, [disabledPersonalDataFields, isSubmitted, trigger, birthCountryValue, t, enabledPersonalDataFields]);

  // Fetch data
  useOnlineAuthorized(() => {
    loadMyData(MyDataType.INFO);
  }, [loadMyData]);

  // Synchronize "dirty" global state
  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty, setDirty]);

  // Reset values
  useEffect(() => {
    const newDefaultValues = {};

    getPersonalDataFields(t).forEach(({ date, valuePath }) => {
      let value = get(personalData, valuePath, date ? null : '');
      if (typeof value === 'boolean') {
        value = value.toString();
      }
      set(newDefaultValues, valuePath, value);
    });

    reset(newDefaultValues);
  }, [personalData, reset, t]);

  // Handle form submission
  const onSubmit = async (data: any) => {
    const mergedPersonalData = merge(
      {
        emplNo: '', emplPeriod: {}, homebases: [], personalId: '', title: '',
      },
      personalData,
      data,
    );
    mergedPersonalData.isSmoker = mergedPersonalData.isSmoker === 'true';
    if (personalData) {
      await updateMyData(MyDataType.INFO, mergedPersonalData, personalData);
    }
    loadMyData(MyDataType.INFO);
  };

  const label = t('myData.personalData.subtitle');
  const subtitle = t('myData.personalData.title');

  return (
    <IadpPage
      dataLoaded={!!personalData}
      headerProps={{ showBackButton: true, subtitle, title: t(titles.profile) }}
      submitButtonProps={!editingEnabled ? undefined : {
        'children': t('myData.saveChanges'),
        'data-test-id': 'submit-personal-data-button',
        'disabled': !online || !isDirty || isSubmitting || !!Object.keys(errors).length,
        'form': formId,
        'type': 'submit',
      }}
    >
      {personalData && !editingEnabled && <MyDataList data={personalData} label={label} list={fields} />}
      {editingEnabled && personalData && (
        <form id={formId} noValidate onSubmit={handleSubmit(onSubmit)}>
          <OfflineEditInfo />
          <MyDataFields
            control={control}
            editing
            errors={errors}
            fields={fields}
            label={label}
          />
        </form>
      )}
    </IadpPage>
  );
};

export default PersonalData;
