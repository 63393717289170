import defaultTheme from './defaultTheme';
import { CustomerTheme } from './themeTypes';

const ryrTheme: CustomerTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#6E6E6E',
      contrastText: '#FFFF00',
    },
    unreadCardBackgroundColor: '#C6E2FF',
    messageStatusIconColor: '#0000FF',
    placeholderColor: '#E0E0E0',
    calendar: {
      ...defaultTheme.palette.calendar,
      suspendedDay: {
        ...defaultTheme.palette.calendar.suspendedDay,
        textAndIconColor: '#FFFF00',
      },
    },
    dutyList: {
      ...defaultTheme.palette.dutyList,
      defaultIconColor: '#000000',
      subeventIconOutlineColor: '#000000',
    },
    events: {
      ...defaultTheme.palette.events,
      par: {
        backgroundColor: '#0000FF',
        textColor: '#FFFFFF',
      },
      leg: {
        backgroundColor: '#0000FF',
        textColor: '#FFFFFF',
      },
      rtg: {
        backgroundColor: '#4cd9ea',
      },
      sby: {
        backgroundColor: '#FFA500',
        textColor: '#FFFFFF',
      },
      fpr: {
        backgroundColor: '#C6E2FF',
        textColor: '#000000',
      },
      gpr: {
        backgroundColor: '#FF8DA1',
        textColor: '#FFFFFF',
      },
      susp: {
        backgroundColor: '#FFFF00',
        borderColor: '#FFFF00',
        textColor: '#000000',
      },
      vac: {
        backgroundColor: '#E0E0E0',
        textColor: '#6E6E6E',
      },
      sick: {
        backgroundColor: '#E0E0E0',
        textColor: '#6E6E6E',
      },
      ground: {
        backgroundColor: '#AB82FF',
        textColor: '#FFFFFF',
      },
      off: {
        backgroundColor: '#E0E0E0',
        textColor: '#6E6E6E',
      },
      sim: {
        backgroundColor: '#AB82FF',
        textColor: '#000000',
      },
      other: {
        backgroundColor: '#00CD00',
        textColor: '#FFFFFF',
      },
      neutral: {
        backgroundColor: '#00CD00',
        textColor: '#FFFFFF',
      },
      textIcon: {
        backgroundColor: '#EEEEEE',
        textColor: '#000000',
      },
    },
    iadp: {
      ...defaultTheme.palette.iadp,
      borderColor: '#000000',
      rosterChangeDetails: {
        oldDutyTextColor: '#FF0000',
        newDutyTextColor: '#00CD00',
      },
    },
  },
};

export default ryrTheme;
