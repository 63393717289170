import {
  useReducer, useCallback, useEffect, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  logger, reducerLogger, reload, ServerConfigHandler,
} from '../../api';
import { translateErrorMessage } from '../../utils';

import { useConnection } from '../Connection';
import { useDialog } from '../Dialog';

import { checkIfAlreadyLoggedIn, setAlreadyLoggedIn } from './authUtils';
import reducer, { ActionType, AuthState, initialState } from './reducer';
import useKeycloakService from './useKeycloakService';

const loggedReducer = reducerLogger(reducer);
const keycloakInitConfig = {};

const timeoutPromise = () => new Promise((resolve) => {
  setTimeout(resolve, 500);
});

export const transformKeycloakUser = (user: any, skipLogin?: boolean) => (skipLogin ? {
  firstName: 'Test',
  lastName: 'User',
  userName: 'Test user',
  credentials: {
    userId: 'ID',
  },
  crmId: '123456',
} : {
  ...user,
  userName: user.preferred_username,
  credentials: {
    userId: user.logicalId,
  },
  crmId: user.crewCode,
});

const useKeycloakAuthService = (oidcConfig: OidcConfigType, loadPemsUser?: () => Promise<any>) => {
  const { t } = useTranslation();
  const skipLogin = useMemo(() => ServerConfigHandler.getUsedServer()?.skipLogin, []);
  const { showDialog, hideDialog } = useDialog();
  const { online } = useConnection();
  const {
    keycloakInitialized, authenticated, initKeycloak, initializeError, keycloakLogout, loadUserInfo, getToken,
  } = useKeycloakService(oidcConfig, keycloakInitConfig, online, logger, skipLogin);

  const [state, dispatch] = useReducer(loggedReducer, initialState);
  const { authState } = state;

  const logout = useCallback(async ({ suppressNotification = false } = {}) => {
    logger.info('Logging out...');
    ServerConfigHandler.onLogout();
    try {
      if (!skipLogin) {
        await Promise.race([keycloakLogout(), timeoutPromise()]);
      }
    } catch (err) {
      logger.error('Error while logout', err);
    }
    reload();
    dispatch({ type: ActionType.API_INIT, authState: AuthState.LOGGING_OUT });

    return new Promise((resolveCompleteLogout) => {
      dispatch({
        type: ActionType.API_SUCCESS,
        authState: AuthState.LOGGED_OUT,
        oidcToken: undefined,
        resolveCompleteLogout,
        suppressLogoutNotification: !!suppressNotification,
      });
      logger.info('Logout was successful.');
      resolveCompleteLogout(undefined);
    });
  }, [dispatch, keycloakLogout, skipLogin]);

  const handleSessionExpired = useCallback(async () => {
    dispatch({ type: ActionType.API_INIT, authState: AuthState.SESSION_EXPIRED });
    showDialog({
      title: t('common.warning'),
      content: translateErrorMessage(t, 'authentication.certificate.expired'),
      buttons: {
        text: t('button.ok'),
        onClick: () => {
          logout();
          hideDialog();
        },
      },
      type: 'warning',
    });
  }, [dispatch, hideDialog, showDialog, logout, t]);

  const authorize = useCallback(async () => {
    try {
      logger.info('Authorizing session...');
      dispatch({
        type: ActionType.API_INIT,
        authState: AuthState.AUTHORIZING,
      });
      const user = loadPemsUser ? await loadPemsUser() : transformKeycloakUser(await loadUserInfo(), skipLogin);
      logger.info('Session authorization was successful.', user);
      dispatch({
        type: ActionType.API_SUCCESS,
        authState: AuthState.AUTHORIZED,
        loginClientTimestamp: new Date().toISOString(),
        user,
      });
      return user;
    } catch (error) {
      logger.error('Error occurred while authorizing session!', error);
      return error;
    }
  }, [loadPemsUser, loadUserInfo, skipLogin]);

  useEffect(() => {
    if (skipLogin || keycloakInitialized || authState !== AuthState.INIT) {
      return;
    }
    // offline login or init keycloak
    if (!online) {
      // check if logged in if offline
      if (!checkIfAlreadyLoggedIn(showDialog, hideDialog, t, () => reload())) {
        return;
      }
      logger.info('Start login in offline mode without token, session expired will shown if getting online');
      dispatch({ type: ActionType.API_SUCCESS, authState: AuthState.AUTHENTICATED });
    } else {
      initKeycloak()?.then(() => logger.debug('Keycloak initialized'));
    }
  }, [initKeycloak, keycloakInitialized, authState, showDialog, skipLogin, hideDialog, t, online]);

  useEffect(() => {
    if (authState === AuthState.INIT) {
      if (skipLogin) {
        dispatch({ type: ActionType.API_SUCCESS, authState: AuthState.AUTHENTICATED });
        return;
      }
      if (keycloakInitialized) {
        dispatch({ type: ActionType.API_INIT, authState: AuthState.AUTHENTICATING });
      } else if (initializeError) {
        // TODO
      }
    }
  }, [authState, initializeError, keycloakInitialized, skipLogin]);

  useEffect(() => {
    if (authState !== AuthState.AUTHENTICATING) {
      return;
    }
    if (authenticated) {
      setAlreadyLoggedIn();
      dispatch({ type: ActionType.API_SUCCESS, authState: AuthState.AUTHENTICATED });
    }
  }, [authState, authenticated]);

  useEffect(() => {
    if (authState === AuthState.AUTHENTICATED) {
      authorize();
    }
  }, [authState, authorize]);

  return {
    ...state,
    authenticated: authenticated || skipLogin,
    oidcEnabled: true,
    handleSessionExpired,
    getToken,
    logout,
    loadUserInfo,
  };
};

export default useKeycloakAuthService;
