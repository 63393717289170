import { Refresh as RefreshIcon } from '@material-ui/icons';
import { IconButton } from '@lsy-netline-ui/netline-ui';

import { useConnection } from '../../../hooks';
import NotificationBadge from '../NotificationBadge/NotificationBadge';

type RefreshButtonProps = {
  edge?: 'start';
  size?: 'small' | 'medium';
  hasUpdates: boolean;
  update: () => void;
};

const RefreshButton = ({
  hasUpdates, update, size, ...otherProps
}: RefreshButtonProps) => {
  const { online } = useConnection();

  return (
    <IconButton data-test-id="refresh-button" disabled={!online} onClick={update} size={size} {...otherProps}>
      <NotificationBadge show={hasUpdates}>
        <RefreshIcon />
      </NotificationBadge>
    </IconButton>
  );
};

export default RefreshButton;
