import { createContext } from 'react';

type UserPreferencesContextType = {
  hasLoaded: boolean;
  setPref: (key: string, value: any) => void;
  [key: string]: any;
};

const UserPreferencesContext = createContext<UserPreferencesContextType>({
  hasLoaded: true,
  setPref: () => {},
});

export default UserPreferencesContext;
