import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  checkinStatusLabel: {
    color: palette.iadp.primaryLabelTextColor,
    fontSize: 14,
    fontWeight: 500,
    margin: '40px 0',
    textAlign: 'center',
  },
  crewMemberLabel: {
    color: palette.iadp.primaryLabelTextColor,
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase',
    marginBottom: 16,
    marginTop: 4,
  },
  pageTitle: {
    marginBottom: spacing(3),
  },
});
