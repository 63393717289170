import { useEffect, useMemo, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  DragDropContext, Droppable, Draggable, OnDragEndResponder,
} from 'react-beautiful-dnd';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';

import { Event, EventContext } from '../../../consts';
import {
  useDutiesPresentation, useMultiColumn, useProfile, useTodayOnServer,
  useRemember,
} from '../../../hooks';
import { isEventCurrent, scrollToEvent } from '../../../utils';
// eslint-disable-next-line import/no-cycle
import DutyRow from '../DutyRow/DutyRow';
import styles from './DutyList.styles';

const useStyles = makeStyles(styles, { name: 'DutyList' });

type Props = {
  availableEventsView?: boolean;
  className?: string;
  detailView?: boolean;
  onDragEnd?: OnDragEndResponder;
  DutyRowSelection?: React.ComponentType<{ context: EventContext; event: Event; parentId?: number }>;
  enabledFeatures: Record<string, boolean>;
  events: Event[];
  handleExpandedLocally?: boolean;
  infinityScrollableContainerId?: string;
  loadNext?: () => void;
  pairingList?: boolean;
  parentId?: number;
  selectionView?: boolean;
  showRosterTags?: boolean;
  subevents?: boolean;
};

const DutyList = ({
  availableEventsView = false,
  className,
  detailView = false,
  onDragEnd,
  DutyRowSelection,
  enabledFeatures,
  events,
  handleExpandedLocally,
  infinityScrollableContainerId,
  loadNext,
  pairingList,
  parentId,
  selectionView,
  showRosterTags = false,
  subevents = false,
}: Props) => {
  const classes = useStyles();
  const { eventToScroll, setEventToScroll } = useDutiesPresentation();
  const multiColumn = useMultiColumn();
  const { profile: { showLocalTime, showUTCTime } } = useProfile();
  const { storeScroll, restoreScroll } = useRemember();
  const getTodayOnServer = useTodayOnServer();
  const rootRef = useRef<HTMLDivElement>(null);

  const supportAutoScroll = !(subevents || detailView || selectionView || availableEventsView);
  const supportInfiniteScroll = !!loadNext;

  const filteredEvents = useMemo(() => events.filter((event) => (
    event.originalEventType !== 'DREM' && (event.originalEventType !== 'RTG' || showRosterTags)
  )), [events, showRosterTags]);

  const handleDragEnd = onDragEnd || (() => {});
  useEffect(() => {
    if (!supportAutoScroll) {
      return undefined;
    }

    restoreScroll('dutyList', rootRef);
    const unsubscribe: any = storeScroll('dutyList', rootRef);

    return () => unsubscribe();
  // run only after first mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!supportAutoScroll) {
      return;
    }
    if (eventToScroll !== null && eventToScroll !== undefined) {
      scrollToEvent(eventToScroll);
      setEventToScroll(undefined);
    }
  }, [eventToScroll, setEventToScroll, supportAutoScroll]);

  const renderEvents = () => {
    let drawLine = false;
    const currentTime = getTodayOnServer(!showLocalTime && showUTCTime);
    const renderedEvents = filteredEvents.map((event: Event, eventIndex: number) => {
      if (event.originalEventType === 'C_I' && pairingList) {
        drawLine = true;
      } else if (eventIndex === filteredEvents.length - 1) {
        drawLine = false;
      }

      if (event.originalEventType === 'DREM') {
        return null;
      }

      const currentEvent = !!subevents && !selectionView && !availableEventsView && !detailView &&
        isEventCurrent(currentTime, event, filteredEvents[eventIndex + 1]);
      if (!onDragEnd) {
        return (
          <DutyRow
            key={event.id}
            availableEventsView={availableEventsView}
            crewMemberPartOfFlight={event.crewMemberPartOfFlight}
            currentEvent={!!currentEvent}
            detailView={detailView}
            drawline={drawLine}
            DutyRowSelection={DutyRowSelection}
            event={event}
            enabledFeatures={enabledFeatures}
            handleExpandedLocally={handleExpandedLocally}
            pairingListItem={pairingList}
            parentId={parentId}
            rowKey={eventIndex}
            selectionView={selectionView}
            subevents={subevents}
          />
        );
      }

      return (
        <Draggable key={event.id} draggableId={`${event.id}`} index={eventIndex} isDragDisabled={!onDragEnd} data-test-id={`draggable-outer-${event.id}`}>
          {(provided) => (
            <div
              id={`draggable-container-${event.id}`}
              data-test-id={`draggable-container-${event.id}`}
              style={{ margin: '4px 0px' }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <DutyRow
                key={event.id}
                availableEventsView={availableEventsView}
                crewMemberPartOfFlight={event.crewMemberPartOfFlight}
                currentEvent={!!currentEvent}
                detailView={detailView}
                drawline={drawLine}
                DutyRowSelection={DutyRowSelection}
                event={event}
                enabledFeatures={enabledFeatures}
                handleExpandedLocally={handleExpandedLocally}
                pairingListItem={pairingList}
                parentId={parentId}
                rowKey={eventIndex}
                selectionView={selectionView}
                subevents={subevents}
              />
            </div>
          )}
        </Draggable>
      );
    });

    if (supportInfiniteScroll) {
      return (
        <AutoSizer>
          {({ height }: Size) => (
            <InfiniteScroll
              dataLength={filteredEvents.length}
              hasMore
              loader={null}
              next={loadNext}
              scrollableTarget={infinityScrollableContainerId}
              height={height}
            >
              {renderedEvents}
            </InfiniteScroll>
          )}
        </AutoSizer>
      );
    }

    return renderedEvents;
  };

  return (
    <div
      ref={rootRef}
      className={
        clsx(classes.root, className, {
          [classes.pairingListContainer]: pairingList,
          [classes.multiColumn]: multiColumn,
          [classes.detailView]: detailView,
        })
      }
      data-test-id="duty-list"
    >
      { handleDragEnd ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" data-test-id="droppable-outer-container">
            {(provided: any) => (
              <div
                id="droppable-container"
                data-test-id="droppable-container"
                className={clsx({ [classes.dutyRowContainer]: !pairingList })}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {renderEvents()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className={clsx({ [classes.dutyRowContainer]: !pairingList })}>
          {renderEvents()}
        </div>
      )}

    </div>
  );
};

export default DutyList;
