import {
  ChangeEvent, Fragment, UIEventHandler, MouseEventHandler,
} from 'react';
import { Checkbox, makeStyles } from '@lsy-netline-ui/netline-ui';
import { Check } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { useProfile } from '@crew-webui/common/hooks';
import { SubmitButton } from '@crew-webui/common/view/components';

import { SelectionMode } from 'consts';
import RosterChange from './RosterChange';
import styles from './NotificationConfirmationContent.styles';

const useStyles = makeStyles(styles, { name: 'NotificationConfirmationContent' });

type ConfirmationProps = {
  allChecked: boolean;
  containerRef: any;
  disableCheckBox: boolean;
  disabledConfirm: boolean;
  fromDateTimeUTC: string;
  handleIsScrollOver: UIEventHandler<HTMLDivElement>;
  onConfirmTap: MouseEventHandler<HTMLButtonElement>;
  onSelectAllChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onSelectChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  rosterChanges: {
    changes: any[];
  };
  selectedChanges: string[];
  confirmableChanges: any[];
  verticalView: boolean;
};

const NotificationConfirmationContent = ({
  allChecked,
  containerRef,
  disableCheckBox,
  disabledConfirm,
  fromDateTimeUTC,
  handleIsScrollOver,
  onConfirmTap,
  onSelectAllChange,
  onSelectChange,
  rosterChanges,
  selectedChanges,
  confirmableChanges,
  verticalView,
}: ConfirmationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    profile: { notificationConfirmationSelectionMode },
  } = useProfile();

  const { changes } = rosterChanges;

  return (
    <>
      {notificationConfirmationSelectionMode !== SelectionMode.ALL && (
        <div className={classes.selectAll} data-test-id="select-all">
          {t('notificationConfirmation.changeInfo', { selectedChangesNo: selectedChanges.length, changesNo: confirmableChanges?.length })}
          <Checkbox
            data-test-id="select-all-checkbox"
            disabled={disableCheckBox}
            onChange={onSelectAllChange}
            checked={allChecked}
            color="default"
            classes={{ root: classes.checkBoxAll }}
          />
        </div>
      )}
      <div ref={containerRef} className={classes.changesContainer} onScroll={handleIsScrollOver}>
        <div data-test-id="roster-changes-list" id="changes" className={classes.changes}>
          {changes && changes.map((change) => (
            <Fragment key={change.date}>
              <RosterChange
                disabledCheckBox={disableCheckBox}
                change={change}
                value={change.date}
                checked={selectedChanges.includes(change.date)}
                onCheckBoxChange={onSelectChange}
                selectedEventDate={fromDateTimeUTC}
                verticalView={verticalView}
              />
              <div className={classes.separator} />
            </Fragment>
          ))}
        </div>
      </div>
      <SubmitButton
        className={classes.selectButton}
        data-test-id="roster-changes-submit-button"
        disabled={disabledConfirm}
        onClick={onConfirmTap}
        startIcon={<Check className={classes.check} />}
      >
        {notificationConfirmationSelectionMode === SelectionMode.ALL ?
          t('notificationConfirmation.confirmChanges') :
          t('notificationConfirmation.confirmSelectedChanges', { selectedChangesNo: selectedChanges.length })}
      </SubmitButton>
    </>
  );
};

export default NotificationConfirmationContent;
