import { makeStyles } from '@lsy-netline-ui/netline-ui';

import styles from './RawEventDetails.styles';

const useStyles = makeStyles(styles, { name: 'RawEventDetails' });

type Props = {
  className?: string;
  items?: string[];
};

const RawEventDetails = ({ className, items }: Props) => {
  const classes = useStyles();

  return (
    <div className={className} data-test-id="details-text">
      {items?.map((detailRow, detailKey) => (
        // eslint-disable-next-line react/no-array-index-key
        <pre key={detailKey} className={classes.detailsText} dangerouslySetInnerHTML={{ __html: detailRow?.trimEnd() || ' ' }} />
      ))}
    </div>
  );
};

export default RawEventDetails;
