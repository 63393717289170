import { createStyles } from '@lsy-netline-ui/netline-ui';

export default () => createStyles({
  root: {
    position: 'fixed',
    zIndex: 9999,
    top: 'calc(50% - 25px)',
    left: 'calc(50% - 25px)',
  },
});
