import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  root: {
    flexShrink: 0,
    paddingRight: spacing(2),
    paddingLeft: spacing(2),
    backgroundColor: palette.dutyList.headerBackgroundColor,
  },
  container: {
    height: 56,
  },
  rightBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  leftBlock: {
    'display': 'flex',
    'margin-right': 'auto',
    'margin-left': spacing(2),
    '& svg': {
      color: 'white',
    },
  },
  button: {
    marginRight: -spacing(1.5),
  },
});
