// Disable iOS context menu which would appear on some elements like buttons
// based on http://tjwoon.com/article/disabling-text-selection-and-the-context-menu-in-hybrid-mobile-apps
import './disableContextMenu.css';

document.addEventListener('click', (event) => {
  const target = event.target as Element;

  if (!target.closest('input, textarea')) {
    document.querySelectorAll('input, textarea').forEach((formElement) => {
      (formElement as HTMLInputElement).blur();
    });
  }
});
