import { createStyles } from '@lsy-netline-ui/netline-ui';

import { CALENDAR_SPACING_TOP } from '../../../consts';

export default ({
  palette, shadows, spacing, classPrefix = '',
}: Theme) => createStyles({
  ownColumn: {
    'display': 'flex',
    'flexDirection': 'column',

    '& $headerRoot': {
      backgroundColor: palette.calendar.headerBackgroundColor,

      [`&, & .${classPrefix}MuiIconButton-root`]: {
        color: palette.common.white,
      },
      [`& .${classPrefix}MuiButton-root`]: {
        color: palette.common.white,
        border: '1px solid white',
      },
    },
    '& $titleRow': {
      height: 56,
    },
    '& $calendarContainer': {
      maxHeight: 'unset',
      flex: '1 1',
      boxShadow: 'none',
      backgroundColor: palette.calendar.columnBackgroundColor,
      borderRadius: 0,
    },
    '& $collapseWrapper': {
      height: '100%',
      padding: `${CALENDAR_SPACING_TOP}px ${spacing(2)}px ${spacing(2)}px`,
    },
    '& $collapseContainer': {
      flexShrink: 0,
    },
    '& $months': {
      'borderRadius': 4,
      'boxShadow': shadows[1],

      '&$months': {
        height: '100%',
        padding: `${spacing(2)}px ${spacing(1)}px ${spacing(1)}px`,
      },
    },
  },
  fullyOpen: {
    'position': 'absolute',
    'top': 0,
    'height': '100%',
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',

    '& $calendarContainer': {
      flexGrow: 1,
      flexShrink: 1,
      maxHeight: 'unset',
    },
    '& $months': {
      height: '100% !important',
    },
    ' & $collapseContainer': {
      height: '100% !important',
      overflowY: 'auto',
    },
  },
  calendarContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.background.secondary,
    borderRadius: '0px 0px 12px 12px',
    fontSize: 16,
    color: palette.text.primary,
    boxShadow: '0 2px 0px 0 rgba(0,0,0,0.12)',
    overflowY: 'auto',
    width: '100%',
    minHeight: 0,
    maxHeight: '47vh',
    zIndex: 10,
    flexShrink: 0,
  },
  collapseContainer: {
    width: '100%',
  },
  collapseWrapper: {},
  headerRoot: {},
  titleRow: {
    overflow: 'hidden',
  },
  months: {},
});
