import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  fieldset: {
    paddingTop: 0,
  },
  radioGroup: {
    marginTop: spacing(2.5),
  },
  inlineRadio: {
    display: 'flex',
    padding: `${spacing(2)}px ${spacing(0.5)}px`,
    borderTop: `1px solid ${palette.iadp.borderColor}`,
    justifyContent: 'space-between',
  },
  label: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
  },
  labelRoot: {
    alignItems: 'flex-start',
    gap: spacing(1),
  },
  radioLabel: {
    paddingTop: 5,
    display: 'flex',
    flexDirection: 'column',
  },
});
