import { createStyles } from '@lsy-netline-ui/netline-ui';

export default () => createStyles({
  contentClass: {
    '&&': {
      paddingRight: 0,
      paddingLeft: 0,
    },
    '&:not($multiColumn)': {
      paddingTop: 0,
    },
  },
  multiColumn: {},
});
