import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  errorHeader: {
    marginTop: 30,
    color: palette.text.secondaryLabel,
    fontSize: 16,
    fontWeight: 500,
  },
  errorInfo: {
    marginTop: 20,
    marginBottom: 20,
    color: palette.text.secondaryLabel,
    fontSize: 14,
  },
});
