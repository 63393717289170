import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  selectAll: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: '0 0 40px',
    backgroundColor: palette.iadp.checkboxColors.onRosterChanges.background,
    color: palette.iadp.checkboxColors.onRosterChanges.color,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.5,
    paddingRight: spacing(1) - 1 /* checkbox padding */,
    textAlign: 'right',
  },
  selectButton: {
    'paddingTop': 12,
    'paddingBottom': 12,

    '& $check': {
      fontSize: 24,
    },
  },
  check: {},
  changesContainer: {
    flex: '1 1',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  changes: {
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  checkBoxAll: {
    color: palette.iadp.checkboxColors.onRosterChanges.color,
  },
  separator: {
    border: '1px solid',
    borderColor: palette.iadp.borderColor,
    marginLeft: -spacing(2),
    marginRight: -spacing(2),
  },
});
