import { useEffect, useRef, useState } from 'react';
import { Add as AddIcon, Close as CloseIcon } from '@material-ui/icons';
import {
  makeStyles, IconButton, InputAdornment, MenuItem, Select, TextField, Typography,
} from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import { useMultiColumn } from '../../../hooks';
import styles from './ProfileSelectInput.styles';

const useStyles = makeStyles(styles, { name: 'ProfileSelectInput' });

type Props = {
  className?: string;
  customItem?: string;
  'data-test-id': string;
  disabled?: boolean;
  error?: false | string;
  label: string;
  name?: string;
  onChange: (value: string) => void;
  options: { value: string; text: string }[];
  predefinedValue?: string;
  type?: string;
  value: string;
};

const ProfileSelectInput = ({
  className,
  customItem,
  'data-test-id': dataTestId,
  disabled,
  error,
  label,
  name,
  onChange,
  options,
  predefinedValue,
  type,
  value,
}: Props) => {
  const classes = useStyles();
  const multiColumn = useMultiColumn();

  const inputRef = useRef<HTMLInputElement>(null);
  const hasSelectedOption = options?.some((option) => option.value === value);
  const [editMode, setEditMode] = useState(!hasSelectedOption);
  const [shouldFocus, setShouldFocus] = useState(false);

  useEffect(() => {
    if (shouldFocus) {
      window.setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, [shouldFocus]);

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    const { value: eventValue } = event.target;

    if (eventValue === undefined) {
      setShouldFocus(true);
      setEditMode(true);
      onChange(predefinedValue || '');
    } else {
      onChange(eventValue);
    }
  };

  const handleClear = () => {
    const newValue = options && options[0] ? options[0].value : predefinedValue || '';
    setEditMode(!newValue);
    onChange(newValue);
  };

  const commonProps = {
    'className': clsx(classes.root, className),
    'data-test-id': dataTestId,
    'disabled': disabled,
    'error': !!error,
    'helperText': error,
    'id': name,
    'label': <Typography>{label}</Typography>,
    'name': name,
    'onChange': handleChange,
    'size': multiColumn ? 'small' : undefined,
    'value': value,
  } as const;

  if (editMode) {
    return (
      <TextField
        {...commonProps}
        inputRef={inputRef}
        type={type}
        InputProps={{
          endAdornment: (
            <InputAdornment className={classes.inputAdornment} position="end">
              <IconButton
                data-test-id="textfield-clear-button"
                disabled={disabled}
                onClick={handleClear}
                size={multiColumn ? 'small' : undefined}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }

  return (
    <Select {...commonProps}>
      {options.map((option) => <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>)}
      {customItem && (
        <MenuItem data-test-id="select-option-custom-item">
          <AddIcon className={classes.addButton} />{customItem}
        </MenuItem>
      )}
    </Select>
  );
};

export default ProfileSelectInput;
