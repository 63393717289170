import { useTranslation } from 'react-i18next';
import { Button, makeStyles } from '@lsy-netline-ui/netline-ui';
import { Edit as EditIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { useConnection } from '@crew-webui/common/hooks';
import { NoContentMessage } from '@crew-webui/common/view/components';

import { RemarkForListing } from 'hooks';
import DailyRemarkMsg from './DailyRemarkMsg';
import useScrollToDailyRemark from './useScrollToDailyRemark';
import styles from './DailyRemarksSection.styles';
import { routePaths } from 'consts';

const useStyles = makeStyles(styles, { name: 'DailyRemarksSection' });

const DailyRemarksSection = ({
  byCrewMember = false, hasLoaded, items, subtitle,
}: {
  byCrewMember?: boolean; hasLoaded: boolean; items: Array<RemarkForListing>; subtitle: string;
}) => {
  const { t } = useTranslation();
  const { online } = useConnection();
  const classes = useStyles();
  const listRef = useScrollToDailyRemark(items);
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.subtitleContainer}>
        <span className={classes.subtitle} data-test-id="remarks-section-title">{subtitle}</span>
        {byCrewMember && (
          <Button
            data-test-id="remarks-create-new-link"
            endIcon={<EditIcon />}
            onClick={() => history.push(routePaths.newRemarks)}
            disabled={!online}
          >
            {t('button.createNew')}
          </Button>
        )}
      </div>
      <div ref={listRef} className={classes.list}>
        {hasLoaded && (
          items.length === 0 ? (
            <NoContentMessage>{t('dailyRemarks.noItems')}</NoContentMessage>
          ) : (
            items.map((item) => (
              <DailyRemarkMsg key={item.logicalIdRelatedEvent} byCrewMember={byCrewMember} remark={item} />
            ))
          )
        )}
      </div>
    </div>
  );
};

export default DailyRemarksSection;
