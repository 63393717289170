import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useConnection } from '../../../hooks';
import ErrorInfo from '../ErrorInfo/ErrorInfo';

type Props = {
  checkCrewCom?: boolean;
  children: React.ReactNode;
  renderAfter?: React.ReactNode;
  visible?: boolean;
};

const OfflineInfo = ({
  checkCrewCom = false, children, visible = false, renderAfter,
}: Props) => {
  const { t } = useTranslation();
  const { online, crewComOnline } = useConnection();
  const showOffline = (!online || (checkCrewCom && !crewComOnline)) && visible;
  const [delaying, setDelaying] = useState(showOffline);

  useEffect(() => {
    if (!showOffline) {
      return undefined;
    }
    const timer = setTimeout(() => setDelaying(false), 100);

    return () => clearTimeout(timer);
  }, [delaying, showOffline]);

  if (delaying || !showOffline) {
    return (
      <>
        {children}
        {renderAfter}
      </>
    );
  }

  return (
    <>
      <ErrorInfo header={t('components.offlineInfo.header')} info={t('components.offlineInfo.info')} />
      {renderAfter}
    </>
  );
};

export default OfflineInfo;
