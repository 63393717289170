import { HotelAddress, HotelContact } from './hotelTypes';

export function transformArray(value: any) {
  if (!Array.isArray(value)) {
    return value;
  }

  const result: any[] = value
    // eslint-disable-next-line react/no-array-index-key
    .map((item, index) => transformArray(item) && <div key={index}>{transformArray(item)}</div>)
    .filter((item) => item && (!Array.isArray(item) || item.length));

  return result.length ? result : null;
}

export const getAddress = (address: HotelAddress | undefined, t: T) => {
  const {
    country, cityName, street, zipCode,
  } = address || {};

  return [
    country && `${t('duties.hotel.country')}: ${country}`,
    zipCode && `${t('duties.hotel.zip')}: ${zipCode}`,
    cityName && `${t('duties.hotel.city')}: ${cityName}`,
    street && `${t('duties.hotel.street')}: ${street}`,
  ];
};

export const getContact = (contact: HotelContact | undefined, t: T) => {
  const {
    phone, fax, email1, email2,
  } = contact || {};

  return [
    phone && `${t('duties.hotel.phone')}: ${phone}`,
    fax && `${t('duties.hotel.fax')}: ${fax}`,
    email1 && `${t('duties.hotel.email')}: ${email1}`,
    email2 && `${t('duties.hotel.otherEmail')}: ${email2}`,
  ];
};
