import {
  useCallback, useMemo, useEffect, useState, useRef,
} from 'react';
import { DateTime } from 'luxon';
import { useHistory, useLocation } from 'react-router-dom';

import { logger, services, useIadpParams } from '@crew-webui/common/api';
import {
  useAppCommon, useDialog, useOnlineAuthorized, useProfile,
} from '@crew-webui/common/hooks';

import { SelectionMode } from 'consts';

const useNotificationConfirmation = ({ t }: { t: T }) => {
  const { crmId } = useIadpParams<{ crmId: string }>();
  const history = useHistory();
  const { state: { fromDateTimeUTC, toDateTimeUTC, redirectTo } = { fromDateTimeUTC: '' } } =
    useLocation<{ fromDateTimeUTC: string; toDateTimeUTC: string; redirectTo: string }>();

  const { setDirty, showFooter, hideFooter } = useAppCommon();
  const { showDialog, hideDialog } = useDialog();
  const { profile } = useProfile();

  const [selectedChanges, setSelectedChanges] = useState<string[]>([]);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [rosterChanges, setRosterChanges] = useState<{ changes: any[]; id?: string }>({ changes: [] });
  const [isScrolledOver, setIsScrolledOver] = useState<boolean>(false);
  const containerRef = useRef(null);
  const { changes } = rosterChanges;
  const { notificationConfirmationSelectionMode: notifConfirmSelectionMode } = profile;
  const disableCheckBox = notifConfirmSelectionMode === SelectionMode.ALL;
  const confirmableChanges = changes && changes.filter((change) => change.confirmable);
  const allChecked = selectedChanges.length === confirmableChanges?.length;
  const [isNotificationConfirming, setIsNotificationConfirming] = useState(false);

  const navigateBack = useCallback(({ loadDuties }: any) => {
    if (loadDuties && redirectTo) {
      history.push({ pathname: redirectTo, state: { loadDuties } });
    } else {
      history.goBack();
    }
  }, [history, redirectTo]);

  useEffect(() => {
    hideFooter();
    return () => showFooter();
  }, [showFooter, hideFooter]);

  const isInRosterChangePeriod = useCallback(
    (dateTime: string) => {
      if (!fromDateTimeUTC || !toDateTimeUTC) {
        return false;
      }
      const dateTimeLux = DateTime.fromISO(dateTime).toUTC().valueOf();
      const fromDateTimeUTCLux = DateTime.fromISO(fromDateTimeUTC).toUTC().startOf('day').valueOf();
      const toDateTimeUTCLux = DateTime.fromISO(toDateTimeUTC).toUTC().endOf('day').valueOf();

      return fromDateTimeUTCLux <= dateTimeLux && dateTimeLux <= toDateTimeUTCLux;
    },
    [fromDateTimeUTC, toDateTimeUTC],
  );

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const { scrollHeight, clientHeight } = containerRef.current;

    if (scrollHeight === clientHeight) {
      setIsScrolledOver(true);
    }
  }, [selectedChanges, containerRef]);

  const handleIsScrollOver = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    const { scrollTop, offsetHeight, lastChild: { offsetHeight: lastChildOffsetHeight } } = containerRef.current;
    if (scrollTop >= lastChildOffsetHeight - offsetHeight - 1) {
      setIsScrolledOver(true);
    }
  }, [containerRef]);

  useOnlineAuthorized(() => {
    if (!crmId) {
      return;
    }
    const getRosterChanges = async () => {
      try {
        const {
          data: { result = {}, success, error = {} },
        } = await services.loadRosterChangeData({ crmId }, { handleErrorFieldLocally: true });

        if (!success && error) {
          showDialog({
            title: t('common.info'),
            content: error.items?.[0]?.errMessage || t('error.unableRetrieveRosterChanges'),
            buttons: {
              text: t('button.ok'),
              onClick: (/* button, id, event */) => {
                hideDialog();
                history.push('/');
              },
            },
          });

          return null;
        }

        setHasLoaded(true);
        setRosterChanges(result);
        return result;
      } catch (ex) {
        logger.warn('Unable to retrieve roster changes!', ex);
        setHasLoaded(false);
      }
      return null;
    };

    getRosterChanges().then((result: { changes: any[] }) => {
      if (result) {
        let changesToBeSelected = [];
        if (notifConfirmSelectionMode === SelectionMode.CURRENT_PERIOD) {
          changesToBeSelected = result.changes
            .filter((change) => isInRosterChangePeriod(change.date) && change.confirmable);
        } else if (notifConfirmSelectionMode === SelectionMode.ALL) {
          changesToBeSelected = result.changes
            .filter((change) => change.confirmable);
        }
        setSelectedChanges(changesToBeSelected.map((change) => change.date));
        handleIsScrollOver();
      }
    });
  }, [crmId, handleIsScrollOver, isInRosterChangePeriod, notifConfirmSelectionMode, showDialog, hideDialog, history, setHasLoaded]);

  const onSelectAllChange = useCallback(() => {
    setDirty(true);
    if (confirmableChanges.length !== selectedChanges.length) {
      setSelectedChanges(confirmableChanges.map((change) => change.date));
    } else {
      setSelectedChanges([]);
    }
  }, [confirmableChanges, selectedChanges, setDirty]);

  const onSelectChange = useCallback(
    ({ target: { checked, value } }: { target: { checked: boolean; value: string } }) => {
      setDirty(true);
      if (checked) {
        setSelectedChanges([...selectedChanges, value]);
      } else {
        setSelectedChanges(selectedChanges.filter((date) => date !== value));
      }
    },
    [selectedChanges, setDirty],
  );

  const onConfirmTap = useCallback(async () => {
    setIsNotificationConfirming(true);
    setDirty(false);
    const { id } = rosterChanges;
    const confirmData = {
      id,
      changes: selectedChanges.map((date) => ({ date })),
    };
    try {
      const {
        data: {
          result: { periods = [], changes: notificationChanges = [] } = {},
          success,
          error,
        },
      } = await services.confirmRosterChange({ crmId, confirmData }, { handleErrorFieldLocally: true });
      if (!success && error) {
        let unconfirmedNotifications = '';
        if (error.isErr && error.items?.[0]?.errId === 'ERR_NOTIF_CHANGED_MEANWHILE') {
          notificationChanges.forEach((notification: any) => {
            unconfirmedNotifications += `${notification.date}\n`;
          });
        }
        showDialog({
          title: t('error.unsuccessfulNotificationConfirmation'),
          content: unconfirmedNotifications ? t('notificationConfirmation.confirmationFailed', { unconfirmedNotificationsNo: notificationChanges.length, unconfirmedNotifications }) : 'error.items?.[0]?.errMessage',
          buttons: t('button.ok'),
          type: 'error',
        });
      }
      navigateBack({ loadDuties: periods });
    } catch (error) {
      logger.warn('Unable to confirm roster change!', error);
    } finally {
      setIsNotificationConfirming(false);
    }
  }, [crmId, navigateBack, rosterChanges, selectedChanges, setDirty, setIsNotificationConfirming, showDialog, t]);

  const disabledConfirm =
    (notifConfirmSelectionMode === SelectionMode.ALL && !isScrolledOver) ||
    (notifConfirmSelectionMode !== SelectionMode.ALL && !selectedChanges.length) || isNotificationConfirming;

  return useMemo(() => ({
    allChecked,
    changes,
    containerRef,
    disableCheckBox,
    disabledConfirm,
    fromDateTimeUTC,
    handleIsScrollOver,
    hasLoaded,
    onConfirmTap,
    onSelectAllChange,
    onSelectChange,
    rosterChanges,
    selectedChanges,
    confirmableChanges,
  }), [
    allChecked,
    changes,
    containerRef,
    disableCheckBox,
    disabledConfirm,
    fromDateTimeUTC,
    handleIsScrollOver,
    hasLoaded,
    onConfirmTap,
    onSelectAllChange,
    onSelectChange,
    rosterChanges,
    selectedChanges,
    confirmableChanges,
  ]);
};

export default useNotificationConfirmation;
