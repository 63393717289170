import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  root: {
    margin: 20,
    color: palette.text.secondaryLabel,
    fontWeight: 500,
    textAlign: 'center',
  },
});
