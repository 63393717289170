import { AxiosResponse, AxiosError } from 'axios';

import { PersistTypes } from '../../consts';
import { isOfflineError, ServiceConfig } from '../api-helper';
import logger from '../logger';
import { CREWCOM_API_PATH } from '../services';
import createCacheHandler from './cacheHandler';

const noCacheHandler = {
  set: (config: ServiceConfig, response: AxiosResponse) => response,
  get: () => undefined,
};

/**
 * Service handler
 * @param setOnline   The global state setter to provide online/offline state for
 * @returns {{processSuccessResult: (function(*=, *=): *), processErrorResult: processErrorResult}}
 */
export default (setOnline: (online: boolean) => void, setCrewComOnline: (online: boolean) => void) => {
  // Create login handler to cache response, encrypt and handle it.
  const cacheHandler = createCacheHandler();

  const persistHandlers = {
    [PersistTypes.AuthPersist]: cacheHandler.authCache,
    [PersistTypes.LoginPersist]: cacheHandler.loginCache,
    [PersistTypes.LogoutPersist]: cacheHandler.logoutCache,
    [PersistTypes.RenewPersist]: cacheHandler.renewCache,
    [PersistTypes.SaltedPersist]: cacheHandler.saltedCache,
    [PersistTypes.PasswordPersist]: cacheHandler.passwordCache,
  };

  const changeOnlineState = (config: ServiceConfig, value: boolean) => {
    const isCrewCom = config.url.indexOf(CREWCOM_API_PATH) >= 0;
    if (isCrewCom) {
      setCrewComOnline(value);
    } else {
      setOnline(value);
      if (logger.setOffline) {
        logger.setOffline(!value);
      }
    }
  };

  const findHandler = (config: ServiceConfig) => {
    const { persistType } = config;
    return persistType ? persistHandlers[persistType] : noCacheHandler;
  };

  const processSuccessResult = async (config: ServiceConfig, response: AxiosResponse) => {
    try {
      await findHandler(config).set(config, response);
      changeOnlineState(config, true);
    } catch (err) {
      logger.error('Error while process success result', err);
    }
    return response;
  };

  const processErrorResult = (config: ServiceConfig, error?: AxiosError) => {
    try {
      const offline = error && isOfflineError(error);
      if (offline) {
        changeOnlineState(config, false);
        return findHandler(config).get(config, error);
      }
    } catch (err) {
      logger.error('Error while process error result', err);
    }
    return undefined;
  };

  return {
    processSuccessResult,
    processErrorResult,
  };
};
