import { createContext, useContext } from 'react';

import { CrewComDomain, PemsDomain } from '../../consts';

type DomainContextType = {
  routePrefix: string;
  pemsDomain: PemsDomain;
  crewComDomain: CrewComDomain;
};

const DomainContext = createContext<DomainContextType>({
  routePrefix: '',
  pemsDomain: PemsDomain.IADP,
  crewComDomain: CrewComDomain.IADP,
});

export const TENANT = 'LH';

export const useDomain = () => useContext(DomainContext);

export default DomainContext;
