import { isArray, isObject } from 'lodash';

import LogCollector from './LogCollector';
import { LogLevel } from '../../consts';

export const DEFAULT_FLUSH_INTERVAL = 60000;

export const DEFAULT_FLUSH_LIMIT = 100;

export const DEFAULT_VERSION = `v${process.env.REACT_APP_VERSION}`;

export const DEFAULT_TRANSFORM = (message, idx, messages, logger) => { // eslint-disable-line @typescript-eslint/no-unused-vars
  if (isArray(message) || isObject(message)) {
    if (message.isAxiosError) {
      return JSON.stringify({ config: message.config, request: message.request, response: message.response }, null, 2);
    }
    return JSON.stringify(message, null, 2);
  }
  return message;
};
export const DEFAULT_TRANSPORT = (messages, logger) => {}; // eslint-disable-line @typescript-eslint/no-unused-vars

// detect device type
const getDeviceType = () => {
  if (window.device?.platform) {
    const loggedProperties = ['isVirtual', 'manufacturer', 'model', 'uuid', 'version'];
    const deviceProperties = Object.entries({ ...window.device })
      .filter(([key]) => loggedProperties.includes(key))
      .map(([key, value]) => `${key}: ${value}`).join(', ');

    return `${window.device.platform} (${deviceProperties})`;
  }

  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }
  return window.navigator.platform;
};

/**
 * A logger class which collects log entries, transforms them and flushes them periodically on a transport interface.
 * Flush conditions are the following:
 *   - periodically: when a given amount of time is elapsed
 *   - message limit exceeded: when a number of messages reached an upper limit
 *   - error: in case of `error` method is called (eg. exceptions).
 *
 * @param  {Object} options                 Configuration object for first parameter.
 * @param  {Function} options.transport     A transport function which is called when the log queue is flushed.
 * @param  {String} options.defaultLevel    The default log level when simple `log` function is called.
 * @param  {Function} options.transform     A transformer function which is called before a message is added to the log queue.
 * @param  {Number} options.flushInterval   The interval in milliseconds in which the log queue is flushed. If set to 0, no periodical flush will happen.
 * @param  {Number} options.flushLimit      The number of messages after the entries will be transferred. If set to 0, no message limit will be available.
 * @param  {String} options.version         Version information to add to each log entry. If it is not set, the default version from package.json will be used.
 * @param  {Boolean} options.useConsole     Wether log everything onto console together with server-side enqueing mechanism.
 * @param  {Object} origConsole             The original console which functionality should expanded (default is window.console).
 */
function ServerLogger({
  transport = DEFAULT_TRANSPORT,
  transform = DEFAULT_TRANSFORM,
  groupLeafLevel = LogLevel.DEBUG,
  flushInterval = DEFAULT_FLUSH_INTERVAL,
  flushLimit = DEFAULT_FLUSH_LIMIT,
  version = DEFAULT_VERSION,
  useConsole = false,
}, origConsole = window.console) {
  /**
   * Each messages contains an unique auto-incrementing id. This variable stores the counter of the last sent message.
   * @type {Number}
   */
  let id = 0;

  /**
   * Set if offline mode
   */
  let offline = false;

  /**
   * An array of strings which stores the transformed messages.
   * @type {Array}
   */
  let entries = [];

  /**
   * A timer (setTimeout) identifier which is used to start / stop periodical log activities.
   * @type {Number}
   */
  let timerId = null;

  const levelKeys = Object.keys(LogLevel);

  const device = getDeviceType();

  // must call initLogParams with level, but will be updated by config
  let defaultLevel;

  // the default level index, to check the priority
  let logLevelIdx;

  // set if log the group leafs
  let logGroupLeaf;

  // the depth of group log
  let groupNo = 0;

  /**
   * A custom data which can extend, to set properties, example username.
   * @type {Object}
   */
  const customData = {};

  /**
   * A promise that indicates, whether a flushing is in progress.
   * @type {Promise}
   */
  let flushPromise = null;

  const initLogParams = (logLevel) => {
    defaultLevel = logLevel;
    logLevelIdx = levelKeys.indexOf(logLevel);
    logGroupLeaf = logLevelIdx <= levelKeys.indexOf(groupLeafLevel);
  };

  const setLogLevel = (logLevel) => {
    if (logLevel) {
      initLogParams(logLevel);
    }
  };

  const setOffline = (isOffline) => {
    offline = isOffline;
  };

  /**
   * Check if log enabled on this level
   * @param logLevel
   * @returns {boolean}
   */
  const isLevelEnabled = (logLevel) => logLevel !== LogLevel.OFF && logLevelIdx <= levelKeys.indexOf(logLevel);

  /**
   * Sets the logger parameters, from service.
   * @param data
   */
  const assignCustomData = (data) => {
    Object.assign(customData, data);
  };

  /**
   * Stops flush timer if it was already enabled.
   * @private
   */
  const stopTimer = () => {
    if (!timerId) {
      return;
    }

    clearTimeout(timerId);
    timerId = null;
  };

  /**
   * Flush log queue via transport layer.
   * @private
   */
  const flush = () => {
    // not logged in or offline, flush the log later
    if (!customData.crmId || offline) {
      return flushPromise;
    }
    // flush is running
    if (flushPromise) {
      return flushPromise;
    }

    stopTimer();

    if (levelKeys.indexOf(LogLevel.OFF) === logLevelIdx) {
      return flushPromise;
    }
    if (transport && entries && entries.length) {
      const request = {
        version,
        device,
        messages: entries,
        ...customData,
      };
      flushPromise = transport(request, this)
        .then(() => {
          entries = [];
          flushPromise = null;
          startTimer(); // eslint-disable-line @typescript-eslint/no-use-before-define
        })
        .catch((error) => {
          entries = [];
          flushPromise = null;
          startTimer(); // eslint-disable-line @typescript-eslint/no-use-before-define

          origConsole.error('Error while flush errors', error);
        });

      return flushPromise;
    }

    entries = [];
    return null;
  };

  /**
   * Starts flush timer for logging.
   * @private
   */
  const startTimer = () => {
    stopTimer();

    if (flushInterval) {
      timerId = setTimeout(flush, flushInterval);
    }
  };

  /**
   * Adds one or more new log item into the log queue, but calls the `transform` config function if it is set.
   * @param  {String}    level    The log entry level.
   * @param  {...Object} messages Objects or strings as messages which must be added to the queue.
   */
  const enqueue = (level, ...messages) => {
    // check if server log is possible
    if (!isLevelEnabled(level)) {
      return;
    }
    // we are in a group leaf, and skip logging it
    if (!logGroupLeaf && groupNo > 0) {
      return;
    }
    const timestamp = Date.now();
    const transformedMessages = messages.filter((message) => message && !message.toString().startsWith('color:')).map((message, idx) => {
      const msg = (transform ? transform(message, idx, messages, this) : message);
      id += 1;
      return {
        id,
        message: msg,
        level: level || defaultLevel,
        timestamp,
      };
    });

    entries.push(...transformedMessages);

    if (flushLimit && entries.length >= flushLimit) {
      flush();
    }
  };

  /**
   * Supplementary method wihc creates a new console group.
   * @private
   */
  const createGroup = (...args) => {
    enqueue(LogLevel.INFO, args);
    groupNo += 1;
  };

  /**
   * Default js `console` interface to log standard message.
   */
  const wrappedFns = {
    log: (...args) => {
      enqueue(defaultLevel, ...args);
    },

    debug: (...args) => {
      enqueue(LogLevel.DEBUG, ...args);
    },

    /**
     * Default js `console` interface to log info message.
     */
    info: (...args) => {
      enqueue(LogLevel.INFO, ...args);
    },

    /**
     * Default js `console` interface to log warning messages
     */
    warn: (...args) => {
      enqueue(LogLevel.WARNING, ...args);
    },

    /**
     * Default js `console` interface to log error. It automatically flushes log queue.
     */
    error: (...args) => {
      enqueue(LogLevel.ERROR, ...args);
      flush();
    },

    /**
     * Default js `console` interface to create a new group.
     */
    group: (...args) => {
      createGroup(...args);
    },

    /**
     * Default js `console` interface to create a new collapsed groupd. In server logger there is no diff btw `group` and `groupCollapsed` call.
     */
    groupCollapsed: (...args) => {
      createGroup(...args);
    },

    /**
     * Default js `console` interface to close an already created group.
     */
    groupEnd: () => {
      groupNo += -1;
    },
  };

  const wrap = (fnName) => (...args) => {
    if (LogCollector[fnName]) {
      LogCollector[fnName](...args);
    }
    const result = useConsole && origConsole[fnName] ? origConsole[fnName](...args) : undefined;
    wrappedFns[fnName](...args);
    return result;
  };

  initLogParams(LogLevel.INFO);

  startTimer();

  return {
    ...origConsole,
    log: wrap('log'),
    info: wrap('info'),
    warn: wrap('warn'),
    error: wrap('error'),
    debug: wrap('debug'),
    group: wrap('group'),
    groupCollapsed: wrap('groupCollapsed'),
    groupEnd: wrap('groupEnd'),
    setLogLevel,
    setOffline,
    assignCustomData,
    flush,
    flushPromise,
  };
}

export default ServerLogger;
