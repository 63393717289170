import { createContext } from 'react';

type ProfileAndThemeContextType = {
  profile: any;
  setProfile: (customerCode: string) => void;
  theme: Record<string, any>;
};

const defaultValue = {
  profile: {},
  setProfile: () => {},
  theme: {},
};

const ProfileAndThemeContext = createContext<ProfileAndThemeContextType>(defaultValue);

export default ProfileAndThemeContext;
