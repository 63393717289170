import { createContext } from 'react';

export type Remark = {
  text: string;
  remarkSender: string;
  remarkTimeStamp: string;
  logicalIdRelatedEvent: string;
  from: { dtUtc: string };
};

export type CreateRemark = {
  date: string;
  remarkType: string;
  comment: string;
};

export type RemarkForListing = Remark & {
  date: string;
  datetime: string;
  read: boolean;
};

type DailyRemarksContextType = {
  dailyRemarks: Remark[];
  hasLoaded: boolean;
  hasNotification: boolean;
  loadDailyRemarks: () => void;
  removeNotification: () => void;
};

const DailyRemarksContext = createContext<DailyRemarksContextType>({
  dailyRemarks: [],
  hasLoaded: true,
  hasNotification: false,

  loadDailyRemarks: () => {},
  removeNotification: () => {},
});

export default DailyRemarksContext;
