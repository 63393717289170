import defaultTheme from './defaultTheme';
import { CustomerTheme } from './themeTypes';

const pgaTheme: CustomerTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    events: {
      ...defaultTheme.palette.events,
      par: {
        backgroundColor: '#BA141A',
        textColor: '#FFFFFF',
      },
      leg: {
        backgroundColor: '#BA141A',
        textColor: '#FFFFFF',
      },
      rtg: {
        backgroundColor: '#BFD730',
      },
      sby: {
        backgroundColor: '#d0a400',
        textColor: '#FFFFFF',
      },
      hot: {
        backgroundColor: '#630090',
        textColor: '#ffffff',
      },
      abs: {
        backgroundColor: '#D3D3D3',
        textColor: '#000000',
      },
      fpr: {
        backgroundColor: '#7E91AA',
        textColor: '#000000',
      },
      gpr: {
        backgroundColor: '#54667E',
        textColor: '#000000',
      },
      vac: {
        backgroundColor: '#08969B',
        textColor: '#FFFFFF',
      },
      sick: {
        backgroundColor: '#74e874',
        textColor: '#FFFFFF',
      },
      ground: {
        backgroundColor: '#9C9090',
        textColor: '#FFFFFF',
      },
      sim: {
        backgroundColor: '#6532B4',
        textColor: '#FFFFFF',
      },
      off: {
        backgroundColor: '#BFD730',
        textColor: '#FFFFFF',
      },
      other: {
        backgroundColor: '#7E7162',
        textColor: '#FFFFFF',
      },
      /*
       * If an event type has no special icon, we use this appearance in duty list view.
       */
      textIcon: {
        backgroundColor: '#7E7162',
        textColor: '#FFFFFF',
      },
    },
  },
};

export default pgaTheme;
