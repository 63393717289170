import { Phone } from '@material-ui/icons';
import { SettingsWithExclamation } from '@crew-webui/common/view/components/icons';

import {
  CheckInTitle, CustomerProfile, ProfileFeature, SelectionMode,
} from 'consts';
import defaultProfileSettings from './defaultProfile';

const atsgCommonProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  enablings: {
    ...defaultProfileSettings.enablings,
    [ProfileFeature.PERFORM_ROLE_EDITING]: false,
  },
  showHomeBase: false,
  showAircraftTypeCodeOfPairing: false,
  showAircraftTypeCodeOfLeg: false,
  showUTCTime: false,
  showRegistration: true,
  preDefinedPhoneValue: '+1',
  icons: {
    ...defaultProfileSettings.icons,
    SUSP: SettingsWithExclamation,
    SBY: Phone,
  },
  checkInTitle: CheckInTitle.ADD_PAIRING_NUMBER,
  notificationConfirmationSelectionMode: SelectionMode.ALL,
  newsListing: {
    ...defaultProfileSettings.newsListing,
    rosterChange: {
      ...defaultProfileSettings.newsListing.rosterChange,
      Icon: SettingsWithExclamation,
    },
  },
  summary: {
    dateFormat: 'dd-MM-yyyy',
    groupsToHighlightExpiration: [
      'Recurrent trainings/checks',
      'Licences',
      'Passports / Visas',
    ],
  },
};

export default atsgCommonProfileSettings;
