import { createContext } from 'react';

import { AdditionalFlightDetails, Event, EventContext } from '../../consts';

export type FetchEventDetailsParam = {
  context: EventContext;
  logicalEventId?: string;
  eventId?: string;
};

export type EventDetailsContextType = {
  eventDetails: { [key: string]: Event | undefined };
  additionalFlightDetails: AdditionalFlightDetails | undefined;
  fetchEventDetails: (param: FetchEventDetailsParam) => void;
  fetchAdditionalFlightDetails: (logicalEventId: string) => void;
  resetAdditionalFlightDetails: () => void;
};

export const defaultContextValue = {
  eventDetails: {},
  additionalFlightDetails: undefined,
  fetchEventDetails: () => { },
  fetchAdditionalFlightDetails: () => { },
  resetAdditionalFlightDetails: () => { },
};

const EventDetailsContext = createContext<EventDetailsContextType>(defaultContextValue);

export default EventDetailsContext;
