export default () => ({
  root: {
    'flexGrow': 0,
    'flexShrink': 0,
    'display': 'flex',
    'width': '100%',
    'height': 'auto',
    'padding': 14,
    'borderRadius': 0,
    'fontSize': 16,
    'letterSpacing': 0.5,
    'lineHeight': '20px',
    'zIndex': 10,

    '&.Mui-disabled': {
      pointerEvents: 'auto', // to prevent clicking the item which it hides
    },
  },
});
