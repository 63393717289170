import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  container: {
    margin: 16,
  },
  list: {
    padding: 0,
  },
  listItem: {
    'padding': 0,

    '&:not(:last-child)': {
      marginBottom: 16,
    },
  },
  listItemText: {
    margin: 0,
  },
  label: {
    color: palette.iadp.primaryLabelTextColor,
    fontSize: 13,
    lineHeight: '24px',
  },
  value: {
    'whiteSpace': 'pre-wrap',
    'color': palette.text.primary,
    'fontSize': 14,
    'lineHeight': '24px',

    // separate groups of values
    '& * *:last-child': {
      marginBottom: 8,
    },
  },
  pageTitle: {
    marginBottom: spacing(3),
  },
});
