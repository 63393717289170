import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing, classPrefix = '' }: Theme) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: spacing(1.5),

    [`& .${classPrefix}MuiFormLabel-root`]: {
      position: 'static',
      maxWidth: '50%',
      color: palette.text.label,
      cursor: 'pointer',
    },
    [`& .${classPrefix}MuiInputBase-root`]: {
      marginTop: 0,
    },
    [`& .${classPrefix}MuiInput-root`]: {
      flexShrink: 0,
      width: '50%',
      maxWidth: 230,
    },
    [`& .${classPrefix}MuiFormHelperText-root`]: {
      width: '100%',
      textAlign: 'right',
    },
  },
  inputAdornment: {
    paddingRight: 0,
  },
  addButton: {
    fontSize: 'inherit',
  },
});
