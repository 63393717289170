import { ServerConfigHandler } from '../api-helper';

const LogCollector = (() => {
  const logs: any[] = [];

  const isEnabled = () => ServerConfigHandler.getUsedServer()?.origin === 'https://tlsylncweba01.nlcrew.weur.cloudtest.lhsystems.com';

  const appendLog = (type: string) => (...args: any[]) => {
    if (!isEnabled()) {
      return;
    }
    logs.push({
      type,
      values: JSON.stringify(args),
    });
    if (logs.length > 20) {
      logs.shift();
    }
  };
  const getLogs = () => (logs);

  return {
    info: appendLog('info'),
    warn: appendLog('warn'),
    error: appendLog('error'),
    group: appendLog('group'),
    debug: appendLog('debug'),
    getLogs,
  };
})();

window.LogCollector = LogCollector;

export default LogCollector;
