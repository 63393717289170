import createCustomTheme from './createCustomTheme';
import { CustomerTheme } from './themeTypes';

const rxiDefault = createCustomTheme('purple');

const rxiTheme: CustomerTheme = {
  ...rxiDefault,
  palette: {
    ...rxiDefault.palette,
    events: {
      ...rxiDefault.palette.events,
      rtg: {
        backgroundColor: '#412074',
      },
      sby: {
        backgroundColor: '#f5ea56',
        textColor: '#FFFFFF',
      },
      hot: {
        backgroundColor: '#630090',
        textColor: '#ffffff',
      },
      abs: {
        backgroundColor: '#edbc9f',
        textColor: '#000000',
      },
      fpr: {
        backgroundColor: '#5b2c9d',
        textColor: '#000000',
      },
      gpr: {
        backgroundColor: '#5f5b82',
        textColor: '#000000',
      },
      vac: {
        backgroundColor: '#75ab3f',
        textColor: '#FFFFFF',
      },
      sick: {
        backgroundColor: '#f25c41',
        textColor: '#FFFFFF',
      },
      ground: {
        backgroundColor: '#24a0f2',
        textColor: '#FFFFFF',
      },
      sim: {
        backgroundColor: '#484ddb',
        textColor: '#FFFFFF',
      },
      off: {
        backgroundColor: '#a39d9d',
        textColor: '#FFFFFF',
      },
      other: {
        backgroundColor: '#a37abb',
        textColor: '#FFFFFF',
      },
      /*
       * If an event type has no special icon, we use this appearance in duty list view.
       */
      textIcon: {
        backgroundColor: '#a37abb',
        textColor: '#FFFFFF',
      },
    },
  },
};

export default rxiTheme;
