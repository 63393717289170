import {
  CheckInTitle, CustomerProfile, SelectionMode, ProfileFeature,
} from 'consts';
import defaultProfileSettings from './defaultProfile';

const gafProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  calendarWeekStartMonday: true,
  checkInTitle: CheckInTitle.NO_PAIRING_NUMBER,
  logo: 'gaf.png',
  showPairingNumber: false,
  notificationConfirmationSelectionMode: SelectionMode.ALL,
  enablings: {
    ...defaultProfileSettings.enablings,
    [ProfileFeature.VIEW_CHECK_IN]: false,
    [ProfileFeature.PERFORM_CHECK_IN]: false,
  },
};

export default gafProfileSettings;
