import { createContext } from 'react';

export interface Device {
  deviceToken: string;
  uuid: string;
}

type DeviceContextType = {
  deviceId?: string;
  devices?: Device[];
  isPushEnabled: boolean;

  isCurrentDeviceRegistered: () => boolean;
  loadDevices: () => void;
  removeDevice: () => void;
  updateDevice: () => void;
};

const DeviceContext = createContext<DeviceContextType>({} as DeviceContextType);

export default DeviceContext;
