import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing, transitions }: Theme) => createStyles({
  root: {
    'marginTop': spacing(1),
    'backgroundColor': palette.dutyList.toggleIconBackground,
    'opacity': 0.4,

    '& svg': {
      transition: transitions.create('transform'),
      color: palette.common.black,
    },
    '&.open svg': {
      transform: 'rotate(180deg)',
    },
  },
});
