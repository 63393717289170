import { MyDataTravelDocType } from '@crew-webui/common/consts';

import {
  CountryIssueDefaultValues,
  CustomerProfile, HotelFields, SelectionMode,
} from 'consts';
import defaultProfileSettings from './defaultProfile';

const acaCommonProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  enablings: {
    ...defaultProfileSettings.enablings,
    // [ProfileFeature.VIEW_BLOCKMONTH_SPECIFIC_DATA]: true,
  },
  showHomeBase: false,
  showAircraftTypeCodeOfPairing: false,
  showAircraftTypeCodeOfLeg: false,
  showArrivalAirportOfPairing: false,
  showPairingClassification: true,
  showGateInfo: true,
  showFinNumber: true,
  localTimePostfix: 'L',
  utcTimePostfix: 'Z',
  preDefinedPhoneValue: '+1',
  typeCodesCalendar: {
    SBY: 'RAP',
  },
  notificationConfirmationSelectionMode: SelectionMode.CURRENT_PERIOD,
  disabledHotelFields: [
    HotelFields.PICKUP_TIME,
  ],
  logo: 'aca.png',
  summary: {
    dateFormat: 'yyyy-MM-dd',
    groupsToHighlightExpiration: [
      'Recurrent trainings/checks',
      'Licences',
      'Passports / Visas',
      'Restricted Area Identity Cards',
    ],
  },
  agreementTexts: {
    ...defaultProfileSettings.agreementTexts,
    sms: {
      en: 'I agree with the following terms: Air Canada text message (SMS) service sends text messages to your mobile device for Air Canada related services such as flight information at no cost. Messages and data rates may apply. To unsubscribe, text \'STOP\' to 66222. To resume, text \'START\' or \'RESUME\' to 66222. For help, text \'HELP\' or \'INFO\' to 66222.',
      fr: 'J’accepte les conditions suivantes : Le service de messagerie texte (SMS) d’Air Canada envoie, sans frais, des SMS sur votre appareil mobile concernant des services connexes d’Air Canada, tels que des renseignements sur le vol. Des frais de transmission de messages et de données peuvent s’appliquer. Pour vous désabonner, envoyez un message texte avec le mot « ARRET » au 66222. Pour reprendre, envoyez un message texte avec les mots « START » ou « RESUME » au 66222. Pour obtenir de l’aide, envoyez un message texte avec les mots « AIDE » ou « INFO » au 66222.',
    },
  },
  enabledTravelDocTypes: [
    MyDataTravelDocType.PASSPORT,
    MyDataTravelDocType.RESTRICTED_AREA_INDENTITY_CARD,
  ],
  countryIssueDefaultValue: CountryIssueDefaultValues.CA,
};

export default acaCommonProfileSettings;
