export enum AuthState {
  INIT = 'INIT',

  LOADING_CONFIG = 'LOADING_CONFIG',
  CONFIG_LOAD_SUCCESS = 'CONFIG_LOAD_SUCCESS',
  CONFIG_LOAD_ERROR = 'CONFIG_LOAD_ERROR',

  PREVALIDATE = 'PREVALIDATE',
  PREVALIDATE_SUCCESS = 'PREVALIDATE_SUCCESS',
  PREVALIDATE_FAILED = 'PREVALIDATE_FAILED',

  AUTHORIZING = 'AUTHORIZING',
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',

  AUTHENTICATING = 'AUTHENTICATING',
  AUTHENTICATED = 'AUTHENTICATED',

  SESSION_EXPIRED = 'SESSION_EXPIRED',
  VALIDATION_ERROR = 'VALIDATION_ERROR',

  LOGGING_OUT = 'LOGGING_OUT',
  LOGGING_OUT_SSO = 'LOGGING_OUT_SSO',
  LOGGING_OUT_SSO_IDP = 'LOGGING_OUT_SSO_IDP',
  LOGGED_OUT = 'LOGGED_OUT',
}

export const ActionType = {
  API_INIT: 'API_INIT',
  API_SUCCESS: 'API_SUCCESS',
  API_ERROR: 'API_ERROR',
};

export const initialState = {
  authState: AuthState.INIT,
  config: {},
  user: {
    firstName: '',
    lastName: '',
    userName: '',
    crmId: undefined,
    credentials: { userId: '' },
    configuration: {
      applicationWebLinks: [],
      enablings: [],
      languageServiceAvailable: false,
      keepHistoryNumberOfDays: 0,
      idpBuddyAccessRightsSimplifiedConfiguration: true,
    },
    refTimestamp: undefined,
    refTimestampLtAtHb: undefined,
    rights: [],
    operatingAirline: '',
  },
  error: undefined,
  response: undefined,
  oidcEnabled: false,
  loginClientTimestamp: undefined,
  authenticated: false,
  crmId: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  authenticate: (param: any) => {},
  logout: () => {},
  loadUserInfo: () => Promise.resolve({}),
  offlineAuth: false,
};

// only for testing
export const getExtendedInitialState = (authState: string, crmId: string) => ({
  ...initialState,
  authState,
  user: {
    ...initialState.user,
    crmId,
    rights: ['View_CrewLinkIdpApplication'],
  } as any,
});

type ReducerState = {
  authState: string;
  error?: any;
  response?: any;
};

const reducer = (state: ReducerState, action: Record<string, any>): ReducerState => {
  const {
    type, authState, error, ...payload
  } = action;

  if (type === ActionType.API_INIT) {
    return {
      ...state,
      ...payload,
      authState,
      error: undefined,
    };
  }

  if (type === ActionType.API_SUCCESS) {
    return {
      ...state,
      ...payload,
      authState,
      error: undefined,
    };
  }

  if (type === ActionType.API_ERROR) {
    return {
      ...state,
      ...payload,
      authState,
      error,
      response: error && error.response,
    };
  }

  return state;
};

export default reducer;
