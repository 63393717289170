import {
  useCallback, useContext, useMemo, useState,
} from 'react';

import { services } from '../../api';
import { useCrewAuth } from '../CrewAuth';
import { useProfile } from '../Profile';
import MyDataContext, { MyDataState } from './MyDataContext';
import { mapEmailsForNotifications, mapPhonesForNotifications } from './myDataUtils';

interface MyDataResult extends MyDataState {}

type MyDataResponse = PemsResponse<MyDataResult>;

const MyDataProvider = ({ children }: { children: React.ReactNode }) => {
  const { crmId } = useCrewAuth();
  const { profile: { preDefinedPhoneValue } } = useProfile();

  const [myData, setMyData] = useState<MyDataState>({});

  const {
    addresses,
    basicInfo: personalData,
    contacts,
    passports,
    visas,
  } = myData;

  const loadMyData = useCallback(
    (type: string) => services.loadMyData({ crmId, type })
      .then(({ data }: { data: MyDataResponse }) => {
        const { result, success } = data;

        if (success && result) {
          setMyData((prevState) => ({ ...prevState, ...result }));
        }
      }),
    [crmId],
  );

  const handleResponse = useCallback(({ data }: { data: PemsResponse<never> }) => {
    const { success } = data;
    return success ? undefined : Promise.reject();
  }, []);

  const deleteMyData = useCallback(
    (type: string, object: any) => services.deleteMyData({
      crmId,
      type,
      natKey: object.natKey,
    })
      .then(handleResponse),
    [crmId, handleResponse],
  );

  const createMyData = useCallback(
    (type: string, object: any) => services.createMyData({
      crmId,
      type,
      data: object,
    })
      .then(handleResponse),
    [crmId, handleResponse],
  );

  const updateMyData = useCallback(
    (type: string, newData: any, prevData: any) => services.updateMyData({
      crmId,
      type,
      originalNatKey: prevData.natKey,
      updatedData: newData,
    })
      .then(handleResponse),
    [crmId, handleResponse],
  );

  const phones = useMemo(
    () => mapPhonesForNotifications(contacts, preDefinedPhoneValue),
    [contacts, preDefinedPhoneValue],
  );
  const emails = useMemo(
    () => mapEmailsForNotifications(contacts),
    [contacts],
  );

  const ctx = useMemo(() => ({
    addresses,
    contacts,
    emails,
    passports,
    personalData,
    phones,
    visas,

    createMyData,
    deleteMyData,
    loadMyData,
    updateMyData,
  }), [
    addresses,
    contacts,
    createMyData,
    deleteMyData,
    emails,
    loadMyData,
    passports,
    personalData,
    phones,
    updateMyData,
    visas,
  ]);

  return <MyDataContext.Provider value={ctx}>{children}</MyDataContext.Provider>;
};

export const useMyData = () => useContext(MyDataContext);

export default MyDataProvider;
