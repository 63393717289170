import { DateTime } from 'luxon';

import { Event } from '../consts';

export const isEventCurrent = (currentTime: DateTime, event: Event, nextEvent?: Event) => (
  currentTime >= event.fromDateAtCalendar! &&
  (
    currentTime < event.toDateAtCalendar! ||
    (event.originalEventType === 'HOT' && nextEvent && currentTime < nextEvent.fromDateAtCalendar!)
  )
);

export const getEventToSelect = (date: DateTime, events: Event[], rosterTags: Event[]) => {
  const eventsWithoutDREM = events.filter(({ originalEventType }) => originalEventType !== 'DREM');

  return (
    // Select roster change event
    eventsWithoutDREM.find(({ originalEventType }) => originalEventType === 'SUSP') ||

    // Select the first event which starts on this day
    eventsWithoutDREM.find(({ fromDateAtCalendarCropped }) => date.hasSame(fromDateAtCalendarCropped!, 'day')) ||

    // Select the event which starts the latest
    eventsWithoutDREM[eventsWithoutDREM.length - 1] ||

    // Select the first roster tag which starts on this day
    rosterTags.find(({ fromDateAtCalendarCropped }) => date.hasSame(fromDateAtCalendarCropped!, 'day')) ||

    // Select the roster tag which starts the latest
    rosterTags[rosterTags.length - 1]
  );
};

const filterForDay = (day: DateTime, events: Event[]) => {
  const dayStart = day.startOf('day');
  const dayEnd = day.endOf('day');

  return events.filter(({ fromDateAtCalendarCropped, toDateAtCalendarCropped }) => (
    // check that the event intersects the day
    fromDateAtCalendarCropped! < dayEnd && toDateAtCalendarCropped! > dayStart
  ));
};

export const getRosterTagsForDay = (day: DateTime, events: Event[]) => filterForDay(day, events)
  .filter(({ originalEventType }) => originalEventType === 'RTG');

export const getEventsForDay = (day: DateTime, events: Event[]) => filterForDay(day, events)
  .filter(({ originalEventType }) => originalEventType !== 'RTG');
