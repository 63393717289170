import logger from '../logger';

const { localStorage } = window;

class SyncStorage {
  keyPrefix = '';

  setKeyPrefix = (keyPrefix: string) => {
    this.keyPrefix = keyPrefix;
  };

  prefixKey = (key: string) => {
    if (this.keyPrefix) {
      return `${this.keyPrefix}_${key}`;
    }
    return key;
  };

  getItem = (key: string, raw?: boolean) => {
    const prefixedKey = this.prefixKey(key);
    try {
      const item = localStorage.getItem(prefixedKey);
      return raw ? item : JSON.parse(item as any);
    } catch (error) {
      logger.warn(`Error decoding syncStorage item at key '${prefixedKey}'`, error);
      return undefined;
    }
  };

  setItem = (key: string, item: any, raw?: boolean) => {
    const prefixedKey = this.prefixKey(key);
    try {
      localStorage.setItem(prefixedKey, raw ? item : JSON.stringify(item));
      return true;
    } catch (error) {
      logger.warn(`Error setting syncStorage item '${prefixedKey}'`, error);
      return false;
    }
  };

  static removeItem = (key: string) => {
    localStorage.removeItem(key);
  };
}

const syncStorage = new SyncStorage();

export default syncStorage;
