import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  role: {
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'space-between',
    'marginBottom': 20,
    '& svg': {
      color: palette.iadp.checkboxColors.onEditRoles,
      fontSize: 24,
    },
  },
  label: {
    color: palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
  },
  notEditable: {
    color: palette.text.secondary,
    fontSize: 12,
  },
});
