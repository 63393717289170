import { Avatar, styled } from '@lsy-netline-ui/netline-ui';

const ModuleAvatar = styled(Avatar)(({ theme }) => ({
  width: '30px',
  minWidth: '30px',
  height: '30px',
  padding: '3px',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  borderRadius: '5px',
}));

export default ModuleAvatar;
