import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  cardContainer: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: '0.17px',
    textTransform: 'none',
  },
  scheduleCard: {
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    boxShadow: 'none',
    borderTop: `1px solid ${palette.divider}`,
  },
  scheduleCardMulticolumn: {
    marginLeft: `-${spacing(2)}px`,
    marginRight: `-${spacing(2)}px`,
  },
  scheduleInfoCard: {
    marginTop: spacing(3),
    boxShadow: 'none',
    padding: 0,
  },
  scheduleInfoLabel: {
    fontSize: 12,
    fontWeight: 500,
    color: palette.text.secondaryLabel,
  },
  scheduleInfoContent: {
    fontSize: 15,
    fontColor: palette.text.primary,
  },
  scheduleGrid: {
    'marginLeft': spacing(1.5),
    'marginRight': spacing(1.5),
    'fontFamily': 'Roboto Mono',
    'color': palette.text.primary,
    'fontSize': 14,
    'letterSpacing': 0,
    '&.IADP-MuiGrid-spacing-xs-2 > .IADP-MuiGrid-item': {
      padding: spacing(0.75),
    },
  },
  card: {
    backgroundColor: 'inherit',
    paddingLeft: 0,
    paddingRight: 0,
    boxShadow: 'none',
    borderBottom: `1px solid ${palette.divider}`,
  },
  cardTitle: {
    color: 'rgba(0,0,0,0.6)',
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
  },
  cardContent: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: 16,
    letterSpacing: 0.15,
  },
  flightDelay: {
    color: palette.success.main,
  },
  flightEarlier: {
    color: palette.error.main,
  },
});
