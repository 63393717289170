import { makeStyles } from '@lsy-netline-ui/netline-ui';

import styles from './WarningBar.styles';

const useStyles = makeStyles(styles, { name: 'WarningBar' });

const WarningBar = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default WarningBar;
