import { createContext } from 'react';

export type DialogType = 'info' | 'warning' | 'error';

export interface DialogItem {
  title?: React.ReactNode | string;
  type?: DialogType;
  content?: React.ReactNode | string | string[] | { title: string; text: string[] }[];
  buttons?: any;
  closeButton?: boolean;
  actionsClassName?: string;
}

type DialogContextType = {
  items: DialogItem[];

  hideDialog: () => void;
  showConfirmDialog: (title: React.ReactNode, handleConfirmed: Function, handleReject?: Function) => void;
  showDialog: (dialogItem: DialogItem, multiple?: boolean) => void;
  showServiceErrorMessage: (error: any, shouldParseError?: boolean) => void;
};

const DialogContext = createContext<DialogContextType>({
  items: [],

  hideDialog: () => {},
  showConfirmDialog: () => {},
  showDialog: () => {},
  showServiceErrorMessage: () => {},
});

export default DialogContext;
