import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime, Info } from 'luxon';
import clsx from 'clsx';
import {
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon,
} from '@material-ui/icons';
import { makeStyles, IconButton } from '@lsy-netline-ui/netline-ui';

import { BlockMonth } from '../../../consts';
import { useDutiesModel, useMultiColumn, useProfile } from '../../../hooks';
import styles from './BlockMonthSwitch.styles';

const useStyles = makeStyles(styles, { name: 'BlockMonthSwitch' });

const getDisplayName = (blockMonth: BlockMonth | undefined, profile: Record<string, any>, language: string) => {
  if (!blockMonth) {
    return null;
  }

  const monthIndex = language !== 'en' ?
    Info.months('long', { locale: 'en' }).findIndex(
      (month) => month.toLocaleLowerCase() === blockMonth.name.toLocaleLowerCase(),
    ) :
    -1;

  const blockMonthName = monthIndex >= 0 ?
    Info.months('long', { locale: language })[monthIndex].toUpperCase() :
    blockMonth.name;

  const formatDate = (date: DateTime) => date.toFormat(profile.dateFormats.SHORT_DATE_SEPARATED, { locale: language });
  const range = `${formatDate(blockMonth.from)} - ${formatDate(blockMonth.to)}`.replace(/ /g, '\u00A0');
  if (profile.showBlockMonthCode && profile.showBlockMonthInterval) {
    return `${blockMonthName} (${range})`;
  }
  if (profile.showBlockMonthCode) {
    return blockMonthName;
  }
  return range;
};

type Props = {
  className?: string;
};

const BlockMonthSwitch = ({ className }: Props) => {
  const classes = useStyles();
  const { profile } = useProfile();
  const { i18n: { language } } = useTranslation();
  const multiColumn = useMultiColumn();

  const {
    activeBlockMonth,
    canGoToNextBlockMonth,
    canGoToPrevBlockMonth,
    goToNextBlockMonth,
    goToPrevBlockMonth,
  } = useDutiesModel();

  const displayName = useMemo(
    () => getDisplayName(activeBlockMonth, profile, language),
    [activeBlockMonth, profile, language],
  );

  return (
    <div className={clsx(classes.root, multiColumn && 'multiColumn', className)} data-test-id="duties-page-title">
      <IconButton
        className={clsx({ [classes.disabled]: !canGoToPrevBlockMonth }, multiColumn && 'multiColumn', classes.navigate)}
        data-test-id="calendar-back-button"
        onClick={goToPrevBlockMonth}
        size="small"
      >
        <ArrowLeftIcon />
      </IconButton>
      <span className={classes.text}>{displayName}</span>
      <IconButton
        className={clsx({ [classes.disabled]: !canGoToNextBlockMonth }, multiColumn && 'multiColumn', classes.navigate)}
        data-test-id="calendar-forward-button"
        onClick={goToNextBlockMonth}
        size="small"
      >
        <ArrowRightIcon />
      </IconButton>
    </div>
  );
};

export default BlockMonthSwitch;
