import { MyDataAccess } from '@crew-webui/common/consts';

export const MAX_ADDRESS_PRIO = 9;

export const MAX_CONTACT_PRIO = 99;

export const COUNTRY_CODE_PATH = 'countryCode';

export const STATE_CODE_PATH = 'stateCode';

export interface CategoryType {
  label?: string;
  items: any;
  newButton: {
    'data-test-id': string;
    'label': string;
    'onClick': () => void;
    'disabled'?: boolean;
  };
}

export interface OptionType {
  label: string;
  labelFormatted?: React.ReactNode;
  value: string | MyDataAccess;
}

export interface MyDataField {
  editOnlyWhenCreating?: boolean;
  fieldSize?: 2 | 3 | 4;
  label: string;
  multiline?: boolean;
  options?: OptionType[];
  validation?: any;
  valueTransform?: (value?: any) => string | undefined;
  valuePath: string;
}

export type MyDataFieldType = MyDataField & {
  autocomplete?: boolean;
  date?: boolean;
  dateWithTime?: boolean;
  disabled?: boolean;
  filterFromStart?: boolean;
  helperText?: React.ReactNode;
  max?: number;
  maxLength?: number;
  min?: number;
  required?: boolean;
  type?: string;
};
