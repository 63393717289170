import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { HashRouter, Prompt } from 'react-router-dom';

import { useAppCommon, useDialog } from '../../../hooks';

const ConfirmationForRedirect = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { dirty, setDirty } = useAppCommon();
  const { showDialog, hideDialog } = useDialog();

  const userConfirmation = useCallback((msg: string, callback: (confirmed: boolean) => void) => {
    const blockNavigation = () => {
      callback(false);
      hideDialog();
    };
    const continueNavigation = () => {
      setDirty(false);
      hideDialog();
      callback(true);
    };
    showDialog({
      title: t('dirty.title'),
      content: t('dirty.text'),
      buttons: [
        { text: t('dirty.yes'), onClick: continueNavigation, secondary: true },
        {
          text: t('dirty.no'), onClick: blockNavigation, primary: true,
        },
      ],
      type: 'warning',
    });
  }, [hideDialog, setDirty, showDialog, t]);

  return (
    <HashRouter getUserConfirmation={userConfirmation}>
      <Prompt message="OK" when={dirty} />
      {children}
    </HashRouter>
  );
};

export default ConfirmationForRedirect;
