export const getCookie = (name: string) => {
  const v = document.cookie.match(`(^|;) ?pems-client-web-app=([^;]*)(;|$)`);
  return v ? v[2] : null;
};

export const setCookie = (name: string, value: string, ms = 365 * 24 * 60 * 60 * 1000) => {
  const d = new Date();
  d.setTime(d.getTime() + ms);
  document.cookie = `pems-client-web-app=${value};path=/;expires=${d.toUTCString()}`;
};

export const deleteCookie = (name: string) => {
  setCookie(name, '', -1);
};
