import { CustomerProfile } from 'consts';
import defaultProfileSettings from './defaultProfile';

const reltstProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  icons: {
    ...defaultProfileSettings.icons,
  },
  logo: 'lsy-logo.png',
};

export default reltstProfileSettings;
