import { createContext } from 'react';

type FlightSearchContextType = {
  editFlightSearchFormData: (formData: object) => void;
  getFlightSearchFormData: () => any;
};

const FlightSearchContext = createContext<FlightSearchContextType>({
  editFlightSearchFormData: () => {},
  getFlightSearchFormData: () => {},
});

export default FlightSearchContext;
