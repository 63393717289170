export enum MyDataAccess {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  BUDDY = 'BUDDY',
}

export enum MyDataType {
  INFO = 'info',
  ADDRESS = 'address',
  CONTACT = 'contact',
  PASSPORT = 'passport',
  VISA = 'visa',
}

export enum MyDataContactType {
  PHONE = 'PHONE',
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
}

export const MyDataPhoneTypes = [MyDataContactType.PHONE, MyDataContactType.MOBILE];
export const MODULE_CHOICE_POPUP_EVENT = 'showModuleChoicePopup';

export enum MyDataCategory {
  PERSONAL = 'PERSONAL',
  CONTACT = 'CONTACT',
  ADDRESS = 'ADDRESS',
  TRAVEL = 'TRAVEL',
  VISA = 'VISA',
  PASSPORT = 'PASSPORT',
  ALIEN_RESIDENT_NUMBER = 'ALIEN_RESIDENT_NUMBER',
  IDENTITY = 'IDENTITY',
  DRIVER_LICENCE = 'DRIVE_LICENCENCE',
  RAIC = 'RAIC',
}

export enum MyDataTravelDocType {
  PASSPORT = 'P',
  ALIEN_RESIDENT_NUMBER = 'A',
  IDENTITY = 'I',
  DRIVER_LICENCE = 'D',
  RESTRICTED_AREA_INDENTITY_CARD = 'R',
}

export interface Address {
  access?: MyDataAccess;
  cityName: string;
  countryCode: string;
  remark?: string;
  stateCode: string;
  street: string;
  zipCode: string;
  natKey: {
    prio: number;
  };
}

export interface Contact {
  access?: MyDataAccess;
  phoneTypeCode?: string;
  remark?: string;
  contact?: string;
  natKey: {
    prio: number;
    type: MyDataContactType;
  };
  phoneNumber?: {
    areaCode?: string;
    localNo: string;
    regionCode?: string;
  };
}

export interface TravelDoc {
  expiryDate?: string;
  issueCountry: string;
  issueDate?: string;
  addInfo?: string;
  natKey: {
    docType: MyDataTravelDocType;
    number: string;
  };
}

export interface Visa {
  docExists?: boolean | string;
  expiryDt?: string;
  natKey: {
    countryCode: string;
    issueDt: string;
    passportNumber?: string;
  };
  number: number;
}
