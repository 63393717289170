import {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { MuiThemeProvider, StylesProvider, createGenerateClassName } from '@lsy-netline-ui/netline-ui';
import { MuiPickersUtilsProvider } from '@lsy-netline-ui/netline-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import enGBLocale from 'date-fns/locale/en-GB';
import enUSLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import { useTranslation } from 'react-i18next';

import ProfileAndThemeContext from './ProfileAndThemeContext';

type Props = {
  children: React.ReactNode;
  classPrefix?: string;
  name?: string;
  ProfileMap: Record<string, { theme: any; profile: any }>;
};

const ProfileAndThemeProvider = ({
  children, name = 'default', classPrefix = '', ProfileMap,
}: Props) => {
  const { i18n: { language: currentLanguage } } = useTranslation();
  const [{ theme, profile }, setProfile] = useState(ProfileMap[name] || ProfileMap.default);

  const locale = useMemo(() => {
    if (currentLanguage === 'fr') {
      return frLocale;
    }

    return profile.calendarWeekStartMonday ? enGBLocale : enUSLocale;
  }, [currentLanguage, profile.calendarWeekStartMonday]);

  const generateClassName = useMemo(() => createGenerateClassName({ seed: classPrefix }), [classPrefix]);

  const setProfileByCustomerCode = useCallback((customerCode: string) => {
    const profileConfig = ProfileMap[customerCode.toLowerCase()] || ProfileMap.default;

    setProfile(profileConfig);

    return profileConfig; // we return {theme, profile} pair for later usage
  }, [ProfileMap]);

  const ctx = useMemo(() => ({
    theme,
    profile,
    setProfile: setProfileByCustomerCode,
  }), [theme, profile, setProfileByCustomerCode]);

  return (
    <ProfileAndThemeContext.Provider value={ctx}>
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            {children}
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </ProfileAndThemeContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileAndThemeContext);

export default ProfileAndThemeProvider;
