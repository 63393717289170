import { useTranslation } from 'react-i18next';
import { ErrorOutline } from '@material-ui/icons';
import { makeStyles, Typography } from '@lsy-netline-ui/netline-ui';

import { useConnection } from '../../../hooks';
import styles from './OfflineEditInfo.styles';

const useStyles = makeStyles(styles, { name: 'OfflineEditInfo' });

type Props = {
  checkCrewCom?: boolean;
  text?: React.ReactNode;
};

const OfflineEditInfo = ({ checkCrewCom = false, text }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { online, crewComOnline } = useConnection();

  if (online && (!checkCrewCom || crewComOnline)) {
    return null;
  }

  return (
    <Typography className={classes.root}>
      <ErrorOutline />
      <span className={classes.noConnectionInfo} data-test-id="offline-edit-message">
        {text || t('components.offlineEditInfo')}
      </span>
    </Typography>
  );
};

export default OfflineEditInfo;
