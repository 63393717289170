import {
  useContext, useEffect, useMemo, useState,
} from 'react';

import { updateAxiosAdapter, initCache } from '../../api';
import ConnectionContext from './ConnectionContext';

const ConnectionProvider = ({ children }: { children: React.ReactNode }) => {
  const [axiosAdapterUpdated, setAxiosAdapterUpdated] = useState(false);
  const [online, setOnline] = useState(true);
  const [crewComOnline, setCrewComOnline] = useState(true);

  useEffect(() => {
    initCache().then((result) => {
      if (result) {
        updateAxiosAdapter(setOnline, setCrewComOnline);
      }
      setAxiosAdapterUpdated(true);
    });
  }, [setOnline, setCrewComOnline]);

  const ctx = useMemo(() => ({
    online,
    setOnline,
    crewComOnline,
    setCrewComOnline,
  }), [online, setOnline, crewComOnline, setCrewComOnline]);

  if (!axiosAdapterUpdated) {
    return <div className="waiting">Waiting for axios adapter updated</div>;
  }

  return <ConnectionContext.Provider value={ctx}>{children}</ConnectionContext.Provider>;
};

export const useConnection = () => useContext(ConnectionContext);

export default ConnectionProvider;
