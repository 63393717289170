import { forwardRef } from 'react';
import { SvgIcon } from '@lsy-netline-ui/netline-ui';

const VacIcon = forwardRef<any>((props, ref) => (
  <SvgIcon ref={ref} {...props}>
    <path d="M11.9999 15.7085C7.04053 15.7085 4.9999 19.421 4.4624 21.2585C5.95303 20.5554 5.72803 21.6429 6.62803 21.6429C7.52803 21.6429 7.30303 21.0429 8.30615 21.0429C9.1124 21.0429 8.8499 21.6429 9.72178 21.6429C10.5937 21.6429 10.3218 21.0429 11.2874 21.0429C12.1405 21.0429 11.8218 21.6429 12.778 21.6429C13.7155 21.6429 13.3499 21.0429 14.3062 21.0429C15.2624 21.0429 14.9062 21.6429 15.778 21.6429C16.6499 21.6429 16.5562 21.0429 17.2687 21.0429C18.1124 21.0429 18.1124 21.6429 18.778 21.6429C19.078 21.6429 19.5468 21.4742 19.5468 21.3429C19.5468 20.1804 17.2593 15.7085 11.9999 15.7085Z" />
    <path d="M8.89678 15.8304C8.37928 15.8304 7.23115 16.6867 6.72178 17.1148C6.79678 12.7123 9.03428 9.7054 10.1437 8.75228L6.84365 11.2554C6.65928 11.0804 6.43865 10.4304 7.03115 9.2304C7.62365 8.0304 9.01553 7.4304 9.6374 7.2804L4.9124 6.95228C5.02178 6.62728 5.58365 5.87415 6.95615 5.46165C8.32865 5.04915 9.59678 5.58978 10.0593 5.91165L6.72178 2.47103C7.1749 2.34603 8.3924 2.2254 9.6374 2.7429C10.8824 3.2604 11.4312 4.56478 11.5499 5.15228C11.5937 4.7429 11.9212 3.7404 12.8812 3.0054C13.8412 2.2704 15.5624 2.3429 16.303 2.47103L12.8812 5.91165C12.9937 5.83665 13.603 5.62478 15.1405 5.37728C16.678 5.12978 17.7749 6.32415 18.1312 6.95228L13.3921 7.2756C13.5932 7.29371 14.1442 7.50747 15.2437 8.28353C16.5187 9.18353 16.4249 10.6398 16.2187 11.2554L12.553 8.5554C9.70303 11.1242 9.1499 14.7242 8.89678 15.8304Z" />
  </SvgIcon>
));

export default VacIcon;
