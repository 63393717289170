export default ({ palette, spacing }: Theme) => ({
  form: {
    flexGrow: 1,
  },
  field: {
    width: '100%',
    marginBottom: 24,
  },
  placeholderItem: {
    display: 'none', // hide the "placeholder" item from the list
  },
  placeholder: {
    color: palette.placeholderColor,
  },
  selectItem: {
    'paddingLeft': spacing(3),

    '&.Mui-disabled': {
      paddingLeft: spacing(2),
      opacity: 1,
      color: palette.text.primary,
      fontWeight: 500,
      pointerEvents: 'none',
    },
  },
});
