import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: '100%',
    overflow: 'hidden',
    padding: 0,
  },
  noContent: {
    color: palette.iadp.primaryLabelTextColor,
    fontSize: 14,
    fontWeight: 500,
    margin: '40px 0',
    textAlign: 'center',
  },
  pageTitle: {
    margin: spacing(2),
  },
});
