import {
  useCallback, useEffect, useMemo, useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  JwtTokenInterceptor, logger, reducerLogger, reload,
} from '../../api';
import { translateErrorMessage } from '../../utils';
import { useDialog } from '../Dialog';
import reducer, { ActionType, AuthState, initialState } from './reducer';
import { transformKeycloakUser } from './useKeycloakAuthService';
import AuthContext from './AuthContext';
import { useSessionExpirationHandler } from './KeycloakAuthProvider';

interface SpaProviderProps extends ModuleAuthProps {
  children: React.ReactNode;
  loadPemsUser?: () => Promise<any>;
}

const loggedReducer = reducerLogger(reducer);

const SpaAuthProvider = ({
  children, loadPemsUser, getToken, getProfile, logout,
}: SpaProviderProps) => {
  const { t } = useTranslation();
  const { showDialog, hideDialog } = useDialog();
  const [state, dispatch] = useReducer(loggedReducer, initialState);

  const { authState } = state;

  const handleSessionExpired = useCallback(async () => {
    dispatch({ type: ActionType.API_INIT, authState: AuthState.SESSION_EXPIRED });
    showDialog({
      title: t('common.warning'),
      content: translateErrorMessage(t, 'authentication.certificate.expired'),
      buttons: {
        text: t('button.ok'),
        onClick: () => {
          hideDialog();
          reload();
        },
      },
      type: 'warning',
    });
  }, [dispatch, hideDialog, showDialog, t]);

  const authorize = useCallback(async () => {
    try {
      logger.info('Authorizing session...');
      dispatch({
        type: ActionType.API_INIT,
        authState: AuthState.AUTHORIZING,
      });
      const myProfile = await getProfile();
      const pemsUser = loadPemsUser ? await loadPemsUser() : transformKeycloakUser(myProfile);
      logger.info('Session authorization was successful in module.');
      dispatch({
        type: ActionType.API_SUCCESS,
        authState: AuthState.AUTHORIZED,
        loginClientTimestamp: new Date().toISOString(),
        user: pemsUser,
      });
      return pemsUser;
    } catch (error) {
      logger.error('Error occurred while authorizing session in module!', error);
      return error;
    }
  }, [loadPemsUser, getProfile]);

  useSessionExpirationHandler({ authState, handleSessionExpired });

  useEffect(() => {
    if (getToken && getProfile) {
      JwtTokenInterceptor.setTokenPromise(getToken);
      dispatch({ type: ActionType.API_SUCCESS, authState: AuthState.AUTHENTICATED });
    } else {
      dispatch({ type: ActionType.API_SUCCESS, authState: AuthState.UNAUTHORIZED });
    }
  }, [getProfile, getToken]);

  useEffect(() => {
    if (authState === AuthState.AUTHENTICATED) {
      authorize();
    }
  }, [authState, authorize]);

  const authContext = useMemo(() => ({
    ...state,
    oidcEnabled: true,
    logout,
    handleSessionExpired,
  }), [handleSessionExpired, logout, state]);

  return (
    <AuthContext.Provider value={authContext}>
      {children}
    </AuthContext.Provider>
  );
};

export default SpaAuthProvider;
