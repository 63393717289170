import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import {
  MyDataType, MyDataTravelDocType, TravelDoc, Visa, MyDataCategory,
} from '@crew-webui/common/consts';
import {
  useMyData, useOnlineAuthorized, useProfile, useTodayOnServer,
} from '@crew-webui/common/hooks';

import { getUniqueTravelDocId, getUniqueVisaId } from 'utils';
import MyDataPage from '../MyDataPage/MyDataPage';
import styles from './TravelDocs.styles';
import { routePaths } from 'consts';

const useStyles = makeStyles(styles, { name: 'TravelDocs' });

const TravelDocs = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { profile: { enabledTravelDocTypes, readonlyMyDataCategories, dateFormats } } = useProfile();
  const { loadMyData, passports, visas } = useMyData();
  const getTodayOnServer = useTodayOnServer();
  const history = useHistory();

  const todayOnServer = getTodayOnServer();

  useOnlineAuthorized(() => {
    loadMyData(MyDataType.PASSPORT);
    loadMyData(MyDataType.VISA);
  }, [loadMyData]);

  const convertDate = (date: string | undefined) => (date ? DateTime.fromISO(date).toFormat(dateFormats.DATE) : undefined);

  const getRightText = (date: string | undefined) => {
    if (!date) {
      return undefined;
    }
    const parsedDate = DateTime.fromISO(date);

    if (!parsedDate.isValid) {
      return undefined;
    }
    return parsedDate.toMillis() < todayOnServer.toMillis() ?
      <span className={classes.error} data-test-id="my-data-card-expired">{t('myData.travelDoc.expired')}</span> :
      <span className={classes.success} data-test-id="my-data-card-active">{t('myData.travelDoc.active')}</span>;
  };

  const getLines = (document: TravelDoc) => ({
    content: [
      document.issueCountry,
      [convertDate(document.issueDate), convertDate(document.expiryDate)].join(' - '),
    ].filter((text) => text).join(', '),
    rightText: getRightText(document.expiryDate),
    title: document.natKey.number,
  });

  const getVisaLines = (document: Visa) => ({
    id: getUniqueVisaId(document),
    content: [
      document.natKey.countryCode,
      [convertDate(document.natKey.issueDt), convertDate(document.expiryDt)].join(' - '),
    ].filter((text) => text).join(', '),
    rightText: getRightText(document.expiryDt),
    title: document.number,
  });

  const travelDocItems = passports?.map((travelDoc) => {
    const { addInfo, natKey, ...otherFields } = travelDoc;
    const id = getUniqueTravelDocId(travelDoc);
    const attachedVisas = visas?.filter((visa) => visa.natKey.passportNumber === natKey.number);
    const visaCount = attachedVisas?.length;
    const handleClick = () => {
      history.push(`${routePaths.myData}/traveldocs/${natKey.docType}/com.lhsystems.crew.pems:pems-client-web-app:jar:2023.1.0`);
    };

    return {
      id,
      type: natKey.docType,
      onClick: handleClick,
      doc: {
        ...getLines({ natKey, ...otherFields }),
        footer: (
          <>
            {addInfo}
            {addInfo && !!visaCount && <br />}
            {!!visaCount && t('myData.travelDoc.visaAttached', { count: visaCount })}
          </>
        ),
      },
      additionalDocs: attachedVisas?.map(getVisaLines),
    };
  });

  const dataLoaded = !!passports && !!visas;

  const categories = [
    {
      type: MyDataTravelDocType.PASSPORT,
      label: t('myData.travelDoc.passports'),
      newButton: {
        'data-test-id': 'add-passport-button',
        'label': t('myData.travelDoc.passport'),
        'disabled': readonlyMyDataCategories.includes(MyDataCategory.PASSPORT),
      },
    },
    {
      type: MyDataTravelDocType.ALIEN_RESIDENT_NUMBER,
      label: t('myData.travelDoc.alienResidentCards'),
      newButton: {
        'data-test-id': 'add-alien-resident-button',
        'label': t('myData.travelDoc.alienResidentCard'),
        'disabled': readonlyMyDataCategories.includes(MyDataCategory.ALIEN_RESIDENT_NUMBER),
      },
    },
    {
      type: MyDataTravelDocType.IDENTITY,
      label: t('myData.travelDoc.identityCards'),
      newButton: {
        'data-test-id': 'add-identity-button',
        'label': t('myData.travelDoc.identityCard'),
        'disabled': readonlyMyDataCategories.includes(MyDataCategory.IDENTITY),
      },
    },
    {
      type: MyDataTravelDocType.DRIVER_LICENCE,
      label: t('myData.travelDoc.drivingLicences'),
      newButton: {
        'data-test-id': 'add-driving-licence-button',
        'label': t('myData.travelDoc.drivingLicence'),
        'disabled': readonlyMyDataCategories.includes(MyDataCategory.DRIVER_LICENCE),
      },
    },
    {
      type: MyDataTravelDocType.RESTRICTED_AREA_INDENTITY_CARD,
      label: t('myData.travelDoc.restrictedAreaIdentityCards'),
      newButton: {
        'data-test-id': 'add-restricted-area-identity-card-button',
        'label': t('myData.travelDoc.restrictedAreaIdentityCard'),
        'disabled': readonlyMyDataCategories.includes(MyDataCategory.RAIC),
      },
    },
  ]
    .filter(({ type }) => enabledTravelDocTypes?.includes(type))
    .map((category) => ({
      ...category,
      items: travelDocItems?.filter((travelDocItem) => travelDocItem.type === category.type),
      newButton: {
        ...category.newButton,
        onClick: () => history.push(`${routePaths.myData}/traveldocs/${category.type}`),
      },
    }));

  return (
    <MyDataPage
      categories={categories}
      dataLoaded={dataLoaded}
      description={t('myData.travelDoc.description')}
      subtitle={t('myData.travelDoc.title')}
      title={t('myData.travelDoc.title')}
      readonly={readonlyMyDataCategories.includes(MyDataCategory.TRAVEL)}
      onBack={() => history.push(`${routePaths.myData}`)}
    />
  );
};

export default TravelDocs;
