import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import { useConnection } from '@crew-webui/common/hooks';
import { OfflineInfo } from '@crew-webui/common/view/components';

import { ViewAgendaOutlined as VerticalViewIcon, ViewColumnOutlined as HorizontalViewIcon } from '@material-ui/icons';

import { IconButton } from '@material-ui/core';

import NotificationConfirmationContent from './NotificationConfirmationContent';
import useNotificationConfirmation from './useNotificationConfirmation';

import { titles } from 'consts';
import { IadpPage } from 'view/components';

import styles from './NotificationConfirmation.styles';

const useStyles = makeStyles(styles, { name: 'NotificationConfirmation' });

const NotificationConfirmation = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { online } = useConnection();
  const {
    allChecked,
    changes,
    containerRef,
    disableCheckBox,
    disabledConfirm,
    fromDateTimeUTC,
    handleIsScrollOver,
    hasLoaded,
    onConfirmTap,
    onSelectAllChange,
    onSelectChange,
    rosterChanges,
    selectedChanges,
    confirmableChanges,
  } = useNotificationConfirmation({ t });

  const subtitle = t('notificationConfirmation.title');

  const [verticalView, setVerticalView] = useState(true);

  return (
    <IadpPage
      headerProps={{
        title: t(titles.dutyList),
        subtitle,
        showBackButton: true,
        actions: <IconButton key="viewType" onClick={() => setVerticalView(!verticalView)}> { verticalView ? <VerticalViewIcon /> : <HorizontalViewIcon />}</IconButton>,
      }}
      contentClass={classes.root}
      dataLoaded={hasLoaded}
    >
      <div className={classes.root}>
        <OfflineInfo visible={!hasLoaded}>
          {hasLoaded && (
            (!changes || !changes.length) ? (
              <div className={classes.noContent}>{t('notificationConfirmation.noRosterChanges')}</div>
            ) : (
              <NotificationConfirmationContent
                allChecked={allChecked}
                containerRef={containerRef}
                disableCheckBox={disableCheckBox || !online}
                disabledConfirm={disabledConfirm || !online}
                fromDateTimeUTC={fromDateTimeUTC}
                handleIsScrollOver={handleIsScrollOver}
                onConfirmTap={onConfirmTap}
                onSelectAllChange={onSelectAllChange}
                onSelectChange={onSelectChange}
                rosterChanges={rosterChanges}
                selectedChanges={selectedChanges}
                confirmableChanges={confirmableChanges}
                verticalView={verticalView}
              />
            )
          )}
        </OfflineInfo>
      </div>
    </IadpPage>
  );
};

export default NotificationConfirmation;
