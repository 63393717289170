import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette } : Theme) => createStyles({
  root: {
    flexShrink: 0,
    backgroundColor: palette.error.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    padding: '2px 8px',
    fontSize: 12,
    lineHeight: '14px',
  },
});
