import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { difference } from 'lodash';

import { useUserPreferences } from './UserPreferencesProvider';

const NOTIFICATION_DURATION = 1000;

const useNotification = (localStorageId: string, IDs: string[]) => {
  const { [localStorageId]: shownIDs, setPref } = useUserPreferences();
  const timeoutRef = useRef<number>();

  // clear up when unmounting
  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  // remove notification after timeout
  const removeNotification = useCallback(() => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = window.setTimeout(() => {
      // store the list of shown IDs which was the actual when removeNotification was called
      setPref(localStorageId, IDs);
    }, NOTIFICATION_DURATION);
  }, [IDs, localStorageId, setPref]);

  const hasNotification = !!difference(IDs, shownIDs).length;

  return useMemo(() => ({ hasNotification, removeNotification }), [hasNotification, removeNotification]);
};

export default useNotification;
