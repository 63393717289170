import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@lsy-netline-ui/netline-ui';

import { OfflineEditInfo } from '../../components';
import NewsSettingsCheckbox from './NewsSettingsCheckbox';
import NewsSettingsSelectInput from '../ProfileSelectInput/ProfileSelectInput';
import styles from './NewsSettingsFormContent.styles';

const useStyles = makeStyles(styles, { name: 'NewsSettingsFormContent' });

interface Subscription {
  editable?: boolean;
  label: string;
  tag: {
    value: string;
  };
}

type Props = {
  className?: string;
  emailChecked: boolean;
  emailSubscriptionEnabled: boolean;
  emailError: false | string;
  emails: {
    value: string;
    text: string;
  }[];
  email: string;
  online: boolean;
  phone: string;
  phoneError: false | string;
  phones: {
    value: string;
    text: string;
  }[];
  preDefinedPhoneValue: string;
  pushAgreementChecked: boolean;
  pushAgreementText?: string;
  pushNotificationChecked: boolean;
  pushSubscriptionEnabled: boolean;
  selectedSubscriptions: Record<string, boolean>;
  smsAgreementChecked: boolean;
  smsAgreementText?: string;
  smsChecked: boolean;
  smsSubscriptionEnabled: boolean;
  subscriptions: Subscription[];

  onChange: (name: string, value: any) => void;
  toggleSubscription: (name: string, value: boolean) => void;
};

const NewsSettingsFormContent = ({
  className,
  email,
  emailChecked,
  emailError,
  emailSubscriptionEnabled,
  emails,
  onChange,
  online,
  phone,
  phoneError,
  phones,
  preDefinedPhoneValue,
  pushAgreementChecked,
  pushAgreementText,
  pushNotificationChecked,
  pushSubscriptionEnabled,
  selectedSubscriptions,
  smsAgreementChecked,
  smsAgreementText,
  smsChecked,
  smsSubscriptionEnabled,
  subscriptions,
  toggleSubscription,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSubscriptionToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    toggleSubscription(name, checked);
  };

  const toggleChannelCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    onChange(name, checked);
  };

  const subscriptionsSection = (
    <section className={classes.section} data-test-id="settings-subscriptions">
      <Typography className={classes.sectionTitle}>{t('settings.subscriptionTitle')}</Typography>
      {subscriptions.map(({ editable, label, tag: { value: name } }) => (
        <div key={name} className={classes.sectionItem}>
          <NewsSettingsCheckbox
            checked={!!selectedSubscriptions[name]}
            disabled={!editable || !online}
            label={label}
            name={name}
            onChange={handleSubscriptionToggle}
          />
        </div>
      ))}
    </section>
  );

  const notificationChannelsSection = (
    <section className={classes.section} data-test-id="settings-notification-channels">
      <Typography className={classes.sectionTitle}>{t('settings.informMe')}</Typography>
      {emailSubscriptionEnabled && (
        <div className={classes.sectionItem}>
          <NewsSettingsCheckbox
            checked={emailChecked}
            data-test-id="email-checkbox"
            disabled={!online}
            label={t('settings.email')}
            name="emailChecked"
            onChange={toggleChannelCheckbox}
          />

          {emailChecked && (
            <NewsSettingsSelectInput
              customItem={t('settings.customEmail')}
              data-test-id="settings-email"
              disabled={!online}
              error={emailError}
              label={t('settings.sendEmail')}
              name="email"
              onChange={(value: string) => onChange('email', value)}
              options={emails}
              value={email}
            />
          )}
        </div>
      )}

      {smsSubscriptionEnabled && (
        <div className={classes.sectionItem}>
          <NewsSettingsCheckbox
            checked={smsChecked}
            data-test-id="sms-checkbox"
            disabled={!online}
            label={t('settings.shortMessage')}
            name="smsChecked"
            onChange={toggleChannelCheckbox}
          />
          {smsChecked && (
            <NewsSettingsSelectInput
              customItem={t('settings.customPhone')}
              data-test-id="settings-sms"
              disabled={!online}
              error={phoneError}
              label={t('settings.sendMessage')}
              name="phone"
              onChange={(value: string) => onChange('phone', value)}
              options={phones}
              predefinedValue={preDefinedPhoneValue}
              type="tel"
              value={phone}
            />
          )}
          {smsAgreementText && smsChecked && (
            <div className={classes.sectionItem}>
              <NewsSettingsCheckbox
                checked={smsAgreementChecked}
                data-test-id="sms-agreement-checkbox"
                disabled={!online}
                label={smsAgreementText}
                name="smsAgreementChecked"
                onChange={toggleChannelCheckbox}
              />
            </div>
          )}
        </div>
      )}

      {pushSubscriptionEnabled && (
        <div className={classes.sectionItem}>
          <NewsSettingsCheckbox
            checked={pushNotificationChecked}
            data-test-id="push-notification-checkbox"
            disabled={!online}
            label={t('settings.pushNotification')}
            name="pushNotificationChecked"
            onChange={toggleChannelCheckbox}
          />
          {pushAgreementText && pushNotificationChecked && (
            <NewsSettingsCheckbox
              checked={pushAgreementChecked}
              data-test-id="push-notification-agreement-checkbox"
              disabled={!online}
              label={pushAgreementText}
              name="pushAgreementChecked"
              onChange={toggleChannelCheckbox}
            />
          )}
        </div>
      )}
    </section>
  );

  return (
    <div className={className}>
      <OfflineEditInfo checkCrewCom />
      {subscriptionsSection}
      {(emailSubscriptionEnabled || smsSubscriptionEnabled || pushSubscriptionEnabled) && notificationChannelsSection}
    </div>
  );
};

export default NewsSettingsFormContent;
