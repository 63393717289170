import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ breakpoints, palette }: Theme) => createStyles({
  blockMonth: {
    fontSize: 18,
    fontWeight: 500,
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    minWidth: '55%',
    [breakpoints.down('xs')]: {
      marginTop: -12,
      position: 'relative',
      left: 0,
      transform: 'none',
    },
  },
  blockMonthMobile: {
    marginTop: -12,
    position: 'relative',
    left: 0,
    transform: 'none',
  },
  selectSampleRange: {
    color: palette.text.primary,
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    width: '100%',
    marginTop: 16,
  },
  summarySection: {
    'marginTop': 20,
    'borderBottom': `1px solid ${palette.iadp.borderColor}`,
    'backgroundColor': palette.background.default,

    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      border: 0,
    },
  },
  summaryRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    color: palette.iadp.primaryLabelTextColor,
    fontSize: 14,
    lineHeight: '20px',
  },
  title: {
    color: palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  calendarContainer: {
    flex: '1 1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 4,
  },
  sampleRangeCalendar: {
    '& .IADP-MuiPickersStaticWrapper-staticWrapperRoot': {
      display: 'inline-block',
    },
  },
  sampleRangeCalendarLabel: {
    color: palette.iadp.primaryLabelTextColor,
    fontSize: '13px',
    margin: '10px 0 10px 29px',
    textTransform: 'uppercase',
  },
  sampleRangeCalendarHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  sampleRangeText: {
    fontSize: '18px',
    color: palette.text.primary,
    lineHeight: '50px',
  },
});
