import { useCallback, useMemo } from 'react';

import { services } from '../../api';
import {
  checkResponseSuccess, KeycloakAuthProvider, SpaAuthProvider,
} from '../Auth';
import { usePublicConfig, PublicConfigProps } from '../PublicConfig';

const CrewAuthChoiceProvider = ({
  children, useWithPems, getToken, getProfile, logout,
}: PublicConfigProps) => {
  const loadPemsUser = useCallback(async () => {
    const response = await services.login();
    checkResponseSuccess(response, true);
    return response.data.result;
  }, []);

  const publicConfig = usePublicConfig();

  const { security: { oidcConfig } = {} } = publicConfig;

  const loadPemsUserParam = useMemo(() => (useWithPems ? loadPemsUser : undefined), [loadPemsUser, useWithPems]);

  if (getProfile || getToken) {
    return (
      <SpaAuthProvider getToken={getToken} getProfile={getProfile} loadPemsUser={loadPemsUserParam} logout={logout}>
        {children}
      </SpaAuthProvider>
    );
  }
  return (
    <KeycloakAuthProvider loadPemsUser={loadPemsUserParam} oidcConfig={oidcConfig!}>
      {children}
    </KeycloakAuthProvider>
  );
};

export default CrewAuthChoiceProvider;
