import { useEffect, useRef } from 'react';
import { DateTime } from 'luxon';

import { useDutiesModel } from '@crew-webui/common/hooks';

import { RemarkForListing } from 'hooks';

const useScrollToDailyRemark = (items: Array<RemarkForListing>) => {
  const { activeBlockMonth } = useDutiesModel();
  const listRef = useRef<any>();

  // Scroll to the first item which is newer then the active block month start date
  useEffect(() => {
    if (!listRef.current || !activeBlockMonth || !items.length) {
      return;
    }
    const blockMonthStart = activeBlockMonth.from.toMillis();
    const blockMonthEnd = activeBlockMonth.to.toMillis();
    // items are in descending order by date
    const itemToScroll = items.find(({ datetime }) => {
      const itemDate = DateTime.fromISO(datetime).toUTC().toMillis();
      return itemDate >= blockMonthStart && itemDate <= blockMonthEnd;
    });

    if (!itemToScroll) {
      return;
    }

    const itemId = itemToScroll.logicalIdRelatedEvent;
    const itemNode = listRef.current.querySelector(`[data-id="${itemId}"]`);

    if (itemNode) {
      itemNode.scrollIntoView();
    }
  }, [activeBlockMonth, items]);

  return listRef;
};

export default useScrollToDailyRemark;
