import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  section: {
    '&:not(:last-child)': {
      marginBottom: spacing(3),
      paddingBottom: spacing(2),
      borderBottom: `1px solid ${palette.divider}`,
    },
  },
  sectionTitle: {
    marginBottom: spacing(2),
    color: palette.text.label,
    textTransform: 'uppercase',
  },
  sectionItem: {
    '&:not(:last-child)': {
      marginBottom: spacing(1.5),
    },
    '& > *:not(:last-child)': {
      marginBottom: spacing(2),
    },
    '& > *:last-child:not(:first-child)': {
      marginBottom: spacing(3),
    },
  },
});
