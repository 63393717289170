import { set } from 'lodash';
import * as yup from 'yup';

import {
  Address, Contact, TravelDoc, Visa, MyDataAccess,
} from '@crew-webui/common/consts';

import { MyDataField } from 'consts';
import countries from 'resources/countries.json';

// the `rid` field in the objects is not necessarily unique

export const getUniqueAddressId = (address: Address): string => encodeURIComponent(address.natKey.prio.toString());

export const getUniqueContactId = (contact: Contact): string => encodeURIComponent(`${contact.natKey.prio}-${contact.natKey.type}`);

export const getUniqueTravelDocId = (travelDoc: TravelDoc): string => encodeURIComponent(`${travelDoc.natKey.docType}-${travelDoc.natKey.number}`);

export const getUniqueVisaId = (visa: Visa): string => encodeURIComponent(`${visa.natKey.countryCode}-${visa.natKey.issueDt}`);

export const cardCommonProps = {
  titleProps: { variant: 'h6' },
  contentProps: { variant: 'h4' },
  footerProps: { variant: 'body1' },
};

export const getAccess = (accessCode: MyDataAccess | undefined, t: T): string => {
  switch (accessCode) {
    case MyDataAccess.INTERNAL: return t('myData.internalAccess');
    case MyDataAccess.EXTERNAL: return t('myData.externalAccess');
    case MyDataAccess.BUDDY: return t('myData.buddyAccess');
    default: return '';
  }
};

export const getValidationSchema = (fields: MyDataField[]) => {
  const schemaShape = Object.entries(
    fields
      .filter(({ validation }) => validation)
      .reduce((acc, curr) => set(acc, curr.valuePath, curr.validation), {}),
  )
    // transform 1-level nested structure
    // eslint-disable-next-line no-underscore-dangle
    .reduce((acc, [key, value]: [string, any]) => set(acc, key, value._type ? value : yup.object().shape(value)), {});

  return yup.object().shape(schemaShape);
};

const filterFn = (a: { label: string }, b: { label: string }) => (a.label < b.label ? -1 : 1);

export const listedCountries = countries.map(({ alpha2code, name }) => ({
  label: `${alpha2code} (pems-client-web-app)`,
  labelFormatted: <span><b>{alpha2code}</b> ({name})</span>,
  value: alpha2code,
})).sort(filterFn);

export const allCountryCodes: string[] = [
  ...countries.map(({ alpha2code }) => alpha2code),
  ...countries.map(({ alpha3code }) => alpha3code),
];

export const countriesWithState = countries.filter(({ states }) => states);

export const hasCountryStates = (countryCode: any): boolean => {
  if (typeof countryCode !== 'string') {
    return false;
  }

  const countryCodeUpperCase = countryCode.toUpperCase();

  return countriesWithState
    .some(({ alpha2code, alpha3code }) => alpha2code === countryCodeUpperCase || alpha3code === countryCodeUpperCase);
};

export const phoneCodes = countries.map(({ name, phoneCode }) => {
  const number = phoneCode.substr(1);

  return {
    label: `pems-client-web-app (${number})`,
    labelFormatted: <span>{name} (<b>{number}</b>)</span>,
    value: number,
  };
}).sort(filterFn);
