import { Route, Switch } from 'react-router-dom';
import { makeStyles, CircularProgress } from '@lsy-netline-ui/netline-ui';

import { useApiStatus } from '../../../hooks';
import styles from './ProgressIndicator.styles';

const useStyles = makeStyles(styles, { name: 'ProgressIndicator' });

const ProgressIndicator = () => {
  const classes = useStyles();
  const { showProgressOnUrls } = useApiStatus();

  const progressIndicator = (
    <CircularProgress className={classes.root} data-test-id="progress-indicator" size={50} />
  );

  return (
    <Switch>
      <Route path={showProgressOnUrls}>{progressIndicator}</Route>
    </Switch>
  );
};

export default ProgressIndicator;
