import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  root: {
    'position': 'relative',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'center',
    'width': spacing(4),
    'height': spacing(4),
    'margin': 'auto',
    'zIndex': 1,

    '& svg': {
      fontSize: 24,
    },
  },
  iconContainerColor: {
    color: palette.dutyList.defaultIconColor,
  },
  iconContainerParListItem: {
    'width': 28,
    'height': 28,
    'borderRadius': '50%',
    'border': '2px solid',
    'borderColor': palette.dutyList.subeventIconOutlineColor,
    'backgroundColor': palette.background.secondary,

    '& svg': {
      fontSize: 16,
    },
  },
  iconContainerParListItemLeg: {
    backgroundColor: palette.events.leg.backgroundColor,
    color: palette.events.leg.textColor,
  },
  iconContainerText: {
    padding: 2,
    borderRadius: '50%',
    fontSize: 10,
    overflow: 'hidden',
    backgroundColor: palette.events.textIcon.backgroundColor,
    color: palette.events.textIcon.textColor,
  },
  // if event has NO icon, apply to the backgroundColor
  iconContainerGround: {
    backgroundColor: palette.events.ground.backgroundColor,
  },
  iconContainerVac: {
    backgroundColor: palette.events.vac.backgroundColor,
  },
  iconContainerSim: {
    backgroundColor: palette.events.sim.backgroundColor,
  },
  iconContainerFpr: {
    backgroundColor: palette.events.fpr.backgroundColor,
  },
  iconContainerSby: {
    backgroundColor: palette.events.sby.backgroundColor,
  },
  iconContainerGpr: {
    backgroundColor: palette.events.gpr.backgroundColor,
  },
  iconContainerSick: {
    backgroundColor: palette.events.sick.backgroundColor,
  },
  airplane: {
    color: palette.events.par.backgroundColor,
  },
  rotate: {
    transform: 'rotate(90deg)',
  },
  rosterChangeIcon: {
    color: palette.events.susp.textColor,
  },
  iconRtg: {
    color: palette.events.rtg.backgroundColor,
  },
  // if event has icon, apply to the color
  iconOff: {
    color: palette.events.off.backgroundColor,
  },
  iconFpr: {
    color: palette.events.fpr.backgroundColor,
  },
  iconSby: {
    color: palette.events.sby.backgroundColor,
  },
  iconVac: {
    color: palette.events.vac.backgroundColor,
  },
  iconSick: {
    color: palette.events.sick.backgroundColor,
  },
});
