import { useCallback } from 'react';
import { DateTime } from 'luxon';

import { useCrewAuth } from '../CrewAuth';

export const getTodayOnServerByParams = (
  loginClientTimestamp: string | undefined,
  refTimestamp: string | undefined,
  refTimestampLtAtHb: string | undefined,
  useUtc: boolean,
) => {
  if (!loginClientTimestamp) {
    throw new Error('loginClientTimestamp not defined in login request');
  }
  const timeSinceLogin = DateTime.fromISO(loginClientTimestamp).diffNow();
  if (useUtc) {
    if (!refTimestamp) {
      throw new Error('refTimestamp not defined in login request');
    }
    return DateTime.fromISO(refTimestamp).minus(timeSinceLogin).toUTC();
  }
  if (!refTimestampLtAtHb) {
    throw new Error('refTimestampLtAtHb not defined in login request');
  }
  return DateTime.fromISO(refTimestampLtAtHb).minus(timeSinceLogin).toUTC();
};

/**
 * Returns a function which retrieves the current date on server
 */
const useTodayOnServer = () => {
  const { loginClientTimestamp, user: { refTimestamp, refTimestampLtAtHb } } = useCrewAuth();

  return useCallback(
    (useUtc: boolean = false) => getTodayOnServerByParams(loginClientTimestamp, refTimestamp, refTimestampLtAtHb, useUtc),
    [loginClientTimestamp, refTimestamp, refTimestampLtAtHb],
  );
};

export default useTodayOnServer;
