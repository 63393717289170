import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MenuItem, makeStyles, TextField } from '@lsy-netline-ui/netline-ui';
import { useUtils } from '@lsy-netline-ui/netline-ui/pickers';
import * as yup from 'yup';

import { printableRuleWithAccents } from '@crew-webui/common/utils';
import {
  useAppCommon, useConnection, useDutiesModel, useTodayOnServer,
} from '@crew-webui/common/hooks';
import { DatePicker, OfflineEditInfo, DATE_FORMAT } from '@crew-webui/common/view/components';

import { routePaths, titles } from 'consts';
import { useDailyRemarkActions, useDailyRemarkTypes } from 'hooks';
import { IadpPage } from 'view/components';
import styles from './NewDailyRemark.styles';

const useStyles = makeStyles(styles, { name: 'NewDailyRemark' });

const formId = 'create-new-remark-form';

const TYPE_GROUP_SEPARATOR = '----------';

const NewDailyRemark = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { setDirty, showFooter, hideFooter } = useAppCommon();
  const { createDailyRemark, isDailyRemarkCreating } = useDailyRemarkActions();
  const dailyRemarkTypes = useDailyRemarkTypes();
  const { online } = useConnection();
  const getTodayOnServer = useTodayOnServer();
  const { rosterPeriod } = useDutiesModel();
  const utils = useUtils();

  const [values, setValues] = useState({
    date: '',
    remarkType: '',
    comment: '',
  });

  useEffect(() => {
    setDirty(Object.values(values).some((value) => value));
  }, [setDirty, values]);

  useEffect(() => {
    hideFooter();
    return () => showFooter();
  }, [showFooter, hideFooter]);

  const canSend = !!values.date && !!values.remarkType;

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  }, []);

  const handleDateChange = useCallback((dateStr: string) => {
    setValues((prevValues) => ({ ...prevValues, date: dateStr }));
  }, []);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDirty(false);
    createDailyRemark(values).then(() => {
      history.push(routePaths.dailyRemarks);
    });
  }, [createDailyRemark, history, setDirty, values]);

  const shouldDisableDate = useCallback(
    (date: Date | null) => utils.format(date, DATE_FORMAT) < utils.format(utils.date(getTodayOnServer()), DATE_FORMAT),
    [getTodayOnServer, utils],
  );

  const dPAst = useMemo(() => utils.format(utils.date(getTodayOnServer()), DATE_FORMAT) >= utils.format(new Date(), DATE_FORMAT), [utils, getTodayOnServer]);

  const commentError = useMemo(() => {
    const commentSchema = yup.string().matches(printableRuleWithAccents.regex, printableRuleWithAccents.errorMessageCreator(t));

    try {
      commentSchema.validateSync(values.comment);
      return false;
    } catch (error: any) {
      return error.message;
    }
  }, [values.comment, t]);

  return (
    <IadpPage
      headerProps={{ title: t(titles.dailyRemarks), subtitle: t('dailyRemarks.new.title'), showBackButton: true }}
      dataLoaded={!!rosterPeriod}
      submitButtonProps={{
        'children': t('dailyRemarks.new.sendRemark'),
        'data-test-id': 'send-remark-button',
        'disabled': !canSend || !online || !!commentError || isDailyRemarkCreating,
        'form': formId,
        'type': 'submit',
      }}
    >
      <OfflineEditInfo />
      <form className={classes.form} onSubmit={handleSubmit} id={formId}>
        <DatePicker
          className={classes.field}
          data-test-id="remark-date-field"
          // only using `shouldDisableDate` would always allow paging to the past in the calendar
          disablePast={dPAst}
          maxDate={rosterPeriod?.to.toISODate()}
          disabled={!online}
          initialFocusedDate={getTodayOnServer().toISODate()}
          label={t('dailyRemarks.new.dateLabel')}
          onChange={handleDateChange}
          placeholder={t('dailyRemarks.new.datePlaceholder')}
          required
          shouldDisableDate={shouldDisableDate}
          value={values.date}
        />
        <TextField
          SelectProps={{
            displayEmpty: true,
          }}
          className={classes.field}
          data-test-id="remark-type-field"
          disabled={!online}
          id="remarkType"
          label={t('dailyRemarks.new.typeLabel')}
          name="remarkType"
          onChange={handleChange}
          placeholder={t('dailyRemarks.new.typePlaceholder')}
          required
          select
          value={values.remarkType}
        >
          <MenuItem className={classes.placeholderItem} disabled value="">
            <span className={classes.placeholder}>{t('dailyRemarks.new.typePlaceholder')}</span>
          </MenuItem>
          {dailyRemarkTypes.map(({ groupName, id, name }) => (
            <MenuItem
              key={id}
              className={classes.selectItem}
              data-test-id="remark-type-option"
              disabled={groupName}
              value={id}
            >
              {name || TYPE_GROUP_SEPARATOR}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={classes.field}
          data-test-id="remark-comment-field"
          disabled={!online}
          error={!!commentError}
          helperText={commentError}
          id="comment"
          label={t('dailyRemarks.new.commentLabel')}
          multiline
          name="comment"
          onChange={handleChange}
          placeholder={t('dailyRemarks.new.commentPlaceholder')}
          rows={3}
          value={values.comment}
        />
      </form>
    </IadpPage>
  );
};

export default NewDailyRemark;
