import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  root: {
    'display': 'flex',
    'flexWrap': 'wrap',
    'marginLeft': spacing(1),
    'color': palette.text.primary,
    'fontSize': 12,
    'lineHeight': '16px',

    '&:first-child': {
      marginBottom: spacing(2),
    },
    '&:not(:first-child)': {
      marginTop: spacing(2),
    },
  },
  item: {
    'marginBottom': spacing(1),

    '&:not(:last-child)': {
      marginRight: spacing(3),
    },
  },
  label: {
    fontWeight: 500,
  },
});
