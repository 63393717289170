import { useTranslation } from 'react-i18next';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import { useIadpParams } from '@crew-webui/common/api';
import { useBroadcastMessage } from '@crew-webui/common/hooks';
import { NoContentMessage } from '@crew-webui/common/view/components';
import { BroadcastContent } from '@crew-webui/common/view';

import { IadpPage } from 'view/components';
import styles from './BroadcastMessage.styles';

const useStyles = makeStyles(styles, { name: 'BroadcastMessage' });

const BroadcastMessage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useIadpParams<{ id: string }>();
  const { hasLoaded, message } = useBroadcastMessage(id);

  const showNoContentMessage = hasLoaded && !message;

  return (
    <IadpPage
      checkCrewComOffline
      headerProps={{ title: t('news.title'), subtitle: t('news.broadcast'), showBackButton: true }}
      dataLoaded={hasLoaded}
    >
      <div className={classes.broadcastContent}>
        {showNoContentMessage && <NoContentMessage>{t('error.messageNotFound')}</NoContentMessage>}
        {message && <BroadcastContent message={message} />}
      </div>
    </IadpPage>
  );
};

export default BroadcastMessage;
