import { createContext } from 'react';

export type ClearAllProgress = () => void;

export type ApiStatusContextType = {
  showProgressOnUrls: string[];
  clearAllProgress: ClearAllProgress;
};

const ApiStatusContext = createContext<ApiStatusContextType>({
  showProgressOnUrls: [],
  clearAllProgress: () => {},
});

export default ApiStatusContext;
