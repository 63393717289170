import logger from './logger';

type Reducer = (state: any, action: any) => any;
export const reducerLogger = (reducer: Reducer): Reducer => (state, action): any => {
  logger.groupCollapsed(`%c action %c${action.type}`, 'color: gray; font-weight: lighter;', 'color: inherit;');
  logger.log('%c prev state', 'color: #9E9E9E; font-weight: 700;', state);
  logger.log('%c action', 'color: #00A7F7; font-weight: 700;', action);

  const newState = reducer(state, action);

  logger.log('%c next state', 'color: #47B04B; font-weight: 700;', newState);
  logger.groupEnd();

  return newState;
};

export default reducerLogger;
