import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import { isEqual } from 'lodash';
import clsx from 'clsx';

import { services, useIadpParams } from '@crew-webui/common/api';
import { useAppCommon, useConnection } from '@crew-webui/common/hooks';
import { OfflineEditInfo } from '@crew-webui/common/view/components';

import { IadpPage } from 'view/components';
import Role from './Role';

import { RoleType } from './roleTypes';
import styles from './EditRoles.styles';

const useStyles = makeStyles(styles, { name: 'EditRoles' });

const mergeRoleArrays = (availableRoles: RoleType[], assignedRoles: RoleType[]) => {
  const result = assignedRoles
    .filter((assignedRole) => !availableRoles.some(({ id }) => assignedRole.id === id))
    .map((role) => ({ ...role, updateByCrewMember: false }));

  return availableRoles
    .concat(result)
    .map(({ id, shortName, updateByCrewMember }) => ({
      id,
      shortName,
      updateByCrewMember,
      setOnEvent: assignedRoles.some((assignedRole) => assignedRole.id === id),
    }))
    .filter((role) => role.updateByCrewMember || role.setOnEvent);
};

const EditRoles = ({
  assignedRoles, availableRoles, history, subtitle,
}: {
  assignedRoles: RoleType[]; availableRoles: RoleType[]; history: any; subtitle: any;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { logicalId, crmId } = useIadpParams<{ logicalId: string; crmId: string }>();
  const logicalEventId = logicalId ? decodeURIComponent(logicalId) : undefined;
  const {
    dirty, setDirty, showFooter, hideFooter,
  } = useAppCommon();
  const [editedRoles, setEditedRoles] = useState(assignedRoles);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { online } = useConnection();

  useEffect(() => {
    hideFooter();
    return () => showFooter();
  }, [showFooter, hideFooter]);

  useEffect(() => {
    setEditedRoles(mergeRoleArrays(availableRoles, assignedRoles));
  }, [assignedRoles, availableRoles]);

  useEffect(() => {
    const initialRoles = mergeRoleArrays(availableRoles, assignedRoles);
    setDirty(!!editedRoles.length && !isEqual(editedRoles, initialRoles));
  }, [editedRoles, assignedRoles, availableRoles, setDirty]);

  const onRemoveRole = useCallback((id: number) => {
    setEditedRoles((prevState) => prevState.map((role) => {
      if (role.id === id) {
        return { ...role, setOnEvent: false };
      }
      return role;
    }));
  }, []);

  const onAddRole = useCallback((id: number) => {
    setEditedRoles((prevState) => prevState.map((role) => {
      if (role.id === id) {
        return { ...role, setOnEvent: true };
      }
      return role;
    }));
  }, []);

  const onDoneClick = useCallback(() => {
    setIsSubmitting(true);
    const updatedRoles = editedRoles
      .map((editedRole) => ({ roleNumber: editedRole.id, setOnEvent: editedRole.setOnEvent }));

    services
      .updateRoles({
        crmId,
        logicalId: logicalEventId,
        roles: updatedRoles,
      })
      .then(({ data }) => {
        const { success } = data;

        if (!success) {
          return;
        }

        history.goBack();
        setEditedRoles([]);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [crmId, logicalEventId, editedRoles, history, setIsSubmitting]);

  return (
    <IadpPage
      contentClass={classes.root}
      headerProps={{ title: t('duties.main.title'), subtitle: t('duties.legDetails.editingRoles'), showBackButton: true }}
      dataLoaded={!!assignedRoles.length}
      submitButtonProps={{
        'children': t('button.done'),
        'data-test-id': 'submit-button',
        'disabled': !online || !dirty || isSubmitting,
        'onClick': onDoneClick,
        'type': 'submit',
      }}
    >
      {subtitle}
      <OfflineEditInfo />
      <div className={clsx(classes.section, classes.roles)} data-test-id="edit-roles">
        <div className={classes.sectionTitle}>{t('duties.legDetails.roles')}</div>
        {editedRoles.map((role) => (
          <Role
            key={role.id}
            role={role}
            onAdd={onAddRole}
            onRemove={onRemoveRole}
            disabled={!online}
          />
        ))}
      </div>
    </IadpPage>
  );
};

export default EditRoles;
