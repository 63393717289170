import {
  useCallback, useContext, useMemo, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { logger, services } from '@crew-webui/common/api';
import { FeatureAccess, LocalStorageKeys } from '@crew-webui/common/consts';
import {
  useCrewAuth,
  useDialog,
  useDutiesModel,
  useFeature,
  useNotification,
  useOnlineAuthorized,
  useUpdates,
  useUserPreferences,
} from '@crew-webui/common/hooks';

import { simpleHash } from '@crew-webui/common/utils';

import { DateFormat, Feature } from 'consts';

import useMarkingAsRead from './useMarkingAsRead';
import DailyRemarksContext, { CreateRemark, Remark } from './DailyRemarksContext';

/**
 * @param {Object} remark
 * @returns {boolean}
 */
const isRemarkFromScheduler = (remark: Remark) => remark.remarkSender === 'SCHED';

type RemarkType = {
  id: string;
  text: string;
};

export const DailyRemarksProvider = ({ children }: { children: React.ReactNode }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [dailyRemarks, setDailyRemarks] = useState<Remark[]>([]);
  const { lastDailyRemarkTimestamp } = useUpdates();
  const { wholeIntervalToShowInCalendar, rosterPeriod } = useDutiesModel();
  const { crmId } = useCrewAuth();
  const { [LocalStorageKeys.LAST_READ_DAILY_REMARK]: storedTimestamp, setPref } = useUserPreferences();

  const IDs = useMemo(
    () => dailyRemarks.filter(isRemarkFromScheduler).map((remark) => remark.logicalIdRelatedEvent),
    [dailyRemarks],
  );
  const { hasNotification, removeNotification } = useNotification(LocalStorageKeys.SHOWN_DAILY_REMAK_IDS, IDs);

  const { from } = wholeIntervalToShowInCalendar || {};
  const { to } = rosterPeriod || {};
  const fromDtUtc = from?.toFormat(DateFormat.DATETIME_ISO);
  const toDtUtc = to?.toFormat(DateFormat.DATETIME_ISO);

  const loadDailyRemarks = useCallback(() => {
    if (!fromDtUtc || !toDtUtc) {
      return undefined;
    }

    return services.getRemarks({ crmId, fromDtUtc, toDtUtc })
      .then(({ data: { result, success } }) => {
        if (!success) {
          return;
        }
        setDailyRemarks(result.dailyRemarks || []);
        setHasLoaded(true);
      })
      .catch((error) => {
        setHasLoaded(false);
        logger.error(error);
      });
  }, [crmId, fromDtUtc, toDtUtc]);

  useOnlineAuthorized(() => {
    if (fromDtUtc && toDtUtc && lastDailyRemarkTimestamp && lastDailyRemarkTimestamp !== storedTimestamp) {
      loadDailyRemarks();
      setPref(LocalStorageKeys.LAST_READ_DAILY_REMARK, lastDailyRemarkTimestamp);
    }
  }, [lastDailyRemarkTimestamp, fromDtUtc, toDtUtc, storedTimestamp, loadDailyRemarks, setPref]);

  const ctx = useMemo(() => ({
    dailyRemarks, hasLoaded, hasNotification, loadDailyRemarks, removeNotification,
  }), [dailyRemarks, hasLoaded, hasNotification, loadDailyRemarks, removeNotification]);

  return <DailyRemarksContext.Provider value={ctx}>{children}</DailyRemarksContext.Provider>;
};

export const useDailyRemarkActions = () => {
  const { t } = useTranslation();
  const { loadDailyRemarks } = useContext(DailyRemarksContext);
  const { markAsRead } = useMarkingAsRead(LocalStorageKeys.READ_DAILY_REMARK_IDS);
  const { crmId } = useCrewAuth();
  const { showConfirmDialog } = useDialog();
  const [isDailyRemarkCreating, setIsDailyRemarkCreating] = useState(false);

  const createDailyRemark = useCallback(({ date, remarkType, comment }: CreateRemark) => {
    setIsDailyRemarkCreating(true);
    return services.createRemark({
      crmId,
      data: { date: DateTime.fromISO(date).toISODate(), remarkType, text: comment },
    })
      .then(({ data: { success } }) => {
        if (success) {
          loadDailyRemarks();
        }
      })
      .catch((error) => {
        logger.error(error);
      })
      .finally(() => {
        setIsDailyRemarkCreating(false);
      });
  }, [crmId, loadDailyRemarks, setIsDailyRemarkCreating]);

  const deleteDailyRemark = useCallback(
    (date: string) => {
      const handleDelete = () => {
        services.deleteRemark({ crmId, date })
          .then(({ data: { success } }) => {
            if (success) {
              loadDailyRemarks();
            }
          })
          .catch((error) => logger.error(error));
      };

      showConfirmDialog(t('dailyRemarks.deleteDialogTitle'), handleDelete);
    },
    [crmId, loadDailyRemarks, showConfirmDialog, t],
  );

  return useMemo(() => ({
    createDailyRemark,
    deleteDailyRemark,
    isDailyRemarkCreating,
    markAsRead,
  }), [createDailyRemark, deleteDailyRemark, isDailyRemarkCreating, markAsRead]);
};

export const getRemarksForListing = (
  dailyRemarks: Remark[],
  { readIds, dailyRemarksByCrmEnabled }: { readIds: RemarkType[]; dailyRemarksByCrmEnabled: boolean },
) => dailyRemarks
  .filter(({ remarkSender }) => dailyRemarksByCrmEnabled || remarkSender === 'SCHED')
  .map((item) => ({
    ...item,
    date: DateTime.fromISO(item.from?.dtUtc).toUTC().toISODate(),
    datetime: item.from?.dtUtc,
    read: readIds.some((remark: RemarkType) => {
      const text = simpleHash(item.text);
      return remark.id === item.logicalIdRelatedEvent && remark.text === text;
    }),
  }))
  // Sort them by date in descending order
  .sort((a, b) => (a.datetime > b.datetime ? -1 : 1));

export const useDailyRemarkListing = (refetchWhenMounting = false) => {
  const {
    dailyRemarks, hasLoaded, hasNotification, loadDailyRemarks, removeNotification,
  } = useContext(DailyRemarksContext);
  const { readIds } = useMarkingAsRead(LocalStorageKeys.READ_DAILY_REMARK_IDS);
  const isFeatureEnabled = useFeature();

  // fetch Daily remarks when the component loads
  useEffect(() => {
    if (refetchWhenMounting) {
      loadDailyRemarks();
    }
  }, [refetchWhenMounting, loadDailyRemarks]);
  useEffect(() => {
    if (!refetchWhenMounting && !hasLoaded) {
      loadDailyRemarks();
    }
  }, [refetchWhenMounting, hasLoaded, loadDailyRemarks]);

  const dailyRemarksForListing = useMemo(
    () => getRemarksForListing(
      dailyRemarks,
      { readIds, dailyRemarksByCrmEnabled: isFeatureEnabled(Feature.VIEW_DAILY_REMARKS, FeatureAccess.WRITE) },
    ),
    [dailyRemarks, isFeatureEnabled, readIds],
  );

  return useMemo(() => ({
    dailyRemarksFromMe: dailyRemarksForListing.filter((item) => !isRemarkFromScheduler(item)),
    dailyRemarksFromSched: dailyRemarksForListing.filter(isRemarkFromScheduler),
    hasLoaded,
    hasNotification,
    removeNotification,
  }), [dailyRemarksForListing, hasLoaded, hasNotification, removeNotification]);
};

export const useDailyRemarkNotification = () => {
  const { hasNotification } = useContext(DailyRemarksContext);
  return useMemo(() => ({ hasNotification }), [hasNotification]);
};
