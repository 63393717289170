import { useEffect } from 'react';

import { useCrewAuth } from '../CrewAuth';
import { LocalStorageKeys } from '../../consts';
import { AuthState } from '../Auth';
import { useConnection } from '../Connection';
import { useUserPreferences } from '../UserPreferences';

const useOnlineAuthorized = (fn: Function, deps: any[]) => {
  const { authState } = useCrewAuth();
  const { online } = useConnection();

  const {
    [LocalStorageKeys.ONLINE_AUTHORIZED]: onlineAuthorized,
    hasLoaded: havePrefsLoaded,
    setPref,
  } = useUserPreferences();

  const isAuthorized = authState === AuthState.AUTHORIZED;

  const haveToLogin = authState === AuthState.LOGGED_OUT || authState === AuthState.SESSION_EXPIRED;

  useEffect(() => {
    if (!havePrefsLoaded) {
      return;
    }

    if (!onlineAuthorized && isAuthorized && online) {
      // set true on first online authorized
      setPref(LocalStorageKeys.ONLINE_AUTHORIZED, true);
    }
    if (onlineAuthorized && haveToLogin && online) {
      // set false if online logged out or session expired
      setPref(LocalStorageKeys.ONLINE_AUTHORIZED, false);
    }
  }, [havePrefsLoaded, onlineAuthorized, online, isAuthorized, haveToLogin, setPref]);

  useEffect(() => {
    // Run the function also when offline and authorized
    if (!onlineAuthorized && (!isAuthorized || online)) {
      return undefined;
    }

    return fn();
  }, [onlineAuthorized, ...deps]); // eslint-disable-line
};

export default useOnlineAuthorized;
