import { makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import { useMemo } from 'react';

import { useMultiColumn } from '../../../hooks';
import DesktopHeader from '../DesktopHeader/DesktopHeader';
import MobileHeader from '../MobileHeader/MobileHeader';
import OfflineInfo from '../OfflineInfo/OfflineInfo';
import PageTitle from './PageTitle/PageTitle';

import styles from './Page.styles';
import { DesktopHeaderProps } from '../../../consts';

const useStyles = makeStyles(styles, { name: 'Page' });

export type PageProps = {
  checkCrewComOffline?: boolean;
  children: React.ReactNode;
  className?: string;
  contentClass?: string;
  dataLoaded: boolean;
  headerProps?: DesktopHeaderProps;
  noContentMessage?: React.ReactNode;
  showNoContentMessage?: boolean;
  submitButton?: React.ReactNode;
};

const Page = ({
  checkCrewComOffline = false,
  children,
  className,
  contentClass,
  dataLoaded,
  headerProps,
  submitButton,
}: PageProps) => {
  const classes = useStyles();
  const multiColumn = useMultiColumn();

  const header = useMemo(() => {
    if (!headerProps) {
      return undefined;
    }
    if (multiColumn) {
      return (
        <div>
          <DesktopHeader actions={!headerProps.subtitle && headerProps.actions} title={headerProps.title} bottomContent={headerProps.bottomContent}>
            {headerProps.children}
          </DesktopHeader>
          {headerProps.subtitle && (
            <div>
              <div className={clsx(classes.subtitle, { [classes.subtitleNewLine]: headerProps.actionsNewLine })}>
                <Page.Title
                  className={clsx({ [classes.titleNewLine]: headerProps.actionsNewLine })}
                  showBackButton={headerProps.showBackButton}
                  onBack={headerProps.onBack}
                >{headerProps.subtitle}
                </Page.Title>
                <div className={clsx({ [classes.titleNewLine]: headerProps.actionsNewLine })}>{headerProps.actions}</div>
              </div>
            </div>
          )}
        </div>
      );
    }
    return (
      <MobileHeader
        actions={headerProps.actions}
        position="sticky"
        showBackButton={headerProps.showBackButton}
        onBack={headerProps.onBack}
        actionsNewLine={headerProps.actionsNewLine}
        title={headerProps.subtitle ?? headerProps.title}
        bottomContent={headerProps.bottomContent}
      />
    );
  }, [headerProps, multiColumn, classes]);

  return (
    <div className={clsx(classes.root, className)} data-test-id="page">
      {header}
      <div className={classes.container}>
        <div className={clsx(classes.content, contentClass)}>
          <OfflineInfo checkCrewCom={checkCrewComOffline} visible={!dataLoaded}>
            {children}
          </OfflineInfo>
        </div>
      </div>
      {submitButton}
    </div>
  );
};

Page.Title = PageTitle;

export default Page;
