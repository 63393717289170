import { useRef } from 'react';
import { useSwipeable as useReactSwipeable, SwipeEventData } from 'react-swipeable';

const SWIPE_TOLERANCE = 100;

type Params = {
  animateFrom?: number;
  downAllowed?: boolean;
  leftAllowed?: boolean;
  onClick?: Function;
  onSwipeSuccess: (direction: 'up' | 'down' | 'left' | 'right') => void;
  rightAllowed?: boolean;
  upAllowed?: boolean;
  timeTolerance?: number;
};

const useSwipeable = ({
  animateFrom = 0,
  downAllowed = false,
  leftAllowed = false,
  onClick,
  onSwipeSuccess,
  rightAllowed = false,
  upAllowed = false,
  timeTolerance,
}: Params) => {
  const ref = useRef<HTMLElement | null>();
  const swiping = useRef(false);
  const rAFTicking = useRef(false); // https://css-tricks.com/debouncing-throttling-explained-examples/

  const checkTime = ({ absX, absY, velocity } : SwipeEventData) => {
    if (!timeTolerance) {
      return true;
    }
    const velocityTransform = Math.sqrt(absX * absX + absY * absY);
    const time = velocityTransform / (velocity || 1);
    // sometimes real swipe time can bigger than timeTolerance
    return time + 100 < timeTolerance;
  };
  // also called when spiwing finishes with mouse, but not with touchscreen
  const handleClick = (event: React.SyntheticEvent) => {
    if (!swiping.current) {
      if (onClick) {
        onClick(event);
      }
    } else {
      event.stopPropagation();
    }
    swiping.current = false;
  };

  // also called when swiping finishes on touch screen, setting swiping to false here is necessary
  const handleTouchEnd = (event: React.SyntheticEvent) => {
    handleClick(event);
    event.preventDefault(); // to prevent calling the handleClick event handler on mobile
  };

  /* eslint-disable no-param-reassign */
  const handlers = useReactSwipeable({
    trackMouse: true,
    onSwipedUp: (event) => {
      if (!event.first && upAllowed && event.absY > SWIPE_TOLERANCE && checkTime(event)) {
        onSwipeSuccess('up');
      }
    },
    onSwipedDown: (event) => {
      if (!event.first && downAllowed && event.absY > SWIPE_TOLERANCE && checkTime(event)) {
        onSwipeSuccess('down');
      }
    },
    onSwipedLeft: (event) => {
      if (!event.first && leftAllowed && event.absX > SWIPE_TOLERANCE && checkTime(event)) {
        onSwipeSuccess('left');
      }
    },
    onSwipedRight: (event) => {
      if (!event.first && rightAllowed && event.absX > SWIPE_TOLERANCE && checkTime(event)) {
        onSwipeSuccess('right');
      }
    },
    onSwiping: ({ absX, deltaX, first }) => {
      if (first) {
        return;
      }

      const changeStyle = () => {
        rAFTicking.current = false;
        if (!ref.current) {
          return;
        }
        if (absX < animateFrom || (deltaX <= 0 && !leftAllowed) || (deltaX >= 0 && !rightAllowed)) {
          ref.current.style.transform = 'none';
        } else {
          ref.current.style.transform = `translateX(${deltaX}px)`;
        }
      };

      if (!rAFTicking.current) {
        window.requestAnimationFrame(changeStyle);
      }
      rAFTicking.current = true;
      swiping.current = true;
    },
    onSwiped: () => {
      // the RAF is needed to make sure that it runs after changeStyle()
      window.requestAnimationFrame(() => {
        if (ref.current) {
          ref.current.style.transform = 'none';
          swiping.current = false;
        }
      });
    },
  });
  /* eslint-enable no-param-reassign */

  const setRef = (node: HTMLElement | null) => {
    ref.current = node;
    handlers.ref(node);
  };

  return {
    ...handlers,
    ref: setRef,
    onClickCapture: handleClick,
    onTouchEnd: onClick && handleTouchEnd,
  };
};

export default useSwipeable;
