import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ spacing }: Theme) => createStyles({
  root: {
    overflow: 'hidden',
    padding: 0,
    paddingTop: 16,
  },
  multiColumn: {
    padding: spacing(2),
  },
});
