import { services } from '@crew-webui/common/api';
import { CrewComDomain } from '@crew-webui/common/consts';

import {
  useConnectionChange, useCrewAuth, useFeature, useLanguageSwitch,
} from '@crew-webui/common/hooks';

import { Feature } from 'consts';

const useIadpConnectionChange = () => {
  const { authenticated } = useCrewAuth();
  const isFeatureEnabled = useFeature();
  const { enabled } = useLanguageSwitch();

  const getCrewComEndpoint = () => {
    if (!authenticated) {
      return undefined;
    }
    if (enabled) {
      return services.loadPreferredLanguage;
    }
    if (isFeatureEnabled(Feature.VIEW_NOTIF_SETTINGS)) {
      return services.loadContactInfo;
    }
    if (isFeatureEnabled(Feature.VIEW_NEWS)) {
      return () => services.getNews({ domain: CrewComDomain.IADP });
    }
    return undefined;
  };

  const crewComEndpoint = getCrewComEndpoint();
  useConnectionChange(crewComEndpoint);
};

export default useIadpConnectionChange;
