import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography, makeStyles } from '@lsy-netline-ui/netline-ui';
import { Delete as DeleteIcon } from '@material-ui/icons';

import styles from './VisaCard.styles';

const useStyles = makeStyles(styles, { name: 'VisaCard' });

type VisaCardsProp = {
  children: ReactNode;
  disableDelete: boolean;
  onDeleteClick: (event: any) => void;
};

const VisaCard = ({ children, disableDelete, onDeleteClick } : VisaCardsProp) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root} data-test-id="my-data-visa-card">
      <Typography className={classes.title} data-test-id="my-data-section-title" variant="h4">
        <span>{t('myData.travelDoc.visa')}</span>
        <IconButton
          className={classes.delete}
          data-test-id="my-data-visa-delete-button"
          disabled={disableDelete}
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </IconButton>
      </Typography>
      {children}
    </div>
  );
};

export default VisaCard;
