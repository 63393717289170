import axios, { AxiosResponse, AxiosError } from 'axios';

import { logger } from '../../api';

const CONTENT_TYPE_HEADER_KEY = 'Content-Type';

const filterMapStringValues = (orig: Record<string, any>) => {
  const result: Record<string, string> = {};
  Object.keys(orig).forEach((key) => {
    if (typeof orig[key] === 'string') {
      result[key] = orig[key];
    }
  });
  if (result[CONTENT_TYPE_HEADER_KEY] !== 'application/json') {
    result[CONTENT_TYPE_HEADER_KEY] = 'application/json';
  }
  return result;
};

// use https://github.com/silkimen/cordova-plugin-advanced-http
const isPluginAvailable = () => !!window.cordova?.plugin?.http;

const callCordovaPlugin = (config: any) => {
  const pluginOptions = {
    method: config.method,
    data: (typeof config.data === 'string') ? JSON.parse(config.data) : config.data || {},
    params: config.params,
    responseType: config.responseType || 'json',
    timeout: config.timeout,
    headers: filterMapStringValues(config.headers),
  };

  logger.debug('Call cordova http plugin', config.url, pluginOptions);
  return new Promise<AxiosResponse>((resolve, reject) => {
    window.cordova!.plugin!.http.sendRequest(
      config.url,
      pluginOptions,
      (result: any) => {
        logger.debug('Cordova http plugin request success', config.url);
        resolve({
          ...result,
          config: {
            headers: result.headers,
            url: result.url,
            ...config,
          },
        });
      },
      (err: any) => {
        logger.error('Cordova http plugin request failed', config.url, err, pluginOptions);
        const axiosErr: AxiosError = {
          ...err,
          message: err.error,
          code: err.status,
          config,
        };
        reject(axiosErr);
      },
    );
  });
};

const setupCordovaHttpPlugin = () => {
  if (isPluginAvailable()) {
    logger.info('Axios adapter changed to cordova-plugin-advanced-http plugin');
    window.cordova!.plugin.http.clearCookies();
    window.cordova!.plugin.http.setDataSerializer('json');
    axios.defaults.adapter = callCordovaPlugin;
  }
};

export default setupCordovaHttpPlugin;
