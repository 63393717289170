import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({
  breakpoints, palette, shadows, spacing,
}: Theme) => createStyles({
  root: {
    '$line + & $eventRowIcons::before, &$line $eventRowIcons::after': {
      content: '""',
    },
    '&:first-child $row': {
      marginTop: 0,
    },
    '& $toggleIcon, & $detailsButton': {
      flexShrink: 0,
      borderRadius: 4,
      width: spacing(4),
      height: spacing(4),
      minHeight: 'auto',
      boxShadow: 'none',
    },
  },
  hidden: {
    opacity: 0,
    visibility: 'hidden',
  },
  availableEventsView: {
    'marginBottom': spacing(1),
    'padding': spacing(2),
    'borderRadius': 8,
    'boxShadow': shadows[1],
    'backgroundColor': palette.background.secondary,

    '& $row': {
      margin: 0,
    },
  },
  line: {},
  toggled: {},
  multiColumn: {
    '& $rightIcons': {
      'flexDirection': 'row',

      '& > *:not(:last-child)': {
        marginRight: spacing(0.5),
        marginBottom: 0,
      },
    },
    [breakpoints.up('md')]: {
      '& $row': {
        'borderRadius': spacing(0.5),

        '&:first-child': {
          '&, & > *': {
            borderTopRightRadius: spacing(0.5),
          },
        },
        '&:last-child': {
          '&, & > *': {
            borderBottomRightRadius: spacing(0.5),
          },
        },
      },
      '& $eventRowContent': {
        paddingRight: spacing(2),
        paddingLeft: spacing(2),
      },
      '& $eventRowDetails': {
        marginLeft: spacing(2.5),
      },
    },
  },
  row: {
    borderRadius: '4px 0 0 4px',
    borderLeft: '4px solid',
    borderLeftColor: palette.events.neutral.backgroundColor,
    backgroundColor: palette.background.secondary,
    margin: '4px 0px',
    position: 'relative',
  },
  rowBroken: {
    position: 'absolute',
    borderRadius: '4px 0 0 4px',
    left: -4,
    width: 4,
    height: '100%',
    backgroundImage: 'linear-gradient(45deg, white 25%, transparent 25%),linear-gradient(-45deg, white 25%, transparent 25%), linear-gradient(45deg, transparent 75%, white 75%), linear-gradient(-45deg, transparent 75%, white 75%)',
    backgroundSize: '4px 4px',
    backgroundPosition: '0 0, 0 2px, 2px -2px, -2px 0px',
  },
  extendedPairingRow: {
    'marginRight': -2,
    'borderTop': '2px solid',
    'borderRight': '2px solid',
    'borderBottom': '2px solid',

    '&:not($detailView)': {
      boxShadow: '0 14px 28px 0 rgba(0,0,0,.26), 0 10px 10px 0 rgb(0,0,0,.22)',
    },
  },
  detailView: {
    margin: 0,
  },
  subEventRow: {
    'borderRadius': spacing(0.5),
    'backgroundColor': palette.background.secondary,
    'margin': 0,

    '&.selected': {
      background: palette.dutyList.selectedRowBackgroundColor,
    },
    '& $eventRowContent.highlighted': {
      backgroundColor: palette.dutyList.subeventHighlightColor,
    },

    '& $eventRowIcons': {
      'position': 'relative',

      '&::before, &::after': {
        content: 'unset',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        width: 2,
        height: '50%',
        backgroundColor: palette.dutyList.subeventIconOutlineColor,
      },
      '&::before': {
        bottom: '50%',
      },
      '&::after': {
        top: '50%',
      },
    },
  },
  eventRowContent: {
    'padding': '0px 5px',
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
    'position': 'relative',
    'transition': 'background-color .3s',

    '&.highlighted': {
      background: palette.dutyList.selectedRowBackgroundColor,
      color: palette.background.secondary,
    },
    '&.toggled:not(:last-child)': {
      '& $eventRowIcons::after': {
        backgroundColor: palette.dutyList.selectedRowBackgroundColor,
      },
    },
    '&[role="button"]': {
      'cursor': 'pointer',
      '-webkit-tap-highlight-color': 'transparent',
    },
    [breakpoints.up('sm')]: {
      paddingRight: spacing(1),
      paddingLeft: spacing(1),
    },
  },
  eventRowDetails: {
    'flexGrow': 1,
    'padding': '8px 16px 8px 0',
    'marginLeft': spacing(2),
    'lineHeight': '16px',
    'white-space': 'pre-wrap',
    'text-align': 'left',
  },
  eventRowIcons: {
    'alignSelf': 'stretch',
    'flexShrink': 0,
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'alignItems': 'center',
    'width': 32,

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -3,
      width: 6,
      height: 6,
      transform: 'rotate(45deg)',
      backgroundColor: 'transparent',
      transition: 'background-color .3s',
    },
  },
  toggleIcon: {},
  rightIcons: {
    'display': 'flex',
    'alignItems': 'center',
    'flexDirection': 'column',

    '& > *:not(:last-child)': {
      marginBottom: spacing(1),
    },
  },
  detailsButton: {
    backgroundColor: 'transparent',
    opacity: 0.6,
  },
  secondaryText: {
    'color': palette.text.secondary,
    'fontSize': 12,
    'fontWeight': 500,

    '&:not(:last-child)': {
      marginBottom: spacing(0.5),
    },
  },
  highlighted: {
    fontWeight: 500,
  },
  eventRemarkIcon: {
    marginLeft: spacing(1.5),
    color: palette.text.label,
    fontSize: 14,
    verticalAlign: 'middle',
  },
  departureArrival: {
    'fontFamily': 'Roboto Mono',
    'fontSize': 13,
    'color': palette.text.primary,

    '&:not(:last-child)': {
      marginBottom: spacing(0.5),
    },
  },
  delayedDepartureArrival: {
    color: palette.dutyList.lateTimeColor,
  },
  earlyDepartureArrival: {
    color: palette.dutyList.earlyTimeColor,
  },
  airportCode: {
    width: spacing(3),
  },
});
