import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  loadConfigByPath,
  logger,
  services,
} from '../../api';
import { useDialog } from '../Dialog';
import { useDomain } from '../Domain';
import { useProfile } from '../Profile';
import { AuthState } from '../Auth';
import { CrewAuthChoiceProvider, CrewAuthProvider } from '../CrewAuth';

import defaultPublicConfig from './defaultPublicConfig.json';
import PublicConfigContext, {
  PublicConfigResponse,
  PublicConfigResult,
} from './PublicConfigContext';

export interface PublicConfigProps extends ModuleAuthProps {
  children: React.ReactNode;
  useWithPems: boolean;
}

const transformError = (err: any) => JSON.stringify(err, null, 2);

export const PublicConfigProvider = ({
  children, useWithPems = false, getToken, getProfile, logout, services: appFrameServices,
}: PublicConfigProps) => {
  const { t } = useTranslation();

  const { showServiceErrorMessage } = useDialog();
  const { pemsDomain } = useDomain();
  const { setProfile } = useProfile();

  const [publicConfig, setPublicConfig] = useState<PublicConfigResult>({
    ...defaultPublicConfig,
    publicConfigLoaded: false,
    defaultSecurityLoaded: false,
    isNetlineAppframe: !!appFrameServices,
    isCsiAppframe: !appFrameServices && !!getToken,
  });

  const loadState = useMemo(() => {
    if (!publicConfig.security || !publicConfig.security.authType) {
      // Security not defined in security.json, must load public config which must contains the security config
      return undefined;
    }

    return AuthState.AUTHENTICATED;
  }, [publicConfig.security]);

  const checkSecurity = useCallback((securityValue: SecurityConfig | undefined) => {
    if (!securityValue) {
      showServiceErrorMessage(t('errorMap.invalidSecurity'));
      logger.error('security.json must exists or security attribute must be defined in publicConfig service');
      return false;
    }
    if (securityValue.authType !== 'OIDC') {
      showServiceErrorMessage(t('errorMap.invalidSecurity'));
      logger.error('The security config authType value must be \'OIDC\'');
      return false;
    }
    return true;
  }, [showServiceErrorMessage, t]);

  useEffect(() => {
    loadConfigByPath('./security.json').then((security) => {
      if (!security || !(security.authType === 'OIDC')) {
        if (Object.keys(security).length !== 0) {
          logger.error('Wrong security value loaded from security.json, ignore it', security);
        }
        setPublicConfig((prevState) => ({
          ...prevState,
          defaultSecurityLoaded: true,
        }));
      } else {
        logger.info('Security loaded from security.json', security);
        setPublicConfig((prevState) => ({
          ...prevState,
          security,
          defaultSecurityLoaded: true,
        }));
      }
    }, (err: any) => {
      logger.error('Error while load security.json', err);
      setPublicConfig((prevState) => ({
        ...prevState,
        securityErr: transformError(err),
        defaultSecurityLoaded: true,
      }));
    });
  }, []);

  const handlePublicConfigLoaded = useCallback((publicConfigResult: PublicConfigResult) => {
    // public config loaded, setup config values
    setProfile(publicConfigResult.customerCode);

    if (logger.setLogLevel) {
      logger.setLogLevel(publicConfigResult.logLevel);
    }
    setPublicConfig(((prevState) => ({
      ...prevState,
      ...publicConfigResult,
      security: prevState.security || publicConfigResult?.security,
      publicConfigLoaded: true,
    })));
  }, [setProfile]);

  const loadPublicConfig = useCallback(() => {
    if (publicConfig.publicConfigLoaded) {
      return;
    }
    logger.debug('Loading public configuration...');

    const processError = (error: any) => {
      logger.error('Error while load public config', error);
      setPublicConfig((prevState) => ({
        ...prevState,
        publicConfigErr: transformError(error),
        publicConfigLoaded: true,
      }));
    };
    const processResult = ({ data }: { data: PublicConfigResponse }) => {
      const { result, success } = data;
      if (!success || !result) {
        processError('Public config load not success or result not found');
        return;
      }
      // no security value in security.json and wrong security definition from service
      if (!publicConfig.security && !checkSecurity(result?.security)) {
        processError('Not found security in config');
        return;
      }
      handlePublicConfigLoaded(result!);
      logger.debug('Public configuration loaded.', result);
      // hold security if exists
    };

    try {
      services.loadPublicConfig({ domain: pemsDomain }).then(processResult).catch(processError);
    } catch (err) {
      processError(err);
    }
  }, [checkSecurity, handlePublicConfigLoaded, pemsDomain, publicConfig.publicConfigLoaded, publicConfig.security]);

  useEffect(() => {
    if (publicConfig && publicConfig.publicConfigLoaded) {
      return;
    }
    // if no security settings are in security.json or file not exists then must load the public config, it should have to contains it.
    if (publicConfig.defaultSecurityLoaded && !publicConfig.security) {
      loadPublicConfig();
    }
  }, [loadPublicConfig, publicConfig, showServiceErrorMessage, t, useWithPems]);

  const onAuthStateChanged = useCallback((authState: AuthState | undefined) => {
    if (loadState === authState) {
      // state is ready to load the public configuration
      logger.info('Load security state are ready to load public configuration.');
      if (useWithPems) {
        loadPublicConfig();
      } else {
        logger.debug('Use public config without pems configuration, from security.json');
        setPublicConfig(((prevState) => ({
          ...prevState,
          publicConfigLoaded: true,
        })));
      }
    }
  }, [loadState, loadPublicConfig, useWithPems]);

  const ctx = useMemo(() => ({
    ...publicConfig,
    onAuthStateChanged,
    appFrameServices,
  }), [appFrameServices, publicConfig, onAuthStateChanged]);

  const showErrors = !!publicConfig.publicConfigErr;

  if (showErrors) {
    return (
      <div className="waiting">
        Error while public config loaded<br />
        Version: {window.VERSION}
        <div>
          <textarea value={publicConfig.publicConfigErr} rows={40} style={{ width: '100%' }} disabled />
        </div>
        {publicConfig.securityErr && (
          <div>
            <textarea value={publicConfig.securityErr} rows={40} style={{ width: '100%' }} disabled />
          </div>
        )}
      </div>
    );
  }
  if (!publicConfig || !publicConfig.security) {
    return (
      <div className="waiting">
        Waiting for public config loaded
      </div>
    );
  }

  return (
    <PublicConfigContext.Provider value={ctx}>
      <CrewAuthChoiceProvider useWithPems={useWithPems} getToken={getToken} getProfile={getProfile} logout={logout}>
        <CrewAuthProvider useWithPems={useWithPems}>
          {children}
        </CrewAuthProvider>
      </CrewAuthChoiceProvider>
    </PublicConfigContext.Provider>
  );
};

export const usePublicConfig = () => useContext(PublicConfigContext);

export default PublicConfigProvider;
