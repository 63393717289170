import { useTranslation } from 'react-i18next';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import { useFeature, useMultiColumn, useNewsListing } from '@crew-webui/common/hooks';
import { NewsCard } from '@crew-webui/common/view';
import { NoContentMessage } from '@crew-webui/common/view/components';

import { Feature } from 'consts';
import { IadpPage } from 'view/components';
import useGetEventsWhenMounted from './useGetEventsWhenMounted';
import styles from './News.styles';

const useStyles = makeStyles(styles, { name: 'News' });

const News = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isFeatureEnabled = useFeature();
  const {
    hasLoaded, markAsRead, news, removeNewsItem,
  } = useNewsListing({
    checkInEnabled: isFeatureEnabled(Feature.VIEW_CHECK_IN),
    notificationsEnabled: isFeatureEnabled(Feature.VIEW_NOTIFICATIONS),
  });
  useGetEventsWhenMounted();
  const multiColumn = useMultiColumn();

  const showNoContentMessage = hasLoaded && !news.length;

  return (
    <IadpPage
      checkCrewComOffline
      contentClass={clsx(classes.contentClass, { [classes.multiColumn]: multiColumn })}
      dataLoaded={hasLoaded}
      headerProps={{ title: t('news.title') }}
    >
      {showNoContentMessage && <NoContentMessage>{t('news.noNews')}</NoContentMessage>}
      {news.map((newsItem) => (
        <NewsCard key={newsItem.id} {...newsItem} markAsRead={markAsRead} remove={removeNewsItem} />
      ))}
    </IadpPage>
  );
};

export default News;
