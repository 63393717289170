import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles, AppHeader as NuiAppHeader, Typography } from '@lsy-netline-ui/netline-ui';

import { useCrewAuth } from '../../../hooks';
import styles from './AppHeader.styles';

const useStyles = makeStyles(styles, { name: 'AppHeader' });

const AppHeader = () => {
  const classes = useStyles();
  const { user: { crmId, firstName, lastName } } = useCrewAuth();

  const title = (
    <Typography className={classes.title} component="span" data-test-id="app-title" variant="body1"><Trans i18nKey="login.header" /></Typography>
  );
  const rightContent = crmId && (
    <Typography variant="body1" component="span">
      <span className={classes.profileCaption} data-test-id="profile-caption">
        {`${crmId}, ${firstName} ${lastName}`}
      </span>
    </Typography>
  );

  return (
    <NuiAppHeader className={classes.root} data-test-id="app-header" LinkComponent={Link} title={title}>
      {rightContent}
    </NuiAppHeader>
  );
};

export default AppHeader;
