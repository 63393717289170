import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import { isOffline, services } from '../../api';
import { useDialog } from '../Dialog';
import { usePublicConfig } from '../PublicConfig';

enum Status {
  OK = 'OK',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

enum Platform {
  Android = 'Android',
  iOS = 'iOS',
}

interface CheckVersionResult {
  status: Status;
  popupTextAndroid?: string;
  popupTextiOS?: string;
}

type CheckVersionResponse = PemsResponse<CheckVersionResult>;

const appVersion = process.env.REACT_APP_VERSION?.trim();
const versionRegex = /^20\d+\.\d+\.\d+$/;

const shouldCheckVersion = (platform?: string) => (
  (platform === Platform.Android || platform === Platform.iOS) &&
  appVersion && versionRegex.test(appVersion)
);

const VersionCheck = ({ children }: { children: ReactElement | null }) => {
  const { t } = useTranslation();
  const { showDialog, showServiceErrorMessage } = useDialog();

  const [compatible, setCompatible] = useState<boolean | undefined>();

  const { publicConfigLoaded } = usePublicConfig();

  const platform = window.device?.platform;

  useEffect(() => {
    if (!shouldCheckVersion(platform)) {
      setCompatible(true);
      return;
    }
    if (!publicConfigLoaded || compatible !== undefined) {
      // can check only if public config loaded or not checked before
      return;
    }
    services.checkVersion({ version: appVersion })
      .then(({ data }: { data: CheckVersionResponse }) => {
        const { result, success } = data;

        if (!success || !result) {
          return;
        }

        if ([Status.OK, Status.WARNING].includes(result.status)) {
          setCompatible(true);
        }

        if ([Status.WARNING, Status.ERROR].includes(result.status)) {
          const contentText = platform === Platform.Android ? result.popupTextAndroid : result.popupTextiOS;
          const content = <ReactMarkdown>{contentText || ''}</ReactMarkdown>;
          const buttons = result.status === Status.WARNING ? t('button.ok') : undefined;
          const type = result.status === Status.ERROR ? 'error' : 'warning';

          showDialog({ buttons, content, type });
        }
      })
      .catch((error) => {
        // if is offline and response is not in cache
        // because <NoConnection /> is not shown at this point
        if (isOffline(error.response?.status)) {
          showServiceErrorMessage(t('error.noConnection'));
        }
      });
  }, [compatible, publicConfigLoaded, platform, showDialog, showServiceErrorMessage, t]);

  return compatible ? children : null;
};

export default VersionCheck;
