import { createContext } from 'react';

export type ServerConfigContextType = {
  possibleServers: ServerConfig[];
  server?: ServerConfig;
  handleLogin: () => void;
  handleLogout: () => void;
};

export const defaultServerConfig: ServerConfigContextType = { possibleServers: [], handleLogin: () => {}, handleLogout: () => {} };

const ServerConfigContext = createContext<ServerConfigContextType>(defaultServerConfig);

export default ServerConfigContext;
