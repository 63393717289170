import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  root: {
    flexShrink: 0,
  },
  wrapper: {
    position: 'relative',
    backgroundColor: palette.error.main,
    overflow: 'hidden',
  },
  deleteIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: spacing(3),
    color: palette.common.white,
  },
  button: {
    'display': 'flex',
    'width': '100%',
    'height': 'auto',
    'minWidth': 'auto',
    'padding': '12px 16px',
    'borderRadius': 0,
    'textAlign': 'left',
    'textTransform': 'none',
    'borderBottom': `1px solid ${palette.divider}`,

    '&, &:hover, &:active': {
      backgroundColor: palette.unreadCardBackgroundColor,
    },
  },
  read: {
    '&, &:hover, &:active': {
      backgroundColor: palette.common.white,
    },
  },
  icon: {
    width: 24,
    height: 24,
    flexShrink: 0,
    marginRight: spacing(2),
    padding: 8,
    borderRadius: '50%',
    backgroundColor: palette.greyIconColor,
    color: palette.common.white,
    boxSizing: 'content-box',
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    color: palette.text.primary,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '22px',
    whiteSpace: 'normal',
  },
  subtitle: {
    'marginLeft': spacing(0.5),
    'color': palette.text.secondaryLabel,
    'lineHeight': '20px',
    'overflow': 'hidden',
    'textOverflow': 'ellipsis',

    '&:not(:empty)': {
      marginTop: spacing(0.5),
    },
  },
  readMark: {
    flexShrink: 0,
    marginLeft: spacing(1),
    color: palette.messageStatusIconColor,
  },
  unReadMark: {
    flexShrink: 0,
    marginLeft: spacing(1),
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: palette.messageStatusIconColor,
  },
});
