import { ProfileFeature } from './profileTypes';

export * from './languages';

export * from './myDataConsts';

export * from './profileTypes';

export { default as routePaths, prefix as routePrefix, isModule } from './routePaths';

export const BASE_URL = '/rest/pems';

export const DateFormat = {
  DATETIME_ISO: "yyyy-MM-dd'T'HH:mm:ssZZZ",
};

export enum ServerFeature {
  GEO_LOCATION_CHECKIN = 'GEO_LOCATION_CHECKIN',
  VIEW_DAILY_REMARKS = 'IDP_DAILY_REMARK',
  VIEW_EVENT_REMARKS = 'IDP_EVENT_REMARK',
  IDP_BUDDY_ACCESS_RIGHTS = 'IDP_BUDDY_ACCESS_RIGHTS',
  IDP_BUDDY_LEG_DEADHEAD_INFO_ACCESS = 'IDP_BUDDY_LEG_DEADHEAD_INFO_ACCESS',
  IDP_BUDDY_PAIRING_INFO_ACCESS = 'IDP_BUDDY_PAIRING_INFO_ACCESS',
  IDP_MY_DATA = 'IDP_MY_DATA',
  IDP_MY_DATA_PERSONAL_DATA = 'IDP_MY_DATA_PERSONAL_DATA',
  IDP_MY_DATA_CONTACT_INFORMATION = 'IDP_MY_DATA_CONTACT_INFORMATION',
  IDP_MY_DATA_ADDRESSES = 'IDP_MY_DATA_ADDRESSES',
  IDP_MY_DATA_IDENTITY_DOCUMENTS = 'IDP_MY_DATA_IDENTITY_DOCUMENTS',
  VIEW_NEWS = 'IDP_NEWS',
  VIEW_NOTIF_SETTINGS = 'IDP_NOTIF_SETTINGS',
  IDP_FLIGHT_SEARCH = 'IDP_FLIGHT_SEARCH',
  OPERATIONAL_AND_FLIGHT_INFORMATION_ENABLED = 'OPERATIONAL_AND_FLIGHT_INFORMATION_ENABLED',
  IDP_MY_DATA_PERSONAL_DATA_BIRTH_DATE = 'IDP_MY_DATA_PERSONAL_DATA_BIRTH_DATE',
  IDP_MY_DATA_PERSONAL_DATA_PLACE_OF_BIRTH = 'IDP_MY_DATA_PERSONAL_DATA_PLACE_OF_BIRTH',
  IDP_MY_DATA_PERSONAL_DATA_STATE_OF_BIRTH = 'IDP_MY_DATA_PERSONAL_DATA_STATE_OF_BIRTH',
  IDP_MY_DATA_PERSONAL_DATA_COUNTRY_OF_BIRTH = 'IDP_MY_DATA_PERSONAL_DATA_COUNTRY_OF_BIRTH',
  IDP_MY_DATA_PERSONAL_DATA_IS_SMOKER = 'IDP_MY_DATA_PERSONAL_DATA_IS_SMOKER',
  IDP_MY_DATA_PERSONAL_DATA_NATIONALITY = 'IDP_MY_DATA_PERSONAL_DATA_NATIONALITY',
  IDP_MY_DATA_PERSONAL_DATA_GENDER = 'IDP_MY_DATA_PERSONAL_DATA_GENDER',
}

export const Feature = {
  ...ProfileFeature,
  ...ServerFeature,
};

export enum CheckInPermission {
  CHECK_IN_ALLOWED = 'CHECK_IN_ALLOWED',
  CHECK_IN_PENDING = 'CHECK_IN_PENDING',
  CHECK_IN_NOT_ALLOWED = 'CHECK_IN_NOT_ALLOWED',
  CHECK_IN_NOT_ALLOWED_BECAUSE_OF_RESTRICTED_IP_ADDRESS = 'CHECK_IN_NOT_ALLOWED_BECAUSE_OF_RESTRICTED_IP_ADDRESS',
}

export const titles = {
  dutyList: 'duties.main.title',
  dailyRemarks: 'dailyRemarks.title',
  news: 'news.title',
  profile: 'settings.profile',
  summary: 'summary.title',
  search: 'flightSearch.title',
  buddyRosterSearch: 'buddy.buddyRosterSearch.title',
};
