import defaultTheme from './defaultTheme';
import main from './mainTheme';
import reltst from './reltstTheme';

import abx from './abxTheme';
import aca from './acaTheme';
import atn from './atnTheme';
import bti from './btiTheme';
import ewg from './ewgTheme';
import ewl from './ewlTheme';
import gaf from './gafTheme';
import gol from './golTheme';
import klc from './klcTheme';
import lot from './lotTheme';
import nax from './naxTheme';
import rxi from './rxiTheme';
import ryr from './ryrTheme';
import pga from './pgaTheme';

const themes: Record<string, any> = {
  abx,
  acafops: aca,
  acaifs: aca,
  atn,
  bti,
  defaultTheme,
  ewg,
  ewl,
  gaf,
  gol,
  klc,
  lot,
  main,
  nax,
  reltst,
  rxi,
  ryr,
  pga,
};

export default themes;
