import { DateTime } from 'luxon';

import { UpdatesResult } from './UpdatesContext';

const convertDate = (date?: string): number | undefined => (date ? DateTime.fromISO(date).toUTC().toMillis() : undefined);

// eslint-disable-next-line import/prefer-default-export
export const transformApiResponse = ({
  lastDailyRemarkTimestamp, lastNewsTimestamp, lastRosterChange, lastRosterChangeTimestamp, lastActivityChangeTimestamp,
}: UpdatesResult) => ({
  lastDailyRemarkTimestamp: convertDate(lastDailyRemarkTimestamp),
  lastNewsTimestamp: convertDate(lastNewsTimestamp),
  lastRosterChangeTimestamp: convertDate(lastRosterChangeTimestamp || lastRosterChange),
  lastActivityChangeTimestamp: convertDate(lastActivityChangeTimestamp),
});
