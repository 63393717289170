import colors, { Color, TypeAction } from '@lsy-netline-ui/netline-ui/theme/colors';
import common from '@material-ui/core/colors/common';

type ColorShades = {
  color6: string; // Divided appbar darker color - header above calendar
  color5: string; // Divided appbar lighter color - header above Duty list, clicked state of buttons
  color4: string; // Hover state of buttons, pairing on Calendar
  color3: string; // Main - Menu items, loading spinner, UI elements: button, checkbox, radio buttons
  color2: string; // Toggle switch
  color1: string; // Delimited area of cards, unread state background on News and Remarks
};

export type CustomThemes = 'blue' | 'purple' | 'stonewall' | 'lynch' | 'bti' | 'eurowings' | 'gol';

type PrimaryColors = {
  [key in CustomThemes]: ColorShades;
};
// Netline UI Colors: https://stunning-guacamole-29e5v4w.pages.github.io/?path=%2Fstory%2Fpalette-colors--colors
const generateColorShades = (baseColor: Partial<Color & TypeAction>) => ({
  color6: baseColor[1200]!,
  color5: baseColor[1100]!,
  color4: baseColor[900]!,
  color3: baseColor[800]!,
  color2: baseColor[300]!,
  color1: baseColor[50]!,
});

export const primaryColors: PrimaryColors = {
  blue: generateColorShades(colors.blue),
  purple: generateColorShades(colors.purple),
  stonewall: {
    ...generateColorShades(colors.stonewall),
    color1: colors.stonewall[100]!,
    color3: colors.stonewall[900]!, // for stonewall we use 900 for color3 and color4 also
  },
  lynch: {
    ...generateColorShades(colors.lynch),
    color1: colors.lynch[100]!,
  },
  bti: {
    color6: '#272A0A',
    color5: '#34370D',
    color4: '#4E5213',
    color3: '#9AA426',
    color2: '#CDDA32',
    color1: '#F3F6CC',
  },
  eurowings: {
    color6: '#701745',
    color5: '#871c54',
    color4: '#9b1d5d',
    color3: '#af1e65',
    color2: '#e3b1c9',
    color1: '#eac4d6',
  },
  gol: {
    color6: '#37322d',
    color5: '#43403c',
    color4: '#4e4a46',
    color3: '#4e4a46',
    color2: '#9a9187',
    color1: '#faebdb',
  },
};

export const neutralColors = {
  white: common.white,
  grey1: colors.grey[100]!,
  grey2: colors.grey[200]!,
  grey3: colors.grey[400]!,
  grey4: colors.grey[600]!,
  grey5: colors.grey[700]!,
  grey6: colors.grey[900]!,
  grey7: '#111', // issue with netline-ui library, can't use colors.grey[1100]
  black: common.black,
};

export const trafficLightColors = {
  red: colors.red[500]!,
  yellow: colors.yellow[500]!,
  green: colors.green[500]!,
};
