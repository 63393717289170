import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useMediaQuery } from '@lsy-netline-ui/netline-ui';

import MultiColumnContext from './MultiColumnContext';

const VERTICAL_BREAKPOINT = 450;

const MultiColumnProvider = ({ children, services }: { children: React.ReactNode; services?: any }) => {
  const [platform, setPlatform] = useState(services?.appFrame.platform.getPlatformConfig());

  // TODO remove when not using CSI appframe
  const bigScreen = useMediaQuery(
    (theme: any) => `(min-width: ${theme.breakpoints.values.sm}px) and (min-height: ${VERTICAL_BREAKPOINT}px)`,
  );

  const multiColumn = useMemo(() => (platform ? platform.name !== 'mobile' : bigScreen), [platform, bigScreen]);

  useEffect(() => {
    const unsubscribe = services?.appFrame.platform.onPlatformChange((actPlatform: string) => {
      setPlatform(actPlatform);
    });
    return unsubscribe;
  }, [services]);

  return <MultiColumnContext.Provider value={multiColumn}>{children}</MultiColumnContext.Provider>;
};

export const useMultiColumn = () => useContext(MultiColumnContext);

export default MultiColumnProvider;
