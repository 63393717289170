import {
  useCallback, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { DateTime } from 'luxon';

import {
  CancelablePromise, logger, services, printAsyncCacheSize,
} from '@crew-webui/common/api';
import {
  useApiStatus, useCrewAuth, useDutiesModel, useFeature, useTodayOnServer,
} from '@crew-webui/common/hooks';

import { filterServices, buildCacheParamMap, CacheServiceConfig } from './cacheUtil';

// The event day range to cache the services
const DEFAULT_BEFORE_DAYS_NO = 10;
const DEFAULT_AFTER_DAYS_NO = 30;
const DEAFULT_FLIGHT_INFO_DAYS_NO = 2;

/**
 * Hold running services, and interrupt them if needed.
 */
const RunningServices = {
  runningServices: new Set<CancelablePromise<any>>(),
  onServiceStart: (service: CancelablePromise<any>) => {
    RunningServices.runningServices.add(service);
  },
  onServiceFinished: (service: CancelablePromise<any>) => {
    RunningServices.runningServices.delete(service);
  },
  clear: () => {
    RunningServices.runningServices.forEach((srv: CancelablePromise<any>) => {
      srv.cancel('Cache interrupted');
    });
    RunningServices.runningServices.clear();
  },
};

/**
 * Manage service call Promises, to handle and checked by number of called services
 * @param resolve The promise resolve
 * @param reject The promise reject
 * @param length The length of the used services
 * @param successCallback The success callback
 * @param errorCallback The error callback
 * @param holdServices Set if hold the running services
 * @returns {{callCounter: (function(*, ...[*]): *), skipCounter: skipCounter}}
 */
const serviceCallCounter = (
  resolve: Function,
  reject: Function,
  length: number,
  successCallback: Function,
  errorCallback?: Function,
  holdServices: boolean = false,
) => {
  let successNo = 0;
  let errNo = 0;

  // If finished resolve with success service number
  const checkFinished = () => {
    if (successNo + errNo === length) {
      resolve(successNo);
    }
  };

  // call and run service with arguments
  const callCounter = (service: Function, ...args: any) => {
    const srv = service(...args);
    if (holdServices) {
      RunningServices.onServiceStart(srv);
    }
    return srv.then((result: any) => {
      if (successCallback) {
        successCallback(result, ...args);
      }
      if (holdServices) {
        RunningServices.onServiceFinished(srv);
      }
      successNo += 1;
      checkFinished();
    }, (err: Error) => {
      errNo += 1;
      if (errorCallback) {
        errorCallback(err);
      }
      checkFinished();
    });
  };

  // skip counter if needed
  const skipCounter = () => {
    successNo += 1;
    checkFinished();
  };

  return { callCounter, skipCounter };
};

const useCache = (
  withoutEvents = false,
  printCacheSize = false,
  cacheBeforeDaysNo = DEFAULT_BEFORE_DAYS_NO,
  cacheAfterDaysNo = DEFAULT_AFTER_DAYS_NO,
  cacheFlightInfoDaysNo = DEAFULT_FLIGHT_INFO_DAYS_NO,
) => {
  const [actualState, setActualState] = useState({
    startTime: 0,
    allNo: 0,
    successNo: 0,
    failedNo: 0,
    interruptedNo: 0,
    actualInfo: '',
    footerText: '',
  });
  const {
    allNo, successNo, actualInfo, failedNo, footerText, interruptedNo,
  } = actualState;

  const { t } = useTranslation();
  const { clearAllProgress } = useApiStatus();
  const getTodayOnServer = useTodayOnServer();
  const { crmId } = useCrewAuth();
  const isFeatureEnabled = useFeature();
  const {
    allEvents, loadDuties, wholeIntervalToShowInCalendar, rosterPeriod, getCurrentBlockMonth,
  } = useDutiesModel();

  const filteredServerConfigs: Map<String, CacheServiceConfig> = useMemo(() => filterServices(isFeatureEnabled), [isFeatureEnabled]);

  /**
   * Build parameter map, with static values, and event specific values
   * @type {function(): Map<any, any>}
   */
  const buildParamMap: (utcDateTime: DateTime) => Promise<Map<string, string[]>> = useCallback(async (utcDateTime) => {
    let events = allEvents;
    if (!withoutEvents) {
      events = (await loadDuties())!;
    }

    return crmId ? buildCacheParamMap(
      events,
      cacheBeforeDaysNo,
      cacheAfterDaysNo,
      cacheFlightInfoDaysNo,
      utcDateTime,
      crmId,
      wholeIntervalToShowInCalendar,
      rosterPeriod,
      getCurrentBlockMonth,
    ) : new Map();
  }, [
    allEvents,
    cacheBeforeDaysNo,
    cacheAfterDaysNo,
    cacheFlightInfoDaysNo,
    crmId,
    loadDuties,
    wholeIntervalToShowInCalendar,
    rosterPeriod,
    getCurrentBlockMonth,
    withoutEvents,
  ]);

  /**
   * Returns the param values to a service by config.
   * Create Cartesian square, with possible param values to the given service.
   */
  const getParamValues = useCallback((config: CacheServiceConfig, paramMap: Map<string, any[]>) => {
    if (!config.paramTypes) {
      return [];
    }
    const functionParams = [{}];
    const paramKeys = Object.keys(config.paramTypes);
    let i = 0;
    paramKeys.forEach((key) => {
      const paramValue = paramMap.get((!!config.replaceKeyMap && config.replaceKeyMap[key]) || key);
      if (paramValue) {
        if (paramValue && paramValue.length > 0) {
          i += 1;
          const arrayValues: string[] = [];
          while (functionParams.length > 0) {
            const actValue = functionParams.pop();
            paramValue.forEach((pValue: string) => {
              const newParam : any = { ...actValue };
              newParam[key] = pValue;
              arrayValues.push(newParam);
            });
          }
          functionParams.push(...arrayValues);
        } else {
          logger.error(`Can not cache ${config.url} because not defined value to ${key} in params`);
        }
      } else {
        logger.error(`Can not cache ${config.url} because ${key} not defined in params`);
      }
    });
    return (i < paramKeys.length) ? undefined : functionParams;
  }, []);

  // the service success callback
  const successCallback = useCallback(() => {
    setActualState((prevState) => ({
      ...prevState,
      successNo: prevState.successNo + 1,
    }));
  }, []);

  // the service error callback
  const errorCallback = useCallback((err: Error) => {
    const isCancelled = axios.isCancel(err);
    if (!isCancelled) {
      logger.error('Error while call service', err);
    }
    setActualState((prevState) => ({
      ...prevState,
      failedNo: isCancelled ? prevState.failedNo : prevState.failedNo + 1,
      interruptedNo: isCancelled ? prevState.interruptedNo + 1 : prevState.interruptedNo,
    }));
  }, []);

  // call service to the given key, with all of the given parameters.
  const callService = useCallback((key: string, paramValues: any[]) => new Promise((resolve, reject) => {
    const length = paramValues && paramValues.length ? paramValues.length : 1;
    const { callCounter } = serviceCallCounter(resolve, reject, length, successCallback, errorCallback, true);
    const service = services[key];
    if (!paramValues || !paramValues.length) {
      callCounter(service, {}, { ignoreErrors: true });
    } else {
      paramValues.forEach((paramValue) => {
        callCounter(service, paramValue, { ignoreErrors: true });
      });
    }
  }), [errorCallback, successCallback]);

  // count the services to call
  const countAllServices = useCallback((paramMap: Map<string, string[]>) => {
    let count = 0;
    filteredServerConfigs.forEach((config) => {
      let actCount = 1;
      if (config.paramTypes) {
        const paramKeys = Object.keys(config.paramTypes);
        paramKeys.forEach((paramKey) => {
          const paramValue = paramMap.get((!!config.replaceKeyMap && config.replaceKeyMap[paramKey]) || paramKey);
          if (paramValue && paramValue.length > 0) {
            actCount *= paramValue.length;
          } else {
            // some of the parameters not defined in param map
            actCount = 0;
          }
        });
      }
      count += actCount;
    });
    return count;
  }, [filteredServerConfigs]);

  // stop the cache process
  const stopProcess = useCallback(async () => {
    await RunningServices.clear();
    clearAllProgress();
  }, [clearAllProgress]);

  // call services by parameter map
  const callAllServices = useCallback(async (paramMap: Map<string, string[]>) => new Promise((resolve, reject) => {
    const { callCounter, skipCounter } = serviceCallCounter(resolve, reject, filteredServerConfigs.size, (count: number, key: string) => {
      logger.debug(`Service cache finished to ${key}, success: ${count}`);
    });
    filteredServerConfigs.forEach((config, key) => {
      const paramValues = getParamValues(config, paramMap);
      if (paramValues) {
        callCounter(callService, key, paramValues);
      } else {
        // some of the parameters not defined in param map
        skipCounter();
      }
    });
  }), [getParamValues, callService, filteredServerConfigs]);

  // start cache process
  const startProcess = useCallback(async () => {
    RunningServices.clear();
    setActualState({
      startTime: Date.now(),
      successNo: 0,
      failedNo: 0,
      interruptedNo: 0,
      allNo: 0,
      actualInfo: t('cache.initText'),
      footerText: '',
    });
    const utcDateTime = getTodayOnServer(true);
    const params = await buildParamMap(utcDateTime);
    const countAll = countAllServices(params);
    logger.info(`Start cache to ${countAll} services`);
    setActualState((prevState) => ({
      ...prevState,
      allNo: countAll,
      actualInfo: t('cache.workingText'),
    }));
    await callAllServices(params);
    setActualState((prevState) => {
      const duration = Date.now() - prevState.startTime;
      const start = `Cache process finished in ${duration} ms, interrupted`;
      logger.info(`${start} ${prevState.interruptedNo}, failed ${prevState.failedNo}, and success ${prevState.successNo} services`);
      if (printCacheSize) {
        printAsyncCacheSize();
      }
      return {
        ...prevState,
        startTime: 0,
        actualInfo: '',
        footerText: t('cache.warningText'),
      };
    });
  }, [buildParamMap, callAllServices, countAllServices, getTodayOnServer, t, printCacheSize]);

  const cacheContext = useMemo(() => ({
    successNo,
    failedNo,
    interruptedNo,
    actualInfo,
    allNo,
    startProcess,
    stopProcess,
    footerText,
  }), [successNo, actualInfo, allNo, failedNo, footerText, interruptedNo, startProcess, stopProcess]);

  return cacheContext;
};

export default useCache;
