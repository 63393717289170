import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  root: {
    position: 'relative',
    display: 'flex',
  },
  badge: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    left: 'calc(50% + 7px)',
    right: 'calc(50% - 7px)',
    position: 'absolute',
    backgroundColor: palette.notificationBadgeColor,
  },
});
