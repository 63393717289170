import React, {
  RefObject, useCallback, useContext, useMemo,
}
  from 'react';

import PageRememberContext from './PageRememberContext';

type Props = {
  children: React.ReactNode;
};

const PageRememberProvider = ({ children }: Props) => {
  const pageData = useMemo<Record<string, any>>(() => ({}), []);

  const storeData = useCallback((name: string, data: any) => {
    const { href } = window.location;
    pageData[href] = pageData[href] || {};
    pageData[href][name] = data;
  }, [pageData]);

  const getData = useCallback((name: string, defaultValue?: any) => {
    const { href } = window.location;
    if (pageData[href] && pageData[href][name]) {
      return pageData[href][name];
    }
    return defaultValue;
  }, [pageData]);

  const clearData = useCallback((name: string) => {
    const { href } = window.location;
    if (!pageData[href]) {
      return;
    }
    if (name) {
      pageData[href][name] = null;
      delete pageData[href][name];
    } else {
      pageData[href] = {};
    }
  }, [pageData]);

  const storeScroll = useCallback((name: string, reactRef: RefObject<HTMLElement>) => {
    if (!reactRef.current) {
      return undefined;
    }

    const scrollListener = (e: any) => {
      // this happens when React replaced the node, it still exists in the memory but not in the DOM
      if (!e.target.isConnected) {
        return;
      }

      storeData(name, {
        scrollTop: e.target.scrollTop,
        scrollLeft: e.target.scrollLeft,
      });
    };
    reactRef.current.addEventListener('scroll', scrollListener);
    return () => reactRef.current?.removeEventListener('scroll', scrollListener);
  }, [storeData]);

  const restoreScroll = useCallback((name: string, reactRef: RefObject<HTMLElement>) => {
    if (!reactRef.current) {
      return;
    }

    const data = getData(name);
    if (data) {
      // eslint-disable-next-line no-param-reassign
      reactRef.current.scrollTop = data.scrollTop;
      // eslint-disable-next-line no-param-reassign
      reactRef.current.scrollLeft = data.scrollLeft;
    }
  }, [getData]);

  const ctx = useMemo(() => ({
    clearData,
    getData,
    restoreScroll,
    storeData,
    storeScroll,
  }), [clearData, getData, restoreScroll, storeData, storeScroll]);
  return <PageRememberContext.Provider value={ctx}>{children}</PageRememberContext.Provider>;
};

export const useRemember = () => useContext(PageRememberContext);

export default PageRememberProvider;
