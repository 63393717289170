import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import ServerSelection from '../../view/ServerSelection/ServerSelection';
import { ServerConfigHandler } from '../../api/api-helper';
import { logger } from '../../api';
import { useDialog } from '../Dialog';
import ServerConfigContext from './ServerConfigContext';

export interface ServerConfigProps {
  children: React.ReactNode;
}

export const ServerConfigProvider = ({ children } : ServerConfigProps) => {
  const { t } = useTranslation();
  const [usedServer, setUsedServer] = useState<ServerConfig | undefined>(ServerConfigHandler.getUsedServer());
  const [testConfirmed, setTestConfirmed] = useState<boolean>(false);
  const { showConfirmDialog } = useDialog();

  const servers = useMemo(() => ({
    possibleServers: ServerConfigHandler.getPossibleServers()!,
    server: usedServer,
  }), [usedServer]);

  // if there are more server in config, and last is selected (last is the test server in config)
  const isTestServer = useMemo(() => servers.server && servers.possibleServers.length > 1 &&
    servers.server.name === servers.possibleServers[servers.possibleServers.length - 1].name, [servers]);

  const userServers = useMemo(() => servers.possibleServers.slice(0, -1), [servers.possibleServers]);

  const onSelectServer = useCallback((server: ServerConfig) => {
    ServerConfigHandler.setUsedServerByName(server.name);
    setUsedServer(server);
  }, []);

  const handleLogin = useCallback(() => ServerConfigHandler.onLogin(), []);

  const handleLogout = useCallback(() => {
    setTestConfirmed(false);
    ServerConfigHandler.onLogout();
  }, []);

  // if login failed, and username is defined as test user in config, than redirected to test server
  const handleBrowserLoad = useCallback((e : any) => {
    const { detail: { browser } } = e;
    browser.executeScript({
      code: 'Array.prototype.slice.call(document.getElementsByTagName("input")).map((input) => input.value)',
    }, (result: string[]) => {
      const inputValues = result[0];
      const match = inputValues && servers.server?.testUsers?.find((user) => inputValues.indexOf(user) >= 0);
      // Test user login failed
      if (match) {
        ServerConfigHandler.switchToTestServer();
      }
    });
  }, [servers]);

  useEffect(() => {
    if (!servers.server || !servers.possibleServers || servers.possibleServers.length < 2) {
      // no test server defined
      return undefined;
    }
    if (isTestServer) {
      // test server selected
      showConfirmDialog(t('serverChoice.testServerSelected'), () => {
        setTestConfirmed(true);
      }, () => {
        ServerConfigHandler.backToDefaultServer();
      });
      return undefined;
    }
    logger.info('Used server is ', servers.server.name);
    window.addEventListener('InAppBrowserPageLoaded', handleBrowserLoad);

    return () => {
      window.removeEventListener('InAppBrowserPageLoaded', handleBrowserLoad);
    };
  }, [servers, handleBrowserLoad, isTestServer, showConfirmDialog, t]);

  const ctx = useMemo(() => ({
    ...servers,
    handleLogin,
    handleLogout,
  }), [servers, handleLogin, handleLogout]);

  if (!usedServer) {
    return <ServerSelection onSelectServer={onSelectServer} userServers={userServers} />;
  }

  if (isTestServer && !testConfirmed) {
    return null;
  }

  return (
    <ServerConfigContext.Provider value={ctx}>
      {children}
    </ServerConfigContext.Provider>
  );
};

export const useServerConfig = () => useContext(ServerConfigContext);

export default ServerConfigProvider;
