import axios, { AxiosAdapter, AxiosPromise, AxiosResponse } from 'axios';

import { getCacheUrl, ServiceConfig } from '../api-helper';
import createServiceHandler from './serviceHandler';

// Request map queue, to handle slow network for same request
type ResolveReject = {
  resolve: (value: any) => void;
  reject: (error: any) => void;
};

const requestMap = new Map<String, ResolveReject[]>();

/**
 * Resolve the promises to the same url, for cached requests
 * @param url The request url
 * @param response The response, if success
 * @param error The error, if failed
 */
const resolveQueue = (url: string, { response, error } : { response?: AxiosResponse; error?: any }) => {
  const waitingQueue = requestMap.get(url);
  if (waitingQueue) {
    requestMap.delete(url);
    waitingQueue.forEach(({ resolve, reject }) => {
      if (response) {
        resolve(response);
      } else if (error) {
        reject(error);
      } else {
        reject(new Error(`Response or error must be defined to handle request promise to url: http://maven.apache.org`));
      }
    });
  }
};

const createOfflineAdapter = (adapter: AxiosAdapter, serviceHandler: any): any => (config: ServiceConfig): AxiosPromise<any> => {
  const cacheUrl = getCacheUrl(config);
  const waitingQueue = config.skipRepeated && requestMap.get(cacheUrl);
  // if request not finished yet for this url, put the result to the queue
  if (waitingQueue) {
    return new Promise((resolve, reject) => {
      waitingQueue.push({ resolve, reject });
    });
  }

  // Mark request to running, to cache same requests
  if (config.skipRepeated) {
    requestMap.set(cacheUrl, []);
  }
  const responsePromise = adapter(config);
  if (!config.persistType) {
    return responsePromise;
  }
  return new Promise<AxiosResponse>((resolve, reject) => {
    responsePromise.then(async (response: AxiosResponse) => {
      resolve(await serviceHandler.processSuccessResult(config, response));
      resolveQueue(cacheUrl, { response });
    }).catch(async (errorOnSuccess: any) => {
      try {
        const response = await serviceHandler.processErrorResult(config, errorOnSuccess);
        if (!response) {
          throw errorOnSuccess; // will be converted to reject(...)
        }
        resolve(response);
        resolveQueue(cacheUrl, { response });
      } catch (error) {
        reject(error);
        resolveQueue(cacheUrl, { error });
      }
    });
  });
};

export default function updateAxiosAdapter(setOnline: any, setCrewComOnline: any) {
  if (axios.defaults.adapter === undefined) {
    return;
  }

  axios.defaults.adapter = createOfflineAdapter(axios.defaults.adapter, createServiceHandler(setOnline, setCrewComOnline));
}
