import { useFeature } from '@crew-webui/common/hooks';

import { useMemo } from 'react';

import { Feature } from 'consts';

const useEnabledPersonalDataFields = () => {
  const isFeatureEnabled = useFeature();
  const enabledFeatures: Record<string, boolean> = useMemo(() => ({
    birthDate: isFeatureEnabled(Feature.IDP_MY_DATA_PERSONAL_DATA_BIRTH_DATE),
    birthPlace: isFeatureEnabled(Feature.IDP_MY_DATA_PERSONAL_DATA_PLACE_OF_BIRTH),
    birthState: isFeatureEnabled(Feature.IDP_MY_DATA_PERSONAL_DATA_STATE_OF_BIRTH),
    birthCountry: isFeatureEnabled(Feature.IDP_MY_DATA_PERSONAL_DATA_COUNTRY_OF_BIRTH),
    isSmoker: isFeatureEnabled(Feature.IDP_MY_DATA_PERSONAL_DATA_IS_SMOKER),
    nationality: isFeatureEnabled(Feature.IDP_MY_DATA_PERSONAL_DATA_NATIONALITY),
    gender: isFeatureEnabled(Feature.IDP_MY_DATA_PERSONAL_DATA_GENDER),
  }), [isFeatureEnabled]);

  return enabledFeatures;
};

export default useEnabledPersonalDataFields;
