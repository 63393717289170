import { translateErrorMessage } from '../../utils';
import { DialogType } from './DialogContext';

type ErrorType = 'SYS' | 'APP' | 'WRN' | 'INF';

const getType = (errorType: ErrorType): DialogType | undefined => {
  if (errorType === 'SYS' || errorType === 'APP') {
    return 'error';
  }
  if (errorType === 'WRN') {
    return 'warning';
  }
  if (errorType === 'INF') {
    return 'info';
  }
  return undefined;
};

const transformApiError = (t: T, item: any) => {
  const translatedKey = translateErrorMessage(t, item.errId);
  return translatedKey === item.errId ? item.errMessage : translatedKey;
};

type TransformApiErrorResult = {
  type?: DialogType;
  title: string;
  text: string | string[] | { title: string; text: string[] }[];
};
// eslint-disable-next-line import/prefer-default-export
export const transformApiErrors = (t: T, error: any): TransformApiErrorResult => {
  const fallbackResult = {
    type: 'error' as 'error',
    title: t('common.error'),
    text: translateErrorMessage(t, 'service.unavailable')!,
  };

  // not the expected PEMS error structure
  if (!Array.isArray(error?.items)) {
    return fallbackResult;
  }

  const errorTypes = [
    {
      errorType: 'SYS',
      title: t('common.error'),
    },
    {
      errorType: 'APP',
      title: t('common.unsuccessful'),
    },
    {
      errorType: 'WRN',
      title: t('common.warning'),
    },
    {
      errorType: 'INF',
      title: t('common.info'),
    },
  ] as const;

  const groupedItems = errorTypes
    .map(({ errorType, title }) => ({
      errorType,
      title,
      items: error.items
        .filter((item: any) => item.errType === errorType)
        .map((item: any) => transformApiError(t, item)),
    }))
    .filter(({ items }) => !!items.length);

  // 0 known type of error messages
  if (groupedItems.length === 0) {
    return fallbackResult;
  }

  // 1 type of error messages
  if (groupedItems.length === 1) {
    const group = groupedItems[0];
    return {
      type: getType(group.errorType),
      title: group.title,
      text: group.items.length === 1 ? group.items[0] : group.items,
    };
  }

  // multiple types of error messages
  return {
    type: getType(groupedItems[0].errorType),
    title: groupedItems[0].title,
    text: groupedItems.map(({ title, items }) => ({ title, text: items })),
  };
};
