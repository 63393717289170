import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import {
  Assessment as AssessmentIcon,
  EventNote as EventNoteIcon,
  Email as EmailIcon,
  Notifications as NotificationsIcon,
  Person as PersonIcon,
  Flight as FlightIcon,
  Group as GroupIcon,
} from '@material-ui/icons';

import {
  useCrewAuth,
  useDutiesModel,
  useFeature,
  useNewsNotification,
} from '@crew-webui/common/hooks';
import { NotificationBadge, Menu } from '@crew-webui/common/view/components';

import styles from './IadpFooterMenu.styles';

import { Feature, routePaths, titles } from 'consts';
import { useDailyRemarkNotification } from 'hooks';

const useStyles = makeStyles(styles, { name: 'Menu' });

type FooterProps = {
  rightSide: boolean;
};

const IadpFooterMenu = ({ rightSide }: FooterProps) => {
  const { t } = useTranslation();
  const { authenticated } = useCrewAuth();
  const { buddyMode } = useDutiesModel();
  const classes = useStyles();
  const { hasNotification: showNewsNotification } = useNewsNotification();
  const { hasNotification: showRemarksNotification } = useDailyRemarkNotification();
  const isFeatureEnabled = useFeature();

  const links = [{
    'icon': <EventNoteIcon />,
    'value': routePaths.root,
    'match': [
      routePaths.root,
      `${routePaths.checkIn}/:crmId/:logicalId`,
      `${routePaths.hotel}/:crmId/:eventId`,
      `${routePaths.leg}/:context/:crmId/:logicalId`,
      `${routePaths.leg}/:context/:crmId/:logicalId/edit-roles`,
      `${routePaths.leg}/:context/:crmId/:logicalId/additional-flight-details`,
      `${routePaths.pairing}/:context/:crmId/:logicalId`,
      `${routePaths.event}/:crmId/:eventId`,
      `${routePaths.notifications}/:crmId`,
    ],
    'disabled': false,
    'data-test-id': 'menu-item-duties',
    'label': t(titles.dutyList),
    'exact': true,
  }, {
    'icon': (
      <NotificationBadge show={showRemarksNotification}>
        <EmailIcon />
      </NotificationBadge>
    ),
    'value': routePaths.dailyRemarks,
    'disabled': !isFeatureEnabled(Feature.VIEW_DAILY_REMARKS),
    'data-test-id': 'menu-item-daily-remarks',
    'label': t(titles.dailyRemarks),
  }, {
    'icon': (
      <NotificationBadge show={showNewsNotification}>
        <NotificationsIcon />
      </NotificationBadge>
    ),
    'value': routePaths.news,
    'disabled': !isFeatureEnabled(Feature.VIEW_NEWS),
    'data-test-id': 'menu-item-news',
    'label': t(titles.news),
  },
  {
    'icon': <PersonIcon />,
    'value': routePaths.profile,
    'match': [
      routePaths.profile,
      routePaths.notificationSettings,
      routePaths.accessRights,
      routePaths.myData,
      routePaths.buddyExcludeList,
    ],
    'data-test-id': 'menu-item-settings',
    'label': t(titles.profile),
  }, {
    'icon': <FlightIcon className={classes.flightIcon} />,
    'value': routePaths.flightSearch,
    'disabled': !isFeatureEnabled(Feature.IDP_FLIGHT_SEARCH),
    'data-test-id': 'menu-item-flightSearch',
    'label': t(titles.search),
  }, {
    'icon': <AssessmentIcon />,
    'value': routePaths.summary,
    'disabled': !isFeatureEnabled(Feature.VIEW_SUMMARY),
    'data-test-id': 'summary-link',
    'label': t(titles.summary),
  }, {
    'icon': <GroupIcon />,
    'value': routePaths.buddyRosterSearch,
    'disabled': !isFeatureEnabled(Feature.IDP_BUDDY_ACCESS_RIGHTS),
    'data-test-id': 'menu-item-buddy-roster-search',
    'label': t(titles.buddyRosterSearch),
  }].filter(({ disabled }) => !disabled);

  if (!authenticated) {
    return null;
  }

  return (
    <Menu
      tabItems={links}
      className={clsx({ [classes.rightSide]: rightSide })}
      disableCurrentItemIndicator={!!buddyMode}
    />
  );
};

export default IadpFooterMenu;
