import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { IconButton } from '@lsy-netline-ui/netline-ui';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { get, merge, set } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

import { useEncodedParams } from '@crew-webui/common/api';
import { FeatureAccess, MyDataCategory, MyDataType } from '@crew-webui/common/consts';
import {
  useAppCommon, useConnection, useCrewAuth, useDialog, useFeature, useMyData, useOnlineAuthorized, useProfile,
} from '@crew-webui/common/hooks';
import { NoContentMessage, OfflineEditInfo } from '@crew-webui/common/view/components';

import {
  COUNTRY_CODE_PATH,
  Feature,
  MAX_ADDRESS_PRIO,
  MyDataField, routePaths,
  STATE_CODE_PATH,
  titles,
} from 'consts';
import { getUniqueAddressId, getValidationSchema, hasCountryStates } from 'utils';
import { IadpPage } from 'view/components';
import MyDataList from '../MyDataList/MyDataList';
import MyDataFields from '../MyDataFields/MyDataFields';
import getAddressFields from './addressFields';

const formId = 'my-data-address-form';

const AddressDetails = () => {
  const { t } = useTranslation();
  const { crmId } = useCrewAuth();
  const {
    addresses, createMyData, deleteMyData, loadMyData, updateMyData,
  } = useMyData();
  const { id } = useEncodedParams<{ id: string }>();
  const isFeatureEnabled = useFeature();
  const { online } = useConnection();
  const { setDirty, clearDirtyAndRedirect } = useAppCommon();
  const { showConfirmDialog } = useDialog();
  const { profile: { readonlyMyDataCategories } } = useProfile();

  const [fields, setFields] = useState<MyDataField[]>([]);

  const newAddress = id === undefined;
  const editingEnabled = isFeatureEnabled(Feature.IDP_MY_DATA, FeatureAccess.WRITE) && !readonlyMyDataCategories.includes(MyDataCategory.ADDRESS);
  const address = addresses?.find((item) => getUniqueAddressId(item) === id);
  const dataLoaded = !!addresses;

  // Init form state
  const {
    control,
    formState: {
      errors, isDirty, isSubmitted, isSubmitting,
    },
    handleSubmit,
    reset,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(getValidationSchema(fields)),
  });

  // Set "fields" state
  const countryCodeValue = watch(COUNTRY_CODE_PATH, false);
  useEffect(() => {
    // revalidate State code when Country code changes
    if (isSubmitted) {
      trigger(STATE_CODE_PATH);
    }

    const newFields = getAddressFields(t).map((field) => {
      if (field.valuePath === STATE_CODE_PATH) {
        return { ...field, disabled: !hasCountryStates(countryCodeValue) };
      }
      return field;
    });
    setFields(newFields);
  }, [isSubmitted, trigger, countryCodeValue, t]);

  // Fetch data
  useOnlineAuthorized(() => {
    if (!dataLoaded) {
      loadMyData(MyDataType.ADDRESS);
    }
  }, [dataLoaded, loadMyData]);

  // Synchronize "dirty" global state
  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty, setDirty]);

  // Reset values
  useEffect(() => {
    const newDefaultValues = {};

    getAddressFields(t).forEach(({ valuePath }) => {
      set(newDefaultValues, valuePath, get(address, valuePath, ''));
    });

    // prefill Priority field with the max existing + 1
    if (dataLoaded && newAddress) {
      const maxPrio = addresses.reduce((acc, curr) => Math.max(acc, curr.natKey.prio), -1);
      if (maxPrio !== MAX_ADDRESS_PRIO) {
        set(newDefaultValues, 'natKey.prio', maxPrio + 1);
      }
    }

    reset(newDefaultValues);
  }, [address, addresses, dataLoaded, newAddress, reset, t]);

  // Handle form submission
  const onSubmit = async (data: any) => {
    const mergedAddress = merge(
      { cityCode: '', districtCode: '', natKey: { crewCode: crmId, employeeId: '', type: 'C' } },
      address,
      data,
    );

    // convert to number
    mergedAddress.natKey.prio = parseInt(mergedAddress.natKey.prio, 10);
    mergedAddress.countryCode = mergedAddress.countryCode?.toUpperCase();

    if (address) {
      await updateMyData(MyDataType.ADDRESS, mergedAddress, address);
    } else {
      await createMyData(MyDataType.ADDRESS, mergedAddress);
    }
    clearDirtyAndRedirect(`${routePaths.myData}/addresses`);
  };

  const label = t('myData.addressInfo.address');

  let title = t('myData.addressInfo.newAddress');
  if (!newAddress) {
    title = address ? `${label}: ${address.street}` : label;
  }

  const rightIconProps = editingEnabled && address && (
    <IconButton
      disabled={!online}
      onClick={() => {
        const handleConfirm = () => {
          deleteMyData(MyDataType.ADDRESS, address).then(() => clearDirtyAndRedirect(`${routePaths.myData}/addresses`));
        };
        showConfirmDialog(t('myData.addressInfo.deleteAddressDialogTitle'), handleConfirm);
      }}
    >
      <DeleteIcon />
    </IconButton>
  );

  const showNoContentMessage = dataLoaded && !newAddress && !address;

  return (
    <IadpPage
      dataLoaded={dataLoaded}
      headerProps={{
        actions: rightIconProps,
        showBackButton: true,
        title: t(titles.profile),
        subtitle: title,
      }}
      submitButtonProps={!editingEnabled ? undefined : {
        'children': newAddress ? t('myData.addressInfo.addNewAddress') : t('myData.saveChanges'),
        'data-test-id': 'submit-address-button',
        'disabled': !online || !isDirty || isSubmitting || !!Object.keys(errors).length,
        'form': formId,
        'type': 'submit',
      }}
    >
      {showNoContentMessage && <NoContentMessage>{t('myData.addressInfo.noAddress')}</NoContentMessage>}

      {address && !editingEnabled && <MyDataList data={address} label={label} list={fields} />}
      {editingEnabled && (address || newAddress) && (
        <form id={formId} noValidate onSubmit={handleSubmit(onSubmit)}>
          <OfflineEditInfo />
          <MyDataFields
            control={control}
            editing={!newAddress}
            errors={errors}
            fields={fields}
          />
        </form>
      )}
    </IadpPage>
  );
};

export default AddressDetails;
