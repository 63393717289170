import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

import { logger, services } from '@crew-webui/common/api';
import {
  useConnection, useCrewAuth, useDialog, useFeature, useUserPreferences, useTodayOnServer,
} from '@crew-webui/common/hooks';
import { LocalStorageKeys } from '@crew-webui/common/consts';

import { Feature, CheckInPermission, routePaths } from 'consts';

import CheckinContext, { CheckInInfoType, initialState } from './CheckinContext';

const { Geofence } = window;

const START_GEOFENCING_BEFORE_SECONDS = 60 * 60 * 12;

const parseCheckinConfiguration = (checkinConfig: any) => {
  const { success, result } = checkinConfig;

  if (!success || !result) {
    return null;
  }

  const {
    upcomingCheckinEvent, ciAllowedAirports, checkInPermission,
  } = result || {};
  const { logicalId, airport, fromDateTimeUTC } = upcomingCheckinEvent || {};

  const allowedAirport = (ciAllowedAirports || []).find(({ code } : { code: string }) => code === airport);
  if (!allowedAirport || !logicalId) {
    logger.warn('No airport found to initiate geofence checkin!');
    return false;
  }

  const { longitude, latitude, radius } = allowedAirport;
  const fromDateTimeUTCLux = DateTime.fromISO(fromDateTimeUTC);

  return {
    logicalId,
    longitude,
    latitude,
    radius,
    airport,
    checkInPermission,
    fromDateTimeUTC: fromDateTimeUTCLux.isValid ? fromDateTimeUTCLux.toJSDate() : null,
  } as CheckInInfoType;
};

const loadCheckinConfiguration = (crmId: string) => {
  const baseReq = services.loadCheckinConfiguration({ crmId }, { handleErrorFieldLocally: true });
  const req = baseReq.then((response) => {
    const checkinInfo = parseCheckinConfiguration(response?.data || {});
    if (checkinInfo === null) {
      logger.error('Unable to load checkin configuration!', response);
    }
    return checkinInfo;
  })
    .catch((error) => {
      if (baseReq.isCancelled(error)) {
        return;
      }
      logger.error(error);
    });
  return req;
};

const CheckinProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { online } = useConnection();
  const { authenticated, crmId } = useCrewAuth();
  const isFeatureEnabled = useFeature();
  const {
    showDialog, hideDialog,
  } = useDialog();
  const [checkinState, setCheckinState] = useState(initialState);
  const getTodayOnServer = useTodayOnServer();
  const {
    [LocalStorageKeys.LOCATION_ALWAYS_POPUP_SHOWN]: locationPopupShown,
    [LocalStorageKeys.LAST_CHECKIN_DIALOG_ID]: lastCheckinDialogId,
    hasLoaded,
    setPref,
  } = useUserPreferences();

  const { geofenceStatus, checkinInfo, isInRange } = checkinState;
  const geofenceEnabled = authenticated && isFeatureEnabled(Feature.GEO_LOCATION_CHECKIN);

  const history = useHistory();

  const notificationConfig = useMemo(() => ({
    title: t('geofence.notificationConfig.title'),
    text: t('geofence.notificationConfig.text'),
    timefenceTitle: t('geofence.notificationConfig.timefenceTitle'),
    timefenceText: t('geofence.notificationConfig.timefenceText'),
  }), [t]);

  const loadNextCheckinInfo = useCallback(() => {
    if (!Geofence || !geofenceEnabled || !crmId || !online) {
      return;
    }
    loadCheckinConfiguration(crmId).then((actualCheckinInfo) => {
      if (!actualCheckinInfo) {
        return;
      }
      setCheckinState((prevState) => (actualCheckinInfo.logicalId === prevState.checkinInfo?.logicalId ? prevState :
        {
          ...prevState,
          checkinInfo: actualCheckinInfo,
          geofenceStatus: window.Geofence?.getStatus(),
          isInRange: false,
        }));
    });
  }, [geofenceEnabled, crmId, online]);

  useEffect(() => {
    if (!checkinInfo) {
      loadNextCheckinInfo();
    }
  }, [checkinInfo, loadNextCheckinInfo]);

  const isGeofenceInTimerange = useMemo(() => {
    if (!checkinInfo || !getTodayOnServer) {
      return false;
    }
    const serverTime = getTodayOnServer(true).toJSDate();
    const checkInDiffInSec = Math.ceil((checkinInfo.fromDateTimeUTC!.getTime() - serverTime.getTime()) / 1000);
    return checkInDiffInSec <= START_GEOFENCING_BEFORE_SECONDS && checkInDiffInSec >= 0;
  }, [checkinInfo, getTodayOnServer]);

  useEffect(() => {
    if (!Geofence || !geofenceEnabled || geofenceStatus !== Geofence?.Status.OK || !isGeofenceInTimerange) {
      return;
    }
    Geofence.checkLocation(checkinInfo);
  }, [geofenceEnabled, geofenceStatus, checkinInfo, isGeofenceInTimerange]);

  const onEnterCheckinZone = useCallback((enteredCheckinInfo: any) => {
    if (!online || !authenticated) {
      // in offline mode can not checkin
      return;
    }
    if (!crmId) {
      return;
    }

    const { checkInPermission, logicalId } = checkinInfo || {};
    if (logicalId !== enteredCheckinInfo?.logicalId) {
      loadNextCheckinInfo();
      return;
    }

    const handleEnterCheckinZone = () => {
      const checkinUrl = `${routePaths.checkIn}/${crmId}/${encodeURIComponent(enteredCheckinInfo.logicalId)}`;
      if (history.location.pathname === checkinUrl) {
        hideDialog();
        return;
      }
      if (Geofence && geofenceEnabled) {
        // if "Check in zone reached" dialog already shown for this logicalId, don't show it again
        if (enteredCheckinInfo.logicalId === lastCheckinDialogId) {
          return;
        }
        if (online) {
          setPref(LocalStorageKeys.LAST_CHECKIN_DIALOG_ID, enteredCheckinInfo.logicalId);
        }

        const content = t(
          online ? 'checkin.redirectToCheckinQuestion' : 'checkin.offlineDialogContent',
          { airport: enteredCheckinInfo.airport },
        );

        const buttons = online ? [{
          text: t('common.yes'),
          onClick: () => {
            hideDialog();
            history.push(checkinUrl);
          },
        }, {
          text: t('common.no'),
          onClick: hideDialog,
        }] : t('button.ok');

        showDialog({ title: t('checkin.geofenceTitle'), content, buttons });
        Geofence.geofenceArrivedToApp(enteredCheckinInfo.logicalId);
      }
    };

    // must check configuration, because we start geofencing some hours before CHECK_IN_ALLOWED
    if (
      checkInPermission === CheckInPermission.CHECK_IN_ALLOWED ||
      checkInPermission === CheckInPermission.CHECK_IN_PENDING
    ) {
      handleEnterCheckinZone();
      setCheckinState((prevState) => (
        {
          ...prevState,
          isInRange: true,
        }));
    }
  }, [geofenceEnabled, crmId, checkinInfo, authenticated, history, lastCheckinDialogId, loadNextCheckinInfo, online, setPref, showDialog, t, hideDialog]);

  useEffect(() => {
    if (Geofence && geofenceEnabled && hasLoaded && !locationPopupShown) {
      setPref(LocalStorageKeys.LOCATION_ALWAYS_POPUP_SHOWN, true);
      const contentKey = `geofence.popupWarning.${Geofence.isPlatformAndroid() ? 'android' : 'ios'}`;
      showDialog({
        title: t('common.warning'),
        content: t(contentKey),
        buttons: t('button.ok'),
        type: 'warning',
      });
    }
  }, [geofenceEnabled, hasLoaded, showDialog, t, locationPopupShown, setPref]);

  useEffect(() => {
    if (!Geofence || !geofenceEnabled || geofenceStatus !== Geofence?.Status.NONE || !online || !authenticated || !onEnterCheckinZone) {
      return;
    }
    // init geofence
    Geofence.initialize({
      logger,
      pathPrefix: `${routePaths.checkIn}/${crmId}/`,
      onEnterCheckinZone,
      onStatusChange: (status: string) => {
        setCheckinState((prevState) => ({ ...prevState, geofenceStatus: status }));
      },
    });
  }, [authenticated, geofenceEnabled, geofenceStatus, onEnterCheckinZone, online, crmId]);

  // setup geofence functions if initialized
  useEffect(() => {
    if (!Geofence || geofenceStatus !== Geofence?.Status.OK) {
      return;
    }
    // reconfigure geofence if changed  notificationConfig
    Geofence.reconfigure({ notificationConfig, onEnterCheckinZone });
  }, [geofenceStatus, notificationConfig, onEnterCheckinZone]);

  const reloadCheckinInfo = useCallback(() => {
    setCheckinState((prevState) => ({
      ...prevState,
      checkinInfo: undefined,
      geofenceStatus: window.Geofence?.getStatus(),
      isInRange: false,
    }));
  }, []);

  const ctx = useMemo(() => ({
    geofenceEnabled,
    geofenceStatus,
    isInRange,
    checkinInfo,
    reloadCheckinInfo,
  }), [checkinInfo, geofenceEnabled, geofenceStatus, isInRange, reloadCheckinInfo]);

  return <CheckinContext.Provider value={ctx}>{children}</CheckinContext.Provider>;
};

export const useCheckin = () => useContext(CheckinContext);

export default CheckinProvider;
