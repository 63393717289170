import { buildServerUrl, loadServerConfig, normalizeUrl } from '../api-helper';

type TokenPromise = (() => Promise<string | undefined>) | (() => string) | string | undefined;

const JwtTokenInterceptor = (() => {
  let tokenPromise: TokenPromise;

  let serverConfig: ServerConfig;
  let serverUrl: string = '';

  const initServerConfig = () => {
    serverConfig = loadServerConfig();
    serverUrl = buildServerUrl(serverConfig);
  };

  const setTokenPromise = (newTokenPromise: TokenPromise) => {
    tokenPromise = newTokenPromise;
  };

  const interceptor = async ({ url, headers, ...config }: any) => {
    let token;

    if (typeof tokenPromise === 'function') {
      token = await tokenPromise();
    } else {
      token = tokenPromise;
    }
    const tokenHeaders = token ? {
      ...headers,
      Authorization: `Bearer ${token}`,
    } : headers;
    return {
      ...config,
      url: normalizeUrl(serverUrl, url),
      headers: tokenHeaders,
    };
  };

  return {
    initServerConfig,
    interceptor,
    setTokenPromise,
  };
})();

export default JwtTokenInterceptor;
