import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, MenuItem, Typography, makeStyles,
} from '@lsy-netline-ui/netline-ui';
import { Add as AddIcon } from '@material-ui/icons';

import { FeatureAccess } from '@crew-webui/common/consts';
import { useConnection, useFeature } from '@crew-webui/common/hooks';
import { DropdownMenu } from '@crew-webui/common/view/components';

import { CategoryType, Feature, titles } from 'consts';
import { IadpPage } from 'view/components';
import MyDataSection from '../MyDataSection/MyDataSection';
import styles from './MyDataPage.styles';

const useStyles = makeStyles(styles, { name: 'MyDataPage' });

type MyDataPageProps = {
  categories: CategoryType[];
  description: string;
  subtitle: string;
  title: string;
  dataLoaded?: boolean;
  readonly?: boolean;
  onBack?: Function;
};

const MyDataPage = ({
  categories, description, subtitle, title, dataLoaded = false, readonly, onBack, ...otherProps
}: MyDataPageProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { online } = useConnection();
  const [menuAnchorEl, setMenuAnchorEl] = useState<any>(null);
  const isFeatureEnabled = useFeature();

  const editingEnabled = isFeatureEnabled(Feature.IDP_MY_DATA, FeatureAccess.WRITE) && !readonly;

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const addNewButtons = editingEnabled && categories?.filter(({ newButton }) => !newButton.disabled).map(({ newButton }) => newButton);

  const addNewDropdown = addNewButtons && (
    <>
      <Button
        className={classes.addNewBtn}
        data-test-id="add-new-button"
        disabled={!online}
        endIcon={<AddIcon />}
        onClick={handleMenuClick}
      >
        {t('myData.addNew')}
      </Button>
      <DropdownMenu anchorEl={menuAnchorEl} onClose={handleMenuClose}>
        {addNewButtons.map(({ label, ...otherButtonProps }) => (
          <MenuItem key={label} {...otherButtonProps}>{label}</MenuItem>
        ))}
      </DropdownMenu>
    </>
  );

  return (
    <IadpPage
      dataLoaded={dataLoaded}
      contentClass={classes.root}
      headerProps={{
        showBackButton: true, onBack, title: t(titles.profile), subtitle: title, actions: addNewDropdown,
      }}
      {...otherProps}
    >
      <Typography
        className={classes.description}
        data-test-id="my-data-description"
        variant="body2"
      >
        {description}
      </Typography>
      {dataLoaded && categories?.map(({ items, label }) => (
        <MyDataSection key={label} label={label} items={items} />
      ))}
    </IadpPage>
  );
};

export default MyDataPage;
