import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette } : Theme) => createStyles({
  detailsText: {
    margin: 0,
    fontFamily: '"Roboto Mono", monospace',
    fontSize: 12,
    color: palette.text.primary,
  },
});
