import { createStyles } from '@lsy-netline-ui/netline-ui';

export default () => createStyles({
  root: {
    marginRight: 16,
    marginLeft: 16,
  },
  fields: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  label: {
    marginBottom: 24,
  },
});
