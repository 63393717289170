import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  outerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mainContainer: {
    'textAlign': 'left',
    'position': 'relative',
    'display': 'flex',
    'flexDirection': 'column',
    'flexGrow': 1,
    'flexShrink': 1,
    'minHeight': 0,
    'overflow': 'hidden',

    '& > :first-child': {
      flexGrow: 1,
    },
  },
  columnContainer: {
    'position': 'relative',
    'flex': '1 1',
    'display': 'flex',
    'minHeight': 0,

    '& > *': {
      flex: '1 1 0',
      width: 0,
      height: '100%',
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
  },
  buddyBar: {
    color: palette.common.white,
    backgroundColor: palette.iadp.button.backgroundColor,
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'center',
  },
});
