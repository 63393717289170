import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDutiesModel, useFeature } from '@crew-webui/common/hooks';
import { DutyList } from '@crew-webui/common/view';
import { NoContentMessage } from '@crew-webui/common/view/components';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import { Feature } from 'consts';
import { IadpPage } from 'view/components';
import styles from './FlightSearchResult.styles';

const useStyles = makeStyles(styles);

const FlightSearchResult = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isFeatureEnabled = useFeature();
  const { search } = useLocation();

  const { flightSearchResult, flightSearchLoading } = useDutiesModel();

  const searchParams = new URLSearchParams(search);
  const depAp = searchParams.get('depAp');
  const arrAp = searchParams.get('arrAp');

  // eslint-disable-next-line no-nested-ternary
  const departureArrivalText = depAp ? (arrAp ? `${depAp}-${arrAp}` : depAp) : arrAp ?? '';

  return (
    <IadpPage
      dataLoaded={!flightSearchLoading}
      headerProps={{ title: t('flightSearch.title'), subtitle: t('flightSearch.resultsTitle'), showBackButton: true }}
    >
      <div className={classes.flightSearchResultText}>
        {searchParams.get('carrier')}{searchParams.get('flightNumber')}/{searchParams.get('flightDepartureDate')} {departureArrivalText}
      </div>
      {!flightSearchResult.length && !flightSearchLoading && <NoContentMessage>{t('flightSearch.emptyResult')}</NoContentMessage>}
      {flightSearchResult && (
        <DutyList
          selectionView
          enabledFeatures={{
            legEnabled: isFeatureEnabled(Feature.VIEW_LEG),
            deadheadEnabled: isFeatureEnabled(Feature.VIEW_DEADHEAD),
            flightInfoEnabled: isFeatureEnabled(Feature.OPERATIONAL_AND_FLIGHT_INFORMATION_ENABLED),
          }}
          events={flightSearchResult}
        />
      )}
    </IadpPage>
  );
};

export default FlightSearchResult;
