import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { logger, services } from '../../api';
import { useCrewAuth } from '../CrewAuth';
import useOnlineAuthorized from './useOnlineAuthorized';
import { useProfile } from '../Profile';

export const useLanguageSwitch = () => {
  const { i18n } = useTranslation();
  const { user } = useCrewAuth();
  const { profile: { availableLanguages } } = useProfile();

  const currentLanguage = i18n.language;
  // Enabling language switch is based on configuration languageServiceAvailable property AND the length of the available languages in the profile
  const enabled = !!user?.configuration?.languageServiceAvailable && availableLanguages.length > 1;

  const switchLanguage = useCallback((languageCode: string) => {
    i18n.changeLanguage(languageCode);
    services.updatePreferredLanguage({ languageCode });
  }, [i18n]);

  return { currentLanguage, enabled, switchLanguage };
};

export const useLanguageFetch = () => {
  const { i18n } = useTranslation();
  const { enabled } = useLanguageSwitch();

  useOnlineAuthorized(() => {
    if (enabled) {
      services.loadPreferredLanguage().then(({ data }: { data?: string }) => {
        const language = data?.trim().toLowerCase();

        if (language && language.length === 2) {
          // do not change the reference of 't' unnecessarily
          if (i18n.language !== language) {
            i18n.changeLanguage(language);
          }
        } else {
          logger.error('Improper response received by loadPreferredLanguage operation', data);
        }
      });
    }
  }, []);
};
