import { useCallback, useMemo } from 'react';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';
import { DateTime, Duration } from 'luxon';

import { useProfile } from '@crew-webui/common/hooks';
import { formatDuration, formatDurationObject } from '@crew-webui/common/utils';

import styles from './SummaryText.styles';

const useStyles = makeStyles(styles, { name: 'SummaryText' });

type SummaryTextProp = {
  highlightExpiration: boolean;
  periodEnd: string;
  textData: {
    name: string;
    value: string;
    type?: string;
  };
};

const FROM_DATE_FORMAT = 'yyyy-MM-dd';

const SummaryText = ({ highlightExpiration, periodEnd, textData }: SummaryTextProp) => {
  const classes = useStyles();
  const { profile } = useProfile();

  const parseDate = useCallback((value: string) => {
    const inputDateFormat = profile.summary?.dateFormat || FROM_DATE_FORMAT;
    const outputFormat = profile.dateFormats.DATE;
    const periodEndInMillis = DateTime.fromISO(periodEnd, { zone: 'utc' }).toUTC().toMillis();
    const date = DateTime.fromFormat(value, inputDateFormat, { zone: 'utc' }).toUTC();

    return {
      highlight: date.isValid && highlightExpiration && date.toMillis() <= periodEndInMillis,
      value: date.isValid ? date.toFormat(outputFormat) : value,
    };
  }, [highlightExpiration, profile, periodEnd]);

  const parseTime = useCallback((value: string) => {
    const duration = Duration.fromISOTime(value);
    return {
      highlight: false,
      value: duration.isValid ? formatDuration(duration, profile.dateFormats.TIME) : value,
    };
  }, [profile]);

  const parseDateTime = useCallback((value: string) => {
    const dateTime = DateTime.fromISO(value);
    return {
      highlight: false,
      value: dateTime.isValid ? dateTime.toFormat(profile.dateFormats.DATE_TIME) : value,
    };
  }, [profile]);

  const parseHours = useCallback((value: string) => ({
    highlight: false,
    value: formatDurationObject({ hours: parseInt(value, 10) }, profile.dateFormats.TIME),
  }), [profile]);

  const parseMinutes = useCallback((value: string) => ({
    highlight: false,
    value: formatDurationObject({ minutes: parseInt(value, 10) }, profile.dateFormats.TIME),
  }), [profile]);

  // Check the text value whether is date type or string. In case of date, highlight when it is expired and format
  const summaryText = useMemo(() => {
    if (textData.type === 'Date') {
      return parseDate(textData.value);
    }
    if (textData.type === 'Hour') {
      return parseHours(textData.value);
    }
    if (textData.type === 'Minute') {
      return parseMinutes(textData.value);
    }
    if (textData.type === 'Time') {
      return parseTime(textData.value);
    }
    if (textData.type === 'DateTime') {
      return parseDateTime(textData.value);
    }
    if (textData.type === 'Percentage') {
      return {
        highlight: false,
        value: `${textData.value}%`,
      };
    }
    return {
      highlight: false,
      value: textData.value,
    };
  }, [parseDate, parseHours, parseMinutes, parseTime, parseDateTime, textData]);

  return (
    <div className={classes.root} data-test-id="summary-line">
      <div data-test-id="summary-line-key">{textData.name}</div>
      <div data-test-id="summary-line-value" className={clsx({ [classes.highlighted]: summaryText.highlight })}>
        {summaryText.value}
      </div>
    </div>
  );
};

export default SummaryText;
