import { AxiosError, AxiosResponse } from 'axios';

import { LocalStorageKeys } from '../../consts';
import { logger, syncStorage as storage } from '../../api';

export const setAlreadyLoggedIn = () => storage.setItem(LocalStorageKeys.ALREADY_LOGGED_IN, true);

export const isAlreadyLoggedIn = () => storage.getItem(LocalStorageKeys.ALREADY_LOGGED_IN);

export const checkIfAlreadyLoggedIn = (showDialog: (params: any) => void, hideDialog: () => void, t: T, loginCallback?: () => void) => {
  const alreadyLoggedIn = isAlreadyLoggedIn();
  if (!alreadyLoggedIn) {
    logger.info('Login suspended: first login in offline mode...');
    showDialog({
      title: t('login.offlineWarning'),
      content: [
        t('login.firstLoginOnline'),
        t('login.checkConnection'),
      ].join(' '),
      buttons: {
        text: t('login.login'),
        onClick: () => {
          hideDialog();
          if (loginCallback) {
            loginCallback();
          }
        },
        hideDialog,
      },
    });
  }
  return alreadyLoggedIn;
};

export const checkResponseSuccess = (response: AxiosResponse, checkResult? : boolean) => {
  if (
    !response || !response.data || typeof response.data === 'string' ||
    (checkResult && (!response.data.success || !response.data.result))
  ) {
    const error: AxiosError = {
      name: 'CustomAxiosError',
      message: 'Service call was unsuccessful!',
      config: response.config,
      isAxiosError: false,
      toJSON: () => ({ }),
      response,
    };
    throw error;
  }
};
