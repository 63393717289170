import { useCallback } from 'react';
import { Button } from '@lsy-netline-ui/netline-ui';
import { useDialog } from '@crew-webui/common/hooks';

import ViewLog from 'view/Profile/ViewLog/ViewLog';

const ViewLogButton = () => {
  const { showDialog, hideDialog } = useDialog();

  const showLog = useCallback(() => {
    showDialog({
      content: <ViewLog />,
      buttons: [{ text: 'Close', onClick: hideDialog }],
    });
  }, [showDialog, hideDialog]);

  return (
    <Button data-test-id="view-log" onClick={() => showLog()}>
      SHOW LOGS
    </Button>
  );
};

export default ViewLogButton;
