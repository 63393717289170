import { forwardRef } from 'react';
import { Autocomplete as MuiAutocomplete, AutocompleteProps } from '@material-ui/lab';

const getOptionLabel = (option: any) => option?.value || option;
const getOptionSelected = (option: any, value: any) => option.value === value;
const renderOption = (option: any) => option?.labelFormatted || option?.label || option;

const Autocomplete = forwardRef<any, AutocompleteProps<any, any, any, any>>(
  // eslint-disable-next-line prefer-arrow-callback
  function Autocomplete(props, ref) {
    return (
      <MuiAutocomplete
        ref={ref}
        autoSelect
        disableClearable
        freeSolo
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        {...props}
      />
    );
  },
);

export default Autocomplete;
