import { AvailableLanguages } from '@crew-webui/common/consts';

import {
  CustomerProfile,
  HotelFields,
  ProfileFeature,
  SummaryHeaders,
} from 'consts';

import defaultProfileSettings from './defaultProfile';

const naxProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  calendarWeekStartMonday: true,
  showPairingNumber: false,
  flightDetailsEncollapsed: true,
  utcTimePostfix: 'Z',
  disabledHotelFields: [
    HotelFields.TYPE,
    HotelFields.TRANSPORT_COMPANY_TYPE,
  ],
  hiddenHeadersIfNoData: [
    SummaryHeaders.RAIC,
  ],
  enablings: {
    ...defaultProfileSettings.enablings,
    [ProfileFeature.VIEW_ROSTER_TAGS_CALENDAR_FILTER]: false,
  },
  availableLanguages: [AvailableLanguages.EN],
};

export default naxProfileSettings;
