import { createStyles } from '@lsy-netline-ui/netline-ui';

export default () => createStyles({
  root: {
    '&:last-child $list': {
      marginBottom: 0,
    },
  },
  label: {
    margin: '0 16px 16px',
  },
  list: {
    marginBottom: 36,
  },
  aside: {
    alignSelf: 'flex-start',
    margin: '4px 8px 4px 4px',
  },
  doc: {
    '&:not(:last-child)': {
      paddingBottom: 7,
      borderBottom: '1px solid rgba(0, 0, 0, .12)',
    },
  },
  additionalDoc: {
    'marginTop': 5,

    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
});
