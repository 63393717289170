import { createStyles } from '@lsy-netline-ui/netline-ui';

export const gridMargin = 8;
const xsViewCellSize = `calc((100vw - ${2 * gridMargin}px) / 7)`;
export const smCalendarPadding = 8;
const smViewCellSize = `calc((50vw - ${2 * smCalendarPadding}px - ${2 * gridMargin}px) / 7)`;
export const mdCalendarPadding = 22;
const mdViewCellSize = `calc((50vw - ${2 * mdCalendarPadding}px - ${2 * gridMargin}px) / 7)`;

export default ({ breakpoints, palette }: Theme) => createStyles({
  root: {
    'display': 'flex',
    'flexDirection': 'column',
    'transition': 'transform .5s ease',
    'width': '100%',

    '& $weekdayContainer, & $days': {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, calc(100% / 7))',
    },
  },
  ownColumn: {
    '& $weekdayContainer': {
      padding: [[4, 0]],
      borderBottom: 'none',
    },
    '& $days': {
      gridAutoRows: smViewCellSize,
    },
    [breakpoints.up('md')]: {
      '& $days': {
        gridAutoRows: mdViewCellSize,
      },
    },
  },
  weekdayContainer: {
    borderBottom: '1px solid #eee',
    backgroundColor: palette.background.secondary,
  },
  weekDay: {
    fontSize: 11,
    lineHeight: '13px',
    textAlign: 'center',
    width: '100%',
    color: palette.text.secondary,
    fontWeight: 500,
  },
  days: {
    gridAutoRows: xsViewCellSize,
    flexGrow: 1,
    width: '100%',
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
});
