import {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import DialogContext, { DialogItem } from './DialogContext';
import { transformApiErrors } from './dialogUtils';

const DialogProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<DialogItem[]>([]);

  const showDialog = useCallback(({
    title, content, buttons, type, closeButton, actionsClassName,
  }: DialogItem, multiple = false) => {
    setItems((prevItems) => {
      const itemIdx = multiple ? -1 : prevItems.findIndex((item) => title === item.title && content === item.content);

      // in case the same message allowed to be displayed only once, we move the item to the top from the message stack
      const newItem = {
        title, content, buttons, type, closeButton, actionsClassName,
      };
      return itemIdx < 0 ?
        [newItem, ...prevItems] :
        [newItem, ...prevItems.slice(0, itemIdx), ...prevItems.slice(itemIdx + 1)];
    });
  }, []);

  const hideDialog = useCallback(() => {
    setItems((prevItems) => prevItems.slice(1));
  }, []);

  const showServiceErrorMessage = useCallback((error: React.ReactNode, shouldParseError = false) => {
    const buttons = { text: t('button.ok'), onClick: hideDialog, primary: true };

    if (shouldParseError) {
      const { type, title, text } = transformApiErrors(t, error);
      showDialog({
        title, content: text, buttons, type,
      });
    } else {
      showDialog({
        title: t('error.serviceError'),
        content: error,
        buttons,
        type: 'error',
      });
    }
  }, [hideDialog, showDialog, t]);

  const showConfirmDialog = useCallback((title: React.ReactNode, handleConfirmed: Function, handleReject?: Function) => {
    const handleYesClick = () => {
      hideDialog();
      handleConfirmed();
    };

    const handleNoClick = () => {
      hideDialog();
      if (handleReject) {
        handleReject();
      }
    };
    showDialog({
      title,
      buttons: [
        { text: t('common.cancel'), onClick: handleNoClick, secondary: true },
        {
          text: t('common.yes'), onClick: handleYesClick, primary: true,
        },
      ],
    });
  }, [hideDialog, showDialog, t]);

  const ctx = useMemo(() => ({
    items,
    showConfirmDialog,
    showDialog,
    showServiceErrorMessage,
    hideDialog,
  }), [hideDialog, items, showConfirmDialog, showDialog, showServiceErrorMessage]);

  return <DialogContext.Provider value={ctx}>{children}</DialogContext.Provider>;
};

export const useDialog = () => useContext(DialogContext);

export default DialogProvider;
