import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  makeStyles,
  Paper,
} from '@lsy-netline-ui/netline-ui';
import {
  Edit as EditIcon, ExpandMore as ExpandMoreIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useProfile } from '@crew-webui/common/hooks';

import { Event } from '../../../consts';
import CrewMember from '../CrewMember/CrewMember';
import OpenPosition from '../OpenPosition/OpenPosition';
import { RoleType } from '../../../consts/dutiesTypes';

import styles from './LegDetailsContent.styles';

const useStyles = makeStyles(styles, { name: 'LegDetails' });

type LegDetailsContentProps = {
  crewMembers: any;
  displayAdditionalFlightDetails?: () => void;
  editRoles?: () => void;
  showAdditionalFlightDetails?: boolean;
  event: Event;
  eventRemarkEnabled?: boolean;
  online?: boolean;
  showEditRoleButton?: boolean;
  simplified?: boolean;
  isForeign?: boolean;
};

const LegDetailsContent = ({
  crewMembers,
  displayAdditionalFlightDetails,
  editRoles,
  event,
  eventRemarkEnabled = false,
  online = false,
  showEditRoleButton = false,
  showAdditionalFlightDetails = false,
  simplified = false,
  isForeign = false,
}: LegDetailsContentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    crew = [], openPositions = [], deadheads = [], deadheadOpenPositions = [],
  } = crewMembers;

  const { roles, remark } = event;
  const { profile: { flightDetailsEncollapsed } } = useProfile();

  return (
    <div className={classes.detailsContainer}>
      {!isForeign && (
        <Accordion className={classes.accordion} defaultExpanded={flightDetailsEncollapsed}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.sectionTitle} data-test-id="crew-members-label">{t('duties.legDetails.crewMembers')}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.section}>
              {(crew as any[]).map((crewMember) => (
                <CrewMember key={crewMember.id} crewMember={crewMember} />
              ))}
              {(openPositions as any[]).map((openPosition) => (
                <OpenPosition key={openPosition.rank} openPosition={openPosition} />
              ))}
              {(deadheads as any[]).map((crewMember) => (
                <CrewMember key={crewMember.id} crewMember={crewMember} deadhead />
              ))}
              {(deadheadOpenPositions as any[]).map((openPosition) => (
                <OpenPosition key={openPosition.rank} openPosition={openPosition} deadhead />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      {!simplified && (
        <>
          {!isForeign && (
            <Accordion className={classes.accordion} defaultExpanded={flightDetailsEncollapsed}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.sectionTitle}>{t('duties.legDetails.yourRole')}</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={clsx(classes.section, classes.rolesContainer)} data-test-id="roles">
                  <div className={classes.roles}>
                    {(!roles || !roles.length) && (
                      <div className={classes.sectionText} data-test-id="roles-no-role">{t('duties.legDetails.noRoles')}</div>
                    )}
                    {roles && !!roles.length && (roles as RoleType[]).map((role) => (
                      <div className={classes.role} data-test-id="roles-role" key={role.id}>
                        {role.name}
                      </div>
                    ))}
                  </div>
                  {showEditRoleButton && (
                    <Button
                      data-test-id="edit-role-button"
                      disabled={!online}
                      onClick={editRoles}
                      startIcon={<EditIcon />}
                    >
                      {t('button.editRole')}
                    </Button>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          )}

          {eventRemarkEnabled && (
            <Accordion className={classes.accordion} defaultExpanded={flightDetailsEncollapsed}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.sectionTitle}>{t('duties.main.eventRemark')}</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={clsx(classes.section, classes.remarksContainer)} data-test-id="event-remark">
                  <span className={classes.sectionText} data-test-id="event-remark-text">{remark || t('duties.legDetails.noRemark')}</span>
                </div>
              </AccordionDetails>
            </Accordion>
          )}

          {showAdditionalFlightDetails && event.originalEventType !== 'FPR' && (
            <Paper
              id="additional-leginfo"
              className={clsx(classes.linkElement, classes.accordion)}
              onClick={displayAdditionalFlightDetails}
              data-test-id="additional-leginfo"
            >
              <div
                id="additional-leginfo-text"
                className={classes.sectionTitle}
                data-test-id="additional-leginfo-text"
              >
                {t('duties.legDetails.additionalFlightDetails.title')}
              </div>
              <div
                id="additional-leginfo-button"
                className={classes.linkElementButton}
                data-test-id="additional-leginfo-button"
              >
                <KeyboardArrowRightIcon />
              </div>
            </Paper>
          )}

        </>
      )}
    </div>
  );
};

export default LegDetailsContent;
