export default ({ palette }: Theme) => ({
  root: {
    color: palette.text.secondaryLabel,
  },
  title: {
    margin: '16px 0 8px 5px',
    fontSize: 14,
  },
  table: {
    'marginLeft': 6,
    'borderSpacing': 0,
    'fontSize': 12,

    '& td': {
      border: '1px solid',
      borderColor: palette.iadp.borderColor,
      padding: 4,
    },
    '& th': {
      backgroundColor: palette.iadp.borderColor,
    },
  },
});
