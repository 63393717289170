import { CustomerProfile } from 'consts';
import defaultProfileSettings from './defaultProfile';

const cpzProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  icons: {
    ...defaultProfileSettings.icons,
  },
  logo: 'tsa.png',
};

export default cpzProfileSettings;
