import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, spacing }: Theme) => createStyles({
  crewMember: {
    backgroundColor: palette.common.white,
    marginBottom: 4,
    borderRadius: 4,
    padding: 12,
    display: 'flex',
    flexDirection: 'row',
    minHeight: 'fit-content',
    overflow: 'hidden',
  },
  avatar: {
    'backgroundColor': palette.greyIconColor,
    'width': 40,
    'height': 40,
    'borderRadius': '100%',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'marginRight': 12,
    '& svg': {
      fontSize: 24,
      color: palette.common.white,
    },
  },
  assignment: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    width: 'calc(100% - 52px)',
    color: palette.text.label,
  },
  name: {
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
    'marginTop': 4,
    'fontWeight': 500,
    '& svg': {
      color: palette.success.main,
      fontSize: 16,
      marginLeft: 8,
      height: 20,
      width: 20,
    },
  },
  crewMemberDetails: {
    fontSize: 12,
    color: palette.text.secondaryLabel,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    columnGap: spacing(2),
    rowGap: spacing(0.5),
  },
  crewMemberDetailsKey: {
    textTransform: 'uppercase',
  },
  previousEvent: {
    fontSize: 13,
    marginTop: 12,
  },
  nextEvent: {
    fontSize: 13,
    marginTop: 4,
  },
  deadhead: {
    '& $name': {
      color: palette.deadheadTextColor.dark,
    },
    '& $crewMemberDetails': {
      color: palette.deadheadTextColor.light,
    },
  },
});
