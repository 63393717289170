import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { set } from 'lodash';

import { logger, syncStorage as storage } from '../../api';
import { useCrewAuth } from '../CrewAuth';
import { useDomain } from '../Domain';
import UserPreferencesContext from './UserPreferencesContext';

const LOCAL_STORAGE_KEY = 'userPrefs';

const loadPrefs = (crmId: string) => {
  try {
    const prefs = storage.getItem(`${LOCAL_STORAGE_KEY}.${crmId}`);
    return JSON.parse(prefs);
  } catch (ex) {
    return false;
  }
};

export const UserPreferencesProvider = ({ children }: { children: React.ReactNode }) => {
  const { crmId } = useCrewAuth();
  const { pemsDomain } = useDomain();

  const [prefs, setPrefs] = useState({});
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (logger.assignCustomData) {
      logger.assignCustomData({ pemsDomain });
    }
  }, [pemsDomain]);

  useEffect(() => {
    if (crmId && logger.assignCustomData) {
      logger.assignCustomData({ crmId });
    }
  }, [crmId]);

  // in case of crmId change, reload state from localStorage
  useEffect(() => {
    if (crmId) {
      setPrefs(loadPrefs(crmId) || {});
      setHasLoaded(true);
    }
  }, [crmId]);

  const setPref = useCallback((key: string, value: any) => {
    setPrefs((prevPrefs) => {
      const newPrefs = set({ ...prevPrefs }, key, value);
      storage.setItem(`${LOCAL_STORAGE_KEY}.${crmId}`, JSON.stringify(newPrefs));
      return newPrefs;
    });
  }, [crmId]);

  const ctx = useMemo(() => ({ ...prefs, hasLoaded, setPref }), [hasLoaded, prefs, setPref]);

  return <UserPreferencesContext.Provider value={ctx}>{children}</UserPreferencesContext.Provider>;
};

export const useUserPreferences = () => useContext(UserPreferencesContext);
