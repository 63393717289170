import { createContext } from 'react';

import {
  Address, Contact, MyDataType, TravelDoc, Visa,
} from '../../consts';

export type MyDataState = {
  addresses?: Address[];
  basicInfo?: Record<string, any>;
  contacts?: Contact[];
  passports?: TravelDoc[];
  visas?: Visa[];
};

type MyDataContextType = Omit<MyDataState, 'basicInfo'> & {
  emails?: string[];
  personalData?: Record<string, any>;
  phones?: string[];

  createMyData: (type: MyDataType, object: {}) => Promise<void>;
  deleteMyData: (type: MyDataType, object: {}) => Promise<void>;
  loadMyData: (type: MyDataType) => Promise<void>;
  updateMyData: (type: MyDataType, newData: {}, prevData: {}) => Promise<void>;
};

const VOID_PROMISE = Promise.resolve();
const MyDataContext = createContext<MyDataContextType>({
  createMyData: () => VOID_PROMISE,
  deleteMyData: () => VOID_PROMISE,
  loadMyData: () => VOID_PROMISE,
  updateMyData: () => VOID_PROMISE,
});

export default MyDataContext;
