import {
  CheckInTitle, CustomerProfile, ProfileFeature, SelectionMode,
} from 'consts';

import defaultProfileSettings from './defaultProfile';

const svrProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  enablings: {
    ...defaultProfileSettings.enablings,
    [ProfileFeature.VIEW_BLOCKMONTH_SWITCH_ON_SUMMARY]: false,
    [ProfileFeature.PERFORM_ROLE_EDITING]: false,
  },
  showAircraftTypeCodeOfLeg: false,
  showRegistration: true,
  showPairingNumber: false,
  calendarWeekStartMonday: true,
  showRosterChanges: false,
  showLocalTime: false,
  localTimePostfix: '',
  icons: {
    ...defaultProfileSettings.icons,
  },
  checkInTitle: CheckInTitle.NO_PAIRING_NUMBER,
  notificationConfirmationSelectionMode: SelectionMode.ALL,
  logo: 'ural.png',
  agreementTexts: {
    ...defaultProfileSettings.agreementTexts,
    sms: {
      en: 'I agree with the following terms: Ural Airlines text message (SMS) service sends text messages to your mobile device for Ural Airlines related services such as flight information at no cost. Message and data rates may apply.',
    },
  },
};

export default svrProfileSettings;
