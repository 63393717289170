import { createStyles } from '@lsy-netline-ui/netline-ui';

import { gridMargin, smCalendarPadding, mdCalendarPadding } from '../Month/Month.styles';

export default ({ breakpoints, palette }: Theme) => createStyles({
  ownColumn: {
    'display': 'flex',
    'flexDirection': 'column',

    '&$root': {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      paddingTop: 0,
      paddingRight: smCalendarPadding,
      paddingLeft: smCalendarPadding,
    },
    '& $rosterPeriodContainer': {
      overflowY: 'auto',
    },

    [breakpoints.up('md')]: {
      '&$root': {
        paddingRight: mdCalendarPadding,
        paddingLeft: mdCalendarPadding,
      },
    },
  },
  root: {
    position: 'relative',
    overflow: 'hidden',
    userSelect: 'none',
    color: palette.text.primary,
    backgroundColor: palette.background.secondary,
    height: 'calc(47vh - 44px)',
    padding: `8px ${gridMargin}px 0`,
  },
  rosterPeriodContainer: {
    height: '100%',
    overflow: 'hidden',
  },
  daysContainer: {
    display: 'flex',
    height: '100%',
  },
});
