import { Typography, makeStyles } from '@lsy-netline-ui/netline-ui';

import { MyDataFieldType } from 'consts';

import MyDataField from './MyDataField';
import styles from './MyDataFields.styles';

const useStyles = makeStyles(styles, { name: 'MyDataFields' });

type MyDataFieldsProps = {
  control: any;
  data?: any;
  editing: any;
  errors: any;
  fields: MyDataFieldType[];
  namePrefix?: string;
  label?: string;
};

const MyDataFields = ({
  control, data, editing, errors, fields, label, namePrefix,
}: MyDataFieldsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {label && (
        <Typography className={classes.label} data-test-id="my-data-section-title" variant="h6">
          {label}
        </Typography>
      )}
      <div className={classes.fields}>
        {fields.map((field) => (
          <MyDataField
            key={field.valuePath}
            control={control}
            data={data}
            editing={editing}
            errors={errors}
            field={field}
            namePrefix={namePrefix || ''}
          />
        ))}
      </div>
    </div>
  );
};

export default MyDataFields;
