import localForage from 'localforage';

import logger from '../logger';
import { getItem, isAsynchCacheWorking, removeItem } from './asyncStorage';

const TIMESTAMP_DELIMITER = '||';
const MAX_TIMESTAMP_SIZE = 24;
const DELETE_OLDEST_NO = 5;

type KeyAndTime = {
  key: string;
  timeStamp: number;
};

export const getKeys = async () => localForage.keys();

// get timestamp
export const getTimeStamp = (value: string) => {
  const idx = value.substr(0, MAX_TIMESTAMP_SIZE).indexOf(TIMESTAMP_DELIMITER);
  return idx > 0 ? Number(value.substring(0, idx)) : undefined;
};

// append timestamp
export const appendTimeStamp = (value: string, time: number) => time + TIMESTAMP_DELIMITER + value;

// cut timestamp
export const cutTimeStamp = (value: string) => {
  const idx = value.substr(0, MAX_TIMESTAMP_SIZE).indexOf(TIMESTAMP_DELIMITER);
  return idx > 0 ? value.substr(idx + TIMESTAMP_DELIMITER.length) : value;
};

// Size is calculated by this topic https://stackoverflow.com/questions/4391575/how-to-find-the-size-of-localstorage
export const printAsyncCacheSize = async () => {
  if (!isAsynchCacheWorking()) {
    logger.error('Cache not working, skip print cache');
    return;
  }
  let length = 0;
  const localForageKeys: string[] = await getKeys();
  await Promise.all(localForageKeys.map(async (key) => {
    const val: string | null = await localForage.getItem(key);
    length += val ? val.length : 0;
  }));
  const size = length ? 3 + ((length * 16) / (8 * 1024)) : 0;
  logger.info(`In asyncStorage there are ${localForageKeys.length} item, size is ${size} KB`);
};

const doCacheProcess = async (processCache: (key: string, timeStamp: number) => void) => {
  const keys = await getKeys();
  await Promise.all(keys.map(async (key) => {
    const value = await getItem(key);
    if (typeof value !== 'string') {
      return undefined;
    }
    const timeStamp = getTimeStamp(value);
    if (timeStamp) {
      await processCache(key, timeStamp);
    }
    return undefined;
  }));
};

// push the item to the array, hold ordered
const pushInOrder = (keysAndTimes: KeyAndTime[], key: string, newTimeStamp: number) => {
  const idx = keysAndTimes.findIndex(({ timeStamp }) => newTimeStamp < timeStamp);
  if (idx < 0) {
    keysAndTimes.push({ key, timeStamp: newTimeStamp });
  } else {
    keysAndTimes.splice(idx, 0, { key, timeStamp: newTimeStamp });
  }
};

/**
 * Clear the latest cache items
 * @param clearNo The number to deleted
 */
export const clearLastCaches = async (clearNo: number = DELETE_OLDEST_NO) => {
  if (!isAsynchCacheWorking()) {
    logger.error('Cache not working, skip clear last caches');
    return;
  }
  const keysAndTimes: KeyAndTime[] = [];
  const collectMinValues = (key: string, timeStamp: number) => {
    if (timeStamp > 0) {
      if (keysAndTimes.length < clearNo) {
        pushInOrder(keysAndTimes, key, timeStamp);
      } else if (timeStamp < keysAndTimes[clearNo - 1].timeStamp) {
        keysAndTimes.pop();
        pushInOrder(keysAndTimes, key, timeStamp);
      }
    }
  };
  await doCacheProcess(collectMinValues);
  let counter = 0;
  keysAndTimes.forEach(({ key }) => {
    removeItem(key);
    counter += 1;
  });
  logger.info(`Last ${counter} caches deleted`);
};

/**
 * Clear cached item, if has timestamp, and it is older then the given max date time in millis.
 * @param {number} [maxDateTime]
 */
export const clearEncryptedCache = async (maxDateTime: number) => {
  if (!isAsynchCacheWorking()) {
    logger.error('Cache not working, skip clear cache');
    return;
  }
  let counter = 0;
  const start = Date.now();
  const removeIfOld = (key: string, timeStamp: number) => {
    if (timeStamp > 0 && timeStamp < maxDateTime) {
      counter += 1;
      removeItem(key);
    }
  };
  await doCacheProcess(removeIfOld);
  const duration = Date.now() - start;
  logger.info(`Clear encrypted cache process before ${maxDateTime}. Deleted: ${counter} in ${duration} ms.`);
};
