import { merge } from 'lodash';

import { MyDataTravelDocType, TravelDoc, Visa } from '@crew-webui/common/consts';

export const getLabel = (type: string, t: T): string => {
  switch (type) {
    case MyDataTravelDocType.PASSPORT: return t('myData.travelDoc.passport');
    case MyDataTravelDocType.ALIEN_RESIDENT_NUMBER: return t('myData.travelDoc.alienResidentCard');
    case MyDataTravelDocType.IDENTITY: return t('myData.travelDoc.identityCard');
    case MyDataTravelDocType.DRIVER_LICENCE: return t('myData.travelDoc.drivingLicence');
    case MyDataTravelDocType.RESTRICTED_AREA_INDENTITY_CARD: return t('myData.travelDoc.restrictedAreaIdentityCard');
    default: return '';
  }
};

export const getSubmitButtonLabel = (newTravelDoc: boolean, type: string, t: T): string => {
  if (!newTravelDoc) {
    return t('myData.saveChanges');
  }
  switch (type) {
    case MyDataTravelDocType.PASSPORT: return t('myData.travelDoc.addNewPassport');
    case MyDataTravelDocType.ALIEN_RESIDENT_NUMBER: return t('myData.travelDoc.addNewAlienResidentCard');
    case MyDataTravelDocType.IDENTITY: return t('myData.travelDoc.addNewIdentityCard');
    case MyDataTravelDocType.DRIVER_LICENCE: return t('myData.travelDoc.addNewDrivingLicence');
    case MyDataTravelDocType.RESTRICTED_AREA_INDENTITY_CARD: return t('myData.travelDoc.addNewRestrictedAreaIdentityCard');
    default: return '';
  }
};

export const getTitle = (travelDoc: TravelDoc | undefined, newTravelDoc: boolean, type: string, t: T) => {
  if (newTravelDoc) {
    switch (type) {
      case MyDataTravelDocType.PASSPORT: return t('myData.travelDoc.newPassport');
      case MyDataTravelDocType.ALIEN_RESIDENT_NUMBER: return t('myData.travelDoc.newAlienResidentCard');
      case MyDataTravelDocType.IDENTITY: return t('myData.travelDoc.newIdentityCard');
      case MyDataTravelDocType.DRIVER_LICENCE: return t('myData.travelDoc.newDrivingLicence');
      case MyDataTravelDocType.RESTRICTED_AREA_INDENTITY_CARD: return t('myData.travelDoc.newRestrictedAreaIdentityCard');
      default: return '';
    }
  }

  const label = getLabel(type, t);
  return travelDoc ? `${label}: ${travelDoc.natKey.number}` : label;
};
export const convertTravelDocForAPI = (defaultDoc: Object, original: TravelDoc | undefined, inForm: object): TravelDoc => {
  const mergedTravelDoc = merge(defaultDoc, original, inForm) as TravelDoc;
  mergedTravelDoc.issueCountry = mergedTravelDoc.issueCountry.toUpperCase();

  return mergedTravelDoc;
};

export const convertVisaForAPI = (defaultVisa: object, original: Visa, inForm: object): Visa => {
  const mergedVisa = merge(defaultVisa, original, inForm) as Visa;
  mergedVisa.docExists = mergedVisa.docExists === 'true';
  mergedVisa.natKey.countryCode = mergedVisa.natKey.countryCode.toUpperCase();

  return mergedVisa;
};
