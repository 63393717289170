import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import { PERSIST_CACHE } from '@crew-webui/common/api';
import { LocalStorageKeys } from '@crew-webui/common/consts';
import {
  useFeature, useMultiColumn, useProfile, useUserPreferences,
} from '@crew-webui/common/hooks';
import { ProfileContent, ProfileItem } from '@crew-webui/common/view';
import { useSnackbar } from '@crew-webui/common/view/components';

import { Feature, languages, routePaths } from 'consts';
import { IadpPage } from '../components';
import styles from './Profile.styles';
import ViewLogButton from './ViewLog/ViewLogButton';

const useStyles = makeStyles(styles, { name: 'Profile' });

const showLog = process.env.REACT_APP_SHOW_LOG === 'true';

const Profile = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isFeatureEnabled = useFeature();
  const multiColumn = useMultiColumn();
  const { profile: { autoRefreshEnabledByDefault } } = useProfile();
  const { enqueueSnackbar } = useSnackbar();
  const {
    [LocalStorageKeys.AUTO_REFRESH_ENABLED]: autoRefreshEnabled = autoRefreshEnabledByDefault,
    setPref,
  } = useUserPreferences();

  const showSettingsLink = isFeatureEnabled(Feature.VIEW_NOTIF_SETTINGS);
  const showMyDataLink = isFeatureEnabled(Feature.IDP_MY_DATA);
  const showAccessRightsLink = isFeatureEnabled(Feature.IDP_BUDDY_ACCESS_RIGHTS);
  const performCheckinEnabled = isFeatureEnabled(Feature.PERFORM_CHECK_IN);

  const handleAutoRefreshChange = useCallback((enabled: boolean) => {
    setPref(LocalStorageKeys.AUTO_REFRESH_ENABLED, enabled);
    enqueueSnackbar(t('components.snackbar.settingsUpdated'), { variant: 'success' });
  }, [enqueueSnackbar, setPref, t]);

  const links: ProfileItem[] = [
    {
      'type': 'link',
      'data-test-id': 'notification-settings-link',
      'label': t('settings.newsSettings'),
      'to': routePaths.notificationSettings,
      'visible': showSettingsLink,
    },
    {
      'type': 'link',
      'data-test-id': 'mydata-link',
      'label': t('myData.title'),
      'to': routePaths.myData,
      'visible': showMyDataLink,
    },
    {
      'type': 'link',
      'data-test-id': 'access-rights-link',
      'label': t('accessRights.title'),
      'to': routePaths.accessRights,
      'visible': showAccessRightsLink,
    },
  ];

  return (
    <IadpPage
      contentClass={multiColumn ? classes.content : undefined}
      headerProps={{ title: t('settings.profile') }}
      dataLoaded
    >
      <ProfileContent
        autoRefreshEnabled={autoRefreshEnabled}
        cacheEnabled={PERSIST_CACHE}
        languages={languages}
        links={links}
        onAutoRefreshChange={handleAutoRefreshChange}
        performCheckinEnabled={performCheckinEnabled}
      />
      {showLog && <ViewLogButton />}
    </IadpPage>
  );
};

export default Profile;
