import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import { useIadpParams } from '@crew-webui/common/api';
import { useProfile } from '@crew-webui/common/hooks';
import { NoContentMessage } from '@crew-webui/common/view/components';

import { titles } from 'consts';
import { useDailyRemarkListing } from 'hooks';
import { IadpPage } from 'view/components';
import styles from './ReadDailyRemark.styles';

const useStyles = makeStyles(styles, { name: 'ReadDailyRemark' });

const ReadDailyRemark = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useIadpParams<{ id: string }>();
  const { dailyRemarksFromSched, dailyRemarksFromMe, hasLoaded } = useDailyRemarkListing(false);
  const {
    profile: {
      dateFormats = {},
    },
  } = useProfile();

  const dailyRemark = [...dailyRemarksFromSched, ...dailyRemarksFromMe].find((item) => item.logicalIdRelatedEvent === id);
  const subtitle = dailyRemark?.remarkSender === 'CRM' ? t('dailyRemarks.read.titleCrm') : t('dailyRemarks.read.title');
  const showNoContentMessage = hasLoaded && !dailyRemark;

  return (
    <IadpPage
      headerProps={{ title: t(titles.dailyRemarks), subtitle, showBackButton: true }}
      dataLoaded={hasLoaded}
    >
      {showNoContentMessage && <NoContentMessage>{t('dailyRemarks.read.noContent')}</NoContentMessage>}
      {dailyRemark && (
        <>
          <dl className={classes.list}>
            <dt className={classes.label} data-test-id="remark-date-label">{t('dailyRemarks.read.date')}</dt>
            <dd className={classes.text} data-test-id="remark-date">
              {DateTime.fromISO(dailyRemark.datetime).toUTC().toFormat(dateFormats.DATE)}
            </dd>

            <dt className={classes.label} data-test-id="remark-text-label">{t('dailyRemarks.read.remark')}</dt>
            <dd className={classes.text} data-test-id="remark-text">{dailyRemark.text}</dd>
          </dl>
          <div className={classes.received} data-test-id="remark-meta">
            {t(
              'dailyRemarks.read.receivedOn',
              { date: DateTime.fromISO(dailyRemark.remarkTimeStamp).toUTC().toFormat(dateFormats.DATE_TIME) },
            )}
          </div>
        </>
      )}

    </IadpPage>
  );
};

export default ReadDailyRemark;
