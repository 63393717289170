import { forwardRef } from 'react';
import { SvgIcon } from '@lsy-netline-ui/netline-ui';

const SbyIcon = forwardRef<any>((props, ref) => (
  <SvgIcon ref={ref} {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.4924 14.8315C18.8437 14.8315 19.1285 15.1163 19.1285 15.4676V17.58C19.1285 17.9313 18.8437 18.2161 18.4924 18.2161C18.1411 18.2161 17.8563 17.9313 17.8563 17.58V15.4676C17.8563 15.1163 18.1411 14.8315 18.4924 14.8315ZM19.8256 15.5938C20.0452 15.3196 20.4455 15.2754 20.7197 15.495C21.5342 16.1473 22.0576 17.1522 22.0576 18.2789C22.0576 20.2479 20.4614 21.8441 18.4924 21.8441C16.5234 21.8441 14.9272 20.2479 14.9272 18.2789C14.9272 17.1522 15.4506 16.1473 16.2651 15.495C16.5393 15.2754 16.9396 15.3196 17.1592 15.5938C17.3788 15.868 17.3345 16.2683 17.0603 16.4879C16.5344 16.9092 16.1994 17.5547 16.1994 18.2789C16.1994 19.5453 17.226 20.5719 18.4924 20.5719C19.7588 20.5719 20.7854 19.5453 20.7854 18.2789C20.7854 17.5547 20.4504 16.9092 19.9245 16.4879C19.6503 16.2683 19.606 15.868 19.8256 15.5938Z" />
    <path d="M21.2209 2.23925L2.2889 10.8416C1.84373 11.0439 1.81738 11.6663 2.24387 11.9055L7.65318 14.9391C7.79037 15.016 7.89164 15.144 7.93498 15.2952L9.66048 21.3157C9.80361 21.8151 10.4723 21.9058 10.7433 21.4626L21.9769 3.09397C22.2838 2.59217 21.7564 1.99592 21.2209 2.23925Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.9703 3.08971C22.2731 2.59451 21.7527 2.00611 21.2242 2.24623L2.29219 10.8486C1.85288 11.0482 1.82687 11.6624 2.24775 11.8985L7.65707 14.932C7.79606 15.01 7.89866 15.1396 7.94256 15.2928L9.66807 21.3133C9.80932 21.8062 10.4692 21.8957 10.7367 21.4584L21.9703 3.08971ZM21.2177 2.23193C21.7603 1.9854 22.2946 2.5895 21.9837 3.0979L10.7501 21.4665C10.4755 21.9156 9.79799 21.8237 9.65297 21.3177L7.92747 15.2972C7.8847 15.148 7.78477 15.0217 7.64938 14.9457L2.24007 11.9122C1.80797 11.6699 1.83466 11.0392 2.28569 10.8343L21.2177 2.23193Z" />
  </SvgIcon>
));

export default SbyIcon;
