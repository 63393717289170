// Copy of advancedProfileEu.ts at 2024.05.17
// Please refer to profileTypes.ts to read more details about each parameter
import {
  AirplanemodeActive,
  ArrowBack,
  ArrowForward,
  Delete,
  ExitToApp,
  ExpandMore,
  Feedback,
  Info,
  LocalHotel,
  LocalOffer,
  PowerSettingsNew,
  Warning,
  WorkOffRounded,
} from '@material-ui/icons';

import { AvailableLanguages, MyDataCategory, MyDataTravelDocType } from '@crew-webui/common/consts';

import {
  CheckInTitle,
  CustomerProfile,
  PersonalDataFieldPaths,
  ProfileFeature,
  SelectionMode,
  CrewMemberAttributes,
  CountryIssueDefaultValues,
  HotelFields,
} from 'consts';
import defaultProfileSettings from './defaultProfile';

const rxiProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  enablings: {
    [ProfileFeature.VIEW_BLOCKMONTH_SPECIFIC_DATA]: false,
    [ProfileFeature.VIEW_LEG]: true,
    [ProfileFeature.VIEW_DEADHEAD]: true,
    [ProfileFeature.VIEW_CHECK_IN]: true,
    [ProfileFeature.PERFORM_CHECK_IN]: true,
    [ProfileFeature.VIEW_NOTIFICATIONS]: true,
    [ProfileFeature.VIEW_PAIRING]: true,
    [ProfileFeature.VIEW_SUMMARY]: true,
    [ProfileFeature.VIEW_BLOCKMONTH_SWITCH_ON_SUMMARY]: true,
    [ProfileFeature.VIEW_HOTEL]: true,
    [ProfileFeature.PERFORM_ROLE_EDITING]: true,
    [ProfileFeature.VIEW_ROSTER_TAGS_CALENDAR_FILTER]: true,

  },
  // BASICS
  logo: '',
  showCookieBanner: true,
  autoRefreshEnabledByDefault: true,
  showUTCTime: true,
  showLocalTime: true,
  localTimePostfix: 'LT',
  utcTimePostfix: 'UTC',
  // for Pairings
  showPairingNumber: false, // US/EU specific parameter. By default set to TRUE for US customers and set to FALSE for EU customers
  showRank: true,
  showHomeBase: true, // US/EU specific parameter. By default set to FALSE for US customers and set to TRUE for EU customers
  showAircraftTypeCodeOfPairing: true, // US/EU specific parameter. By default set to FALSE for US customers and set to TRUE for EU customers
  showPairingClassification: false,
  // for Legs
  showAircraftSubType: true,
  showAircraftTypeCodeOfLeg: true,
  showRegistration: true,
  showGateInfo: false,
  // ----------------------------------------------------------------
  showRosterChanges: false,
  showBlockMonthCode: true,
  showBlockMonthInterval: false,
  calendarWeekStartMonday: false,
  showLargeSuspIcon: true,
  showGroundActivityCodeInCalendar: false,
  showCalendarAfterLogin: true,
  cacheBeforeDaysNo: 30,
  cacheAfterDaysNo: 30,
  cacheFlightInfoDaysNo: 2,
  printCacheSize: true,
  showFinNumber: false,
  showArrivalAirportOfPairing: true,
  flightDetailsEncollapsed: false,
  icons: {
    PAR: AirplanemodeActive,
    PAR_EMPTY: AirplanemodeActive,
    C_I: ArrowForward,
    LEG: AirplanemodeActive,
    HOT: LocalHotel,
    C_O: ArrowBack,
    SUSP: Warning,
    SBY: PowerSettingsNew,
    RTG: LocalOffer,
    OFF: WorkOffRounded,
  },
  buttonIcons: {
    ExpandMore,
    Info,
  },
  newsListing: {
    checkIn: {
      Icon: ExitToApp,
      color: '#60AE60',
    },
    rosterChange: {
      Icon: Warning,
      color: '#FFC901',
    },
    message: {
      Icon: Feedback,
      color: '#177FD2',
    },
    delete: {
      Icon: Delete,
    },
  },
  typeCodesCalendar: {},
  typeCodesDutyList: {
    GROUND: 'GRND',
    FPR: 'DH',
  },
  // EU specific time formats
  dateFormats: {
    DATE: 'dd MMM yyyy',
    SHORT_DATE: 'ddMMM',
    SHORT_DATE_SEPARATED: 'dd MMM',
    TIME: 'HH:mm',
    DATE_TIME: 'dd MMM yyyy HH:mm',
    SHORT_DATE_TIME: 'dd/MM HH:mm',
  },
  notificationConfirmationSelectionMode: SelectionMode.ALL,
  checkInTitle: CheckInTitle.SHOW_ONLY_PAIRING_NUMBER,
  disabledHotelFields: [
    HotelFields.REMARK,
  ],
  hiddenHeadersIfNoData: [],
  countryIssueDefaultValue: CountryIssueDefaultValues.NONE,
  preDefinedPhoneValue: '+',
  agreementTexts: {
    pushNotification: {
      en:
        'The push messaging feature is based on the Firebase Cloud Messaging product of Google. Google Firebase © is a third-party product. The owner of this product does not provide SLA for the service, so the service cannot be guaranteed. However, the service is offered free of charge. The third-party service is not used in case you do not select the mobile push feature.',
      fr:
        'La fonction de notification poussée se base sur le produit Firebase Cloud Messaging de Google. Google Firebase © est un produit de tiers. Le propriétaire de ce produit ne fournit aucune entente sur les niveaux de service, de sorte que ce service ne peut être garanti. Le service est toutefois offert gratuitement. Vous n’avez pas recours au service de tiers si vous ne sélectionnez pas la fonction de notification poussée.',
    },
  },
  attributesCrewMember: {
    en: {
      [CrewMemberAttributes.ADD_QUALIF]: 'Additional Qualif',
      [CrewMemberAttributes.ASSIGNED_RANK]: 'Assigned Rank',
      [CrewMemberAttributes.CONTRACT_CODE]: 'Contract',
      [CrewMemberAttributes.CREWCODE]: 'Crew Id',
      [CrewMemberAttributes.EMAIL]: 'E-mail',
      [CrewMemberAttributes.HOMEBASE]: 'HB',
      [CrewMemberAttributes.PAIRING_CLASSIFICATION]: 'Pairing classification',
      // TBD: this should be disabled for EU customers!!!
      [CrewMemberAttributes.PAIRING_NUMBER]: 'Pairing number',
      [CrewMemberAttributes.PHONE]: 'Phone',
      [CrewMemberAttributes.RANK]: 'Rank',
      [CrewMemberAttributes.SENIORITY]: 'Seniority',
      [CrewMemberAttributes.SEX]: 'Gender',
    },
    fr: {
      [CrewMemberAttributes.ADD_QUALIF]: 'Qualification supplémentaire',
      [CrewMemberAttributes.ASSIGNED_RANK]: 'Rang attribué',
      [CrewMemberAttributes.CONTRACT_CODE]: 'Contrat',
      [CrewMemberAttributes.CREWCODE]: 'No de matricule',
      [CrewMemberAttributes.EMAIL]: 'E-mail',
      [CrewMemberAttributes.HOMEBASE]: 'HB',
      [CrewMemberAttributes.PHONE]: 'Téléphone',
      [CrewMemberAttributes.RANK]: 'Rang',
      [CrewMemberAttributes.SENIORITY]: 'Ancienneté',
      [CrewMemberAttributes.SEX]: 'Genre',
    },
  },
  phoneTypeCodes: ['Default', 'Cell', 'Home', 'Landline'],
  disabledPersonalDataFields: [
    PersonalDataFieldPaths.birthCountry,
    PersonalDataFieldPaths.birthDate,
    PersonalDataFieldPaths.birthPlace,
    PersonalDataFieldPaths.birthState,

  ],
  enabledTravelDocTypes: [
    MyDataTravelDocType.PASSPORT,
    MyDataTravelDocType.IDENTITY,
    MyDataTravelDocType.DRIVER_LICENCE,
  ],
  readonlyMyDataCategories: [
    MyDataCategory.PERSONAL,
    MyDataCategory.CONTACT,
    MyDataCategory.ADDRESS,
    MyDataCategory.ADDRESS,
    MyDataCategory.TRAVEL,
    MyDataCategory.VISA,
  ],
  availableLanguages: [AvailableLanguages.EN, AvailableLanguages.FR],
};

export default rxiProfileSettings;
