import { forwardRef } from 'react';
import { Menu } from '@lsy-netline-ui/netline-ui';

interface Props {
  anchorEl?: Element | ((element: Element) => Element) | null;
  children?: React.ReactNode;
  onClose?: () => void;
}

const DropdownMenu = forwardRef<any, Props>(
  // eslint-disable-next-line prefer-arrow-callback
  function DropdownMenu(props: Props, ref) {
    return (
      <Menu
        ref={ref}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        getContentAnchorEl={null}
        keepMounted
        open={!!props.anchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        {...props}
      />
    );
  },
);

export default DropdownMenu;
