import { makeStyles, Fab } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import { useProfile } from '../../../hooks';
import styles from './DutyRowToggle.styles';

const useStyles = makeStyles(styles, { name: 'DutyRowToggle' });

type DutyRowToggleProps = {
  className: string;
  expanded: boolean;
  toggle: () => void;
};

const DutyRowToggle = ({ className, expanded, toggle }: DutyRowToggleProps) => {
  const classes = useStyles();
  const { profile: { buttonIcons } } = useProfile();

  const onDutyIconClick = (clickEvent: React.MouseEvent) => {
    clickEvent.stopPropagation();
    toggle();
  };

  const { ExpandMore } = buttonIcons;

  return (
    <Fab
      data-test-id="toggle-sublist-button"
      onClick={onDutyIconClick}
      className={clsx(classes.root, className, { open: expanded })}
    >
      <ExpandMore />
    </Fab>
  );
};

export default DutyRowToggle;
