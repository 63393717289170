import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import { get } from 'lodash';
import clsx from 'clsx';

import { useConnection } from '@crew-webui/common/hooks';

import { MyDataFieldType } from 'consts';

import MyDataFormElement from './MyDataFormElement';
import styles from './MyDataField.styles';

const useStyles = makeStyles(styles, { name: 'MyDataField' });

type MyDataFieldProps = {
  control: any;
  data?: any;
  editing: any;
  errors: any;
  field: MyDataFieldType;
  namePrefix: string;
};

const MyDataField = ({
  control,
  data,
  editing,
  errors,
  field,
  namePrefix,
}: MyDataFieldProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { online } = useConnection();

  const disabledField = field.disabled || (field.editOnlyWhenCreating && editing);
  const error = get(errors, `${namePrefix}${field.valuePath}`);

  const getHelperText = () => {
    if (error?.message) {
      return error?.message;
    }
    if (disabledField) {
      return t('myData.validation.disabled', { label: field.label });
    }
    return field.helperText;
  };

  const commonProps = {
    'className': clsx(classes.root, { [`size-${field.fieldSize}`]: field.fieldSize }),
    'data-test-id': `"field-${field.valuePath}`,
    'disabled': !online || disabledField,
    'id': field.valuePath,
    'multiline': field.multiline,
    'rows': field.multiline ? 3 : undefined,
    'type': field.type,
  };

  let defaultValue = get(data, field.valuePath) || '';
  if (defaultValue === '' && field.date) {
    defaultValue = null;
  }

  return (
    <Controller
      key={field.valuePath}
      control={control}
      defaultValue={defaultValue}
      name={`${namePrefix}${field.valuePath}`}
      rules={field.validation}
      render={({ field: fieldProp }) => (
        <MyDataFormElement
          disabledField={disabledField}
          error={!!error}
          field={field}
          helperText={getHelperText()}
          {...fieldProp}
          {...commonProps}
        />
      )}
    />
  );
};

export default MyDataField;
