import { createStyles } from '@lsy-netline-ui/netline-ui';

export default () => createStyles({
  root: {
    marginBottom: 20,
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 24,
    margin: '0 16px 16px',
  },
  addNewBtn: {
    marginTop: -2,
    marginRight: -2,
    textAlign: 'right',
  },
  description: {
    margin: '0 16px 36px',
  },
});
