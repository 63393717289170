import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, IconButton, Box } from '@lsy-netline-ui/netline-ui';
import { ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';

import { useDialog } from '../../hooks/Dialog';
import styles from './ServerSelection.styles';

const useStyles = makeStyles(styles, { name: 'ServerSelection' });

type ServerSelectionProps = {
  onSelectServer: (server: ServerConfig) => void;
  userServers: ServerConfig[];
};

const ServerSelection = ({ onSelectServer, userServers } : ServerSelectionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showDialog, hideDialog } = useDialog();

  const handleServerClick = useCallback((server: ServerConfig) => () => {
    showDialog({
      title: t('serverChoice.popup.title', { name: server.name }),
      content: t('serverChoice.popup.description', { name: server.name }),
      actionsClassName: classes.buttonContainer,
      type: 'warning',
      buttons: [
        {
          text: t('serverChoice.popup.selectOther'),
          variant: 'contained',
          className: classes.popupButton,
          onClick: () => {
            hideDialog();
          },
        },
        {
          text: t('serverChoice.popup.logInAsEmployee', { name: server.name }),
          className: classes.popupButton,
          onClick: () => {
            onSelectServer(server);
            hideDialog();
          },
        },
      ],
    });
  }, [classes, hideDialog, onSelectServer, showDialog, t]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>{t('serverChoice.title')}</div>
      <div className={classes.subHeader}>{t('serverChoice.description')}</div>
      <div className={classes.choiceHeader}>{t('serverChoice.userTitle')}</div>
      <div className={classes.userChoices}>
        {userServers.map((server) => (
          <Box key={server.name} className={clsx(classes.choiceItem, classes.testChoiceItem)} onClick={handleServerClick(server)}>
            <span className={classes.nameWithIcon}>
              <span className={classes.emptyIcon} />
              <span>{server.name}</span>
            </span>
            <IconButton size="small">
              <ChevronRight />
            </IconButton>
          </Box>
        ))}
      </div>
    </div>
  );
};

export default ServerSelection;
