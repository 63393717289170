import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette, shadows, spacing }: Theme) => createStyles({
  content: {
    '&:not($multiColumn)': {
      'paddingRight': 0,

      '& $operationButton': {
        marginRight: spacing(2),
      },
    },
  },
  multiColumn: {},
  header: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'flexWrap': 'wrap',
    'marginBottom': spacing(2),

    '& > *': {
      height: spacing(3),
    },
  },
  operationButton: {},
  details: {
    'maxHeight': '35vh',
    'marginBottom': spacing(2),
    'overflow': 'auto',
    'border': `1px solid ${palette.divider}`,
    'backgroundColor': '#fff',

    '& > *': {
      width: 'fit-content',
      paddingRight: spacing(1),
      paddingLeft: spacing(1),
    },
  },
  pairingList: {
    overflow: 'auto',
    boxShadow: shadows[1],
    borderRadius: '4px 0 0 4px',
  },
});
