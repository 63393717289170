import clsx from 'clsx';
import { makeStyles, Typography } from '@lsy-netline-ui/netline-ui';

import styles from './NoContentMessage.styles';

const useStyles = makeStyles(styles, { name: 'NoContentMessage' });

interface Props {
  children: React.ReactNode;
  className?: string;
}

const NoContentMessage = ({
  children, className, ...otherProps
}: Props) => {
  const classes = useStyles();

  return (
    <Typography className={clsx(classes.root, className)} data-test-id="no-content-message" {...otherProps}>
      {children}
    </Typography>
  );
};

export default NoContentMessage;
