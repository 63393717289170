import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isString, isPlainObject } from 'lodash';
import { DateTime } from 'luxon';

import logger from './logger';

const initI18next = (languageFiles: Record<string, Record<string, any>>) => {
  const resources = Object.entries(languageFiles)
    .reduce(
      (acc, [key, value]) => ({ ...acc, [key]: { translation: value } }),
      {},
    );

  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      detection: {
        order: ['localStorage'],
      },
      react: {
        useSuspense: false,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i', 'p'],
      },
      debug: true,
      backend: {
        loadPath: '{{lng}}.json',
      },
      fallbackLng: 'en',
      keySeparator: '.',
      saveMissing: true,
      resources,
      interpolation: {
        escapeValue: false, // react already safes from xss
        format: (value, format, lng) => {
          if (!value || !format || !lng) {
            return '';
          }
          if (format.startsWith('()')) {
            const result = i18n.format(value, format.substr(2), lng);
            return `(${result})`;
          }
          if (value instanceof DateTime) {
            return value.setLocale(lng).toFormat(format);
          }
          return value;
        },
      },
      missingKeyHandler: (lng, ns, key) => {
        logger.warn(`Translation key is missing! [key='${key}', lng='${lng}', ns='${ns}'}]`);
      },
    });
  // to make translation key more flexible we override the original t(...) method to a new one
  // supporting a key-as-bject with already translated messages (translated) and
  // accepting the key and params in a single object.
  const originalTranslator = i18n.t;
  i18n.t = (keyOrObj: any, options: any) => {
    if (isPlainObject(keyOrObj)) {
      const { key, translated, ...opts } = keyOrObj;
      // in case 'translated' prop is set we can simply return it (eg. server-side text error)
      return isString(translated) ? translated : originalTranslator.call(i18n, key, { ...opts, ...options });
    }

    return originalTranslator.call(i18n, keyOrObj, options);
  };
};

export default initI18next;
