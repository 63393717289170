import defaultTheme from './defaultTheme';
import { CustomerTheme } from './themeTypes';

const text = {
  ...defaultTheme.palette.text,
  primary: '#003145',
};

const klcTheme: CustomerTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    text,
    primary: {
      ...defaultTheme.palette.primary,
      main: '#07A2DE',
    },
    calendar: {
      ...defaultTheme.palette.calendar,
      day: {
        ...defaultTheme.palette.calendar.day,
        selectedDayBackgroundColor: '#B3E8FC',
        selectedIntervalBackgroundColor: '#B3E8FC',
      },
    },
    dutyList: {
      ...defaultTheme.palette.dutyList,
      selectedRowBackgroundColor: '#B3E8FC',
      defaultIconColor: '#E77B2F',
    },
    events: {
      ...defaultTheme.palette.events,
      par: {
        ...defaultTheme.palette.events.par,
        backgroundColor: '#07A2DE',
      },
      leg: {
        ...defaultTheme.palette.events.leg,
        backgroundColor: '#07A2DE',
      },
      sby: {
        ...defaultTheme.palette.events.sby,
        backgroundColor: '#07A2DE',
      },
      ground: {
        ...defaultTheme.palette.events.ground,
        backgroundColor: '#07A2DE',
      },
      hot: {
        ...defaultTheme.palette.events.hot,
        backgroundColor: '#E77B2F',
      },
      vac: {
        ...defaultTheme.palette.events.vac,
        backgroundColor: '#E77B2F',
      },
    },
    iadp: {
      ...defaultTheme.palette.iadp,
      button: {
        ...defaultTheme.palette.iadp.button,
        backgroundColor: '#07A2DE',
      },
      rosterChangeDetails: {
        ...defaultTheme.palette.iadp.rosterChangeDetails,
        oldDutyTextColor: '#07A2DE',
        newDutyTextColor: '#07A2DE',
      },
    },
  },
  typography: {
    h4: {
      ...defaultTheme.typography.h4,
      color: text.primary,
    },
    h5: {
      ...defaultTheme.typography.h5,
      color: text.primary,
    },
    body1: {
      ...defaultTheme.typography.body1,
      color: text.primary,
    },
  },
};

export default klcTheme;
