import { createStyles } from '@lsy-netline-ui/netline-ui';

export default ({ palette }: Theme) => createStyles({
  root: {
    'boxShadow': '0 1px 3px 0 rgba(0,0,0,0.12), 0 1px 2px 0 rgba(0,0,0,0.24)',
    'marginTop': 1,
    'padding': '12px 16px',
    'backgroundColor': palette.background.secondary,

    '&[role="button"]': {
      cursor: 'pointer',
    },
  },
});
