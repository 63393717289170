import { CustomerTheme } from './themeTypes';
import createCustomTheme from './createCustomTheme';

const ewlDefault = createCustomTheme('eurowings');

const ewlTheme: CustomerTheme = {
  ...ewlDefault,
  palette: {
    ...ewlDefault.palette,
    calendar: {
      ...ewlDefault.palette.calendar,
      suspendedDay: {
        ...ewlDefault.palette.calendar.suspendedDay,
        textAndIconColor: '#FFE579',
      },
    },
    events: {
      ...ewlDefault.palette.events,
      par: {
        backgroundColor: '#9b1d5d',
        textColor: '#FFFFFF',
      },
      leg: {
        backgroundColor: '#9b1d5d',
        textColor: '#FFFFFF',
      },
      rtg: {
        backgroundColor: '#6BCCE0',
      },
      sby: {
        backgroundColor: '#00A6CF',
        textColor: '#FFFFFF',
      },
      hot: {
        backgroundColor: '#630090',
        textColor: '#ffffff',
      },
      abs: {
        backgroundColor: '#D3D3D3',
        textColor: '#000000',
      },
      fpr: {
        backgroundColor: '#E1F5F9',
        textColor: '#000000',
      },
      gpr: {
        backgroundColor: '#C3D3D6',
        textColor: '#000000',
      },
      susp: {
        backgroundColor: '#FFE579',
        borderColor: '#000000',
        textColor: '#000000',
      },
      vac: {
        backgroundColor: '#BDBABA',
        textColor: '#FFFFFF',
      },
      sick: {
        backgroundColor: '#787878',
        textColor: '#FFFFFF',
      },
      ground: {
        backgroundColor: '#6BCCE0',
        textColor: '#FFFFFF',
      },
      sim: {
        backgroundColor: '#8AB6BF',
        textColor: '#FFFFFF',
      },
      off: {
        backgroundColor: '#464646',
        textColor: '#FFFFFF',
      },
      other: {
        backgroundColor: '#06434F',
        textColor: '#FFFFFF',
      },
      /*
         * If an event type has no special icon, we use this appearance in duty list view.
         */
      textIcon: {
        backgroundColor: '#06434F',
        textColor: '#FFFFFF',
      },
    },
  },
};

export default ewlTheme;
