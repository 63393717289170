import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import { MyDataCategory, MyDataType } from '@crew-webui/common/consts';
import { useMyData, useOnlineAuthorized, useProfile } from '@crew-webui/common/hooks';

import { CategoryType, routePaths } from 'consts';
import { cardCommonProps, getAccess, getUniqueAddressId } from 'utils';
import MyDataPage from '../MyDataPage/MyDataPage';
import styles from './Addresses.styles';

const useStyles = makeStyles(styles, { name: 'Addresses' });

const Addresses = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { addresses, loadMyData } = useMyData();
  const { profile: { readonlyMyDataCategories } } = useProfile();

  useOnlineAuthorized(() => {
    loadMyData(MyDataType.ADDRESS);
  }, [loadMyData]);

  const addressItems = addresses
    ?.map((address) => {
      const {
        access, cityName, countryCode, natKey, remark, stateCode, street, zipCode,
      } = address;
      const country = [stateCode, zipCode, countryCode].filter((text) => text).join(', ');
      const remarkText = country && remark ? ` - ${remark}` : remark;
      const id = getUniqueAddressId(address);
      const handleClick = () => {
        history.push(`${routePaths.myData}/addresses/details/com.lhsystems.crew.pems:pems-client-web-app:jar:2023.1.0`);
      };

      return {
        id,
        onClick: handleClick,
        prio: natKey.prio,
        doc: {
          ...cardCommonProps,
          title: getAccess(access, t),
          content: [street, cityName].filter((text) => text).join(', '),
          footer: <>{country} <span className={classes.remark}>{remarkText}</span></>,
          rightText: <span data-test-id="my-data-card-prio">{natKey.prio}</span>,
        },
      };
    })
    .sort((a, b) => a.prio - b.prio);

  const dataLoaded = !!addresses;

  const categories: CategoryType[] = [
    {
      label: '',
      items: addressItems,
      newButton: {
        'data-test-id': 'add-address-button',
        'label': t('myData.addressInfo.address'),
        'onClick': () => history.push(`${routePaths.myData}/addresses/details`),
      },
    },
  ];

  return (
    <MyDataPage
      categories={categories}
      dataLoaded={dataLoaded}
      description={t('myData.addressInfo.description')}
      subtitle={t('myData.addressInfo.title')}
      title={t('myData.addressInfo.title')}
      readonly={readonlyMyDataCategories.includes(MyDataCategory.ADDRESS)}
      onBack={() => history.push(`${routePaths.myData}`)}
    />
  );
};

export default Addresses;
