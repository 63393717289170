import { CrewComDomain } from '@crew-webui/common/consts';

export const isModule = `${process.env.REACT_APP_ISMODULE}` === 'true';
export const prefix = isModule ? `/${CrewComDomain.IADP.toLowerCase()}` : '';

const routePaths = {
  root: isModule ? prefix : `${prefix}/`,
  login: `${prefix}/login`,
  profile: `${prefix}/profile`,
  notificationSettings: `${prefix}/notification-settings`,
  accessRights: `${prefix}/access-rights`,
  summary: `${prefix}/summary`,
  dailyRemarks: `${prefix}/daily-remarks`,
  newRemarks: `${prefix}/daily-remarks/new`,
  news: `${prefix}/news`,
  checkIn: `${prefix}/check-in`,
  hotel: `${prefix}/hotel`,
  leg: `${prefix}/leg`,
  pairing: `${prefix}/pairing`,
  event: `${prefix}/event`,
  notifications: `${prefix}/notifications`,
  myData: `${prefix}/my-data`,
  flightSearch: `${prefix}/flight-search`,
  flightSearchResult: `${prefix}/flight-search-result`,
  buddyRosterSearch: `${prefix}/buddy-roster-search`,
  buddyExcludeList: `${prefix}/buddy-exclude`,
};

export default routePaths;
