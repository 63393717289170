import { useHistory } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { makeStyles, IconButton, Typography } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import styles from './PageTitle.styles';

const useStyles = makeStyles(styles, { name: 'PageTitle' });

interface Props {
  children: React.ReactNode;
  className?: string;
  negativeMargin?: boolean;
  showBackButton?: boolean;
  onBack?: Function;
}

const PageTitle = ({
  children, className, negativeMargin, showBackButton = false, onBack, ...otherProps
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleBackClick = () => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };

  return (
    <Typography
      className={clsx(classes.root, className, { [classes.negativeMargin]: negativeMargin })}
      data-test-id="page-title"
      variant="subtitle1"
      {...otherProps}
    >
      {showBackButton && (
        <IconButton className={classes.backButton} data-test-id="back-link" edge="start" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
      )}

      <span>{children}</span>
    </Typography>
  );
};

export default PageTitle;
