import {
  useCallback, useContext, useMemo, useState,
} from 'react';

import { useHistory } from 'react-router-dom';

import AppCommonContext from './AppCommonContext';

const AppCommonProvider = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();
  const [footerVisible, setFooterVisible] = useState(true);
  const [dirty, setDirty] = useState(false);

  const showFooter = useCallback(() => setFooterVisible(true), []);
  const hideFooter = useCallback(() => setFooterVisible(false), []);
  const toggleFooter = useCallback(() => setFooterVisible((prevState) => !prevState), []);

  const clearDirtyAndRedirect = useCallback((route: string) => {
    setDirty(false);
    setTimeout(() => {
      history.push(route);
    }, 0);
  }, [history]);

  const ctx = useMemo(() => ({
    dirty,
    footerVisible,
    hideFooter,
    setDirty,
    showFooter,
    toggleFooter,
    clearDirtyAndRedirect,
  }), [dirty, footerVisible, hideFooter, setDirty, showFooter, toggleFooter, clearDirtyAndRedirect]);

  return <AppCommonContext.Provider value={ctx}>{children}</AppCommonContext.Provider>;
};

export const useAppCommon = () => useContext(AppCommonContext);

export default AppCommonProvider;
