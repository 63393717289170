import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import {
  Check as CheckIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@material-ui/icons';

import { RoleType } from './roleTypes';
import styles from './Role.styles';

const useStyles = makeStyles(styles, { name: 'Role' });

const Role = ({
  role: {
    id, shortName, setOnEvent, updateByCrewMember,
  }, onAdd, onRemove, disabled,
}: {
  role: RoleType; onAdd: (id: number) => void; onRemove: (id: number) => void; disabled: boolean;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onAddClick = useCallback(() => {
    if (!disabled) {
      onAdd(id);
    }
  }, [disabled, onAdd, id]);
  const onRemoveClick = useCallback(() => {
    if (!disabled) {
      onRemove(id);
    }
  }, [disabled, onRemove, id]);

  return (
    <div className={classes.role} data-test-id="role">
      <div>
        <span className={classes.label}>{shortName}</span>
        {!updateByCrewMember && <span className={classes.notEditable}> ({ t('duties.legDetails.canNoChange') })</span>}
      </div>
      {(setOnEvent && updateByCrewMember && (
        <CheckBoxIcon data-test-id="role-checkbox" onClick={onRemoveClick} />
      ))}
      {(!setOnEvent && updateByCrewMember && (
        <CheckBoxOutlineBlankIcon data-test-id="role-checkbox" onClick={onAddClick} />
      ))}
      {(setOnEvent && !updateByCrewMember && <CheckIcon data-test-id="role-check-icon" />)}
    </div>
  );
};

export default Role;
