import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, makeStyles } from '@lsy-netline-ui/netline-ui';
import { useTranslation } from 'react-i18next';

import { routePaths, titles } from 'consts';
import { IadpPage } from 'view/components';
import styles from './GeneralEventDetails.styles';

const useStyles = makeStyles(styles, { name: 'GeneralEventDetails' });

const GeneralEventDetails = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { state: { event } = {} } = useLocation<{ event: any }>();
  const history = useHistory();

  useEffect(() => {
    if (!event) {
      history.push(routePaths.root);
    }
  }, [event, history]);

  const subtitle = t('duties.main.eventRemark');

  return (
    <IadpPage
      dataLoaded
      headerProps={{ title: t(titles.dutyList), subtitle, showBackButton: true }}
    >
      <Typography className={classes.remark}>{event?.remark}</Typography>
    </IadpPage>
  );
};

export default GeneralEventDetails;
