import { DateTime } from 'luxon';

export type BlockMonthDataProperty = {
  label: string;
  type: 'Date' | 'Time' | 'DateTime' | 'Number' | 'String' | 'Minute' | 'Hour' | 'Percentage';
  value: string;
};

export const convertBlockMonthProperty = ({ label, type, value }: BlockMonthDataProperty, profile: any) => {
  const getValue = () => {
    if (type === 'Percentage') {
      return `${value}%`;
    }
    if (type === 'Hour') {
      return `${value}:00`;
    }
    if (type === 'Minute') {
      const hours = Math.floor(Number(value) / 60);
      const minutes = (Number(value) % 60).toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    }
    if (type === 'Date') {
      return DateTime.fromISO(value).toFormat(profile.dateFormats.DATE);
    }
    if (type === 'DateTime') {
      return DateTime.fromISO(value).toFormat(profile.dateFormats.DATE_TIME);
    }
    return value;
  };
  return { label, value: getValue() };
};
