import { useCallback } from 'react';
import { useSnackbar as useNuiSnackbar } from '@lsy-netline-ui/netline-ui';

const SNACKBAR_AUTOHIDE_DURATION = 5000;

const useSnackbar = () => {
  const { enqueueSnackbar: originalEnqueueSnackbar } = useNuiSnackbar();

  const enqueueSnackbar = useCallback((text: React.ReactNode, options?: any) => {
    // hide snacks of "success" type automatically after timeout
    if (options?.variant === 'success') {
      originalEnqueueSnackbar(text, { autoHideDuration: SNACKBAR_AUTOHIDE_DURATION, ...options });
    } else {
      originalEnqueueSnackbar(text, options);
    }
  }, [originalEnqueueSnackbar]);

  return { enqueueSnackbar };
};

export default useSnackbar;
