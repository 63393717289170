import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  List, ListItem, ListItemText, makeStyles,
} from '@lsy-netline-ui/netline-ui';
import { DateTime } from 'luxon';

import { services, useIadpParams } from '@crew-webui/common/api';
import { useOnlineAuthorized, useProfile } from '@crew-webui/common/hooks';
import { displayTime } from '@crew-webui/common/utils';

import { HotelFields } from 'consts';
import { IadpPage } from 'view/components';

import { getAddress, getContact, transformArray } from './hotelUtils';
import { HotelData } from './hotelTypes';
import styles from './Hotel.styles';

const useStyles = makeStyles(styles, { name: 'Hotel' });

const Hotel = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    profile: {
      dateFormats,
      disabledHotelFields,
      localTimePostfix,
      showLocalTime,
      showUTCTime,
      utcTimePostfix,
    },
  } = useProfile();
  const { eventId, crmId } = useIadpParams<{ eventId: string; crmId: string }>();
  const [hotelData, setHotelData] = useState<HotelData>();

  useOnlineAuthorized(() => {
    services
      .getHotel({ crmId, hotelId: eventId })
      .then(({ data }) => {
        const { result, success } = data;

        if (success && result) {
          setHotelData(result);
        }
      });
  }, [crmId, eventId]);

  const items = hotelData ? [
    {
      id: HotelFields.DATE,
      label: t('common.date'),
      getValue: () => {
        // these date fields do not have timezone info
        const formatDate = (timestamp: string) => (timestamp ? DateTime.fromISO(timestamp).toFormat(dateFormats.DATE) : null);
        const {
          fromDateTimeLtAtAirport, fromDateTimeUTC, toDateTimeLtAtAirport, toDateTimeUTC,
        } = hotelData;

        if (!fromDateTimeLtAtAirport) {
          return null;
        }

        const fromDate = formatDate(showLocalTime ? fromDateTimeLtAtAirport : fromDateTimeUTC);
        const toDate = formatDate(showLocalTime ? toDateTimeLtAtAirport : toDateTimeUTC);
        return fromDate === toDate ? fromDate : `${fromDate} - ${toDate}`;
      },
    },
    {
      id: HotelFields.NAME,
      label: t('duties.hotel.name'),
      getValue: () => hotelData.hotel?.name,
    },
    {
      id: HotelFields.TYPE,
      label: t('duties.hotel.type'),
      getValue: () => hotelData.hotel?.hotelAtAirport?.type,
    },
    {
      id: HotelFields.ADDRESS,
      label: t('duties.hotel.address'),
      getValue: () => getAddress(hotelData.hotel?.hotelLocation, t),
    },
    {
      id: HotelFields.CONTACTS,
      label: t('duties.hotel.contacts'),
      getValue: () => getContact(hotelData.hotel?.hotelContact, t),
    },
    {
      id: HotelFields.TRANSFER_TIME,
      label: t('duties.hotel.transferTime'),
      getValue: () => {
        const { transferTime } = hotelData.hotel?.hotelAtAirport || {};
        return transferTime && `${transferTime} min`;
      },
    },
    {
      id: HotelFields.PICKUP_TIME,
      label: t('duties.hotel.pickupTime'),
      getValue: () => {
        const formatDate = (timestamp: string | undefined) => (timestamp ? DateTime.fromISO(timestamp).toUTC().toFormat(dateFormats.SHORT_DATE_TIME) : null);

        const { pickupTime, pickupTimeLocal } = hotelData.hotel?.hotelAtAirport || {};

        const pickupDateTimeLocal = showLocalTime ? formatDate(pickupTimeLocal) : '';
        const pickupDateTimeUTC = showUTCTime ? formatDate(pickupTime) : '';

        if (pickupDateTimeLocal || pickupDateTimeUTC) {
          if (pickupDateTimeLocal && pickupDateTimeUTC && pickupTime) {
            return `${pickupDateTimeLocal} ${localTimePostfix} (${displayTime(pickupTime, dateFormats.TIME, utcTimePostfix)})`;
          }
          if (pickupDateTimeLocal) {
            return `${pickupDateTimeLocal} ${localTimePostfix}`;
          }
          return `${pickupDateTimeUTC} ${utcTimePostfix}`;
        }
        return null;
      },
    },
    {
      id: HotelFields.TRANSPORT_COMPANY_NAME,
      label: t('duties.hotel.transportCompanyName'),
      getValue: () => hotelData.transportCompany?.name,
    },
    {
      id: HotelFields.TRANSPORT_COMPANY_TYPE,
      label: t('duties.hotel.transportCompanyType'),
      getValue: () => hotelData.transportCompany?.type,
    },
    {
      id: HotelFields.TRANSPORT_COMPANY_ADDRESS,
      label: t('duties.hotel.transportCompanyAddress'),
      getValue: () => {
        const { transportCompanyLocations = [] } = hotelData.transportCompany || {};
        return transportCompanyLocations.map((address) => getAddress(address, t));
      },
    },
    {
      id: HotelFields.TRANSPORT_COMPANY_CONTACTS,
      label: t('duties.hotel.transportCompanyContacts'),
      getValue: () => {
        const { transportCompanyContacts = [] } = hotelData.transportCompany || {};
        return transportCompanyContacts.map((contact) => getContact(contact, t));
      },
    },
    {
      id: HotelFields.REMARK,
      label: t('duties.hotel.remark'),
      getValue: () => hotelData.hotel?.remark,
    },
  ].filter(({ id }) => !disabledHotelFields?.includes(id)) : [];

  return (
    <IadpPage
      dataLoaded={!!hotelData}
      headerProps={{ title: t('duties.main.title'), subtitle: t('duties.hotel.title'), showBackButton: true }}
    >
      {hotelData && (
        <List className={classes.list}>
          {items.map(({ getValue, label }) => {
            const value = transformArray(getValue());

            if (!value) {
              return null;
            }

            return (
              <ListItem key={label} className={classes.listItem} disableGutters>
                <ListItemText
                  className={classes.listItemText}
                  disableTypography
                  primary={<div className={classes.label} data-test-id="hotel-key">{label}</div>}
                  secondary={<div className={classes.value} data-test-id="hotel-value">{value}</div>}
                />
              </ListItem>
            );
          })}
        </List>
      )}
    </IadpPage>
  );
};

export default Hotel;
