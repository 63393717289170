import { useTranslation } from 'react-i18next';
import {
  makeStyles, Box, Button, Typography,
} from '@lsy-netline-ui/netline-ui';

import { LocalStorageKeys } from '../../../consts';
import {
  useConnection, useCrewAuth, useLanguageSwitch, usePerformCaching, useProfile, useUserPreferences,
} from '../../../hooks';
import { useSnackbar } from '../../components';
import ProfileList, { ProfileItem } from '../ProfileList/ProfileList';
import styles from './ProfileContent.styles';

const useStyles = makeStyles(styles, { name: 'ProfileContent' });

type Props = {
  autoRefreshEnabled: boolean;
  cacheEnabled?: boolean;
  links: ProfileItem[];
  languages: { key: string; label: string }[];
  performCheckinEnabled?: boolean;

  onAutoRefreshChange: (enabled: boolean) => void;
};

const ProfileContent = ({
  autoRefreshEnabled, onAutoRefreshChange, links, languages, cacheEnabled, performCheckinEnabled,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { crmId, logout, user: { userName } } = useCrewAuth();
  const { online, crewComOnline } = useConnection();
  const { profile: { availableLanguages } } = useProfile();
  const {
    currentLanguage,
    enabled: languageSwitchEnabled,
    switchLanguage,
  } = useLanguageSwitch();
  const {
    [LocalStorageKeys.CACHE_ON_CHECK_IN]: cacheOnCheckin = false,
    [LocalStorageKeys.CACHE_ON_LOG_IN]: cacheOnLogin = false,
    setPref,
  } = useUserPreferences();
  const startCache = usePerformCaching();
  const { enqueueSnackbar } = useSnackbar();

  const handleAutoRefreshChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onAutoRefreshChange(event.target.checked);
  };

  const handleCacheOnCheckinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPref(LocalStorageKeys.CACHE_ON_CHECK_IN, event.target.checked);
    enqueueSnackbar(t('components.snackbar.settingsUpdated'), { variant: 'success' });
  };

  const handleCacheOnLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPref(LocalStorageKeys.CACHE_ON_LOG_IN, event.target.checked);
    enqueueSnackbar(t('components.snackbar.settingsUpdated'), { variant: 'success' });
  };

  const listItems: ProfileItem[] = [
    ...links.map((link) => ({ ...link })),
    {
      'type': 'select',
      'data-test-id': 'change-language',
      'label': t('settings.language'),
      'onChange': switchLanguage,
      // Filter languges based on customer profile
      'options': languages.filter(({ key }) => availableLanguages.includes(key)).map(({ key, label }) => ({ value: key, text: label })),
      'value': currentLanguage,
      'visible': languageSwitchEnabled,
    },
    {
      'type': 'switch',
      'checked': autoRefreshEnabled,
      'data-test-id': 'auto-refresh-switch',
      'label': t('settings.refreshBehaviourTitle'),
      'onChange': handleAutoRefreshChange,
      'visible': true,
    },
    {
      'type': 'switch',
      'checked': cacheOnCheckin,
      'data-test-id': 'cache-on-checkin-switch',
      'label': t('settings.cacheOnCheckin'),
      'onChange': handleCacheOnCheckinChange,
      'visible': performCheckinEnabled && cacheEnabled,
    },
    {
      'type': 'switch',
      'checked': cacheOnLogin,
      'data-test-id': 'cache-on-login-switch',
      'label': t('settings.cacheOnLogin'),
      'onChange': handleCacheOnLoginChange,
      'visible': cacheEnabled,
    },
  ];

  return (
    <div>
      <Typography className={classes.text} data-test-id="profile-username" variant="subtitle2">{userName}</Typography>
      <Typography className={classes.text} data-test-id="profile-crmid" variant="body1">{crmId}</Typography>

      <ProfileList items={listItems} />

      {cacheEnabled && (
        <div className={classes.cache}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box mr={1}>
              <Typography className={classes.text} data-test-id="cache-process-title" variant="subtitle2">{t('cache.profileTitle')}</Typography>
            </Box>
            <Button className={classes.cacheButton} disabled={!online || !crewComOnline} onClick={() => startCache()}>
              {t('cache.profileButton')}
            </Button>
          </Box>
          <Typography className={classes.text} data-test-id="cache-process-description" variant="body1">{t('cache.profileDescription')}</Typography>
        </div>
      )}

      <div>
        <Button className={classes.logoutButton} data-test-id="logout-button" disabled={!online} onClick={() => logout()}>
          {t('button.logout')}
        </Button>
      </div>
      <Typography
        className={classes.version}
        component="div"
        data-test-id="version"
        variant="caption"
      >
        {t('settings.version')}: {process.env.REACT_APP_VERSION}
      </Typography>
    </div>
  );
};

export default ProfileContent;
