import {
  CheckInTitle, CustomerProfile, ProfileFeature, SelectionMode,
} from 'consts';

import defaultProfileSettings from './defaultProfile';

const klcProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  enablings: {
    ...defaultProfileSettings.enablings,
    [ProfileFeature.VIEW_BLOCKMONTH_SWITCH_ON_SUMMARY]: false,
    [ProfileFeature.VIEW_CHECK_IN]: false,
    [ProfileFeature.PERFORM_CHECK_IN]: false,
    [ProfileFeature.PERFORM_ROLE_EDITING]: false,
  },
  showPairingNumber: false,
  calendarWeekStartMonday: true,
  showRosterChanges: false,
  showCalendarAfterLogin: false,
  icons: {
    ...defaultProfileSettings.icons,
  },
  checkInTitle: CheckInTitle.NO_PAIRING_NUMBER,
  notificationConfirmationSelectionMode: SelectionMode.ALL,
  logo: 'klm.png',
  agreementTexts: {
    ...defaultProfileSettings.agreementTexts,
    sms: {
      en: 'I agree with the following terms: KLM Cityhopper text message (SMS) service sends text messages to your mobile device for KLM Cityhopper related services such as flight information at no cost. Message and data rates may apply.',
    },
  },
};

export default klcProfileSettings;
