import localForage from 'localforage';

import logger from '../logger';

let asynchCacheWorking = false;
let initialized = false;

const initAsync = async () => {
  localForage.config({});
  await localForage.ready();
  initialized = true;
  logger.info('Asynch cache initialized');
};

const TEST_KEY = 'TEST_LOCAL_FORAGE';

const testSetItem = () => new Promise((resolve) => {
  const timer = setTimeout(() => {
    logger.error('Cache will not working until restart application');
    resolve(false);
  }, 500);
  localForage.setItem(TEST_KEY, true).then(() => {
    clearTimeout(timer);
    resolve(true);
  });
});

export const isAsynchCacheWorking = () => asynchCacheWorking;

export const initAndTestCache = async () => {
  await initAsync();
  asynchCacheWorking = await testSetItem() as boolean;
  return asynchCacheWorking;
};

export const getItem = async (key: string) => {
  if (!asynchCacheWorking) {
    logger.error(`Async cache not working get item failed to ${key}`);
    return undefined;
  }
  try {
    const item = await localForage.getItem(key);
    return item;
  } catch (error) {
    logger.warn(`Error decoding asyncStorage item at key '${key}'`, error);
    return undefined;
  }
};

export const setItem = async (key: string, item: any) => {
  if (!initialized) {
    logger.error('Must call asyncStrorage initAndTestCache before setItem');
    return false;
  }
  if (!asynchCacheWorking) {
    return false;
  }
  try {
    await localForage.setItem(key, item);
    return true;
  } catch (error) {
    logger.warn(`Error setting asyncStorage item '${key}'`, error);
    return false;
  }
};

export const removeItem = (key: string) => localForage.removeItem(key);
