import { createStyles } from '@lsy-netline-ui/netline-ui';

export const EVENT_BORDER_RADIUS = 4;

export default ({ breakpoints, palette }: Theme) => createStyles({
  root: {
    'height': '100%',
    'position': 'relative',
    'display': 'flex',
    'flexDirection': 'column',
    'color': palette.text.primary,
    'cursor': 'pointer',

    '& $emailIcon, & $calendarEventBackground': {
      backgroundColor: palette.calendar.day.backgroundColor,
    },

    // to prevent that event tag goes outside of the background of the cell
    '&:nth-child(7n), &:nth-child(7n+1)': {
      '& $calendarEventBackground': {
        width: 'calc(100% - 1px)',
      },
    },
    '&:nth-child(7n)': {
      '& $calendarEventBackground': {
        alignSelf: 'flex-end',
      },
    },
    '&:nth-child(7n+1)': {
      '& $calendarEventBackground': {
        alignSelf: 'flex-start',
      },
    },
  },
  mobile: {
    '& $eventText': {
      fontSize: 10,
    },
  },
  suspendedDay: {
    '&, & $emailIcon': {
      color: palette.calendar.suspendedDay.textAndIconColor,
    },
    '& $emailIcon, & $calendarEventBackground': {
      backgroundColor: palette.calendar.suspendedDay.backgroundColor,
    },
  },
  noEvent: {},
  outsideBlockMonth: {
    '& $emailIcon, & $calendarEventBackground': {
      backgroundColor: 'transparent',
    },
    '& $dateNumber': {
      color: palette.greyIconColor,
    },
  },
  highlight: {
    '& $emailIcon, & $calendarEventBackground': {
      backgroundColor: palette.calendar.day.selectedDayBackgroundColor,
    },
  },
  highlightInterval: {
    '& $emailIcon, & $calendarEventBackground': {
      backgroundColor: palette.calendar.day.selectedIntervalBackgroundColor,
    },
  },
  outsideRosterPeriod: {
    '&$outsideBlockMonth$noEvent $dateNumber': {
      display: 'none',
    },
  },
  ownColumn: {
    '& $suspLarge svg': {
      fontSize: 'calc(50vw / 12)',
    },
    [breakpoints.up('md')]: {
      '& $dateNumber': {
        padding: 5,
      },
      '& $rosterTags': {
        top: 18,
      },
      '& $rosterTag': {
        height: 6,
      },
    },
  },
  today: {
    '& $calendarEventBackground': {
      border: `2px solid ${palette.common.black}`,
    },
  },
  emailIcon: {
    'position': 'absolute',
    'right': 3,
    'top': 2,
    'width': 14,
    'height': 14,
    'padding': '0 1px',
    'color': palette.text.label,
    'zIndex': 10,

    '& svg': {
      fontSize: 14,
    },
  },
  calendarEventBackground: {
    width: 'calc(100% - 2px)',
    height: 'calc(100% - 2px)',
    alignSelf: 'center',
    boxSizing: 'border-box',
    transition: 'background-color .3s',
  },
  dateNumber: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px 4px',
    fontSize: 12,
    lineHeight: '1em',
    fontWeight: 'bold',
  },
  pairingCount: {
    color: palette.calendar.day.pairingCountColor,
  },
  rosterTags: {
    position: 'absolute',
    top: 16,
    width: '100%',
  },
  rosterTag: {
    position: 'absolute',
    height: 4,
  },
  rtg: {
    backgroundColor: palette.events.rtg.backgroundColor,
  },
  eventTag: {
    'backgroundColor': palette.placeholderColor,
    'color': palette.text.primary,
    'height': '33%',
    'position': 'absolute',
    'bottom': 2,
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
    'zIndex': 2,
    'textDecoration': 'none',
    'pointerEvents': 'none',

    '&$susp': {
      zIndex: 3,
    },
    '&$suspLarge': {
      zIndex: 1,
    },
  },
  eventTextWrapper: {
    width: '100%',
    overflow: 'hidden',
  },
  eventTextWrapperContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  eventIcon: {
    'paddingLeft': 2,
    'paddingRight': 2,

    '& svg': {
      fontSize: 12,
    },
  },
  noSpacing: {},
  eventText: {
    'fontSize': 12,
    'zIndex': 1,
    'paddingLeft': 2,
    'whiteSpace': 'nowrap',

    '&$noSpacing': {
      marginLeft: 0,
    },
  },
  susp: {
    '&:not($suspLarge)': {
      bottom: 2,
      left: 2,
      height: 16,
    },
    '& svg': {
      color: palette.calendar.suspendedDay.textAndIconColor,
      fontSize: 16,
    },
  },
  suspLarge: {
    'width': '100%',

    '& svg': {
      position: 'absolute',
      left: '50%',
      fontSize: 'calc(100vw / 12)',
      transform: 'translate(-50%, -70%)',
    },
  },
  neutral: {
    backgroundColor: palette.events.neutral.backgroundColor,
    color: palette.events.neutral.textColor,
  },
  eventStart: {
    '&:not([data-cropped-at-start])': {
      borderTopLeftRadius: EVENT_BORDER_RADIUS,
      borderBottomLeftRadius: EVENT_BORDER_RADIUS,
    },
  },
  eventEnd: {
    '&:not([data-cropped-at-end])': {
      borderTopRightRadius: EVENT_BORDER_RADIUS,
      borderBottomRightRadius: EVENT_BORDER_RADIUS,
    },
  },
});
