import { createStyles } from '@lsy-netline-ui/netline-ui';

export default () => createStyles({
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 20,
    margin: '20px 16px 16px',
  },
  addNewBtn: {
    marginTop: -6,
    marginRight: -2,
  },
});
