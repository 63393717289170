import { useEffect } from 'react';

import { Service, services } from '../../api';

const useConnectionChange = (crewComEndpoint?: Service) => {
  useEffect(() => {
    const healthCheck = () => {
      // check if PEMS is available
      services.loadPublicConfig();

      // check if CrewCom is available
      if (crewComEndpoint) {
        crewComEndpoint();
      }
    };

    window.addEventListener('offline', healthCheck);
    window.addEventListener('online', healthCheck);

    return () => {
      window.removeEventListener('offline', healthCheck);
      window.removeEventListener('online', healthCheck);
    };
  }, [crewComEndpoint]);
};

export default useConnectionChange;
