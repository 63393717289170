import { CustomerTheme } from './themeTypes';
import createCustomTheme from './createCustomTheme';

const golDefault = createCustomTheme('gol');

const golTheme: CustomerTheme = {
  ...golDefault,
  palette: {
    ...golDefault.palette,
    events: {
      ...golDefault.palette.events,
      rtg: {
        backgroundColor: '#4e4a46',
      },
      sby: {
        backgroundColor: '#009a8b',
        textColor: '#FFFFFF',
      },
      hot: {
        backgroundColor: '#630090',
        textColor: '#ffffff',
      },
      abs: {
        backgroundColor: '#ff7020',
        textColor: '#000000',
      },
      fpr: {
        backgroundColor: '#00324b',
        textColor: '#000000',
      },
      gpr: {
        backgroundColor: '#007895',
        textColor: '#000000',
      },
      susp: {
        backgroundColor: '#FFE579',
        borderColor: '#000000',
        textColor: '#000000',
      },
      vac: {
        backgroundColor: '#db5014',
        textColor: '#FFFFFF',
      },
      sick: {
        backgroundColor: '#f9c78b',
        textColor: '#FFFFFF',
      },
      ground: {
        backgroundColor: '#9A9187',
        textColor: '#FFFFFF',
      },
      sim: {
        backgroundColor: '#007895',
        textColor: '#FFFFFF',
      },
      off: {
        backgroundColor: '#ff7020',
        textColor: '#FFFFFF',
      },
      other: {
        backgroundColor: '#ffb814',
        textColor: '#FFFFFF',
      },
      /*
         * If an event type has no special icon, we use this appearance in duty list view.
         */
      textIcon: {
        backgroundColor: '#ffb814',
        textColor: '#FFFFFF',
      },
    },
  },
};

export default golTheme;
