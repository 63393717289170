import {
  ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Clear as ClearIcon, Search as SearchIcon,
} from '@material-ui/icons';
import {
  makeStyles, InputAdornment, TextField, IconButton,
} from '@lsy-netline-ui/netline-ui';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useConnection } from '@crew-webui/common/hooks';

import Page from '../Page/Page';
import SubmitButton from '../SubmitButton/SubmitButton';
import styles from './SearchTextPage.styles';
import { DesktopHeaderProps } from '../../../consts';

const useStyles = makeStyles(styles);

interface SearchFormProps {
  formId: string;
  headerProps?: DesktopHeaderProps;
  searchLabel: string;
  defaultValue?: string;
  searchPlaceholder: string;
  onSubmit: (value: string) => Promise<any>;
  children: ReactNode;
}

const SearchTextPage = ({
  formId, onSubmit, headerProps, searchLabel, searchPlaceholder, children, defaultValue = '',
} : SearchFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const schema = useMemo(() => (yup.object().shape({
    value: yup.string().min(3).required(),
  })), []);

  const {
    control, handleSubmit, getValues, reset, setValue, formState: { isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const [dataLoading, setDataLoading] = useState(false);
  const { online } = useConnection();

  const doSubmit = useCallback(({ value }: any) => {
    reset({}, { keepValues: true });
    setDataLoading(true);
    return onSubmit(value).then(() => setDataLoading(false));
  }, [onSubmit, reset]);

  useEffect(() => {
    reset({}, { keepValues: true });
    setValue('value', defaultValue);
  }, [setValue, defaultValue, reset]);

  const resetValue = useCallback(() => {
    reset({}, { keepValues: true });
    const rv = { value: '' };
    reset(rv);
    doSubmit(rv);
  }, [reset, doSubmit]);

  const submitButton = useMemo(() => (
    <SubmitButton
      disabled={!isDirty || !isValid || !online}
      form={formId}
      type="submit"
    >
      {t('button.search')}
    </SubmitButton>
  ), [isDirty, isValid, online, formId, t]);

  return (
    <Page
      dataLoaded={dataLoading}
      headerProps={headerProps}
      submitButton={submitButton}
    >
      <form id={formId} onSubmit={handleSubmit(doSubmit)}>
        <Controller
          control={control}
          name="value"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <TextField
              fullWidth
              label={searchLabel}
              onChange={onChange}
              placeholder={searchPlaceholder}
              value={value}
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                endAdornment: !!getValues('value') && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={resetValue}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
            />
          )}
        />

      </form>
      {children}
    </Page>
  );
};

export default SearchTextPage;
