import { Typography, makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import styles from './CardContent.styles';

const useStyles = makeStyles(styles, { name: 'CardContent' });

interface Props {
  aside?: React.ReactNode;
  className?: string;
  content?: React.ReactNode;
  contentProps?: Record<string, any>;
  footer?: React.ReactNode;
  footerProps?: Record<string, any>;
  nowrap?: boolean;
  title?: React.ReactNode;
  titleProps?: Record<string, any>;
}

const CardContent = ({
  aside,
  className,
  content,
  contentProps,
  footer,
  footerProps,
  nowrap,
  title,
  titleProps,
  ...otherProps
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, { [classes.nowrap]: nowrap }, className)}
      {...otherProps}
    >
      <div className={classes.container}>
        {title && (
          <Typography className={classes.text} data-test-id="card-title" variant="h4" {...titleProps}>
            {title}
          </Typography>
        )}
        {content && (
          <Typography className={classes.text} data-test-id="card-content" {...contentProps}>
            {content}
          </Typography>
        )}
        {footer && (
          <Typography className={classes.text} data-test-id="card-footer" variant="caption" {...footerProps}>
            {footer}
          </Typography>
        )}
      </div>
      {aside}
    </div>
  );
};

export default CardContent;
