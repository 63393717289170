import { makeStyles } from '@lsy-netline-ui/netline-ui';
import { Person as PersonIcon } from '@material-ui/icons';
import clsx from 'clsx';

import styles from './OpenPosition.styles';

const useStyles = makeStyles(styles, { name: 'OpenPosition' });

type OpenPositionProps = {
  deadhead?: boolean;
  openPosition: { rank: string; openPositions: number };
};

const OpenPosition = ({ deadhead = false, openPosition }: OpenPositionProps) => {
  const classes = useStyles();
  const { rank, openPositions } = openPosition;

  return (
    <div className={clsx(classes.openPosition, { [classes.deadhead]: deadhead })}>
      <div className={classes.avatar}>
        <PersonIcon />
      </div>
      <div className={classes.assignment}>
        <div className={classes.openPositionDetails}>
          <span>{rank}: {openPositions}</span>
        </div>
      </div>
    </div>
  );
};

export default OpenPosition;
