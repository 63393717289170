import { createStyles } from '@lsy-netline-ui/netline-ui';

import { CALENDAR_SPACING_TOP } from '../../../../consts';

export default ({ palette, spacing }: Theme) => createStyles({
  root: {
    color: palette.text.primary,
  },
  negativeMargin: {
    display: 'flex',
    alignItems: 'center',
    minHeight: CALENDAR_SPACING_TOP,
    marginTop: -spacing(2),
    marginBottom: spacing(1),
  },
  backButton: {
    margin: -spacing(1.5),
    marginRight: spacing(1.5),
    padding: spacing(1.5),
  },
});
