import { ReactNode } from 'react';
import { Typography, makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';

import styles from './CardContainer.styles';

const useStyles = makeStyles(styles, { name: 'CardContainer' });

const CardContainer = ({ children, label, className }: { children: ReactNode; label: string; className?: string }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography className={clsx(classes.label, className)} variant="h6">
        {label}
      </Typography>
      {children}
    </div>
  );
};

export default CardContainer;
