import { useTranslation } from 'react-i18next';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import { ProfileList, ProfileItem } from '@crew-webui/common/view';

import { useHistory } from 'react-router-dom';

import { useFeature } from '@crew-webui/common/hooks';

import { Feature, routePaths, titles } from 'consts';
import { IadpPage } from 'view/components';
import Fieldset from '../Fieldset/Fieldset';
import styles from './MyData.styles';

const useStyles = makeStyles(styles, { name: 'MyData' });

const MyData = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const isFeatureEnabled = useFeature();
  const showPersonalData = isFeatureEnabled(Feature.IDP_MY_DATA_PERSONAL_DATA);
  const showContactInfo = isFeatureEnabled(Feature.IDP_MY_DATA_CONTACT_INFORMATION);
  const showAddressInfo = isFeatureEnabled(Feature.IDP_MY_DATA_ADDRESSES);
  const showIdentityDocs = isFeatureEnabled(Feature.IDP_MY_DATA_IDENTITY_DOCUMENTS);

  const links: ProfileItem[] = [
    {
      'type': 'link',
      'label': t('myData.personalData.title'),
      'data-test-id': 'personal-data-link',
      'to': `${routePaths.myData}/personal`,
      'visible': showPersonalData,
    },
    {
      'type': 'link',
      'label': t('myData.contactInfo.title'),
      'data-test-id': 'contact-info-link',
      'to': `${routePaths.myData}/contacts`,
      'visible': showContactInfo,
    },
    {
      'type': 'link',
      'label': t('myData.addressInfo.title'),
      'data-test-id': 'address-info-link',
      'to': `${routePaths.myData}/addresses`,
      'visible': showAddressInfo,
    },
    {
      'type': 'link',
      'label': t('myData.travelDoc.title'),
      'data-test-id': 'travel-doc-link',
      'to': `${routePaths.myData}/traveldocs`,
      'visible': showIdentityDocs,

    },
  ];

  const subtitle = t('myData.title');

  return (
    <IadpPage
      dataLoaded
      headerProps={{
        showBackButton: true,
        onBack: () => history.push(`${routePaths.profile}`),
        title: t(titles.profile),
        subtitle,
      }}
    >
      <Fieldset className={classes.fieldset} noBorder>
        <ProfileList items={links} />
      </Fieldset>
    </IadpPage>
  );
};

export default MyData;
