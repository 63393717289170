import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { Person as PersonIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import { makeStyles } from '@lsy-netline-ui/netline-ui';

import { useProfile } from '@crew-webui/common/hooks';
import { displayTime } from '@crew-webui/common/utils';

import styles from './CrewMember.styles';

const useStyles = makeStyles(styles, { name: 'CrewMember' });

const attributePlaceholder = '-';
const deadheadPrefix = 'DH ';

interface Flight {
  flightNumber: string;
  destination: string;
  toDateTimeLtAtAirport: string;
  toDateTimeUTC: string;
  fromDateTimeLtAtAirport: string;
  fromDateTimeUTC: string;
}

type CrewMemberProps = {
  checkedIn?: boolean;
  crewMember: {
    attributes?: { key: string; value: string }[];
    name?: string;
    nextEvent?: Flight;
    previousEvent?: Flight;
  };
  deadhead?: boolean;
};

const CrewMember = ({ checkedIn = false, deadhead = false, crewMember }: CrewMemberProps) => {
  const { i18n, t } = useTranslation();
  const {
    profile: {
      attributesCrewMember, dateFormats, showUTCTime, showLocalTime, localTimePostfix, utcTimePostfix,
    },
  } = useProfile();
  const classes = useStyles();
  const {
    name, attributes = [], previousEvent = {}, nextEvent = {},
  } = crewMember;
  const {
    flightNumber: prevFlightNumber = '',
    origin = '',
    toDateTimeLtAtAirport = '',
    toDateTimeUTC = '',
  } = previousEvent as any;
  const {
    flightNumber: nextFlightNumber = '',
    destination = '',
    fromDateTimeLtAtAirport = '',
    fromDateTimeUTC = '',
  } = nextEvent as any;
  const endDate = DateTime.fromISO(showLocalTime ? toDateTimeLtAtAirport : toDateTimeUTC)
    .toUTC();
  const beginDate = DateTime.fromISO(showLocalTime ? fromDateTimeLtAtAirport : fromDateTimeUTC)
    .toUTC();
  const endLocalTime = showLocalTime ? toDateTimeLtAtAirport : '';
  const beginLocalTime = showLocalTime ? fromDateTimeLtAtAirport : '';
  const endTimeUTC = showUTCTime ? toDateTimeUTC : '';
  const beginTimeUTC = showUTCTime ? fromDateTimeUTC : '';

  const displayTimes = (local: string, utc: string) => {
    const localFormatted = displayTime(local, dateFormats.TIME, localTimePostfix);
    const utcFormatted = displayTime(utc, dateFormats.TIME, utcTimePostfix);

    if (local && utc) {
      return `${localFormatted} (${utcFormatted})`;
    }
    return local ? localFormatted : utcFormatted;
  };

  return (
    <div className={clsx(classes.crewMember, { [classes.deadhead]: deadhead })} data-test-id="crew-member-card">
      <div className={classes.avatar} data-test-id="crew-member-picture">
        <PersonIcon />
      </div>
      <div className={classes.assignment}>
        <div className={classes.name} data-test-id="crew-member-name">
          {deadhead && deadheadPrefix}
          {name}
          {checkedIn && <CheckCircleIcon data-test-id="crew-member-checkedin-icon" />}
        </div>
        <div className={classes.crewMemberDetails} data-test-id="crew-member-details">
          {attributes.map((attribute) => (
            <span key={attribute.key}>
              <span className={classes.crewMemberDetailsKey}>
                {`${attributesCrewMember?.[i18n.language]?.[attribute.key] ||
                attributesCrewMember?.[(i18n.options.fallbackLng as any)[0]]?.[attribute.key]}: `}
              </span>
              <span>{`${attribute.value || attributePlaceholder}`}</span>
            </span>
          ))}
        </div>
        <div className={classes.previousEvent} data-test-id="crew-member-previous-event">
          {isEmpty(previousEvent) || t('components.crewMember.previousEvent', {
            origin, prevFlightNumber, endDate, times: displayTimes(endLocalTime, endTimeUTC),
          })}
        </div>
        <div className={classes.nextEvent} data-test-id="crew-member-next-event">
          {isEmpty(nextEvent) || t('components.crewMember.nextEvent', {
            destination, nextFlightNumber, beginDate, times: displayTimes(beginLocalTime, beginTimeUTC),
          })}
        </div>
      </div>
    </div>
  );
};

export default CrewMember;
