import { MouseEvent, TouchEvent, useCallback } from 'react';
import { makeStyles } from '@lsy-netline-ui/netline-ui';
import clsx from 'clsx';
import { DateTime, Interval } from 'luxon';

import { CalendarState } from '../../../../consts';
import {
  useDutiesModel, useDutiesPresentation, useMultiColumn, useShowPairingCount, useShowRosterTags,
} from '../../../../hooks';
import { getEventsForDay, getRosterTagsForDay } from '../../../../utils';
import { activeMonthAttribute } from '../Event/alignEventTags';
import Event from '../Event/Event';
import BlockMonthData from './BlockMonthData';
import styles from './Month.styles';

export const horizontalSpacingBetweenMonths = 8;

export type MonthProps = {
  calendarEventHandlers: {
    onMouseDown: (e: MouseEvent) => void;
    onMouseUp: (e: MouseEvent) => void;
    onTouchStart: (e: TouchEvent) => void;
    onTouchEnd: (e: TouchEvent) => void;
  };
  days: DateTime[];
  index: number;
  rosterPeriodInterval: Interval;
  showDailyRemarks: boolean;
  today: DateTime;
  weekDaysArray: string[];
};

const useStyles = makeStyles(styles, { name: 'Month' });

const Month = ({
  calendarEventHandlers, days, index, rosterPeriodInterval, showDailyRemarks, today, weekDaysArray,
}: MonthProps) => {
  const classes = useStyles();
  const {
    activeBlockMonthIndex,
    blockMonthList,
    dutyList: events,
    openPairingStatistics,
  } = useDutiesModel();
  const { calendarState, highlightedDateInterval } = useDutiesPresentation();
  const multiColumn = useMultiColumn();
  const { pairingCountShown } = useShowPairingCount();
  const { rosterTagsShown } = useShowRosterTags();

  const getOpenPairingCount = useCallback((day: DateTime) => {
    if (!pairingCountShown || openPairingStatistics.length === 0) {
      return 0;
    }
    const dateIso = day.toISODate();
    const stat = openPairingStatistics.find((statistic) => statistic.date === dateIso);
    return stat ? stat.count : 0;
  }, [pairingCountShown, openPairingStatistics]);

  const active = activeBlockMonthIndex === index;
  const blockMonth = blockMonthList[index];
  const blockMonthInterval = blockMonth.from.startOf('day').until(blockMonth.to.endOf('day'));

  const blockMonthDataListed = <BlockMonthData blockMonth={blockMonth} />;

  return (
    <div
      className={clsx(classes.root, { [classes.ownColumn]: multiColumn })}
      style={{
        marginRight: horizontalSpacingBetweenMonths,
        transform: `translateX(calc(${-activeBlockMonthIndex!} * (100% + ${horizontalSpacingBetweenMonths}px)))`,
      }}
    >
      {multiColumn && blockMonthDataListed}

      <div className={classes.weekdayContainer} data-test-id="calendar-weekdays">
        {weekDaysArray.map((weekday) => (
          <div className={classes.weekDay} key={weekday}>
            {weekday}
          </div>
        ))}
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={classes.days}
        {...(active ? { [activeMonthAttribute]: true } : {})}
        onMouseDown={calendarEventHandlers.onMouseDown}
        onMouseUp={calendarEventHandlers.onMouseUp}
        onTouchStart={calendarEventHandlers.onTouchStart}
        onTouchEnd={calendarEventHandlers.onTouchEnd}
      >
        {days.map((day) => (
          <Event
            key={day.toLocaleString()}
            events={active ? getEventsForDay(day, events) : []}
            pairingCount={getOpenPairingCount(day)}
            rosterTags={active && rosterTagsShown ? getRosterTagsForDay(day, events) : []}
            date={day}
            inBlockMonth={blockMonthInterval.contains(day)}
            inRosterPeriod={rosterPeriodInterval.contains(day)}
            showDailyRemarks={showDailyRemarks}
            today={day.hasSame(today, 'day')}
            highlightedDateInterval={highlightedDateInterval}
          />
        ))}
      </div>

      {!multiColumn && calendarState === CalendarState.OPEN && blockMonthDataListed}
    </div>
  );
};

export default Month;
