import { createStyles } from '@lsy-netline-ui/netline-ui';

export default () => createStyles({
  rightSide: {
    paddingLeft: '50%',
  },
  flightIcon: {
    transform: 'rotate(45deg)',
  },
});
