import { createStyles } from '@lsy-netline-ui/netline-ui';

export default () => createStyles({
  '@keyframes open': {
    from: {
      transform: 'translate(0px, 150px)',
    },
    to: {
      transform: 'translate(0px, 0px)',
    },
  },
  'root': {
    zIndex: 998,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  'banner': {
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'fixed',
    backgroundColor: 'white',
    padding: 10,
    bottom: 0,
    margin: 'auto',
    left: 0,
    right: 0,
    boxShadow: '0px -1px 5px grey',
    transition: 'transform ease-in .3s',
    animationName: '$open',
    animationDuration: '0.2s',
  },
  'text': {
    fontSize: 12,
    textAlign: 'justify',
    margin: 5,
  },
  'button': {
    width: '100%',
    margin: 5,
    fontSize: 16,
  },
});
