import { createStyles } from '@lsy-netline-ui/netline-ui';

export default () => createStyles({
  nowrap: {
    whiteSpace: 'nowrap',
  },
  topSpace: {
    marginTop: '10px',
  },
  summary: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
});
