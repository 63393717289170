import { makeStyles, Checkbox, Typography } from '@lsy-netline-ui/netline-ui';

import styles from './NewsSettingsCheckbox.styles';

const useStyles = makeStyles(styles, { name: 'NewsSettingsCheckbox' });

type Props = {
  checked: boolean;
  'data-test-id'?: string;
  disabled: boolean;
  label: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const NewsSettingsCheckbox = ({
  checked, 'data-test-id': dataTestId = 'checkbox', disabled, label, name, onChange,
}: Props) => {
  const classes = useStyles();

  return (
    <Checkbox
      LabelProps={{ 'className': classes.checkboxLabel, 'data-test-id': 'checkbox-label', 'labelPlacement': 'start' }}
      checked={checked}
      className={classes.root}
      data-test-id={dataTestId}
      disabled={disabled}
      label={<Typography className={classes.checkboxLabelText}>{label}</Typography>}
      name={name}
      onChange={onChange}
    />
  );
};

export default NewsSettingsCheckbox;
