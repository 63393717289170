import { MyDataFieldType } from 'consts';
import {
  getAccessProperties,
  getCountryCodeProperties,
  getDateProperties,
  getTextFieldProperties,
  getYesNoProperties,
} from 'utils';

export default (t: T): MyDataFieldType[] => [
  {
    ...getTextFieldProperties(t, { required: true, maxLength: 35 }),
    label: t('myData.fields.travelIdentifier'),
    valuePath: 'natKey.number',
    editOnlyWhenCreating: true,
  },
  {
    ...getTextFieldProperties(t, { required: true, maxLength: 30 }),
    label: t('myData.fields.firstName'),
    valuePath: 'firstName',
  },
  {
    ...getTextFieldProperties(t, { maxLength: 30 }),
    label: t('myData.fields.middleName'),
    valuePath: 'middleName',
  },
  {
    ...getTextFieldProperties(t, { required: true, maxLength: 30 }),
    label: t('myData.fields.lastName'),
    valuePath: 'lastName',
  },
  {
    ...getDateProperties(t, { required: false }),
    label: t('myData.fields.dateOfIssue'),
    valuePath: 'issueDate',
    fieldSize: 3,
  },
  {
    ...getTextFieldProperties(t, { required: false, maxLength: 25 }),
    label: t('myData.fields.placeOfIssue'),
    valuePath: 'issuePlace',
    fieldSize: 3,
  },
  {
    ...getCountryCodeProperties(t),
    label: t('myData.fields.countryOfIssue'),
    valuePath: 'issueCountry',
    fieldSize: 3,
  },
  {
    ...getDateProperties(t),
    label: t('myData.fields.dateOfExpiry'),
    valuePath: 'expiryDate',
    fieldSize: 3,
  },
  getAccessProperties(t),
  {
    ...getTextFieldProperties(t, { maxLength: 20 }),
    label: t('myData.fields.addInfo'),
    valuePath: 'addInfo',
  },
];

export const getVisaFields = (t: T): MyDataFieldType[] => [
  {
    ...getTextFieldProperties(t, { maxLength: 35 }),
    label: t('myData.fields.visaIdentifier'),
    valuePath: 'number',
  },
  {
    ...getDateProperties(t, { dateWithTime: true }),
    label: t('myData.fields.dateOfIssue'),
    valuePath: 'natKey.issueDt',
    fieldSize: 3,
    editOnlyWhenCreating: true,
  },
  {
    ...getTextFieldProperties(t, { maxLength: 25 }),
    label: t('myData.fields.placeOfIssue'),
    valuePath: 'issuePlace',
    fieldSize: 3,
  },
  {
    ...getCountryCodeProperties(t),
    label: t('myData.fields.countryCode'),
    valuePath: 'natKey.countryCode',
    fieldSize: 3,
    editOnlyWhenCreating: true,
  },
  {
    ...getDateProperties(t, { required: true, dateWithTime: true }),
    label: t('myData.fields.dateOfExpiry'),
    valuePath: 'expiryDt',
    fieldSize: 3,
  },
  {
    ...getTextFieldProperties(t, { required: true }),
    ...getYesNoProperties(t),
    label: t('myData.fields.docExists'),
    valuePath: 'docExists',
    fieldSize: 3,
  },
  getAccessProperties(t),
  {
    ...getTextFieldProperties(t, { maxLength: 20 }),
    label: t('myData.fields.addInfo'),
    valuePath: 'addInfo',
  },
];
