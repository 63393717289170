import { useEffect, useMemo } from 'react';

import { logger } from '../../api';
import { formatTime } from '../../utils';
import { NewsStatus, NewsType } from '../../consts';
import { useOnlineAuthorized } from '../common';
import { useProfile } from '../Profile';

import { NewsId, NewsItem } from './NewsContext';
import { useNews } from './NewsProvider';

export const getNewsForListing = (news: NewsItem[], {
  checkInEnabled, deletingIds, notificationsEnabled, profile,
}: {
  checkInEnabled: boolean;
  deletingIds: NewsId[];
  notificationsEnabled: boolean;
  profile: { dateFormats: any; newsListing: any };
}) => news
  .map((newsItem) => ({ ...newsItem, category: newsItem.category?.toUpperCase() }))
  .filter(({ category }) => (
    category === NewsType.Broadcast ||
    (category === NewsType.CheckInAlert && checkInEnabled) ||
    (category === NewsType.RosterChange && notificationsEnabled)
  ))
  .map((newsItem) => {
    let internalMessage: any = {};
    try {
      internalMessage = JSON.parse(newsItem.internalMessage);
    } catch (error) {
      logger.error('internalMessage is not a valid json');
    }

    const profileObject = (() => {
      switch (newsItem.category) {
        case NewsType.Broadcast: return profile.newsListing.message;
        case NewsType.CheckInAlert: return profile.newsListing.checkIn;
        case NewsType.RosterChange: return profile.newsListing.rosterChange;
        default: return {};
      }
    })();

    // as discussed with backend developers, the recipients array always contains exactly 1 item
    const status = newsItem.recipients?.[0]?.status?.toUpperCase();

    return {
      Icon: profileObject?.Icon,
      color: profileObject?.color,
      date: formatTime(newsItem.createdAt, profile.dateFormats.DATE_TIME),
      checkInLogicalId: internalMessage.checkInEventLogicalId,
      id: newsItem.notificationId,
      expired: status === NewsStatus.EXPIRED,
      read: status === NewsStatus.READ,
      sender: newsItem.sender?.name,
      subtitle: internalMessage.body,
      title: internalMessage.subject,
      type: newsItem.category,
      deleting: deletingIds.includes(newsItem.notificationId),
      properties: internalMessage.properties,
    };
  });

export const useNewsListing = ({
  checkInEnabled = false,
  notificationsEnabled = false,
} = {}) => {
  const {
    clearPushNotifications,
    deletingIds,
    hasLoaded,
    hasNotification,
    loadNews,
    markAsRead,
    news,
    removeNewsItem,
    removeNotification,
  } = useNews();
  const { profile } = useProfile();

  // fetch news
  useOnlineAuthorized(() => {
    loadNews();
  }, [loadNews]);

  // remove the notification badge and push notifications when the News page is shown
  useEffect(() => {
    if (hasNotification) {
      removeNotification();
      clearPushNotifications();
    }
  }, [clearPushNotifications, hasNotification, removeNotification]);

  // clear push notifications when user visits News page
  useEffect(() => {
    clearPushNotifications();
    document.addEventListener('resume', clearPushNotifications, false);
    return () => document.removeEventListener('resume', clearPushNotifications, false);
  }, [clearPushNotifications]);

  const newsForListing = useMemo(
    () => getNewsForListing(
      news,
      {
        profile: profile as any,
        deletingIds,
        notificationsEnabled,
        checkInEnabled,
      },
    ),
    [checkInEnabled, news, notificationsEnabled, profile, deletingIds],
  );

  return useMemo(
    () => ({
      hasLoaded,
      markAsRead,
      news: newsForListing,
      removeNewsItem,
    }),
    [hasLoaded, markAsRead, newsForListing, removeNewsItem],
  );
};

export const useBroadcastMessage = (id: NewsId) => {
  const {
    deletingIds, hasLoaded, loadNews, news,
  } = useNews();
  const { profile } = useProfile();

  // fetch news if hasn't loaded yet
  useOnlineAuthorized(() => {
    if (!hasLoaded) {
      loadNews();
    }
  }, [hasLoaded, loadNews]);

  const newsForListing = useMemo(
    () => getNewsForListing(
      news,
      {
        checkInEnabled: false,
        deletingIds,
        notificationsEnabled: false,
        profile: profile as any,
      },
    ),
    [news, profile, deletingIds],
  );

  return useMemo(
    () => ({
      hasLoaded,
      message: newsForListing?.find((newsItem) => newsItem.id === id),
    }),
    [hasLoaded, id, newsForListing],
  );
};

export const useNewsNotification = () => {
  const { hasNotification } = useNews();
  return useMemo(() => ({ hasNotification }), [hasNotification]);
};
