import { createStyles } from '@lsy-netline-ui/netline-ui';

import { CALENDAR_SPACING_TOP } from '@crew-webui/common/consts';

export default () => createStyles({
  content: {
    '&&': {
      paddingTop: CALENDAR_SPACING_TOP,
    },
  },
});
